.write-reviews input, .write-reviews textarea {
    border-radius: 5px;
    width: 100%;
    margin: 0 auto 30px auto !important;
    border: 1px solid #ccc;
    padding: 15px;
}

.text-gray{
    color: gray !important;
}

.write-reviews label {
    font-size: 22px;
   
}

.MuiRating-label  {
    font-size: 36px !important;
}

.write-reviews .btnrating .bi-star-fill {
    color: #e6e6e6;
    font-size: 36px;
}

@media (max-width: 768px) {
    .breadcrumbs .d-flex {
        display: block !important;
    }

    .breadcrumbs ol {
        display: block;
    }

    .breadcrumbs ol li {
        display: inline-block;
    }
}
@media (min-width:320px) and (max-width: 767px) {
    .MuiRating-label  {
        font-size: 22px !important;
    }
    
}
@media (min-width:320px) and (max-width: 374px) {
    .reviewCardText  {
        font-size: 14px !important;
    }
    
}


.top-colleges .test_content_wrp {padding:20px;}

.top-colleges .testi_wrp
{
    padding: 10px;
}
