.h1all {
    margin: 0;
    font-size: 41px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 1px;
  }
  .cblack{
    color: #f3ff43;
  }

  .fh3allcourses{
    color:rgb(195 31 31);
    font-size: 33px !important;
  }
.font-size23{
    font-size: 23px !important;
    
}
.font-white23{
    font-size: 21px !important;
    color: white;
    
}
.font40{
    font-size: 40px !important;
}
.allcourses .card{
    width: 100%;
}
.custompaddingall{
  /* padding-left: 38px; */
    padding-top: 10px;
    float: right;
}
@media only screen and (max-width: 600px) {
    .h1all {
        margin: 0;
        font-size: 33px;
        font-weight: 700;
        color: #ffffff;
        letter-spacing: 1px;
      }
      .font40{
        font-size: 25px !important;
    }
    .font-white23{
      font-size: 18px !important;
      color: white;
      
  }
  .testimonials .testi-item-inner{
    padding: 0px;
  }
  }

