Ensure the react-tel-input container does not allow overflow .react-tel-input {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

/* Style the input to ensure it fits within its container */
.react-tel-input .form-control {
  flex: 1;
  width: -webkit-fill-available !important;
  height: -webkit-fill-available !important;
  margin: 0 0 0 39px !important;
  border-radius: 0px 10px 10px 0px !important;
}

/* Adjust position of the flag dropdown */
.react-tel-input .flag-dropdown {
  position: absolute !important;
  border: 1px solid black !important;
  border-radius: 10px 0px 0px 10px !important;
    color: black !important;
}

/* Center align the flag dropdown vertically */
.react-tel-input .selected-flag {
  top: 50%;
  transform: translateY(-50%);
  border-radius: 10px 0px 0px 10px !important;
}

.react-phone-p-0 input{
  padding-left: 10px !important;
}
