.write-reviews input,
.write-reviews textarea {
  border-radius: 5px;
  width: 100%;
  margin: 0 auto 30px auto !important;
  border: 1px solid #ccc;
  padding: 15px;
}

.write-reviews label {
  font-size: 22px;
}

.write-reviews .btnrating .bi-star-fill {
  color: #e6e6e6;
  font-size: 36px;
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

#share-this .btn-close,
#enquiryformpopup .btn-close,
#globalappdownload .btn-close {
  margin: 15px;
}

#share-this .btn-close:hover,
#enquiryformpopup .btn-close:hover,
#globalappdownload .btn-close:hover {
  background-color: transparent;
}

#share-this .close,
#enquiryformpopup .close,
#globalappdownload .close {
  width: 50px;
  height: 50px;
  background: #fff;
  color: #000;
  font-size: 40px;
  font-weight: normal;
  float: right;
  position: absolute;
  z-index: 9;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  box-shadow: 5px 5px 5px #ccc;
  padding: 0;
  right: -15px;
  top: -15px;
}

#share-this,
#globalappdownload {
  top: 0px;
  max-width: 1024px;
}

#share-this .modal-body {
  padding: 80px;
}

#share-this .modal-body h4 {
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 30px;
  color: #000;
  text-align: center;
}

#share-this .modal-body ul li {
  display: inline-block;
  padding: 0 10px;
}

#share-this .modal-body ul {
  text-align: center;
}

#share-this .modal-body ul li button,
#share-this .modal-body ul li a i {
  background: #fff;
  font-size: 32px;
  box-shadow: 5px 5px 5px #dcdcdc;
  width: 52px;
  height: 52px;
  display: block;
  text-align: center;
  line-height: 52px;
  border-radius: 6px;
}

#share-this .modal-body ul li a.facebook {
  color: #3c6bd7;
}

#share-this .modal-body ul li a.twitter {
  color: #03a9f5;
}

#share-this .modal-body ul li a.pinterest {
  color: #d32f2e;
}

#share-this .modal-body ul li a.linkedin {
  color: #0076b4;
}

#share-this .modal-body ul li a.youtube {
  color: #be0d0c;
}

#enquiryformpopup {
  max-width: 725px;
}

#enquiryformpopup #header-form {
  background: transparent !important;
  color: #000 !important;
}

#enquiryformpopup #header-form label {
  color: #000 !important;
}

#header-form input,
#header-form textarea {
  border: 1px solid black !important;
}

#enquiryformpopup #header-form input,
#enquiryformpopup #header-form textarea {
  color: #000 !important;
}

.left-mobile-app-details {
  float: left;
  width: 50%;
  padding: 20px;
  background: #f0f9fe;
}

.right-exam-alert-details {
  float: right;
  width: 50%;
  padding: 20px;
  background: #fff;
}

.right-exam-alert-details h3 {
  color: #000;
  font-size: 28px;
  margin: 0 0 20px 0;
  font-weight: bold;
  text-align: center;
}

.right-exam-alert-details ul {
  padding: 0px;
  margin: 0;
  text-align: left !important;
}

.right-exam-alert-details ul li {
  color: #000;
}

.right-exam-alert-details ul li {
  display: inline-block !important;
  font-size: 22px;
  padding: 5px !important;
}

.right-exam-alert-details ul li::before {
  content: '\ea41';
  font-family: boxicons !important;
  color: #018ed4;
  font-size: 18px;
  display: inline;
  position: relative;
  left: -8px;
  top: 2px;
  border: 1px solid #018ed4;
  border-radius: 50%;
}

.right-exam-alert-details .col-lg-3.col-md-6 {
  width: 50%;
  float: left;
}

.rgt-app-qrcode img,
.lft-app-icon img {
  width: 80%;
}

.lft-app-icon img {
  margin: 10px 0;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  #enquiryformpopup .close {
    width: 30px;
    height: 30px;
    right: -5px;
    top: -5px;
  }
  #enquiryformpopup .btn-close {
    margin: 5px;
    font-size: 12px;
  }
}

.applyNowButton-editor {
  padding: 2px 7px !important;
  line-height: 30px !important;
  font-size: 15px !important;
}
