@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap);
pre {
  border-radius: 4px;
  background-color: #191c27;
  padding: 16px;
  font-size: 14px;
  margin-bottom: 24px
}

code[class*="language-"],
pre[class*="language-"] {
  color: rgb(191, 199, 213);
  font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
  direction: ltr;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  tab-size: 4;
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}

/* Code blocks */
pre[class*="language-"] {
  padding: 1em;
  margin: .5em 0;
  overflow: auto;
  border-radius: 0.3em;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: #1d1f21;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: .1em;
  border-radius: .3em;
}

.token.prolog {
  color: rgb(0, 0, 128);
}

.token.parameter {
  color: rgb(255, 255, 255);
}

.token.comment {
  color: rgb(106, 153, 85);
}

.token.doctype {
  color: rgb(191, 199, 213);
}

.token.cdata {
  color: rgb(191, 199, 213);
}

.token.punctuation {
  color: rgb(136, 198, 190);
}

.token.property {
  color: rgb(252, 146, 158);
}

.token.tag {
  color: rgb(252, 146, 158);
}

.token.class-name {
  color: rgb(250, 200, 99);
}

.token.boolean {}

.token.constant {
  color: rgb(100, 102, 149);
}

.token.symbol {
  color: rgb(141, 200, 145);
}

.token.deleted {
  color: rgb(141, 200, 145);
}

.token.number {
  color: rgb(181, 206, 168);
}

.token.inserted {
  color: rgb(181, 206, 168);
}

.token.selector {
  color: rgb(215, 186, 125);
}

.token.char {
  color: rgb(209, 105, 105);
}

.token.builtin {
  color: rgb(197, 165, 197);
}

.token.changed {
  color: rgb(197, 165, 197);
}

.token.keyword {
  color: rgb(197, 165, 197);
}

.token.string{
  color: rgb(195, 232, 141);
}

.token.attr-name {
  color: rgb(156, 220, 254);
}

.token.variable {
  color: rgb(156, 220, 254);
}

.token.operator {
  color: #EDEDED;
}

.token.entity {
  color: #FFFFB6;
  cursor: help;
}

.token.url {
  color: #96CBFE;
}

.language-css .token.string,
.style .token.string {
  color: #87C38A;
}

.token.atrule,
.token.attr-value {
  color: #F9EE98;
}

.token.function {
  color: rgb(121, 182, 242)
}

.token.regex {
  color: #E9C062;
}

.token.important {
  color: #fd971f;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: "Poppins", sans-serif;
  color: #444;
}

a {
  color: #428bca;
  text-decoration: none;
}

a:hover {
  color: #9eccf4;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.font-primary {
  font-family: "Poppins", sans-serif;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/

.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  background: #428bca;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #629fd3;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }

  
}


/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
    background: #00A3F4;
    font-size: 17px;
    height: 40px;
    padding: 0;
  }
  
  #topbar .contact-info a {
    line-height: 0;
    color: #fff;
    transition: 0.3s;
  }
  
  #topbar .contact-info a:hover {
    color: #fff;
  }
  
  #topbar .contact-info i {
    color: #fff;
    line-height: 0;
    margin-right: 5px;
  }
  
  #topbar .contact-info .phone-icon {
    margin-left: 15px;
  }
  
  #topbar .social-links a {
    color: #fff;
    padding: 4px 12px;
    display: inline-block;
    line-height: 1px;
    transition: 0.3s;
  }
  
  #topbar .social-links a:hover {
    color: #fff;
  }
  .top-links ul{margin:0; float:right;}
  .top-links ul li{display:inline; padding:0 15px; line-height:20px; border-right:1px solid #fff;}
  .top-links ul li:last-child{border:0px;}
  .top-links ul li a{color:#fff; font-size:17px;}
  .top-links ul li a:hover{color:#fa9b24;}
  
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  #header {
    height:100px;
    background:rgba(255, 255, 255, 50%) !important;
    z-index: 997;
    position:absolute; width:100%;
  }
  
  
  #header .logo img {
    padding: 0;
    margin: 0;
    max-height:58px;
  }
  
  .scrolled-offset {
    margin-top: 70px;
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /**
  * Desktop Navigation 
  */
  .navbar {
    padding: 0;
  }
  
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  
  .navbar li {
    position: relative;
  }
  
  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 15px;
    font-size: 18px;
    color: #000000; font-weight:600;
    white-space: nowrap;
    transition: 0.3s;
  }
  
  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }
  
  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    color: #fff;
    background:#00a3f4; border-radius:5px;
  }
  
  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: -50px; /*14px;*/
    top: 100%;
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;/*#00a3f4;*/
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
  }
  
  .navbar .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar .dropdown ul a {
    padding: 10px 20px;
    text-transform: none;
  }
  
  .navbar .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbar .dropdown ul a:hover,
  .navbar .dropdown ul .active:hover,
  .navbar .dropdown ul li:hover>a {
    color: #fff;
  }
  
  .navbar .dropdown:hover>ul {
    opacity: 1;
    visibility: visible;
  }
  
  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }
  
  .navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
  
  @media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
      left: -90%;
    }
  
    .navbar .dropdown .dropdown:hover>ul {
      left: -100%;
    }
  }
  
  /**
  * Mobile Navigation 
  */
  .mobile-nav-toggle {
    color: #1f3548;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
  }
  
  .mobile-nav-toggle.bi-x {
    color: #fff;
  }
  
  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
      color:black;
      font-size: 39px;
    }
  
    .navbar ul {
      display: none;
    }
  }
  
  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(31, 53, 72, 0.9);
    transition: 0.3s;
    z-index: 998;
  }
  
  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  
  .navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
  }
  
  .navbar-mobile a,
  .navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #1f3548;
  }
  
  .navbar-mobile a:hover,
  .navbar-mobile .active,
  .navbar-mobile li:hover>a {
    color: #428bca;
  }
  
  .navbar-mobile .getstarted,
  .navbar-mobile .getstarted:focus {
    margin: 15px;
  }
  
  .navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }
  
  .navbar-mobile .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar-mobile .dropdown ul a {
    padding: 10px 20px;
  }
  
  .navbar-mobile .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbar-mobile .dropdown ul a:hover,
  .navbar-mobile .dropdown ul .active:hover,
  .navbar-mobile .dropdown ul li:hover>a {
    color: #428bca;
  }
  
  .navbar-mobile .dropdown>.dropdown-active {
    display: block;
  }


  .broucher {
    display: block;
    position: fixed;
    width: 80px;
    z-index: 994;
    top: 30%;
    cursor: pointer;
}

.broucher.down {
  
  width: 65px;

  left:-10px;
}

  .broucher img {
    width: 100%;
}





.MuiInputLabel-outlined.MuiInputLabel-shrink {
   transform: translate(14px, -10px) scale(0.75) !important; 
   background: #FFF;
  padding: 0px 6px;
}


.courseli{
  position: relative;

}


.dropdown-hover:hover>.dropdown-menu {
  display: inline-block;
  }
  
  .dropdown-hover>.dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
    /*pointer-events: none;*/
  }


  .dropdown-menu{
    position: absolute;
    left: -30%;
  }

  .dropdown-menu .list-group-item-action{
    color: #343434;
    font-size: 14px;
    padding: 5px 5px;
  }

  .navbar-expand-lg .navbar-nav{
    grid-gap: 16px;
    gap: 16px;
  }
  
  .mainnav .active{

  }

  .mainnav .active:hover{

  }

  .navbar-nav .nav-link.active{
    color: #fff;
  }


  .mainnav .list-group-item-action:focus{
    font-size: 14px;
    padding: 8px 5px;
  }

.groupcont{
  display: block;
}

.groupcont .list-group-item-action{
  width: 24%;
  float: left;
  margin: 0 0.25rem;
  padding: 8px 5px;
}

span.menu-line {
  height: 3px;
  width: 24px;
  background: #000000;
  display: block;
  margin-bottom: 5px;
}

span.menu-line:last-child {
  width: 16px;
}

.new_hamgur {
  cursor: pointer;
  right: -20px;
}

.secondnavbar a{
  color: #000 !important;
  margin: 5px;
  font-size: 14px;
}

.secondnavbar a:hover{
  color: #fff !important;;
}

.top-blogs .col-xl-6 {

  padding: 0;
  width:46%;
  background: #fff;
  float: left;

  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 13px hsl(0deg 0% 80% / 80%);
  margin: 0 4% 30px 0;

}

@media only screen and (max-width: 425px) {
  
}

@media only screen and (max-width: 1024px) {
  .inner-top-header .top-course-header h1,.inner-top-header .top-college-header h1
  {

    font-size:20px !important;
  }

  .inner-top-header .top-college-header h1 img{
    width: 60px !important;
  }

  .inner-top-header .top-college-header ul li{
    font-size:17px !important;
  }
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  .blog-post-entrancenews
  {    width: 100% !important;

  }
  #hero.inner-top-header
  {
      min-height: auto !important;
  }
  .inner-top-header .top-course-header h1,.inner-top-header .top-college-header h1
  {

    font-size:20px !important;
  }
.inner-top-header .top-college-header h1
  {
  line-height: 28px !important;
  }
  .hero_explore
  {
    padding-bottom: 16px;
  }

  .inner-top-header .top-college-header ul li
  {
    font-size: 16px !important;
    line-height: 32px !important;

  }

  .right-exam-alert-details,.left-exam-alert-details
  {
width: 100% !important;
  }
 
  .right-exam-alert-details ul li
  {
    font-size: 19px !important;
  }

  .top-blogs .col-xl-6 {

  
    width:auto;
    margin: 15px;
  
  }

  .custcrowseritem.carousel-caption h3
  {

    font-size: 18px !important;
  }

.college-locations .google-map 
{margin-top: 30px;
}
 
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  
  .f-app-links a {width: 131px;}
  .download-app{padding: 0px 0px 15px; margin-top: 20px;}
  #footer .footer-top .footer-links{margin-bottom: 20px;}
  /* #footer .footer-top h4{} */
  .banner_bottom .box_set{ float:unset !important; margin: 0 auto; margin-bottom: 50px; margin-top: 50px;}
  .mb_app_0{ width:150px;}
  .app_set_head{    width: 300px;
    margin: 0 auto;}
  #hero h2 {
    font-size: 28px;
    margin-top: 40px;
    text-align: center;
}
  .hero_spc_top {
    margin-top: 15px;
    margin-left: 17px; padding-left: 15px;
}
  #topbar{display:none !important;}
  .mobile-nav-toggle {
    color:black;}
  #navbar .nav-link.active{color:#fff;}
  #navbar .nav-link.active:focus{color:#fff;}
  .navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover>a {
    color: #fff !important;}
  .signUp .hero_spc_top{padding-left:0px; margin-left: 0px;}

  .inner-top-header .top-course-header h1, .inner-top-header .top-college-header h1
  {
    font-size:28px !important;
  }

  .inner-top-header .top-college-header ul li{
    display: block !important;
  }

  .reviews-button{
    margin: 5px 0px !important;
  }
  
}

.menuhide{
  display: none !important;
}

.all-colleges-admission h2
{
  text-transform:inherit !important;
}

.bs-editor-text table {
  max-width: 100%;
  /* width: 100%!important; */
  
  /* overflow-x: auto; */
  /* display: block */
}

.bs-editor-text table td {
border: 1px solid;
}
/* .bs-editor-text ul {
margin-left: 36px !important;
} */



@media only screen and (max-width: 768px) {

  .bs-editor-text  p iframe {
    width: 100% !important;
    height: 242px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


.write-reviews input,
.write-reviews textarea {
  border-radius: 5px;
  width: 100%;
  margin: 0 auto 30px auto !important;
  border: 1px solid #ccc;
  padding: 15px;
}

.write-reviews label {
  font-size: 22px;
}

.write-reviews .btnrating .bi-star-fill {
  color: #e6e6e6;
  font-size: 36px;
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

#share-this .btn-close,
#enquiryformpopup .btn-close,
#globalappdownload .btn-close {
  margin: 15px;
}

#share-this .btn-close:hover,
#enquiryformpopup .btn-close:hover,
#globalappdownload .btn-close:hover {
  background-color: transparent;
}

#share-this .close,
#enquiryformpopup .close,
#globalappdownload .close {
  width: 50px;
  height: 50px;
  background: #fff;
  color: #000;
  font-size: 40px;
  font-weight: normal;
  float: right;
  position: absolute;
  z-index: 9;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  box-shadow: 5px 5px 5px #ccc;
  padding: 0;
  right: -15px;
  top: -15px;
}

#share-this,
#globalappdownload {
  top: 0px;
  max-width: 1024px;
}

#share-this .modal-body {
  padding: 80px;
}

#share-this .modal-body h4 {
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 30px;
  color: #000;
  text-align: center;
}

#share-this .modal-body ul li {
  display: inline-block;
  padding: 0 10px;
}

#share-this .modal-body ul {
  text-align: center;
}

#share-this .modal-body ul li button,
#share-this .modal-body ul li a i {
  background: #fff;
  font-size: 32px;
  box-shadow: 5px 5px 5px #dcdcdc;
  width: 52px;
  height: 52px;
  display: block;
  text-align: center;
  line-height: 52px;
  border-radius: 6px;
}

#share-this .modal-body ul li a.facebook {
  color: #3c6bd7;
}

#share-this .modal-body ul li a.twitter {
  color: #03a9f5;
}

#share-this .modal-body ul li a.pinterest {
  color: #d32f2e;
}

#share-this .modal-body ul li a.linkedin {
  color: #0076b4;
}

#share-this .modal-body ul li a.youtube {
  color: #be0d0c;
}

#enquiryformpopup {
  max-width: 725px;
}

#enquiryformpopup #header-form {
  background: transparent !important;
  color: #000 !important;
}

#enquiryformpopup #header-form label {
  color: #000 !important;
}

#header-form input,
#header-form textarea {
  border: 1px solid black !important;
}

#enquiryformpopup #header-form input,
#enquiryformpopup #header-form textarea {
  color: #000 !important;
}

.left-mobile-app-details {
  float: left;
  width: 50%;
  padding: 20px;
  background: #f0f9fe;
}

.right-exam-alert-details {
  float: right;
  width: 50%;
  padding: 20px;
  background: #fff;
}

.right-exam-alert-details h3 {
  color: #000;
  font-size: 28px;
  margin: 0 0 20px 0;
  font-weight: bold;
  text-align: center;
}

.right-exam-alert-details ul {
  padding: 0px;
  margin: 0;
  text-align: left !important;
}

.right-exam-alert-details ul li {
  color: #000;
}

.right-exam-alert-details ul li {
  display: inline-block !important;
  font-size: 22px;
  padding: 5px !important;
}

.right-exam-alert-details ul li::before {
  content: '\ea41';
  font-family: boxicons !important;
  color: #018ed4;
  font-size: 18px;
  display: inline;
  position: relative;
  left: -8px;
  top: 2px;
  border: 1px solid #018ed4;
  border-radius: 50%;
}

.right-exam-alert-details .col-lg-3.col-md-6 {
  width: 50%;
  float: left;
}

.rgt-app-qrcode img,
.lft-app-icon img {
  width: 80%;
}

.lft-app-icon img {
  margin: 10px 0;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  #enquiryformpopup .close {
    width: 30px;
    height: 30px;
    right: -5px;
    top: -5px;
  }
  #enquiryformpopup .btn-close {
    margin: 5px;
    font-size: 12px;
  }
}

.applyNowButton-editor {
  padding: 2px 7px !important;
  line-height: 30px !important;
  font-size: 15px !important;
}



.header-form h2{color: #fff;
  font-size:24px; margin-bottom:20px;
  font-weight: 700;}

.download-app{padding:15px 0;}
.download-app img{margin:0 10px 0 0}

#header-form{background:rgba(0,0,0,0.6); color:#fff; border:1px solid #fff; padding:30px; border-radius:30px;}

#header-form label{font-size:17px; margin-bottom:10px; color:#fff;}
#header-form .form-group{margin-bottom:15px;}

#header-form input, #header-form textarea{background:rgba(255,255,255,0.4); color:#fff !important; padding:10px; border-radius:10px;}

::-webkit-input-placeholder,
:-ms-input-placeholder,:-moz-placeholder,::-moz-placeholder
::placeholder, :-ms-input-placeholder,
input::placeholder {
    font-weight: bold;
    opacity: .5;
    color: red;
}
#header-form .form-control{border:0px;}




#header-form input, #header-form textarea {
  background: rgba(255,255,255,0.4);
  color: #fff !important;
  padding: 10px;
  border-radius: 10px;
}
input::-webkit-input-placeholder { /* Edge */
  color: #ccc !important;
}


.submit_message {
  background: #00A3F4;
  border-radius: 15px;
  width: 171px;
  height: 56px;
  border: 0px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.small_submit_message {
  background: #00A3F4;
  border-radius: 15px;
  width: 171px;
  height: 50px;
  border: 0px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.mrb{
  margin-bottom: 15px !important;
}
.error{
  color:red !important;
}
#inputID:-ms-input-placeholder {
  color:#c5c8cc;
  opacity: 1;
}
#inputID::placeholder {
  color:#c5c8cc;
  opacity: 1;
}






Ensure the react-tel-input container does not allow overflow .react-tel-input {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

/* Style the input to ensure it fits within its container */
.react-tel-input .form-control {
  flex: 1 1;
  width: -webkit-fill-available !important;
  height: -webkit-fill-available !important;
  margin: 0 0 0 39px !important;
  border-radius: 0px 10px 10px 0px !important;
}

/* Adjust position of the flag dropdown */
.react-tel-input .flag-dropdown {
  position: absolute !important;
  border: 1px solid black !important;
  border-radius: 10px 0px 0px 10px !important;
    color: black !important;
}

/* Center align the flag dropdown vertically */
.react-tel-input .selected-flag {
  top: 50%;
  transform: translateY(-50%);
  border-radius: 10px 0px 0px 10px !important;
}

.react-phone-p-0 input{
  padding-left: 10px !important;
}

.fa-x-twitter:before {
  content: "\e61b";
  font-size: 15px !important;
}


.fFooter#footer {
  background: #4e5763;
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;
  font-family: 'Poppins';
}

.fFooter#footer .footer-top {
  /* background: #101010; */
  background: #37475a;
  border-top: 1px solid #101010;
  border-bottom: 1px solid #101010;
  padding: 60px 0 30px 0;
}

.fFooter#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

.fFooter#footer .footer-top .footer-info p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: 'Poppins';
  font-weight: 300;
  color: #ffffff;
  margin-top: 20px;
}

.fFooter#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

.fFooter#footer .footer-top h4 {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}

.fFooter#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.fFooter#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}
.fFooter#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.fFooter#footer .footer-top .footer-links ul li img {
  margin: 3px 10px 0 0px;
}

.fFooter#footer .footer-top .footer-links ul a {
  color: #ffffff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
  font-size: 15px;
  font-weight: 300;
}

.fFooter#footer .footer-top .footer-links ul a:hover {
  color: #9eccf4;
}
.fFooter .footer-newsletter p {
  color: #ffffff;
  margin-bottom: 0px;
}

.fFooter .f-appa a {
  width: 121px;
  display: block;
  float: left;
  margin-right: 15px;
}

.fFooter .f-appa a img {
  width: 100%;
}

.fFooter#footer .footer-top .footer-newsletter form {
  margin-top: 10px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 10px;
}

.fFooter#footer .footer-top .footer-newsletter form input[type=email] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
}

.fFooter#footer .footer-top .footer-newsletter form input[type=submit] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #00A3F4;
  color: #fff;
  transition: 0.3s;
  border-radius: 7px;
  margin: 4px;
}

.fFooter .call_ul_list {
  display: block;
  float: left;
  margin: 0px;
  padding: 0px;
  font-size: 16px;
  font-weight: 300;
  color: #ffffffbd;
}

.fFooter .call_ul_list li {
  float: left;
  margin: 0px 10px 0 0px !important;
  padding: 0px !important;
}

.fFooter .download_b {
  background: #FFFFFF;
  border-radius: 6px;
  padding: 9px 13px;
  font-size: 14px;
  color: #000;
  font-weight: 600;
  margin-top: 8px;
  display: flex;
  float: left;
  line-height: 16px;
  align-items: center;
  cursor: pointer;
}

.f-app-links a {
  width: 221px;
  display: block;
  margin: 0 10px;
}

.f-app-links a img {
  width: 100%;
}
.download-app img {
  margin: 0 10px 0 0;
}

#footer .footer-top .social-links a {
  font-size: 18px !important;
  display: inline-block;
  color: #ffffff;
  line-height: 1;
  padding: 0px 3px;
  margin-right: 4px;
  text-align: center;
  /* width: 36px; */
  /* height: 36px; */
  transition: 0.3s;
}

.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 25px;
  bottom: 80px;
  background: #428bca;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #629fd3;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

#footer .copyright {
  text-align: center;
  padding-top: 30px;    font-size: 14px;color:#ffffffbd;
    font-weight: 300;
}



@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .f-app-links a {width: 131px;}
}

.whatsapplink{
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 15px;
  right: 15px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 45px;
  box-shadow: 2px 2px 3px #999;
  z-index: 1059;
}


#hero {
  width: 100%;
  padding: 140px 0 50px 0;
  overflow: hidden;
  /* background:url('src/assets/img/slide-1.jpg'); */
}


.header-form h2 {
  color: #fff;
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 700;
}

.download-app {
  padding: 15px 0;
}

.download-app img {
  margin: 0 10px 0 0
}

#header-form {
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  border: 1px solid #fff;
  padding: 30px;
  border-radius: 30px;
}

#header-form label {
  font-size: 17px;
  margin-bottom: 10px;
  color: #fff;
}

#header-form .form-group {
  margin-bottom: 15px;
}

#header-form input,
#header-form textarea {
  background: rgba(255, 255, 255, 0.4);
  color: #fff !important;
  padding: 10px;
  border-radius: 10px;
}

::-webkit-input-placeholder,
:-ms-input-placeholder,
:-moz-placeholder,
::-moz-placeholder::placeholder,
:-ms-input-placeholder,
input::placeholder {
  font-weight: bold;
  opacity: .5;
  color: red;
}

#header-form .form-control {
  border: 0px;
}

#header-form input::-moz-placeholder {
  color: rgb(206, 196, 196) /* Your desired color */
}

/*
#hero .carousel-item {
  width: 100%;
  height: calc(100vh - 110px);
  background-size: cover;
  background-position: top right;
  background-repeat: no-repeat;
  overflow: hidden;
}

#hero .carousel-item::before {
  content: "";
  background-color: rgba(13, 30, 45, 0.2);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

#hero .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

#hero .carousel-content {
  text-align: left;
}

@media (max-width: 992px) {

  #hero,
  #hero .carousel-item {
    height: calc(100vh - 70px);
  }

  #hero .carousel-content.container {
    padding: 0 50px;
  }
}
*/


#hero h3 {
  color: #fff;
  margin-bottom: 14px;
  font-size: 48px;
  font-weight: 900;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
}

#hero p {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* or 211% */


  color: #FFFFFF;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  animation-delay: 0.4s;
}

#hero .carousel-inner .carousel-item {
  transition-property: opacity;
  background-position: center top;
}

.hero-app {
  width: 100%;
  display: block;
  float: left;
}

.hero-app img {
  width: 100%;
  display: block;
  float: left;
}

#hero .carousel-inner .carousel-item,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
  opacity: 0;
}

#hero .carousel-inner .active,
#hero .carousel-inner .carousel-item-next.carousel-item-start,
#hero .carousel-inner .carousel-item-prev.carousel-item-end {
  opacity: 1;
  transition: 0.5s;
}

#hero .carousel-inner .carousel-item-next,
#hero .carousel-inner .carousel-item-prev,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
  left: 0;
  transform: translate3d(0, 0, 0);
}

#hero .carousel-control-prev,
#hero .carousel-control-next {
  width: 10%;
}

#hero .carousel-control-next-icon,
#hero .carousel-control-prev-icon {
  background: none;
  font-size: 48px;
  line-height: 1;
  width: auto;
  height: auto;
}

#hero .carousel-indicators li {
  cursor: pointer;
}

#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 5px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #fff;
  animation-delay: 0.8s;
  border: 0;
  background: #428bca;
}

#hero .btn-get-started:hover {
  background: #1c5c93;
}

@media (max-width: 768px) {
  #hero h2 {
    font-size: 28px;
  }
}


@media (min-width: 1024px) {
  #hero p {
    width: 60%;
  }

  #hero .carousel-control-prev,
  #hero .carousel-control-next {
    width: 5%;
  }
}

.hero_explore {
  width: 75%;
  display: block;
  float: left;
  position: relative;
}

.hero_explore .ex-field {
  width: 100%;
  display: block;
  float: left;
  background: #FFFFFF;
  box-shadow: 0px 8px 20px rgb(0 0 0 / 14%);
  border: 0px;
  border-radius: 10px;
  height: 64px;
  padding: 0 163px 0 40px;
}

.hero_bullets {
  width: 20px;
  display: block;
  margin-left: -5%;
  color: #fff;
  top: 10%;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.hero_explore img {
  position: absolute;
  z-index: 1;
  left: 13px;
  top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

#header-form input,
#header-form textarea {
  background: rgba(255, 255, 255, 0.4);
  color: #fff !important;
  padding: 10px;
  border-radius: 10px;
}

input::-webkit-input-placeholder {
  /* Edge */
  color: #ccc !important;
}

.hero_spc_top {
  margin-top: 155px;
}

.submit_message {
  background: #00A3F4;
  border-radius: 15px;
  width: 171px;
  height: 56px;
  border: 0px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}


.crslCont {}

.custcrowseritem {}

.custcrowseritem.carousel-caption {
  /*right: 0; */
  /* width: 50%;
  text-align: left; */
  left: 1%;
  /* top: 22%; */
  /* bottom: 5%;*/
  padding-bottom: 20px;
}

.custcrowseritem.carousel-caption h3 {
  color: #fff;
  margin-bottom: 14px;
  font-size: 40px;
  font-weight: 900;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 30%);
}

.custcrowseritem.carousel-caption p {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #FFFFFF;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 30%);
  animation-delay: 0.4s;
  width: 70%;
  text-align: justify;
}

.crslCont {
  position: relative;
}

.custSearch.hero_explore {
  /* width: auto; */
}

.maskSection {
  position: absolute;
  /*z-index: 888;*/
  top: 12%;
  width: 100%;
}

.frmconts {
  position: relative;
}


.frmconts .ex-field {
  width: 100%;
  display: block;
  float: left;
  background: #FFFFFF;
  box-shadow: 0px 8px 20px rgb(0 0 0 / 14%);
  border: 0px;
  border-radius: 10px;
  height: 64px;
  padding: 0 163px 0 40px;
}

.sidebarsearch {
  width: 95% !important;
  display: block;
  float: left;
  position: relative;
}

.sidebarsearch .ex-submit {
  background: #00A3F4;
  border-radius: 10px;
  width: 151px;
  height: 56px;
  position: absolute;
  top: 4px;
  right: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #FFFFFF;
  border: 0px;
}

.sidebarsearch img {
  position: absolute;
  z-index: 1;
  left: 13px;
  top: 20px;
}

.sidebarsearch {
  margin-top: 310px;
  z-index: 8888;
}


@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .hero_explore {
    width: 100% !important;
  }
}











@media only screen and (min-device-width:992px) and (max-device-width:1140px) {

  #header {
    background: #000;
    position: relative;
  }


}



@media only screen and (min-device-width:480px) and (max-device-width:767px) {

  #header {
    background: #000;
    position: relative !important;
  }

  .sidebarsearch {
    margin-top: 0 !important;
  }

  .maskSection {
    position: relative !important;
    background: #2e2e2e !important;
    padding: 20px 0 !important;
  }

  #header-form {
    margin-top: 0px !important;
  }

  .banner_bottom {
    margin-top: 0 !important;
  }

  .owl-theme .owl-dots .owl-dot span {
    display: none;
  }

  .our_portal .owl-theme .owl-dots .owl-dot {
    width: 15px !important;
    height: 15px !important;
  }

  .why_choose h2 {
    font-size: 32px;
  }

  .our_portal .owl-theme .owl-dots .owl-dot {
    width: 15px !important;
    height: 15px !important;
    margin: 25px 2px 0;
  }

  .owl-carousel .item .full_wdth {
    width: 50%;
    margin: 0 !important;
  }


  .carousel-caption {
    right: 2%;
    bottom: 24%;
    left: 2%;
    text-align: center;
  }

  .custcrowseritem.carousel-caption h3 {
    font-size: 28px !important;
    background: rgba(0, 0, 0, 0.5) !important;
    margin: 0 auto;
  }



}



@media only screen and (min-device-width:320px) and (max-device-width:480px) {

  #header {
    background: #000;
    position: relative !important;
  }

  .sidebarsearch {
    margin-top: 0 !important;
  }

  .maskSection {
    position: relative !important;
    background: #2e2e2e !important;
    padding: 20px 0 !important;
  }

  #header-form {
    margin-top: 0px !important;
  }

  .banner_bottom {
    margin-top: 0 !important;
  }

  .owl-theme .owl-dots .owl-dot span {
    display: none;
  }

  .our_portal .owl-theme .owl-dots .owl-dot {
    width: 15px !important;
    height: 15px !important;
  }

  .why_choose h2 {
    font-size: 32px;
  }

  .our_portal .owl-theme .owl-dots .owl-dot {
    width: 15px !important;
    height: 15px !important;
    margin: 25px 2px 0;
  }

  .owl-carousel .item .full_wdth {
    /*width: 50%;*/
    margin: 0 !important;
  }

  .carousel-caption {
    right: 2%;
    bottom: 24%;
    left: 2%;
    text-align: center;
  }

  .custcrowseritem.carousel-caption h3 {
    font-size: 28px !important;
    background: rgba(0, 0, 0, 0.5) !important;
    margin: 0 auto;
  }

  #enquiryformpopup #header-form {
    padding: 10px;
    zoom: 0.7;
  }

  .right-content-widgets {
    float: right;
    width: 100% !important;
    padding: 0px !important;
  }

  #lefttabs-block, .left-tabs-nav-widgets {
    padding: 0px !important;
  }
  
  .right-content-widgets .tab-pane ul li h4 a{
    font-size: 16px !important;
  }

  .top-colleges .col-xl-4{
    padding: 0px 5px !important;
  }

  .top-colleges{
    padding: 0px !important;
  }

  .schoolbox.topcollege-box img{
    float: left !important;
  }

  #lefttabs-block{
    margin-top: 10px !important;
  }

  #share-this .modal-body{
    padding: 20px;
  }

  #share-this .modal-body ul{
    padding-left: 0px;
  }

  #share-this .modal-body ul li {
    padding: 0 4px;
  }

  .get-details-now-form{
    padding: 10px !important;
  }

  .coming-exam-block img {
    width: 115px !important;
  }

  .blogoverviewsection{
    padding: 15px 0px;
  }
}

@media only screen and (min-device-width:320px) and (max-device-width:424px) {
  .container, .container-md, .container-sm{
    max-width: 90%;
  }

  .features-infra ul li h3{
    font-size: 15px !important;
  }

  .interested-in-course {
    padding: 70px 20px !important;
  }

  .interested-in-course p {
    font-size: 15px !important;
  }

  .interested-in-course h2 {
    font-size: 25px !important;
  }

  .upcomingexamlist h2{
    font-size: 23px !important;
  }

  
}



.broucher {
  width: 55px;
}

/* .form-control::placeholder {
  color: #fff !important;
} */

.broucher.down {
  width: 65px;
  left: -55px;
}

.mh1000{
  min-height: auto;
}


@media only screen and (max-device-width:1440px) {
  ul.secondnavbar{
    left: -125px !important;
  }
}

@media only screen and (max-device-width:1350px) {

  .interested-in-course h2{
    font-size: 30px !important;
  }

  .interested-in-course{
    padding: 90px 20px !important;
  }

  .sticky-container{
    top: 22% !important;
    left: 10px !important;
  }
}

@media only screen and (max-device-width:1024px) {

  .interested-in-course h2{
    font-size: 27px !important;
  }

  .interested-in-course{
    padding: 60px 20px !important;
  }

  .maskSection {
    top: 0%;
  }
}

@media only screen and (max-device-width:768px) {
  .mh1000{
    min-height: 1050px;
  }
  .interested-in-course h2{
    font-size: 27px !important;
  }

  .interested-in-course{
    padding: 100px 20px !important;
  }

  .college-location h2{
    font-size: 27px !important;
  }
  
  .college-location p{
    font-size: 19px !important;
  }

  .sticky-container{
    position: static !important;
    position: initial !important;
  }

  .sticky-container .sticky{
    display: inline-flex !important;
  }
  
  .sticky li{
    padding: 10px !important;
  }

  .maskSection {
    top: 10%;
  }

  .homepageslider .carousel-item {
    height: auto !important;
  }

  .homepageslider .custcrowseritem.carousel-caption{
    display: none !important;
  }
}

@media only screen and (max-device-width:425px) {
  .mh1000{
    min-height: 930px;
  }
  #hero.inner-top-header{
    padding: 80px 0 100px 0 !important;
  }
  .sticky-container{
    top: 22% !important;
    left: 20px !important;
  }

  .wrapblogdetailcontent img{
    width: 100% !important;
  }

  .homepageslider .carousel-item {
    height: auto !important;
  }
}


.homepageslider .carousel-item{
  height: 650px ;
  z-index: 889;
}



.maskSection .header-form {
  /*z-index: 8888888;*/
  z-index: 8888;
}

.homepageslider .carousel-control-prev{
  z-index: 555555;
}

.homepageslider .carousel-control-next{
  z-index: 555555;
}



.header-form h2{color: #fff;
  font-size:24px; margin-bottom:20px;
  font-weight: 700;}

.download-app{padding:15px 0;}
.download-app img{margin:0 10px 0 0}

#header-form{background:rgb(255 255 255 / 54%); color:#fff; border:1px solid #fff; padding:30px; border-radius:30px;}

#header-form label{font-size:17px; margin-bottom:10px; color:#fff;}
#header-form .form-group{/*margin-bottom:15px;*/}

#header-form input, #header-form textarea{background:#545454c7; color:#fff !important; padding:10px; border-radius:10px;}

::-webkit-input-placeholder,
:-ms-input-placeholder,:-moz-placeholder,::-moz-placeholder
::placeholder, :-ms-input-placeholder,
input::placeholder {
    font-weight: bold;
    opacity: .5;
    color: red;
}
#header-form .form-control{border:0px;}




#header-form input, #header-form textarea {
  background: 545454c7;
  color: #fff !important;
  padding: 10px;
  border-radius: 10px;
}
input::-webkit-input-placeholder { /* Edge */
  color: #ccc !important;
}


.submit_message {
  background: #00A3F4;
  border-radius: 15px;
  width: 171px;
  height: 56px;
  border: 0px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.home_submit_message{
  width: auto !important;
}

.mrb{
  margin-bottom: 15px !important;
}
.error{
  color:red !important;
}



#inputID:-ms-input-placeholder {
  color:#c5c8cc;
  opacity: 1;
}



#inputID::placeholder {
  color:#c5c8cc;
  opacity: 1;
}

section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f5f9fc;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #5c768d;
}

.section-title p {
  margin-bottom: 0;
}


.banner_bottom{ display:block; float:left; width:100%;    margin-top: 110px;
  margin-bottom: 53px;}

.banner_bottom .box_set{ 
  width:344px; 
  height:344px; 
  display:block;
  float:left; 
  position:relative;     
  margin: 0 44px;

}
.banner_bottom .box_set .inner_top{
  width: 320px;
  height: 320px;
  display: block;
  float: left;
  border-radius: 100%;
  position: relative;
  z-index: 5;}
.banner_bottom .box_set .inner_top h2{    font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 61px;
  line-height: 52px;
  margin: 0px;
  padding: 0px;
  color: #FFFFFF;
  width: 100%;
  float: left;
  text-align: center;
  margin-top: 110px;}
.banner_bottom .box_set .inner_bottom h2{    font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 31px;
  line-height: 46px;
  text-align: center;
  color: #000000;
  position: absolute;
  bottom: 43px;
  text-align: center;
  width: 100%;
  margin-bottom: 0px;}
.banner_bottom .box_set .inne_hidden{ width:100%; display: block; float:left; height: 190px; overflow: hidden;}
.banner_bottom .box_set .inner_bottom{ width:275px; height:300px; display:block;float:left; background: #FFFFFF;
box-shadow: 0px 20px 34px rgba(0, 0, 0, 0.06); border-radius:100%;    position: absolute;
  top: 23px;
  left: 22px; position: absolute; 
}
.banner_bottom  .inner_bottom .border_area{    width: 90%;
  height: 90%;
  position: absolute;
  border-radius: 100%;
  margin: 5%;}
.banner_bottom  .l_t{     width: 50px;
  height: 30px;
  display: block;
  position: absolute;
  top: 159px;
  left: -17px;
  transform: rotate(45deg);
  z-index: -1;}
.banner_bottom  .r_t{     width: 50px;
  height: 30px;
  display: block;
  position: absolute;
  top: 159px;
  right: -17px;
  transform: rotate(136deg);
  z-index: -1;}

  .banner_bottom .icon_box {
    width: 120px;
    height: 120px;
    display: block;
    position: absolute;
    z-index: 50;
    border-radius: 100%;
    background: #fff;
    left: 0%;
    right: 0%;
    text-align: center;
    margin: 0 auto;
    top: -44px;
}

.red_bg .box_color {
  border: 1px solid #EB2E45;
}

.red_bg .icon_box img {
  display: block;
  margin: 0 auto;
  margin-top: 24px;
}

.banner_bottom .box_set .inne_hidden {
  width: 100%;
  display: block;
  float: left;
  height: 190px;
  overflow: hidden;
}
.red_bg .inner_top {
  background: #EB2E45;
}

.banner_bottom .inner_bottom .border_area {
  width: 90%;
  height: 90%;
  position: absolute;
  border-radius: 100%;
  margin: 5%;
}
.red_bg .border_area {
  border: 1px dashed #EB2E45;
}

.red_bg .tr_color {
  background: #8B1927;
}
.banner_bottom .l_t {
  width: 50px;
  height: 30px;
  display: block;
  position: absolute;
  top: 159px;
  left: -17px;
  transform: rotate(45deg);
  z-index: -1;
}
.red_bg .tr_color {
  background: #8B1927;
}
.banner_bottom .r_t {
  width: 50px;
  height: 30px;
  display: block;
  position: absolute;
  top: 159px;
  right: -17px;
  transform: rotate(136deg);
  z-index: -1;
}

.blue_bg .icon_box img {
  display: block;
  margin: 0 auto;
  margin-top: 24px;
}

.blue_bg .box_color {
  border: 1px solid #00A3F4;
}
.blue_bg .inner_top {
  background: #00A3F4;
}
.blue_bg .tr_color {
  background: #004E75;
}
.banner_bottom .r_t {
  width: 50px;
  height: 30px;
  display: block;
  position: absolute;
  top: 159px;
  right: -17px;
  transform: rotate(136deg);
  z-index: -1;
}

.blue_bg .border_area {
  border: 1px dashed #00A3F4;
}
.orange_bg .box_color {
  border: 1px solid #F99746;
}

.orange_bg .inner_top {
  background: #F99746;
}

.orange_bg .tr_color {
  background: #70431E;
}

.orange_bg .border_area {
  border: 1px dashed #F99746;
}

.orange_bg .icon_box img {
  display: block;
  margin: 0 auto;
  margin-top: 24px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px){
  .banner_bottom .box_set {
    float: unset !important;
    margin: 0 auto;
    margin-bottom: 50px;
    margin-top: 50px;
  }

}



@media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {

  .banner_bottom .box_set .inner_bottom{ width:300px !important; }

  .banner_bottom .box_set .inner_top{
    width: 344px !important;
    height: 344px !important;
   }


  .banner_bottom .box_set {
    width:344px !important;
    height:344px !important;
    float: unset !important;
    margin: 0 auto;
    margin-bottom: 50px;
    margin-top: 50px;
  }

}


@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {


  .banner_bottom .box_set .inner_bottom{ width:300px !important; }

  .banner_bottom .box_set .inner_top{
    width: 344px !important;
    height: 344px !important;
   }


  .banner_bottom .box_set {
    width:344px !important;
    height:344px !important;
    float: unset !important;
    margin: 0 auto;
    margin-bottom: 50px;
    margin-top: 50px;
  }/* your css rules for ipad landscape */
}






@media only screen and (min-device-width:1440px) and (max-device-width:1440px) {

  .banner_bottom .box_set {
      width: 330px;
      height: 330px;
      margin: 0 20px;
  }

  .banner_bottom .icon_box {
      width: 100px;
      height: 100px;
      top: -34px;
  }

  .banner_bottom .box_set .inne_hidden {
      width: 100%;
      height: 190px;
  }

  .banner_bottom .box_set .inner_top {
      width: 330px;
      height: 330px;
  }

  .banner_bottom .box_set .inner_top h2 {
      font-size: 60px;
      margin-top: 100px;
  }

  .banner_bottom .box_set .inner_bottom {
      width: 309px;
      height: 309px;
      top: 32px;
      left: 10px;
  }

  .banner_bottom .l_t {
      top: 150px;
      left: -5px;
  }

  .banner_bottom .r_t {
      top: 150px;
      right: -5px;
  }

  .banner_bottom .inner_bottom .border_area {
      width: 90%;
      height: 90%;
      margin: 5%;
  }

  .banner_bottom .box_set .inner_bottom h2 {
      font-size: 32px;
      bottom: 60px;
  }

  .banner_bottom .box_set .icon_box.box_color img {
      width: auto;
      height: 60px;
  }

}



@media only screen and (min-device-width:1200px) and (max-device-width:1440px) {

  .banner_bottom .box_set {
      width: 330px;
      height: 330px;
      margin: 0 30px;
  }

  .banner_bottom .icon_box {
      width: 100px;
      height: 100px;
      top: -34px;
  }

  .banner_bottom .box_set .inne_hidden {
      width: 100%;
      height: 190px;
  }

  .banner_bottom .box_set .inner_top {
      width: 330px;
      height: 330px;
  }

  .banner_bottom .box_set .inner_top h2 {
      font-size: 60px;
      margin-top: 100px;
  }

  .banner_bottom .box_set .inner_bottom {
      width: 309px;
      height: 309px;
      top: 32px;
      left: 10px;
  }

  .banner_bottom .l_t {
      top: 150px;
      left: -5px;
  }

  .banner_bottom .r_t {
      top: 150px;
      right: -5px;
  }

  .banner_bottom .inner_bottom .border_area {
      width: 90%;
      height: 90%;
      margin: 5%;
  }

  .banner_bottom .box_set .inner_bottom h2 {
      font-size: 32px;
      bottom: 60px;
  }

  .banner_bottom .box_set .icon_box.box_color img {
      width: auto;
      height: 60px;
  }

  .navbar a,
  .navbar a:focus {
      font-size: 16px;
      padding: 8px 10px;
  }

}

@media only screen and (max-device-width:1200px) {

  .navbar a,
  .navbar a:focus {
      font-size: 14px;
      padding: 8px 10px;
  }
}



@media only screen and (min-device-width:992px) and (max-device-width:1200px) {

  .banner_bottom .box_set {
      width: 270px;
      height: 270px;
      margin: 0 20px;
  }

  .banner_bottom .icon_box {
      width: 100px;
      height: 100px;
      top: -34px;
  }

  .banner_bottom .box_set .inne_hidden {
      width: 100%;
      height: 150px;
  }

  .banner_bottom .box_set .inner_top {
      width: 270px;
      height: 270px;
  }

  .banner_bottom .box_set .inner_top h2 {
      font-size: 50px;
      margin-top: 90px;
  }

  .banner_bottom .box_set .inner_bottom {
      width: 250px;
      height: 250px;
      top: 32px;
      left: 10px;
  }

  .banner_bottom .l_t {
      top: 110px;
      left: -5px;
  }

  .banner_bottom .r_t {
      top: 110px;
      right: -5px;
  }

  .banner_bottom .inner_bottom .border_area {
      width: 90%;
      height: 90%;
      margin: 5%;
  }

  .banner_bottom .box_set .inner_bottom h2 {
      font-size: 24px;
      bottom: 40px;
  }

  .banner_bottom .box_set .icon_box.box_color img {
      width: auto;
      height: 50px;
  }

  .navbar a,
  .navbar a:focus {
      font-size: 14px;
  }

  #topbar .social-links a {
      padding: 3px;
  }

  .top-links ul li a {
      font-size: 12px;
  }

  .top-links ul li {
      padding: 0 5px;
  }

  #topbar {
      font-size: 12px;
  }



}


@media only screen and (min-device-width:992px) and (max-device-width:1200px) {
  /*.banner_bottom .box_set {
      width: 210px;
      height: 210px;
      margin: 0 10px;
      float: left;
  }

  .banner_bottom .icon_box {
      width: 80px;
      height: 80px;
      top: -34px;
  }

  .banner_bottom .box_set .inne_hidden {
      width: 100%;
      height: 120px;
  }

  .banner_bottom .box_set .inner_top {
      width: 210px;
      height: 210px;
  }

  .banner_bottom .box_set .inner_top h2 {
      font-size: 40px;
      margin-top: 60px;
  }

  .banner_bottom .box_set .inner_bottom {
      width: 188px;
      height: 188px;
      top: 32px;
      left: 10px;
  }

  .banner_bottom .l_t {
      top: 80px;
      left: -5px;
  }

  .banner_bottom .r_t {
      top: 80px;
      right: -5px;
  }

  .banner_bottom .inner_bottom .border_area {
      width: 90%;
      height: 90%;
      margin: 5%;
  }

  .banner_bottom .box_set .inner_bottom h2 {
      font-size: 20px;
      bottom: 40px;
  }

  .banner_bottom .box_set .icon_box.box_color img {
      width: auto;
      height: 40px;
  }*/

  .mb_app_0 {
      width: 150px;
  }

  .navbar a,
  .navbar a:focus {
      font-size: 14px;
  }

  #topbar .social-links a {
      padding: 3px;
  }

  .top-links ul li a {
      font-size: 12px;
  }

  .top-links ul li {
      padding: 0 5px;
  }

  #topbar {
      font-size: 12px;
  }

  .top-colleges .col-xl-4 {
      width: 50%;
  }

  .topcollege-box .college_button_wrp a {
      padding: 5px 15px;
  }

}



@media only screen and (min-device-width:768px) and (max-device-width:992px) {
  .banner_bottom .box_set {
      width: 210px;
      height: 210px;
      margin: 0 10px;
      float: left;
  }

  .banner_bottom .icon_box {
      width: 80px;
      height: 80px;
      top: -34px;
  }

  .banner_bottom .box_set .inne_hidden {
      width: 100%;
      height: 120px;
  }

  .banner_bottom .box_set .inner_top {
      width: 210px;
      height: 210px;
  }

  .banner_bottom .box_set .inner_top h2 {
      font-size: 40px;
      margin-top: 60px;
  }

  .banner_bottom .box_set .inner_bottom {
      width: 188px;
      height: 188px;
      top: 32px;
      left: 10px;
  }

  .banner_bottom .l_t {
      top: 80px;
      left: -5px;
  }

  .banner_bottom .r_t {
      top: 80px;
      right: -5px;
  }

  .banner_bottom .inner_bottom .border_area {
      width: 90%;
      height: 90%;
      margin: 5%;
  }

  .banner_bottom .box_set .inner_bottom h2 {
      font-size: 20px;
      bottom: 40px;
  }

  .banner_bottom .box_set .icon_box.box_color img {
      width: auto;
      height: 40px;
  }

  .mb_app_0 {
      width: 150px;
  }

  .navbar a,
  .navbar a:focus {
      font-size: 14px;
  }

  #topbar .social-links a {
      padding: 3px;
  }

  .top-links ul li a {
      font-size: 12px;
  }

  .top-links ul li {
      padding: 0 5px;
  }

  #topbar {
      font-size: 12px;
  }

  .top-colleges .col-xl-4 {
      width: 50%;
  }

  .topcollege-box .college_button_wrp a {
      padding: 0px 8px !important;
  }

}




@media screen and (max-width:767px) {
  .f-app-links a {
      width: 131px;
  }

  .download-app {
      padding: 0px;
      margin-top: 20px;
  }

  #footer .footer-top .footer-links {
      margin-bottom: 20px;
  }

  #footer .footer-top h4 {}

  .banner_bottom .box_set {
      float: unset !important;
      margin: 50px auto;
  }

  .mb_app_0 {
      width: 150px;
  }

  .app_set_head {
      width: 300px;
      margin: 0 auto;
  }

  #hero h2 {
      font-size: 28px;
      margin-top: 40px;
      text-align: center;
  }

  .hero_spc_top {
      margin-top: 15px;
      margin-left: 17px;
      padding-left: 15px;
  }

  .hero_explore {
      width: 100%;
  }

  #topbar {
      display: none !important;
  }

  .mobile-nav-toggle {
      color: #000000 !important;
      font-size: 39px;
  }

  #navbar .nav-link.active {
      color: #fff !important;
  }

  #navbar .nav-link.active:focus {
      color: #fff;
  }

  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
      color: #fff !important;
  }

  .signUp .hero_spc_top {
      padding-left: 0px;
      margin-left: 0px;
  }

}

@media only screen and (min-device-width:992px) and (max-device-width:1280px) {


  .our-courses-section .col-lg-3.col-md-6.text-center.aos-init.aos-animate {
      width: 32%;
  }

  .our-courses-section .courses-box ul li {
      padding: 5px 8px;
  }



}


@media only screen and (min-device-width:860px) and (max-device-width:992px) {


  .our-courses-section .col-lg-3.col-md-6.text-center.aos-init.aos-animate {
      width: 32%;
  }

  .our-courses-section .courses-box ul li {
      padding: 5px 8px;
  }



}


@media only screen and (min-device-width:480px) and (max-device-width:860px) {


  .our-courses-section .col-lg-3.col-md-6.text-center.aos-init.aos-animate {
      width: 50%;
  }

  .our-courses-section .courses-box ul li {
      padding: 5px 8px;
  }



}

@media only screen and (min-device-width: 480px) and (max-device-width:540px) {
  .blog-post {
      float: none;
      width: 100% ;
      margin: 0 0 30px 0;
  }

}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .f-app-links a {
      width: 131px;
  }

  .download-app {
        padding: 0px 0px 15px;
      margin-top: 20px;
  }

  #footer .footer-top .footer-links {
      margin-bottom: 20px;
  }

  #footer .footer-top h4 {}

  .banner_bottom .box_set {
      float: unset !important;
      margin: 0 auto;
      margin-bottom: 50px;
      margin-top: 50px;
  }

  .mb_app_0 {
      width: 150px;
  }

  .app_set_head {
      width: 300px;
      margin: 0 auto;
  }

  #hero h2 {
      font-size: 28px;
      margin-top: 40px;
      text-align: center;
  }

  .hero_spc_top {
      margin-top: 15px;
      margin-left: 17px;
      padding-left: 15px;
  }

  .hero_explore {
      width: 100%;
  }

  #topbar {
      display: none !important;
  }

  .mobile-nav-toggle {
      color: #fff;
  }

  #navbar .nav-link.active {
      color: #fff;
  }

  #navbar .nav-link.active:focus {
      color: #fff;
  }

  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
      color: #fff !important;
  }

  .signUp .hero_spc_top {
      padding-left: 0px;
      margin-left: 0px;
  }

  .blog-post {
      float: none;
      width: 100% !important;
      margin: 0 0 30px 0;
  }


}

@media(min-width:425px) {
    .container, .container-md, .container-sm{
        max-width: 90%;
    }
}


@media(min-width:576px) {
  .container, .container-sm{
      max-width: 95%;
  }
}

@media(min-width:768px) {
  .container, .container-md, .container-sm{
      max-width: 95%;
  }
}

@media(min-width:992px) {
  .container, .container-lg, .container-md, .container-sm{
      max-width: 95%;
  }
}

@media(min-width:1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl{
      max-width: 90%;
  }
}

@media(min-width:1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{
      max-width: 1320px;
  }
}

@media only screen and (max-device-width:425px) {
    .banner_bottom{
        margin-bottom: auto;
    }
    
  }

.boxcol{
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}


/********************************/



a.glightbox 
{color: var(--color-primary);
    text-decoration: none;
}
.abouts .play-btn:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.abouts .play-btn:before {
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    animation-delay: 0s;
    animation: pulsate-btn 2s;
    animation-direction: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(13, 66, 255, 0.7);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
}
.abouts .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(#00A3F4 50%, rgb(95 122 218 / 40%) 52%);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
    overflow: hidden;
    cursor: pointer;
}

.abouts .content ul i {
    flex-shrink: 0;
    font-size: 48px;
    color: var(--color-primary);
    margin-right: 20px;
    line-height: 0;
}
.abouts .content ul {
    list-style: none;
    padding: 0;
}
.abouts .content ul li {
    /*display: flex;
    align-items: flex-start;
    margin-top: 40px;
	    flex-wrap: wrap;*/
	display: flex;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: center;
}

.abouts .counter{
    color: #eb2e45;
    text-align: center;
    width: 100px;
    padding: 6px 0 0;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}

.abouts .counter.blue{
	color: #00A3F4;
}

.abouts .counter.orange{
	color: #F99746;
}

.abouts .counter:before,
.abouts .counter:after{
    content: '';
    background-color: #eb2e45;
    height: 55px;
    border-radius: 50% 50% 0 0/100% 100% 0 0;
    position: absolute;
    left: -4px;
    top: 0;
    bottom: 0;
    right: -4px;
    z-index: -1;
}

.abouts .counter.blue:before,
.abouts .counter.blue:after{
	background-color: #00A3F4;
}

.abouts .counter.orange:before,
.abouts .counter.orange:after{
	background-color: #F99746;
}

.abouts .counter:after{
    background-color: transparent;
    height: 65px;
    border: 5px solid #eb2e45;
    border-radius: 0 0 50% 50%/0 0 100% 100%;
    top: 55px;
    left: -10px;
    right: -10px;
}

.abouts .counter.blue:after{
	background-color: transparent;
	border: 5px solid #00A3F4;
}

.abouts .counter.orange:after{
	background-color: transparent;
	border: 5px solid #F99746;
}

.abouts .counter .counter-content{
    background-color: #fff;
    height: 100px;
    width: 100px;
    padding: 20px 10px;
    margin: 0 auto 5px;
    border-radius: 50%;
    box-shadow: 0 0 20px rgba(0,0,0,0.3);
}
.abouts .counter .counter-value{
    font-size: 14px;
    font-weight: 700;
    margin: 0 0 5px;
    display: block;
}
.abouts .counter h3{
    color: #eb2e45;
    font-size: 19px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 50px;
    text-transform: capitalize;
    margin: 0 0 15px;
}

.abouts .counter.blue h3{
    color: #00A3F4;
}

.counter.orange h3{
    color: #F99746;
}

.abouts .counter .counter-icon{
    background-color: #fff;
    font-size: 25px;
    line-height: 52px;
    height: 50px;
    width: 50px;
    margin: 0 auto;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
}

@media screen and (max-width:990px){
    .abouts .counter{ margin-bottom: 40px; }
}   

.abouts .icon_box_new img{
    width:20px;
}

.modal {
    padding-left: 0px !important;
}
.common_sec_h {
    font-family: 'Open Sans';
    font-size: 32px;
    color: #000;
    font-weight: 600;
    margin-bottom: 25px;
    text-align: center;
}

.mt-30 {
    margin-top: 30px;
}

.courses_list {
    display: flex;
    align-items: center;
}

.courses_list .icon {
    width: 60px;
    height: 60px;
    display: flex;
    float: left;
    background: #00a3f42e;
    border-radius: 100%;
}

.courses_list .icon img {
    width: 28px !important;
    margin: 0 auto;
    display: block;
    float: unset;
}

.courses_list h2 {
    font-family: 'Open Sans';
    font-size: 18px;
    color: #000000;
    margin: 0;
    font-weight: 700;
    display: block;
    float: left;
    margin-left: 20px;
}

.topTrendingCourses .owl-nav {
    top: 18% !important;
}

.loop_Universities h3 {
    margin-top: 300px;
}

.loop_Universities .overlay_grad {
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    z-index: 0;
}

.loop_Universities .text_upper {
    position: relative;
    z-index: 2;
}

.loop_Universities .inner_wrp {
    overflow: hidden;
}

.loop_Universities h3 {
    color: #fff;
}

.loop_Universities p {
    color: rgba(255, 255, 255, 0.6);
}

.loop_Universities a {
    color: #fff;
}

.submit_message {
    background: #00A3F4;
    border-radius: 15px;
    width: 171px;
    height: 56px;
    border: 0px;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
}

/* 
.hero_explore{ 
    width:75%;
     display:block;
      float:left;   
        position: relative;} */

.hero_explore .ex-field {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.14);
    border: 0px;
    border-radius: 10px;
    height: 64px;
    padding: 0 163px 0 40px;
}

.hero_explore img {
    position: absolute;
    z-index: 1;
    left: 13px;
    top: 20px;
}

.light_gray {
    background: #5694e30f;
}

.light_gray {
    background: #5694e30f;
}

.spac_01 {
    padding: 40px 0;
}

.common_sec_h span {
    color: #00A3F4;
    /*font-weight:600;*/
}

.our_portal .item img {
    width: 100%;
    display: block;
    float: left;
}

.our_portal .owl-dot.active {
    margin-top: 0px;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    background: #fff !important;
    border: 2px solid #00A3F4;
}

.our_portal .owl-dot.active span {
    width: 10px;
    height: 10px;
    display: block !important;
    margin: 2px 3px !important;
    background: #00A3F4 !important;
}

.our_portal .owl-theme .owl-dots .owl-dot {
    margin: 0 5px;
    width: 20px !important;
    height: 20px !important;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 100px;
    margin-top: 25px;
}

.our_portal .owl-theme .owl-dots .owl-dot span {
    background: rgba(0, 0, 0, 0);
    margin: 2px 3px !important;
}

.our_portal .owl-theme .owl-dots .owl-dot:hover span {
    background: rgba(0, 0, 0, 0);
}

.topTrendingCourses .owl-nav {
    top: 18% !important;
}

.owl-theme .owl-nav {
    margin-top: 10px;
}

.owl-theme .owl-dots,
.owl-theme .owl-nav {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}

.loop_nav .owl-nav {
    position: absolute;
    width: 100%;
    top: 35%;
}

.loop_nav .owl-nav span i {
    font-size: 18px !important;
    line-height: 28px !important;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
    background: 0 0;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
}


.loop_nav .owl-nav .owl-prev {
    width: 54px !important;
    height: 54px !important;
    border: 2px solid rgba(0, 0, 0, 0.4) !important;
    display: block !important;
    float: left !important;
    border-radius: 100% !important;
    margin-left: -80px !important;
}

.owl-theme .owl-nav [class*=owl-]:hover {
    background: #00A3F4 !important;
    color: #fff !important;
    border: 2px solid #00A3F4 !important;
}

.loop_nav .owl-nav .owl-next {
    width: 54px !important;
    height: 54px !important;
    border: 2px solid rgba(0, 0, 0, 0.4) !important;
    display: block !important;
    float: right !important;
    border-radius: 100% !important;
    margin-right: -80px !important;
}

@media only screen and (max-width: 1350px) {
    .loop_nav .owl-nav .owl-next {
        width: 50px !important;
        height: 50px !important;
        margin-right: -50px !important;
    }

    .loop_nav .owl-nav .owl-prev {
        width: 50px !important;
        height: 50px !important;
        margin-left: -50px !important;
    }
}

@media only screen and (max-width: 1024px) {
    .loop_nav .owl-nav .owl-next {
        width: 35px !important;
        height: 35px !important;
        margin-right: -35px !important;
    }

    .loop_nav .owl-nav .owl-prev {
        width: 35px !important;
        height: 35px !important;
        margin-left: -35px !important;
    }

    .news_ct h2 {
        font-size: 15px !important;
    }

    .students .blue,
    .students .red,
    .students .orange {
        font-size: 35px !important;
        line-height: 35px !important;
    }

    .counts .count-box p {
        font-size: 16px !important;
    }
}

@media only screen and (max-width: 768px) {
    .loop_nav .owl-nav .owl-next {
        width: 35px !important;
        height: 35px !important;
        margin-right: -30px !important;
    }

    .loop_nav .owl-nav .owl-prev {
        width: 35px !important;
        height: 35px !important;
        margin-left: -30px !important;
    }

    .students .blue,
    .students .red,
    .students .orange {
        font-size: 30px !important;
        line-height: 30px !important;
    }

    .counts .count-box p {
        font-size: 14px !important;
    }
}
.topColgs .testi_wrp {
    position: relative;
    width: 100%;
    display: block;
    float: left;
}

.topColgs .loop_colleges .testi_wrp {
    padding: 10px;
}

.topColgs .testi_wrp:hover .testi_hover {
    display: block;
}

.topColgs .testi_wrp .testi_hover {
    width: 95%;
    height: 93%;
    display: none;
    background: #00A3F4;
    position: absolute;
    left: 3%;
    border-radius: 10px;
    top: 4%;
    transform: rotate(3.45deg);
}

.topColgs .loop_colleges .inner_wrp {
    padding: 0px 0 20px 0 !important;
}

.topColgs .testi_wrp .inner_wrp {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    padding: 15px;
    position: relative;
    box-shadow: 0px 3px 8px rgb(0 0 0 / 12%);
    border-radius: 10px;
}

.topColgs .loop_colleges .rating_box {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 65px !important;
    font-size: 17px !important;
    color: #000000;
    height: 44px;
    position: absolute;
    top: 0px !important;
    right: 0px !important;
    text-align: center;
    line-height: 44px;
}

.topColgs .loop_colleges h3 {
    font-style: normal;
    font-weight: 700;
    width: 100%;
    display: block;
    float: left;
    font-size: 18px;
    padding: 0 15px !important;
    line-height: 26px;
    margin: 10px 0;
    padding: 0;
    color: #000000;
}


.topColgs .loop_colleges p {
    font-style: normal;
    padding: 0 15px !important;
    font-weight: 400;
    margin-bottom: 20px !important;
    font-size: 16px;
    width: 100%;
    display: block;
    float: left;
    line-height: 20px;
    margin: 0px;
    padding: 0px;
    color: rgba(0, 0, 0, 0.6);
}

.topColgs .loop_colleges .college_button_wrp {
    width: 255px;
    display: block;
    margin: 0 auto;
}

.topColgs .loop_colleges a {
    height: 44px;
    width: 121px;
    display: block;
    float: left;
    font-size: 13px;
    color: #00A3F4;
    font-weight: 500;
    border: 1px solid #00A3F4;
    border-radius: 10px;
    line-height: 44px;
    text-align: center;
}

.topColgs .loop_colleges .college_button_wrp .active_bt {
    background: #00A3F4;
    color: #fff;
    margin-right: 10px;
}

.topColgs .loop_colleges .rating_box {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 86px;
    font-size: 18px;
    color: #000000;
    height: 44px;
    position: absolute;
    top: 15px;
    right: 15px;
    text-align: center;
    line-height: 44px;
}

.loop_colleges .rating_box .rating_act {
    color: #FFB108;
    position: relative;
    top: -4px;
}

.wrapcontent h3 {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    height: 50px !important;
}

.wrapcontent img
{
    min-height: 290px;
    object-fit: cover;
}

.leftalign{
    text-align: left !important;
}

.examdesignslider img{
    min-height: 190px !important;
    max-height: 190px !important;
}
.common_sec_h {
    font-family: 'Open Sans';
    font-size: 32px;
    color: #000;
    font-weight: 700;
    margin-bottom: 25px;
    text-align: center;
}

.testi_wrp {
    position: relative;
    width: 100%;
    display: block;
    float: left;
}

.loop_colleges .testi_wrp {
    padding: 10px;
}

.loop_Universities .inner_wrp {
    overflow: hidden;
}
.loop_colleges .inner_wrp {
    padding: 0px 0 20px 0 !important;
}
.testi_wrp .inner_wrp {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    padding: 15px;
    position: relative;
    box-shadow: 0px 3px 8px rgb(0 0 0 / 12%);
    border-radius: 10px;
}
.loop_Universities .text_upper {
    position: relative;
    z-index: 2;
}

.loop_colleges .rating_box {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 86px;
    font-size: 18px;
    color: #000000;
    height: 44px;
    position: absolute;
    top: 15px;
    right: 15px;
    text-align: center;
    line-height: 44px;
}

.loop_colleges .rating_box .rating_act {
    color: #FFB108;
}

.loop_Universities h3 {
    color: #fff;
}
.loop_Universities h3 {
    margin-top: 300px;
}
.loop_colleges h3 {
    font-style: normal;
    font-weight: 700;
    width: 100%;
    display: block;
    float: left;
    font-size: 18px;
    padding: 0 15px !important;
    line-height: 26px;
    margin: 10px 0;
    padding: 0;
    color: #000000;
}

.loop_Universities p {
    color: rgba(255, 255, 255, 0.6);
}
.loop_colleges p {
    font-style: normal;
    padding: 0 15px !important;
    font-weight: 400;
    margin-bottom: 20px !important;
    font-size: 16px;
    width: 100%;
    display: block;
    float: left;
    line-height: 20px;
    margin: 0px;
    padding: 0px;
    color: rgba(0, 0, 0, 0.6);
}

.loop_colleges .college_button_wrp {
    width: 255px;
    display: block;
    margin: 0 auto;
}


.loop_colleges .college_button_wrp .active_bt {
    background: #00A3F4;
    color: #fff;
    margin-right: 10px;
}

.loop_Universities a {
    color: #fff;
}
.loop_colleges a {
    height: 44px;
    width: 121px;
    display: block;
    float: left;
    font-size: 13px;
    color: #00A3F4;
    font-weight: 500;
    border: 1px solid #00A3F4;
    border-radius: 10px;
    line-height: 44px;
    text-align: center;
}

.loop_colleges .inner_wrp {
    padding: 0px 0 20px 0 !important;
}

.loop_Universities .overlay_grad {
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    width: 100%;
    height: 100%;
    z-index: 0;
}


.loop_colleges h3 {
    font-style: normal;
    font-weight: 700;
    width: 100%;
    display: block;
    float: left;
    font-size: 18px;
    padding: 0 15px !important;
    line-height: 26px;
    margin: 10px 0;
    padding: 0;
    color: #000000;
}


.loop_Universities h3 {
    margin-top: 300px;
}

.loop_Universities h3 {
    color: #fff;
}

.loop_Universities p {
    color: #ffffff99;
}

.signUp {
    width: 100%;
    padding: 20px 0 20px 0 !important;
    overflow: hidden;
}

.signUp h3 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 50px;
    line-height: 64px;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgb(0 0 0 / 40%);
}
.signUp h3 {
    margin-bottom: 8px !important;
}

.signUp p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #FFFFFF;
    text-shadow: 0px 2px 2px rgb(0 0 0 / 40%);
}

.hero_explore .contactUs {
    background: #00A3F4;
    border-radius: 10px;
    width: 151px;
    height: 56px;
    display: block;
    float: left;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 56px;
    color: #FFFFFF;
    border: 0px;
}
.common_sec_h {
    font-family: 'Open Sans';
    font-size: 32px;
    color: #000;
    font-weight: 700;
    margin-bottom: 25px;
    text-align: center;
}

.loop_Universities .inner_wrp {
    overflow: hidden;
}
.loop_colleges .inner_wrp {
    padding: 0px 0 20px 0 !important;
}
.testi_wrp .inner_wrp {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    padding: 15px;
    position: relative;
    box-shadow: 0px 3px 8px rgb(0 0 0 / 12%);
    border-radius: 10px;
    overflow: hidden;
}

.loop_Exams .test_content_wrp {
    z-index: 5;
    position: relative;
}

.loop_Exams .short_title {
    width: 100%;
    display: block;
    float: left;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    padding: 0 15px;
    margin-bottom: 0px;
    margin-top: 180px;
}

.loop_Exams h3 {
    margin: 10px 0;
}

.loop_Exams p {
    font-size: 16px;
    color: #fff;
    margin: 0px 0 0 0 !important;
}

.wrapexamcontent h3 {
    color:#000 !important;
    font-size: 18px !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    height: 50px !important;
}

.our_portal .owl-carousel .wrapexamcontent img{
    /* min-height: 190px; */
    object-fit: cover;
    width: 100% !important;
}

.exam_section_slider h3{
    text-align: center !important;
}
.blog_footer .users_post {
    display: block;
    float: left;
    margin-left: 15px;
}

.blog_footer .users_post a.userbt {
    width: unset !important;
    height: unset !important;
    border: 0px !important;
    line-height: unset !important;
    display: block;
    float: left;
    margin-left: 0 !important;
}
.loop_colleges a {
    height: 44px;
    width: 121px;
    display: block;
    float: left;
    font-size: 13px;
    color: #00A3F4;
    font-weight: 500;
    border: 1px solid #00A3F4;
    border-radius: 10px;
    line-height: 44px;
    text-align: center;
}

.blog_footer .users_post a .photo {
    width: 45px;
    height: 45px;
    display: block;
    float: left;
    border-radius: 100%;
}

.blog_footer .users_post .user_name {
    display: block;
    float: left;
    margin-left: 10px;
}

.blog_footer .users_post .user_name h2 {
    font-size: 17px;
    font-weight: 600;
    color: #000;
    margin: 0px;
    text-align: left;
    padding: 0px;
}

.blog_footer .users_post .user_name p {
    font-size: 15px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    margin: 0px !important;
    padding-left: 0px !important;
    text-align: left;
    padding: 0px;
}
.blog_footer .users_post .user_name p span {
    display: block;
    float: left;
}

.blog_footer .users_post .user_name p span.bullets {
    width: 5px;
    height: 5px;
    background: #ccc;
    border-radius: 100%;
    margin: 7px 6px 0 6px;
}

.blog_footer .users_post .user_name p span {
    display: block;
    float: left;
}

.blog_footer .blog_bt {
    float: right;
    margin-right: 15px;
    width: 97px !important;
}

.loop_blogs_news .view_all {
    height: 44px !important;
    width: 150px !important;
    display: block;
    margin: 20px auto !important;
    float: unset !important;
    font-size: 13px;
    color: #fff !important;
    font-weight: 500;
    background: #00A3F4;
    border-radius: 10px;
    line-height: 44px;
    text-align: center;
}

.lt_news{
    width: 100%;
    display: block;
    float: left;
    list-style-type: none;
    margin: 0 !important;
    padding: 0 19px 0 0;
}

.lt_news li {
    margin: 10px 0;
}

.lt_news li {
    display: flex;
    float: left;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 6px 18px rgb(0 0 0 / 12%);
    border-radius: 20px;
    padding: 5px;
    align-items: center;
}

.lt_news li .date_set {
    background: #00A3F4;
    padding: 20px 0px;
    border-radius: 20px;
    font-size: 40px;
    color: #fff;
    text-align: center;
    display: block;
    float: left;
    line-height: 40px;
    width: 110px;
}

.lt_news li .date_set p {
    width: 100%;
    display: block;
    font-size: 15px !important;
    float: left;
    text-align: center;
    margin: 0px !important;
    color: #fff;
    width: 100px;
}

.news_ct {
    display: block;
    float: left;
    padding-left: 15px;
}

.news_ct h2 {
    margin: 0px;
    font-size: 18px;
    color: #000;
    margin-bottom: 10px;
}

.news_ct p {
    font-size: 15px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    margin: 0px !important;
    padding-left: 0px !important;
    text-align: left;
    padding: 0px;
}

.full_wdth {
    width: 100%;
    display: block;
    float: left;
}

.loop_blogs_news a{
    color: #000 !important;
    width: 100% !important;
    height: auto !important;
    border: 0px !important;
}

.loop_blogs_news .blog_bt{
    border: 1px solid #00A3F4 !important;
}
.counts .count-box {
    background: #FFFFFF;
    box-shadow: 0px 14px 26px rgb(0 0 0 / 8%);
    border-radius: 20px;
    padding: 5px;
    /* margin-bottom: 25px; */
}

.students_icons {
    width: 87px;
    height: 87px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    background: #FFFFFF;
    box-shadow: 0px 1px 8px rgb(0 0 0 / 14%);
    border-radius: 100%;
}
.students_icons img {
    width: 44px;
    display: block;
    margin: 0 auto;
}

.students .red {
    font-size: 37px;
    line-height: 30px;
    color: #EB2E45 !important;
    margin-top: 20px !important;
    font-weight: 700;
}

.students .blue {
    font-size: 37px;
    line-height: 30px;
    color: #00A3F4 !important;
    margin-top: 20px !important;
    font-weight: 700;
}

.students .orange {
    font-size: 37px;
    line-height: 30px;
    color: #F99746 !important;
    margin-top: 20px !important;
    font-weight: 700;
}

.counts .count-box p {
    padding: 0;
    margin: 0;
    font-size: 20px;
    font-weight: 500;
}

@media only screen and (min-device-width:320px) and (max-device-width:480px) {
    .webcountersection {
        padding: 30px 0px;
    }
}
.why_choose {
    width: 100%;
    display: block;
    float: left;
    
    background-size: cover;
    background-attachment: fixed;
}

.why_choose h2 {
    font-weight: 700;
    font-size: 50px;
    line-height: 75px;
    margin: 0px;
    padding: 0px;
    text-shadow: 0px 4px 4px rgb(0 0 0 / 30%);
    color: #fff;
}

.why_choose p {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
    color: #FFFFFF;
    text-shadow: 0px 2px 2px rgb(0 0 0 / 40%);
}


.choose_list {
    display: block;
    position: relative;
    padding-left: 70px;
    width: 100%;
    float: left;
}

.why_choose .choose_list .icon {
    width: 55px;
    height: 55px;
    background: #FFFFFF;
    box-shadow: 0px 1px 20px rgb(0 0 0 / 14%);
    border-radius: 100%;
    /* top: 10px; */
    text-align: center;
    position: absolute;
    left: 0;
}

.why_choose .choose_list .icon img {
    position: absolute;
    top: 16px;
    left: 0;
    right: 0;
    margin: 0 auto;
}


.choose_list span {
    display: block;
    float: left;
}

.why_choose .choose_list span h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    margin: 0px;
    line-height: 36px;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgb(0 0 0 / 40%);
}

.why_choose .choose_list span p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgb(0 0 0 / 40%);
}

.get_call {
    text-align: center;
    line-height: 46px;
    border-radius: 15px;
    padding: 5px 15px;
    display: block;
    background-color: #ffff;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    width: 225px;
    height: 56px;
    margin: 0 auto;
    color: #00A3F4;
}

.hide{
    display: none;

}

@media only screen and (max-device-width:768px)
{
    .hide{
        display: block ;
    }
}

@media only screen and (min-device-width:320px) and (max-device-width:480px) {
    .why_choose {
        padding: 30px 0px;
    }

    .why_choose h2 {
        font-size: 30px;
        line-height: 55px;
    }
    
    .why_choose p {
        font-size: 16px;
        line-height: 24px;
    }

    .why_choose .choose_list .icon{
        width: 50px;
        height: 50px;
    }

    .why_choose .choose_list .icon img{
        width: 30px;
        top: 10px;
    } 
    
    .why_choose .choose_list span h3{
        font-size: 22px;
        line-height: 26px;
    }
}


.testiSec .testi_wrp {
    position: relative;
    width: 100%;
    display: block;
    float: left;
}

.testiSec .testi_wrp .testi_hover {
    width: 95%;
    height: 93%;
    display: none;
    background: #00A3F4;
    position: absolute;
    left: 3%;
    border-radius: 10px;
    top: 4%;
    transform: rotate(3.45deg);
}

.testiSec .loop_testi .item .testi_wrp {
    padding: 10px;
}

.testiSec .testi_wrp .inner_wrp {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    padding: 15px;
    position: relative;
    box-shadow: 0px 3px 8px rgb(0 0 0 / 12%);
    border-radius: 10px;
}

.testiSec .testi_wrp .inner_wrp {
    overflow: hidden;
}

.testiSec .loop_testi .test_content_wrp {
    width: 100%;
    display: block;
    float: left;
    margin-top: 15px;
}

.testiSec .loop_testi .test_content_wrp ul {
    display: block;
    float: left;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.testiSec .loop_testi .test_content_wrp ul li {
    display: block;
    float: left;
    color: rgba(0, 0, 0, 0.1);
    margin-right: 5px;
}

.testiSec .loop_testi .test_content_wrp ul li.active {
    color: #F7B500;
}

.testiSec .loop_testi .test_content_wrp ul li i {
    font-size: 18px;
}


.testiSec .our_portal .item img {
    width: 100%;
    display: block;
    float: left;
}

.testiSec .testi_wrp .lquote {
    width: 35px !important;
    position: absolute;
    top: 0px;
}
.testiSec .testi_wrp .rquote {
    width: 35px !important;
    position: absolute;
    right: 20px;
    bottom: 0px;
}

/* .testiSec .loop_testi .test_content_wrp p {
    font-size: 15px;
    font-weight: 300;
    width: 100%;
    display: block;
    float: left;
    margin: 20px 0px;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 10 !important;
    -webkit-box-orient: vertical !important;
    height: 180px !important;
} */
.testiSec .loop_testi .test_content_wrp ul + p {
    font-size: 15px;
    font-weight: 300;
    width: 100%;
    display: block;
    float: left;
    margin: 20px 0px;
    text-overflow: ellipsis !important;
    /* overflow: scroll !important; */
    display: -webkit-box !important;
    -webkit-line-clamp: 10 !important;
    -webkit-box-orient: vertical !important;
    height: 180px !important;
}

.testiSec .testi_user {
    display: flex;
    float: left;
    align-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    height: auto !important;
}

.testiSec .owl-carousel .testi_user img {
    width: 55px !important;
    display: block !important;
    float: left !important;
}

.testiSec .testi_user span {
    display: block;
    float: left;
    margin-left: 10px;
}

.testiSec .testi_user span h3 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0px !important;
    color: #000;
}

.testiSec .testi_user span p {
    margin: 0px !important;
    padding: 0px !important;
    font-size: 13px;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.6);
}

/* @media (min-width:320px) and (max-width:767px){
    .testiSec .testi_user {

        height: 126px !important;
    }
} */

.common_sec_h {
    font-family: 'Open Sans';
    font-size: 32px;
    color: #000;
    font-weight: 700;
    margin-bottom: 25px;
    text-align: center;
}

.loop_Universities .inner_wrp {
    overflow: hidden;
}
.loop_colleges .inner_wrp {
    padding: 0px 0 20px 0 !important;
}
.testi_wrp .inner_wrp {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    padding: 15px;
    position: relative;
    box-shadow: 0px 3px 8px rgb(0 0 0 / 12%);
    border-radius: 10px;
    overflow: hidden;
}


.loop_colleges .rating_box {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 86px;
    font-size: 18px;
    color: #000000;
    height: 44px;
    position: absolute;
    top: 15px;
    right: 15px;
    text-align: center;
    line-height: 44px;
}

.loop_colleges .rating_box .rating_act {
    color: #FFB108;
}

.loop_colleges p {
    font-style: normal;
    padding: 0 15px !important;
    font-weight: 400;
    margin-bottom: 20px !important;
    font-size: 16px;
    width: 100%;
    display: block;
    float: left;
    line-height: 20px;
    margin: 0px;
    padding: 0px;
    color: rgba(0, 0, 0, 0.6);
}

.loop_colleges .college_button_wrp {
    width: 255px;
    display: block;
    margin: 0 auto;
}


.loop_colleges .college_button_wrp .active_bt {
    background: #00A3F4;
    color: #fff;
    margin-right: 10px;
}

.loop_colleges a {
    height: 44px;
    width: 121px;
    display: block;
    float: left;
    font-size: 13px;
    color: #00A3F4;
    font-weight: 500;
    border: 1px solid #00A3F4;
    border-radius: 10px;
    line-height: 44px;
    text-align: center;
}

.loop_Exams .overlay_grad {
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    width: 100%;
    height: 100%;
    z-index: 0;
}

.loop_Exams .test_content_wrp {
    z-index: 5;
    position: relative;
}

.loop_Exams .short_title {
    width: 100%;
    display: block;
    float: left;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    padding: 0 15px;
    margin-bottom: 0px;
    margin-top: 180px;
}
.loop_Exams h3 {
    font-size: 22px;
    color: #fff;
    margin: 10px 0;
}

.loop_Exams p {
    font-size: 16px;
    color: #fff;
    margin: 0px 0 0 0 !important;
}


/*.owl-carousel .owl-item img{
    width: auto !important;
}*/

.btn,
button {
    background: #00A3F4;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 40px;
    color: #FFFFFF;
    border: 0px;
    padding: 6px 30px;
}

.btn:hover,
button:hover,
.btn:focus {
    background: #00a040;
    color: #FFFFFF;
}

#hero.inner-top-header {
    padding: 200px 0 100px 0;
}

.header-search-box {
    text-align: center;
}

.header-search-box input[type="text"] {
    border-radius: 5px;
    width: 80%;
    margin: 0 auto 30px auto !important;
    border: 0px;
    padding: 15px;
}

.header-search-box input[type="text"].search-box {
    background-position: 20px center;
    border-radius: 30px;
    width: 95%;
    margin: 0 auto 0px auto !important;
    border: 0px;
    padding: 15px 15px 15px 50px;
}


.medical-button {
    float: left;
}

.talk-to-expert {
    float: right;
    background: #00a040
}

.main-heading {
    font-size: 38px;
    text-align: center;
    color: #000;
    font-weight: bold
}

.main-heading b {
    color: #00a2f3;
}
.blogsearchbox input[type="text"] {
    margin: 0 auto 0px auto !important;
    padding: 15px 15px
}
.our-courses-section .courses-box ul {
    /* margin: 20px 0; */
    padding: 0;
    /* display: inline-block;
    min-height: 145px; */
    display: block;
    min-height: 50px;
}

.our-courses-section .courses-box ul li {
    display: inline-block;
    text-align: center;
    /* padding: 10px 20px; */
}

.our-courses-section .courses-box ul li a {
    color: #666;
    font-size: 18px;
    text-transform: uppercase;
}

.our-courses-section .courses-box ul li:first-child {
    border-right: 0px solid #e6e6e6;
}

.our-courses-section .courses-box h2 {
    font-size: 22px;
    display: block;
    font-weight: 600;
    color: #000;
    margin: 5px 0;
    border-bottom: 1px solid #e6e6e6;
    /*line-height: 60px;*/
    height: 60px;
}

.our-courses-section .courses-box .button {
    background: #fff;
    font-size: 15px;
    border: 1px solid #00A3F4;
    line-height: 32px;
    color: #00A3F4;
    display: inline-block;
    float: none;
}

.our-courses-section .courses-box .button:hover {
    background: #00a040;
    border: 1px solid #00a040;
    color: #fff;
}


.outer-hover-courses-box {
    position: relative;
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px #ccc;
    border-radius: 10px;
    padding: 25px;
    margin-bottom: 30px;
    height: auto;
    overflow: hidden;
}

.outer-hover-courses-box:hover .courses-box-hover {
    cursor: pointer;
    padding: 0;
    visibility: visible;
    opacity: 1;
}

.courses-box-hover {
    position: absolute;
    bottom: 0;
    right: 0;
    background: black;
    color: white;
    margin-bottom: 5px;
    font-family: sans-serif;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    width: 100%;
    height: 100%;
    margin: 0px;
}

.our-courses-section .courses-box-hover ul {
    margin: 0px 0 20px 0;
    padding: 0 15px;
    width: 100%;
    display: block;
}

.our-courses-section .courses-box-hover ul li {
    display: block;
    text-align: left;
    padding: 10px 20px;
    border-bottom: 1px solid #333333;
}

.our-courses-section .courses-box-hover ul li a {
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
}

.our-courses-section .courses-box-hover ul li:left-child {
    border-bottom: 0px solid #fff;
}

.our-courses-section .courses-box-hover h2 {
    text-align: center;
    background: #00a2f3;
    font-size: 22px;
    display: block;
    font-weight: 600;
    color: #fff;
    margin: 0;
    line-height: 60px;
}

.our-courses-section .courses-box-hover .button {
    background: #000;
    font-size: 15px;
    border: 1px solid #fff;
    line-height: 32px;
    color: #fff;
    display: inline-block;
    float: none;
}

.our-courses-section .courses-box-hover .button:hover {
    background: #00a2f3;
    border: 1px solid #00a2f3;
    color: #fff;
}

.courseenquirybtn{
    width: 100%;
    display: flex;
    margin: 0 auto;
    justify-content: center;
}
.explore-top-courses,
.top-management-colleges {
    /*background: #eff3f6 url('../img/course-bg.jpg');*/
}

.explore-top-courses .explore-box:hover {
    background: #f1f8ff;
}

.explore-top-courses .explore-box {
    text-align: left;
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 15px;
}

.explore-top-courses .explore-box ul {
    margin: 0px 0;
    padding: 0;
    display: inline-block;
}

.explore-top-courses .explore-box ul li {
    display: block;
    text-align: left;
    padding: 0px 0 0 10px;
    line-height: 28px;
}

.explore-top-courses .explore-box ul li:before {
    content: "\ea50";
    font-family: boxicons !important;
    color: #00a2f3;
    display: inline;
    position: relative;
    left: -8px;
    top: 1px;
}

.explore-top-courses .explore-box ul li a {
    color: #333;
    font-size: 16px;
}

.explore-top-courses .explore-box ul li a:hover {
    color: #00a2f3;
}

.explore-top-courses .explore-box h2 {
    font-size: 22px;
    text-align: left;
    display: block;
    font-weight: 600;
    color: #00a2f3;
    margin: 0 0 20px 0;
    border-bottom: 1px solid #e6e6e6;
    line-height: 60px;
}
.inner-top-header .top-course-header h1 {
    text-transform: uppercase;
    font-size: 52px;
    color: #fff;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.499) !important;
}

.inner-top-header .top-course-header h1 span {
    color: #00a2f3
}

.inner-top-header .top-course-header ul {
    margin: 0px 0;
    padding: 0;
    display: inline-block;
}

.inner-top-header .top-course-header ul li {
    display: inline;
    text-align: left;
    color: #fff;
    font-size: 22px;
    padding: 0px 30px 0 10px;
    line-height: 40px;
}

.inner-top-header .top-course-header ul li:before {
    content: "\ea50";
    font-family: boxicons !important;
    color: #fff;
    font-size: 25px;
    display: inline;
    position: relative;
    left: -8px;
    top: 2px;
}
.left-overviews-section {
    padding: 20px 0;
}

.course-overviews-section .nav-tabs .nav-link.active,
.course-overviews-section .nav-tabs .nav-link:hover {
    padding: 10px 20px;
    background: #3e6eb0;
    border-radius: 5px;
    font-size: 18px;
    color: #fff;
}

.course-overviews-section .nav-tabs .nav-link {
    padding: 10px 20px;
    border: 0px;
    margin: 0 2px 0;
    background: #f0f7ff;
    border-radius: 5px;
    font-size: 18px;
    color: #000;
}

.course-overviews-section .nav-tabs {
    border: 0px;
}

.widgets {
    margin: 0 0 20px 0;
    display: block;
}

.large-heading-bg {
    line-height: 30px;
    padding: 50px 0;
    margin: 0 0 20px 0;
}

.large-heading-bg h2 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
}

.top-streams-widget {
    border-radius: 10px;
    overflow: hidden;
    background: #f3f3f3;
    box-shadow: 5px 5px 10px #ccc;
}

.top-streams-widget ul {
    padding: 20px;
}

.top-streams-widget ul li {
    display: inline-block;
    font-size: 18px;
    width: 48%;
    padding: 5px;
}

.top-streams-widget ul li a {
    color: #000;
}

.top-streams-widget ul li a:hover {
    color: #018ed4;
}

.top-streams-widget ul li a::before {
    content: "\ea41";
    font-family: boxicons !important;
    color: #018ed4;
    font-size: 18px;
    display: inline;
    position: relative;
    left: -8px;
    top: 2px;
    border: 1px solid #018ed4;
    border-radius: 50%;
}

.top-manag-course-widget {
    background-size: cover;
    background-position: center center;
    padding: 130px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px #ccc;
}

.top-manag-course-widget h2 {
    color: #fff;
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 600;
    margin: 0 0 20px 0;
}

.top-manag-course-widget p {
    color: #fff;
    font-size: 18px;
}

p {
    line-height: 28px;
    font-style: 16px;
}


.top-get-more-details {
    display: block;
    position: relative;
    width: 100%;
    color: #fff;
    border-radius: 10px;
    box-shadow: 5px 5px 10px #ccc;
    text-align: center;
    overflow: hidden;
}

.management-courses-block {
    position: relative;
    z-index: 9;
    overflow: hidden;
    height: 100%;
    padding: 60px 20px;
}

.top-left-orange {
    position: absolute;
    top: 0px !important;
    left: 0px !important;
    margin: 0;
    transform: rotate(360deg);
}

.top-get-more-details h2 {
    font-size: 26px;
    color: #fff;
    margin: 0px 0;
    text-align: center;
}

.top-get-more-details h3 {
    font-size: 32px;
    font-weight: 600;
    color: #fff;
    margin: 0 0 30px 0;
    text-align: center;
}

.top-get-more-details .apply-now-btn {
    color: #00A3F4;
    background: #fff;
}

.green-overly {
    background: rgba(9,122,151, 0.7);
    background: linear-gradient(to right, rgba(9,122,151, 0.7) 0%, rgba(17,167,156, 0.7) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(9,122,151, 0.7)', endColorstr='rgba(17,167,156, 0.7)',GradientType=1 );
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.coursestreamtag a{
    color: #000;
}

.coursestreamtag a:hover{
    color: #018ed4;
}


.topColgs .testi_wrp {
    position: relative;
    width: 100%;
    display: block;
    float: left;
}

.topColgs .loop_colleges .testi_wrp {
    padding: 10px;
}

.topColgs .testi_wrp:hover .testi_hover {
    display: block;
}

.topColgs .testi_wrp .testi_hover {
    width: 95%;
    height: 93%;
    display: none;
    background: #00A3F4;
    position: absolute;
    left: 3%;
    border-radius: 10px;
    top: 4%;
    transform: rotate(3.45deg);
}

.topColgs .loop_colleges .inner_wrp {
    padding: 0px 0 20px 0 !important;
}

.topColgs .testi_wrp .inner_wrp {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    padding: 15px;
    position: relative;
    box-shadow: 0px 3px 8px rgb(0 0 0 / 12%);
    border-radius: 10px;
}

.topColgs .loop_colleges .rating_box {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 86px;
    font-size: 18px;
    color: #000000;
    height: 44px;
    position: absolute;
    top: 15px;
    right: 15px;
    text-align: center;
    line-height: 44px;
}

.topColgs .loop_colleges h3 {
    font-style: normal;
    font-weight: 700;
    width: 100%;
    display: block;
    float: left;
    font-size: 18px;
    padding: 0 15px !important;
    line-height: 26px;
    margin: 10px 0;
    padding: 0;
    color: #000000;
}


.topColgs .loop_colleges p {
    font-style: normal;
    padding: 0 15px !important;
    font-weight: 400;
    margin-bottom: 20px !important;
    font-size: 16px;
    width: 100%;
    display: block;
    float: left;
    line-height: 20px;
    margin: 0px;
    padding: 0px;
    color: rgba(0, 0, 0, 0.6);
}

.topColgs .loop_colleges .college_button_wrp {
    width: 255px;
    display: block;
    margin: 0 auto;
}

.topColgs .loop_colleges a {
    height: 44px;
    width: 121px;
    display: block;
    float: left;
    font-size: 13px;
    color: #00A3F4;
    font-weight: 500;
    border: 1px solid #00A3F4;
    border-radius: 10px;
    line-height: 44px;
    text-align: center;
}

.topColgs .loop_colleges .college_button_wrp .active_bt {
    background: #00A3F4;
    color: #fff;
    margin-right: 10px;
}

.topColgs .loop_colleges .rating_box {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 86px;
    font-size: 18px;
    color: #000000;
    height: 44px;
    position: absolute;
    top: 15px;
    right: 15px;
    text-align: center;
    line-height: 44px;
}

.loop_colleges .rating_box .rating_act {
    color: #FFB108;
    position: relative;
    top: -4px;
}

.courseviewcollege img{
    object-fit: contain !important;
}

.inner-top-header .top-course-header h1 {
    text-transform: uppercase;
    font-size: 52px;
    color: #fff;
    font-weight: bold
}

.inner-top-header .top-course-header h1 span {
    color: #00a2f3
}

.inner-top-header .top-course-header ul {
    margin: 0px 0;
    padding: 0;
    display: inline-block;
}

.inner-top-header .top-course-header ul li {
    display: inline;
    text-align: left;
    color: #fff;
    font-size: 22px;
    padding: 0px 30px 0 10px;
    line-height: 40px;
}

.inner-top-header .top-course-header ul li:before {
    content: "\ea50";
    font-family: boxicons !important;
    color: #fff;
    font-size: 25px;
    display: inline;
    position: relative;
    left: -8px;
    top: 2px;
}
.left-overviews-section {
    padding: 20px 0;
}

.course-overviews-section .nav-tabs .nav-link.active,
.course-overviews-section .nav-tabs .nav-link:hover {
    padding: 10px 20px;
    background: #3e6eb0;
    border-radius: 5px;
    font-size: 18px;
    color: #fff;
}

.course-overviews-section .nav-tabs .nav-link {
    padding: 10px 20px;
    border: 0px;
    margin: 0 2px 0;
    background: #f0f7ff;
    border-radius: 5px;
    font-size: 18px;
    color: #000;
}

.course-overviews-section .nav-tabs {
    border: 0px;
}

.widgets {
    margin: 0 0 20px 0;
    display: block;
}

.large-heading-bg {
    line-height: 30px;
    padding: 50px 0;
    margin: 0 0 20px 0;
}

.large-heading-bg h2 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
}

.top-streams-widget {
    border-radius: 10px;
    overflow: hidden;
    background: #f3f3f3;
    box-shadow: 5px 5px 10px #ccc;
}

.top-streams-widget ul {
    padding: 20px;
}

.top-streams-widget ul li {
    display: inline-block;
    font-size: 18px;
    width: 48%;
    padding: 5px;
}

.top-streams-widget ul li a {
    color: #000;
}

.top-streams-widget ul li a:hover {
    color: #018ed4;
}

.top-streams-widget ul li a::before {
    content: "\ea41";
    font-family: boxicons !important;
    color: #018ed4;
    font-size: 18px;
    display: inline;
    position: relative;
    left: -8px;
    top: 2px;
    border: 1px solid #018ed4;
    border-radius: 50%;
}

.top-manag-course-widget {
    background-size: cover;
    background-position: center center;
    padding: 130px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px #ccc;
}

.top-manag-course-widget h2 {
    color: #fff;
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 600;
    margin: 0 0 20px 0;
}

.top-manag-course-widget p {
    color: #fff;
    font-size: 18px;
}

p {
    line-height: 28px;
    font-style: 16px;
}


.fullwidth li{
    width: 100% !important;
}
.topColgs .testi_wrp {
    position: relative;
    width: 100%;
    display: block;
    float: left;
}

.topColgs .loop_colleges .testi_wrp {
    padding: 10px;
}

.topColgs .testi_wrp:hover .testi_hover {
    display: block;
}

.topColgs .testi_wrp .testi_hover {
    width: 95%;
    height: 93%;
    display: none;
    background: #00A3F4;
    position: absolute;
    left: 3%;
    border-radius: 10px;
    top: 4%;
    transform: rotate(3.45deg);
}

.topColgs .loop_colleges .inner_wrp {
    padding: 0px 0 20px 0 !important;
}

.topColgs .testi_wrp .inner_wrp {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    padding: 15px;
    position: relative;
    box-shadow: 0px 3px 8px rgb(0 0 0 / 12%);
    border-radius: 10px;
}

.topColgs .loop_colleges .rating_box {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 86px;
    font-size: 18px;
    color: #000000;
    height: 44px;
    position: absolute;
    top: 15px;
    right: 15px;
    text-align: center;
    line-height: 44px;
}

.topColgs .loop_colleges h3 {
    font-style: normal;
    font-weight: 700;
    width: 100%;
    display: block;
    float: left;
    font-size: 18px;
    padding: 0 15px !important;
    line-height: 26px;
    margin: 10px 0;
    padding: 0;
    color: #000000;
}


.topColgs .loop_colleges p {
    font-style: normal;
    padding: 0 15px !important;
    font-weight: 400;
    margin-bottom: 20px !important;
    font-size: 16px;
    width: 100%;
    display: block;
    float: left;
    line-height: 20px;
    margin: 0px;
    padding: 0px;
    color: rgba(0, 0, 0, 0.6);
}

.topColgs .loop_colleges .college_button_wrp {
    width: 255px;
    display: block;
    margin: 0 auto;
}

.topColgs .loop_colleges a {
    height: 44px;
    width: 121px;
    display: block;
    float: left;
    font-size: 13px;
    color: #00A3F4;
    font-weight: 500;
    border: 1px solid #00A3F4;
    border-radius: 10px;
    line-height: 44px;
    text-align: center;
}

.topColgs .loop_colleges .college_button_wrp .active_bt {
    background: #00A3F4;
    color: #fff;
    margin-right: 10px;
}

.topColgs .loop_colleges .rating_box {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 86px;
    font-size: 18px;
    color: #000000;
    height: 44px;
    position: absolute;
    top: 15px;
    right: 15px;
    text-align: center;
    line-height: 44px;
}

.loop_colleges .rating_box .rating_act {
    color: #FFB108;
    position: relative;
    top: -4px;
}

.inlineflex li {
  display: inline-flex !important;
}

.broucher-button {
  background: #10ac20;
 
  color: #ffffff;
}

.broucher-button:hover {
  background: #0a64b2;
  color: #fff;
}

@media only screen and (min-width: 427px) and (max-width: 480px) {
  /* Insert your CSS styles here */
  .college-write-button {
    margin-top: 0px !important;
    margin-left: 5px !important;
    /* Your CSS styles for this class */
  }
.college-button-download{
  text-align: center;

}
  
}


.left-overviews-section {
    padding: 20px 0;
}

.course-overviews-section .nav-tabs .nav-link.active,
.course-overviews-section .nav-tabs .nav-link:hover {
    padding: 10px 20px;
    background: #3e6eb0;
    border-radius: 5px;
    font-size: 18px;
    color: #fff;
}

.course-overviews-section .nav-tabs .nav-link {
    padding: 10px 20px;
    border: 0px;
    margin: 0 2px 0;
    background: #f0f7ff;
    border-radius: 5px;
    font-size: 18px;
    color: #000;
}

.course-overviews-section .nav-tabs {
    border: 0px;
}

.widgets {
    margin: 0 0 20px 0;
    display: block;
}

.large-heading-bg {
    line-height: 30px;
    padding: 50px 0;
    margin: 0 0 20px 0;
}

.large-heading-bg h2 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
}

.top-streams-widget {
    border-radius: 10px;
    overflow: hidden;
    background: #f3f3f3;
    box-shadow: 5px 5px 10px #ccc;
}

.top-streams-widget ul {
    padding: 20px;
}

.top-streams-widget ul li {
    display: inline-block;
    font-size: 18px;
    width: 48%;
    padding: 5px;
}

.top-streams-widget ul li a {
    color: #000;
}

.top-streams-widget ul li a:hover {
    color: #018ed4;
}

.top-streams-widget ul li a::before {
    content: "\ea41";
    font-family: boxicons !important;
    color: #018ed4;
    font-size: 18px;
    display: inline;
    position: relative;
    left: -8px;
    top: 2px;
    border: 1px solid #018ed4;
    border-radius: 50%;
}

.top-manag-course-widget {
    background-size: cover;
    background-position: center center;
    padding: 130px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px #ccc;
}

.top-manag-course-widget h2 {
    color: #fff;
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 600;
    margin: 0 0 20px 0;
}

.top-manag-course-widget p {
    color: #fff;
    font-size: 18px;
}

p {
    line-height: 28px;
    font-style: 16px;
}


.panel-title > a:before {
    float: right !important;
    content:"\f068";
    padding-right: 5px;
}
.panel-title > a.collapsed:before {
    float: right !important;
    content:"\f067";
}
.panel-title > a:hover, 
.panel-title > a:active, 
.panel-title > a:focus  {
    text-decoration:none;
}

.bs-course-detail-card {
    padding: 40px;
    border-radius: 3px;
    margin-top: 30px !important;
    margin: 0 auto;
}
.bs-shadow-boxed {
    box-shadow: 0 2px 30px 0 rgb(0 0 0 / 10%);
}

.bs-course-detail-label {
    margin-bottom: 20px;
}

.bs-course-detail-label span {
    background-color: #f6f6f6;
    border: 1px solid #eaeaea;
    border-radius: 3px;
    padding: 5px 10px;
    margin-right: 19px;
}

.bs-streams {
    margin-left: -10px;
    margin-bottom: 10px;
}

.bs-course-cost {
    text-align: right;
}

.bs-course-detail-card .bs-orange-link {
    float: right;
}
.bs-orange-link {
    color: #f47d2a;
}

.bs-course-cost span:first-child {
    font-size: 20px;
    margin-right: 5px;
    font-weight: 500;
}

.bs-course-cost span:last-child {
    font-size: 12px;
}

.bs-course-detail-card.bs-shadow-boxed h6 {
    font-size: 18px;
}

.bs-streams {
    margin-left: -10px;
}

.bs-streams span:last-child {
    border-right: none;
}

.bs-streams span {
    padding: 0 10px;
    border-right: 1px solid #ccc;
    color: #666;
}
.write-reviews input, .write-reviews textarea {
    border-radius: 5px;
    width: 100%;
    margin: 0 auto 30px auto !important;
    border: 1px solid #ccc;
    padding: 15px;
}

.text-gray{
    color: gray !important;
}

.write-reviews label {
    font-size: 22px;
   
}

.MuiRating-label  {
    font-size: 36px !important;
}

.write-reviews .btnrating .bi-star-fill {
    color: #e6e6e6;
    font-size: 36px;
}

@media (max-width: 768px) {
    .breadcrumbs .d-flex {
        display: block !important;
    }

    .breadcrumbs ol {
        display: block;
    }

    .breadcrumbs ol li {
        display: inline-block;
    }
}
@media (min-width:320px) and (max-width: 767px) {
    .MuiRating-label  {
        font-size: 22px !important;
    }
    
}
@media (min-width:320px) and (max-width: 374px) {
    .reviewCardText  {
        font-size: 14px !important;
    }
    
}


.top-colleges .test_content_wrp {padding:20px;}

.top-colleges .testi_wrp
{
    padding: 10px;
}

.features-infra ul {
    margin: 0;
    padding: 0;
}

.features-infra ul li {
    display: inline-block;
    width: 13%; 
    float: left;
    text-align: center;
    margin: 0 1%;
    padding: 0;
}

.features-infra ul li a {
    display: inline-block;
    width: 100%;
    float: left;
    text-align: center;
    background: #fff;
    border-radius: 10px;
    box-shadow: 1px 0px 10px #ccc;
    margin: 0;
    padding: 10px !important;
}

.features-infra ul li a:hover {
    box-shadow: 1px 0px 10px #01a3f6;
}

.features-infra ul li img {
    margin: 0 0 0px 0
}

.features-infra ul li h3 {
    color: #000;
    font-size: 18px;
    text-align: center;
    margin: 2px 0;
}





@media only screen and (max-device-width:1250px) {
    .features-infra ul li{
        width: 18% !important;
    }
}



@media only screen and (max-device-width:1024px) {
    .features-infra ul li{
        width: 18% !important;
    }
}

@media only screen and (max-device-width:992px) {
    .features-infra ul li{
        width: 23% !important;
    }
}

@media only screen and (max-device-width:768px) {
    .features-infra ul li{
        width: 31% !important;
    }
}

@media only screen and (max-device-width:576px) {
    .features-infra ul li{
        width: 48% !important;
    }
}

@media only screen and (max-device-width:425px) {
    .features-infra ul li{
      width: 48% !important;
    }
}

.disabled{
    cursor: pointer;
}

.border-radius-20 {
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 1px 0px 10px #ccc;
}

.blue-bg {
    background: #01a3f4;
}

.college-location img {
    margin: 0 0 0px 0;
}

.college-location h2 {
    margin: 0 0 15px 0;
    color: #fff;
    font-size: 25px;
    font-weight: bold;
}

.college-location p {
    margin: 0 0 0px 0;
    color: #fff;
    font-size: 17px;
}

.college-location-details {
    padding: 30px;
}
.inner-top-header .college-block {
    background: rgba(0, 0, 0, 0.5);
    padding: 20px
}

.inner-top-header .top-college-header .rating_box {
    color: #fff;
    margin-top: 25px;
}

.inner-top-header .top-college-header h1 {
    text-transform: uppercase;
    line-height: 83px;
    font-size: 42px;
    float: left;
    color: #fff;
    font-weight: bold;
    width: 95%;
}

.inner-top-header .top-college-header h1 img {
    vertical-align: middle;
    margin: 0 20px 0 0;
    float: left;
}

.inner-top-header .top-college-header h1 span {
    color: #00a2f3
}

.inner-top-header .top-college-header ul {
    margin: 0px 0;
    padding: 0;
    display: inline-block;
}

.inner-top-header .top-college-header ul li {
    display: inline;
    text-align: left;
    color: #fff;
    font-size: 20px;
    padding: 0px 30px 0 10px;
    line-height: 40px;
}

.inner-top-header .top-college-header ul li .bi-pin-map-fill {
    color: red;
}

.reviews-button {
    background: #fff;
    margin: 0 0 0 20px;
    color: #000;
}

.reviews-button:hover {
    background: #3e6eb0;
    color: #fff;
}

.spac_02 {
    padding: 20px 0;
}

.rating_box {
    float: right;
}

.rating_box .rating_act {
    color: #ffb107;
}

.heading-popup h2{
  text-align: center;
  padding-top: 30px;
}

#header-form .form-group {
  margin-bottom: 15px;
}

form .group_field {
  display: block;
}

/*form*/
form fieldset{
  width: 100%;  
  display: inline-flex;
}
form fieldset input,
form fieldset select {
    width: 100%;
    border-radius: 4px;
    padding: 17px 30px;
    margin-top: 15px;
    border: none;
    box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
    background: #fff;
    outline: none;
    color: #8a8a8a;
    font-size: 14px;
    font-weight: 400;
}
form fieldset button{
    width: 100%;
    border-radius: 50px;
    padding: 17px 30px;
    margin-top: 22px;
    border: none;
    box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
    background: #ff6525;
    outline: none;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    border: none;
    text-align: center;
}
textarea:focus,
input:focus,
/* input[type]:focus{   
    border: 1px solid #ff6525;
} */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}








form .group_field {
  display: block;
}


.fHorizon {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
.span-horzon{
  color: gray; font-style: italic;
}



.horizonmain .header-form {
  /*z-index: 8888888;*/
  z-index: 8888;
}

.topColgs .testi_wrp {
    position: relative;
    width: 100%;
    display: block;
    float: left;
}

.topColgs .loop_colleges .testi_wrp {
    padding: 10px;
}

.topColgs .testi_wrp:hover .testi_hover {
    display: block;
}

.topColgs .testi_wrp .testi_hover {
    width: 95%;
    height: 93%;
    display: none;
    background: #00A3F4;
    position: absolute;
    left: 3%;
    border-radius: 10px;
    top: 4%;
    transform: rotate(3.45deg);
}

.topColgs .loop_colleges .inner_wrp {
    padding: 0px 0 20px 0 !important;
}

.topColgs .testi_wrp .inner_wrp {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    padding: 15px;
    position: relative;
    box-shadow: 0px 3px 8px rgb(0 0 0 / 12%);
    border-radius: 10px;
}

.topColgs .loop_colleges .rating_box {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 86px;
    font-size: 18px;
    color: #000000;
    height: 44px;
    position: absolute;
    top: 15px;
    right: 15px;
    text-align: center;
    line-height: 44px;
}

.topColgs .loop_colleges h3 {
    font-style: normal;
    font-weight: 700;
    width: 100%;
    display: block;
    float: left;
    font-size: 18px;
    padding: 0 15px !important;
    line-height: 26px;
    margin: 10px 0;
    padding: 0;
    color: #000000;
}


.topColgs .loop_colleges p {
    font-style: normal;
    padding: 0 15px !important;
    font-weight: 400;
    margin-bottom: 20px !important;
    font-size: 16px;
    width: 100%;
    display: block;
    float: left;
    line-height: 20px;
    margin: 0px;
    padding: 0px;
    color: rgba(0, 0, 0, 0.6);
}

.topColgs .loop_colleges .college_button_wrp {
    width: 255px;
    display: block;
    margin: 0 auto;
}

.topColgs .loop_colleges a {
    height: 44px;
    width: 121px;
    display: block;
    float: left;
    font-size: 13px;
    color: #00A3F4;
    font-weight: 500;
    border: 1px solid #00A3F4;
    border-radius: 10px;
    line-height: 44px;
    text-align: center;
}

.topColgs .loop_colleges .college_button_wrp .active_bt {
    background: #00A3F4;
    color: #fff;
    margin-right: 10px;
}

.topColgs .loop_colleges .rating_box {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 86px;
    font-size: 18px;
    color: #000000;
    height: 44px;
    position: absolute;
    top: 15px;
    right: 15px;
    text-align: center;
    line-height: 44px;
}

.loop_colleges .rating_box .rating_act {
    color: #FFB108;
    position: relative;
    top: -4px;
}

.schoollist .wrapcontent img{


    object-fit: contain !important;
}
.features-infra ul {
    margin: 0;
    padding: 0;
}


.features-infra ul li {
    display: inline-block;
    width: 13%;
    float: left;
    text-align: center;
    margin: 0 1%;
    padding: 0;
}

.features-infra ul li a {
    display: inline-block;
    width: 100%;
    float: left;
    text-align: center;
    background: #fff;
    border-radius: 10px;
    box-shadow: 1px 0px 10px #ccc;
    margin: 0;
    padding: 10px !important;
}

.features-infra ul li a:hover {
    box-shadow: 1px 0px 10px #01a3f6;
}

.features-infra ul li img {
    margin: 0 0 0px 0;
    width: 100%;
}

.features-infra ul li h3 {
    color: #000;
    font-size: 18px;
    text-align: center;
    margin: 2px 0;
}

@media only screen and (max-device-width:1250px) {
    .features-infra ul li{
        width: 18% !important;
    }
}



@media only screen and (max-device-width:1024px) {
    .features-infra ul li{
        width: 18% !important;
    }
}

@media only screen and (max-device-width:992px) {
    .features-infra ul li{
        width: 23% !important;
    }
}

@media only screen and (max-device-width:768px) {
    .features-infra ul li{
        width: 31% !important;
    }
}

@media only screen and (max-device-width:576px) {
    .features-infra ul li{
        width: 48% !important;
    }
}

@media only screen and (max-device-width:425px) {
    .features-infra ul li{
      width: 48% !important;
    }
}

.disabled{
    cursor: pointer;
}

.schooladdress{
    min-height: 166px !important;
}
.inner-top-header .college-block {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
}

.inner-top-header .top-college-header .rating_box {
  color: #fff;
  margin-top: 25px;
}

.inner-top-header .top-college-header h1 span {
  color: #00a2f3;
}

.inner-top-header .top-college-header ul {
  margin: 0px 0;
  padding: 0;
  display: inline-block;
}

.inner-top-header .top-college-header ul li {
  display: inline;
  text-align: left;
  color: #fff;
  font-size: 20px;
  padding: 0px 30px 0 10px;
  line-height: 40px;
}

.inner-top-header .top-college-header ul li .bi-pin-map-fill {
  color: red;
}

.reviews-button {
  background: #fff;
  margin: 0 0 0 20px;
  color: #000;
}

.reviews-button:hover {
  background: #3e6eb0;
  color: #fff;
}

.rating_box {
  float: right;
}

.rating_box .rating_act {
  color: #ffb107;
}

.placementtable {
  width: 750px;
  border-collapse: collapse;
  margin: 50px auto;
}

/* Zebra striping */
.placementtable tr:nth-of-type(odd) {
  background: #eee;
}

.placementtable th {
  background: #3498db;
  color: white;
  font-weight: bold;
}

.placementtable td,
.placementtable th {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
  font-size: 18px;
}

/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  .placementtable {
    width: 100%;
  }

  /* Force table to not be like tables anymore */
  /*table, thead, tbody, th, td, tr { 
		display: block; 
	}*/

  /* Hide table headers (but not display: none;, for accessibility) */
  .placementtable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .placementtable tr {
    border: 1px solid #ccc;
  }

  .placementtable td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  .placementtable td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    /* Label the data */
    content: attr(data-column);

    color: #000;
    font-weight: bold;
  }
}

.removesomepadding {
  padding: 120px 0 60px 0 !important;
}

.broucher-button {
  background: #10ac20;
  margin: 0 0 0 20px ; 
  color: #ffffff;
}

.broucher-button:hover {
  background: #0a64b2;
  color: #fff;
}



@media only screen and (min-device-width: 320px) and (max-device-width: 480px){
	.broucher-button {
		margin:0px !important;
	}

}





.header-form h2{color: #fff;
  font-size:24px; margin-bottom:20px;
  font-weight: 700;}

.download-app{padding:15px 0;}
.download-app img{margin:0 10px 0 0}

#header-form{background:rgba(0,0,0,0.6); color:#fff; border:1px solid #fff; padding:30px; border-radius:30px;}

#header-form label{font-size:17px; margin-bottom:10px; color:#fff;}
#header-form .form-group{margin-bottom:15px;}

#header-form input, #header-form textarea{background:rgba(255,255,255,0.4); color:#fff !important; padding:10px; border-radius:10px;}

::-webkit-input-placeholder,
:-ms-input-placeholder,:-moz-placeholder,::-moz-placeholder
::placeholder, :-ms-input-placeholder,
input::placeholder {
    font-weight: bold;
    opacity: .5;
    color: red;
}
#header-form .form-control{border:0px;}




#header-form input, #header-form textarea {
  background: rgba(255,255,255,0.4);
  color: #fff !important;
  padding: 10px;
  border-radius: 10px;
}
input::-webkit-input-placeholder { /* Edge */
  color: #ccc !important;
}


.submit_message {
  background: #00A3F4;
  border-radius: 15px;
  width: 171px;
  height: 56px;
  border: 0px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.small_submit_message {
  background: #00A3F4;
  border-radius: 15px;
  width: 171px;
  height: 50px;
  border: 0px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.mrb{
  margin-bottom: 15px !important;
}
.error{
  color:red !important;
}
#inputID:-ms-input-placeholder {
  color:#c5c8cc;
  opacity: 1;
}
#inputID::placeholder {
  color:#c5c8cc;
  opacity: 1;
}
.customborder{
  border: 1px solid black !important;
    height: 46px;
}


@media only screen and (min-device-width: 320px) and (max-device-width: 480px){

  .widtth-50{
    width: 80vh;
  }
}





.left-overviews-section {
    padding: 20px 0;
}

.course-overviews-section .nav-tabs .nav-link.active,
.course-overviews-section .nav-tabs .nav-link:hover {
    padding: 10px 20px;
    background: #3e6eb0;
    border-radius: 5px;
    font-size: 18px;
    color: #fff;
}

.course-overviews-section .nav-tabs .nav-link {
    padding: 10px 20px;
    border: 0px;
    margin: 0 2px 0;
    background: #f0f7ff;
    border-radius: 5px;
    font-size: 18px;
    color: #000;
}

.course-overviews-section .nav-tabs {
    border: 0px;
}

.widgets {
    margin: 0 0 20px 0;
    display: block;
}

.large-heading-bg {
    line-height: 30px;
    padding: 50px 0;
    margin: 0 0 20px 0;
}

.large-heading-bg h2 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
}

.top-streams-widget {
    border-radius: 10px;
    overflow: hidden;
    background: #f3f3f3;
    box-shadow: 5px 5px 10px #ccc;
}

.top-streams-widget ul {
    padding: 20px;
}

.top-streams-widget ul li {
    display: inline-block;
    font-size: 18px;
    width: 48%;
    padding: 5px;
}

.top-streams-widget ul li a {
    color: #000;
}

.top-streams-widget ul li a:hover {
    color: #018ed4;
}

.top-streams-widget ul li a::before {
    content: "\ea41";
    font-family: boxicons !important;
    color: #018ed4;
    font-size: 18px;
    display: inline;
    position: relative;
    left: -8px;
    top: 2px;
    border: 1px solid #018ed4;
    border-radius: 50%;
}

.top-manag-course-widget {
    background-size: cover;
    background-position: center center;
    padding: 130px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px #ccc;
}

.top-manag-course-widget h2 {
    color: #fff;
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 600;
    margin: 0 0 20px 0;
}

.top-manag-course-widget p {
    color: #fff;
    font-size: 18px;
}

p {
    line-height: 28px;
    font-style: 16px;
}
.features-infra ul {
    margin: 0;
    padding: 0;
}

.features-infra ul li {
    display: inline-block;
    width: 13%;
    float: left;
    text-align: center;
    margin: 10px 1% 0px !important;
    padding: 0;
}

.features-infra ul li a {
    display: inline-block;
    width: 100%;
    float: left;
    text-align: center;
    background: #fff;
    border-radius: 10px;
    box-shadow: 1px 0px 10px #ccc;
    margin: 0;
    padding: 10px !important;
}

.features-infra ul li a:hover {
    box-shadow: 1px 0px 10px #01a3f6;
}

.features-infra ul li img {
    margin: 0 0 0px 0;
    /*height: auto;*/
    object-fit: contain;
}

.features-infra ul li h3 {
    color: #000;
    font-size: 18px;
    text-align: center;
    margin: 2px 0;
}


@media only screen and (max-device-width:1250px) {
    .features-infra ul li{
        width: 18% !important;
    }
}



@media only screen and (max-device-width:1024px) {
    .features-infra ul li{
        width: 18% !important;
    }
}

@media only screen and (max-device-width:992px) {
    .features-infra ul li{
        width: 23% !important;
    }
}

@media only screen and (max-device-width:768px) {
    .features-infra ul li{
        width: 31% !important;
    }
}

@media only screen and (max-device-width:576px) {
    .features-infra ul li{
        width: 48% !important;
    }
}

@media only screen and (max-device-width:425px) {
    .features-infra ul li{
      width: 48% !important;
    }
}

.disabled{
    cursor: pointer;
}

.border-radius-20 {
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 1px 0px 10px #ccc;
}

.blue-bg {
    background: #01a3f4;
}

.college-location img {
    margin: 0 0 0px 0;
}

.college-location h2 {
    margin: 0 0 15px 0;
    color: #fff;
    font-size: 28px;
    font-weight: bold;
}

.college-location p {
    margin: 0 0 0px 0;
    color: #fff;
    font-size: 22px;
}

.college-location-details {
    padding: 30px;
}
.inner-top-header .top-course-header h1 {
    text-transform: uppercase;
    font-size: 52px;
    color: #fff;
    font-weight: bold
}

.inner-top-header .top-course-header h4 {
    font-size: 30px;
    color: #fff;
}

.inner-top-header .top-course-header h1 span {
    color: #00a2f3
}

.inner-top-header .top-course-header ul li:before {
    content: "\ea50";
    font-family: boxicons !important;
    color: #fff;
    font-size: 25px;
    display: inline;
    position: relative;
    left: -8px;
    top: 2px;
}
.bg-dark1{
    background: rgba(0, 0, 0, 0.499);
}
.left-overviews-section {
    padding: 20px 0;
}

.course-overviews-section .nav-tabs .nav-link.active,
.course-overviews-section .nav-tabs .nav-link:hover {
    padding: 10px 20px;
    background: #3e6eb0;
    border-radius: 5px;
    font-size: 18px;
    color: #fff;
}

.course-overviews-section .nav-tabs .nav-link {
    padding: 10px 20px;
    border: 0px;
    margin: 0 2px 0;
    background: #f0f7ff;
    border-radius: 5px;
    font-size: 18px;
    color: #000;
}

.course-overviews-section .nav-tabs {
    border: 0px;
}

.widgets {
    margin: 0 0 20px 0;
    display: block;
}

.large-heading-bg {
    line-height: 30px;
    padding: 50px 0;
    margin: 0 0 20px 0;
}

.large-heading-bg h2 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
}

.top-streams-widget {
    border-radius: 10px;
    overflow: hidden;
    background: #f3f3f3;
    box-shadow: 5px 5px 10px #ccc;
}

.top-streams-widget ul {
    padding: 20px;
}

.top-streams-widget ul li {
    display: inline-block;
    font-size: 18px;
    width: 48%;
    padding: 5px;
}

.top-streams-widget ul li a {
    color: #000;
}

.top-streams-widget ul li a:hover {
    color: #018ed4;
}

.top-streams-widget ul li a::before {
    content: "\ea41";
    font-family: boxicons !important;
    color: #018ed4;
    font-size: 18px;
    display: inline;
    position: relative;
    left: -8px;
    top: 2px;
    border: 1px solid #018ed4;
    border-radius: 50%;
}

.top-manag-course-widget {
    background-size: cover;
    background-position: center center;
    padding: 130px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px #ccc;
}

.top-manag-course-widget h2 {
    color: #fff;
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 600;
    margin: 0 0 20px 0;
}

.top-manag-course-widget p {
    color: #fff;
    font-size: 18px;
}

p {
    line-height: 28px;
    font-style: 16px;
}

.inner-top-header .top-course-header h1 {
    text-transform: uppercase;
    font-size: 52px;
    color: #fff;
    font-weight: bold
}

.inner-top-header .top-course-header h4 {
    font-size: 30px;
    color: #fff;
}

.inner-top-header .top-course-header h1 span {
    color: #00a2f3
}

.inner-top-header .top-course-header ul li:before {
    content: "\ea50";
    font-family: boxicons !important;
    color: #fff;
    font-size: 25px;
    display: inline;
    position: relative;
    left: -8px;
    top: 2px;
}
.bg-dark1{
    background: rgba(0, 0, 0, 0.5) !important;
    }

.inner-top-header .top-course-header h1 {
    text-transform: uppercase;
    font-size: 52px;
    color: #fff;
    font-weight: bold
}

.inner-top-header .top-course-header h4 {
    font-size: 30px;
    color: #fff;
}

.inner-top-header .top-course-header h1 span {
    color: #00a2f3
}

.inner-top-header .top-course-header ul li:before {
    content: "\ea50";
    font-family: boxicons !important;
    color: #fff;
    font-size: 25px;
    display: inline;
    position: relative;
    left: -8px;
    top: 2px;
}
.bg-dark1{
    background: rgba(0, 0, 0, 0.5) !important;
    }

.inner-top-header .top-course-header h1 {
    text-transform: uppercase;
    font-size: 52px;
    color: #fff;
    font-weight: bold
}

.inner-top-header .top-course-header h4 {
    font-size: 30px;
    color: #fff;
}

.inner-top-header .top-course-header h1 span {
    color: #00a2f3
}

.inner-top-header .top-course-header ul li:before {
    content: "\ea50";
    font-family: boxicons !important;
    color: #fff;
    font-size: 25px;
    display: inline;
    position: relative;
    left: -8px;
    top: 2px;
}
.bg-dark1{
background: rgba(0, 0, 0, 0.5) !important;
}

.inner-top-header .top-course-header h1 {
    text-transform: uppercase;
    font-size: 52px;
    color: #fff;
    font-weight: bold
}

.inner-top-header .top-course-header h4 {
    font-size: 30px;
    color: #fff;
}

.inner-top-header .top-course-header h1 span {
    color: #00a2f3
}

.inner-top-header .top-course-header ul li:before {
    content: "\ea50";
    font-family: boxicons !important;
    color: #fff;
    font-size: 25px;
    display: inline;
    position: relative;
    left: -8px;
    top: 2px;
}
.bg-dark1{
    background: rgba(0, 0, 0, 0.5) !important;
    }
.ql-align-center{
    text-align: center;
}

.left-overviews-section {
    padding: 20px 0;
}

/*.overviews-course h4 span {
    color: #00a2f3
}*/

.course-overviews-section .nav-tabs .nav-link.active,
.course-overviews-section .nav-tabs .nav-link:hover {
    padding: 10px 20px;
    background: #3e6eb0;
    border-radius: 5px;
    font-size: 18px;
    color: #fff;
}

.course-overviews-section .nav-tabs .nav-link {
    padding: 10px 20px;
    border: 0px;
    margin: 0 2px 0;
    background: #f0f7ff;
    border-radius: 5px;
    font-size: 18px;
    color: #000;
}

.course-overviews-section .nav-tabs {
    border: 0px;
}

.widgets {
    margin: 0 0 20px 0;
    display: block;
}

.large-heading-bg {
    line-height: 30px;
    padding: 50px 0;
    margin: 0 0 20px 0;
}

.large-heading-bg h2 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
}

.top-streams-widget {
    border-radius: 10px;
    overflow: hidden;
    background: #f3f3f3;
    box-shadow: 5px 5px 10px #ccc;
}

.top-streams-widget ul {
    padding: 20px;
}

.top-streams-widget ul li {
    display: inline-block;
    font-size: 18px;
    width: 48%;
    padding: 5px;
}

.top-streams-widget ul li a {
    color: #000;
}

.top-streams-widget ul li a:hover {
    color: #018ed4;
}

.top-streams-widget ul li a::before {
    content: "\ea41";
    font-family: boxicons !important;
    color: #018ed4;
    font-size: 18px;
    display: inline;
    position: relative;
    left: -8px;
    top: 2px;
    border: 1px solid #018ed4;
    border-radius: 50%;
}

.top-manag-course-widget {
    background-size: cover;
    background-position: center center;
    padding: 130px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px #ccc;
}

.top-manag-course-widget h2 {
    color: #fff;
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 600;
    margin: 0 0 20px 0;
}

.top-manag-course-widget p {
    color: #fff;
    font-size: 18px;
}

p {
    line-height: 28px;
    font-style: 16px;
}


.inner-top-header .college-block {
    background: rgba(0, 0, 0, 0.5);
    padding: 20px
}

.inner-top-header .top-college-header .rating_box {
    color: #fff;
    margin-top: 25px;
}

.inner-top-header .top-college-header h1 {
    text-transform: capitalize;
    line-height: 83px;
    font-size: 42px;
    float: left;
    color: #fff;
    font-weight: bold
}

.inner-top-header .top-college-header h1 img {
    vertical-align: middle;
    margin: 0 20px 0 0;
    float: left;
}

.inner-top-header .top-college-header h1 span {
    color: #00a2f3
}

.inner-top-header .top-college-header ul {
    margin: 0px 0;
    padding: 0;
    display: inline-block;
}

.inner-top-header .top-college-header ul li {
    display: inline;
    text-align: left;
    color: #fff;
    font-size: 20px;
    padding: 0px 30px 0 10px;
    line-height: 40px;
}

.inner-top-header .top-college-header ul li .bi-pin-map-fill {
    color: red;
}

.reviews-button {
    background: #fff;
    margin: 0 0 0 20px;
    color: #000;
}

.reviews-button:hover {
    background: #3e6eb0;
    color: #fff;
}

.spac_02 {
    padding: 20px 0;
}

.rating_box {
    float: right;
}

.rating_box .rating_act {
    color: #ffb107;
}

.hero_explore {
    width: 75%;
    display: block;
    float: left;
    position: relative;
}

.hero_explore .ex-field {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.14);
    border: 0px;
    border-radius: 10px;
    height: 64px;
    padding: 0 163px 0 40px;
}

.hero_explore img {
    position: absolute;
    z-index: 1;
    left: 13px;
    top: 20px;
}

.hero_explore .MuiAutocomplete-root{
    border: 0px !important;
}
.backdark{
    background: #ffffff87;
}

.displaynone {
    display: none;
}

.left-widgets {
    border: 1px solid #d9d9d9;
}

.left-widgets .widget-scroll-box {
    height: 240px;
    padding: 5px;
    overflow-y: scroll;
}

.left-widgets .widget-scroll-box ul {
    margin: 5px 0;
    padding: 0px
}

.left-widgets .widget-scroll-box ul li {
    list-style: none;
    font-style: 18px;
    color: #000
}

.left-widgets .widget-scroll-box ul li a {
    float: left;
    width: 100%;
    color: #000;
    font-size: 18px;
    line-height: 30px;
    padding: 10px 10px;
    border-bottom: 1px solid #f0f0f0;
}

.left-widgets .widget-scroll-box ul li a:hover {
    background: #f1f8ff;
    color: #000;
}

.left-widgets .heading-title {
    padding: 10px;
    border-bottom: 1px solid #d9d9d9;
    margin: 0;
    font-size: 22px;
    color: #000;
    font-weight: 600;
}

.left-widgets .heading-title img {
    vertical-align: middle;
}

.top-colleges .col-xl-4{float:left; overflow:hidden; padding:0 15px;}

.topcollege-box {
    box-shadow: -1px 0px 8px rgba(0, 0, 0, 0.12);
    display: block;
    overflow: hidden;
    padding-bottom: 6px;
    margin-bottom: 25px !important;
    border-radius: 10px;
    margin: 0 auto;
}

.topcollege-box .full_wdth{
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 100% !important;
    float: none !important;
    height: 190px !important;
}

.topcollege-box h3 {
    float: left;
    font-size: 20px;
    width: 95%;
    font-weight: 600;
    color: #000;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.college-content {
    padding: 8px;
    float: left;
}

.rating_box {
    float: right;
}

.rating_box .rating_act {
    color: #ffb107;
}

.college-content p {
    font-size: 14px;
    color: #666;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.topcollege-box ul {
    margin: 10px 0;
    padding: 0px
}

.topcollege-box li {
    list-style: none;
    font-style: 14px;
    color: #666
}

.topcollege-box:hover {
    background: #f1f8ff
}

.topcollege-box .college_button_wrp a:hover {
    background: #0c87c4;
    color: #fff;
}

.topcollege-box .college_button_wrp a {
    height: 44px;
    width: auto;
    /* 125px; */
    display: block;
    float: right;
    font-size: 14px;
    color: #00A3F4;
    font-weight: 500;
    border: 1px solid #00A3F4;
    border-radius: 10px;
    line-height: 40px;
    text-align: center;
    padding: 0px 15px;
}

.topcollege-box .college_button_wrp .active_bt {
    background: #00A3F4;
    color: #fff;
    float: left;
}

#toggle-search {
    float: right;
    font-size: 25px;
    margin: 7px 0 0 0;
}

.bx-search:hover
{
    color: #00A3F4;
    cursor: pointer;

}
.topColgs .testi_wrp {
    position: relative;
    width: 100%;
    display: block;
    float: left;
}

.topColgs .loop_colleges .testi_wrp {
    padding: 10px;
}

.topColgs .testi_wrp:hover .testi_hover {
    display: block;
}

.topColgs .testi_wrp .testi_hover {
    width: 95%;
    height: 93%;
    display: none;
    background: #00A3F4;
    position: absolute;
    left: 3%;
    border-radius: 10px;
    top: 4%;
    transform: rotate(3.45deg);
}

.topColgs .loop_colleges .inner_wrp {
    padding: 0px 0 20px 0 !important;
}

.topColgs .testi_wrp .inner_wrp {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    padding: 15px;
    position: relative;
    box-shadow: 0px 3px 8px rgb(0 0 0 / 12%);
    border-radius: 10px;
}

.topColgs .loop_colleges .rating_box {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 86px;
    font-size: 18px;
    color: #000000;
    height: 44px;
    position: absolute;
    top: 15px;
    right: 15px;
    text-align: center;
    line-height: 44px;
}

.topColgs .loop_colleges h3 {
    font-style: normal;
    font-weight: 700;
    width: 100%;
    display: block;
    float: left;
    font-size: 18px;
    padding: 0 15px !important;
    line-height: 26px;
    margin: 10px 0;
    padding: 0;
    color: #000000;
}


.topColgs .loop_colleges p {
    font-style: normal;
    padding: 0 15px !important;
    font-weight: 400;
    margin-bottom: 20px !important;
    font-size: 16px;
    width: 100%;
    display: block;
    float: left;
    line-height: 20px;
    margin: 0px;
    padding: 0px;
    color: rgba(0, 0, 0, 0.6);
}

.topColgs .loop_colleges .college_button_wrp {
    width: 255px;
    display: block;
    margin: 0 auto;
}

.topColgs .loop_colleges a {
    height: 44px;
    width: 121px;
    display: block;
    float: left;
    font-size: 13px;
    color: #00A3F4;
    font-weight: 500;
    border: 1px solid #00A3F4;
    border-radius: 10px;
    line-height: 44px;
    text-align: center;
}

.topColgs .loop_colleges .college_button_wrp .active_bt {
    background: #00A3F4;
    color: #fff;
    margin-right: 10px;
}

.topColgs .loop_colleges .rating_box {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 86px;
    font-size: 18px;
    color: #000000;
    height: 44px;
    position: absolute;
    top: 15px;
    right: 15px;
    text-align: center;
    line-height: 44px;
}

.loop_colleges .rating_box .rating_act {
    color: #FFB108;
    position: relative;
    top: -4px;
}

.all-colleges-admission {
    padding: 120px 0;
}

.all-colleges-admission h2 {
    color: #fff;
    font-size: 32px;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 0 20px 0;
}

.all-colleges-admission p {
    color: #fff;
    margin: 0 0 10px 0;
}

.all-colleges-admission input,
.all-colleges-admission textarea {
    border: 0px;
    padding: 10px;
    font-size: 16px;
    margin: 0 0 15px 0;
}
.colourwhite{
    color: white
}
.best-management-in-course {
    background-size: cover;
    background-position: center center;
    padding: 130px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px #ccc;
}

.best-management-in-course h2 {
    color: #fff;
    font-size: 52px;
    font-weight: 700;
    margin: 0 0 20px 0;
    text-transform: uppercase;
}

.best-management-in-course h2 span {
    color: #00a2f3;
}

.best-management-in-course p {
    color: #fff;
    font-size: 22px;
}

.spac_01 {
    padding: 40px 0;
}
.testi_wrp {
    position: relative;
    width: 100%;
    display: block;
    float: left;
}

.testi_wrp .lquote {
    width: 35px !important;
    position: absolute;
    top: -11px;
}

.testi_wrp .rquote {
    width: 35px !important;
    position: absolute;
    right: 20px;
    bottom: -12px;
}

.testi_wrp .inner_wrp {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    padding: 15px;
    position: relative;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    overflow: hidden;
}

.loop_testi .item .testi_wrp {
    padding: 10px;
}

.loop_testi .test_content_wrp {
    width: 100%;
    display: block;
    float: left;
    margin-top: 15px;
}

.loop_testi .test_content_wrp ul {
    display: block;
    float: left;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.loop_testi .test_content_wrp ul li {
    display: block;
    float: left;
    color: rgba(0, 0, 0, 0.1);
    margin-right: 5px;
}

.loop_testi .test_content_wrp ul li i {
    font-size: 18px;
}

.loop_testi .test_content_wrp ul li.active {
    color: #F7B500;
}

.loop_testi .test_content_wrp p {
    font-size: 15px;
    font-weight: 300;
    width: 100%;
    display: block;
    float: left;
    margin: 20px 0px;
}

.testi_user {
    display: flex;
    float: left;
    align-items: center;
}

.testi_user img {
    width: 55px !important;
    display: block !important;
    float: left !important;
}

.testi_user span {
    display: block;
    float: left;
    margin-left: 10px;
}

.testi_user span h3 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0px !important;
    color: #000;
}

.testi_user span p {
    margin: 0px !important;
    padding: 0px !important;
    font-size: 13px;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.6);
}

.testi_wrp .testi_hover {
    width: 95%;
    height: 93%;
    display: none;
    background: #00A3F4;
    position: absolute;
    left: 3%;
    border-radius: 10px;
    top: 4%;
    transform: rotate(3.45deg);
}

.testi_wrp:hover .testi_hover {
    display: block;
}

.get-scholorship .full_wdth {margin: 0 0 20px 0;}

.getscholorshipscroll img{
    height: 290px;
    object-fit: contain;
}

.enquirybtnwrap{
    display: flex !important;
    flex-wrap: nowrap;
    justify-content: space-around;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px)
{
    .heightboxcontent .full_wdth {
         width: 100% !important;
    }
}

.inner-top-header .college-block {
    background: rgba(0, 0, 0, 0.5);
    padding: 20px
}

.rating_box {
    float: right;
}

.rating_box .rating_act {
    color: #ffb107;
}

.hero_explore {
    width: 75%;
    display: block;
    float: left;
    position: relative;
}

.hero_explore .ex-field {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.14);
    border: 0px;
    border-radius: 10px;
    height: 64px;
    padding: 0 163px 0 40px;
}

.hero_explore img {
    position: absolute;
    z-index: 1;
    left: 13px;
    top: 20px;
}
.text-left{
    text-align: left;
}

.displaynone {
    display: none;
}

.left-widgets {
    border: 1px solid #d9d9d9;
}

.left-widgets .widget-scroll-box {
    height: 240px;
    padding: 5px;
    overflow-y: scroll;
}

.left-widgets .widget-scroll-box ul {
    margin: 5px 0;
    padding: 0px
}

.left-widgets .widget-scroll-box ul li {
    list-style: none;
    font-style: 18px;
    color: #000
}

.left-widgets .widget-scroll-box ul li a {
    float: left;
    width: 100%;
    color: #000;
    font-size: 18px;
    line-height: 30px;
    padding: 10px 10px;
    border-bottom: 1px solid #f0f0f0;
}

.left-widgets .widget-scroll-box ul li a:hover {
    background: #f1f8ff;
    color: #000;
}

.left-widgets .heading-title {
    padding: 10px;
    border-bottom: 1px solid #d9d9d9;
    margin: 0;
    font-size: 22px;
    color: #000;
    font-weight: 600;
}

.left-widgets .heading-title img {
    vertical-align: middle;
}

.top-colleges .col-xl-4{float:left; overflow:hidden; padding:0 15px;}

.topcollege-box {
    box-shadow: -1px 0px 8px rgba(0, 0, 0, 0.12);
    display: block;
    overflow: hidden;
    padding-bottom: 6px;
    margin-bottom: 25px !important;
    border-radius: 10px;
    margin: 0 auto;
}

.topcollege-box .full_wdth{
    margin-left: auto;
    margin-right: auto;
    display: block;
    /*width: 70% !important;*/
    float: none !important;
    height: 190px !important;
}

.topcollege-box h3 {
    float: left;
    font-size: 20px;
    width: 95%;
    font-weight: 600;
    color: #000;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.college-content {
    padding: 8px;
    float: left;
}

.rating_box {
    float: right;
}

.rating_box .rating_act {
    color: #ffb107;
}

.college-content p {
    font-size: 14px;
    color: #666;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.topcollege-box ul {
    margin: 10px 0;
    padding: 0px
}

.topcollege-box li {
    list-style: none;
    font-style: 14px;
    color: #666
}

.topcollege-box:hover {
    background: #f1f8ff
}

.drawerCl {
    z-index: 999999999 !important;
}

.topColgs .testi_wrp {
    position: relative;
    width: 100%;
    display: block;
    float: left;
}

.topColgs .loop_colleges .testi_wrp {
    padding: 10px;
}

.topColgs .testi_wrp:hover .testi_hover {
    display: block;
}

.topColgs .testi_wrp .testi_hover {
    width: 95%;
    height: 93%;
    display: none;
    background: #00A3F4;
    position: absolute;
    left: 3%;
    border-radius: 10px;
    top: 4%;
    transform: rotate(3.45deg);
}

.topColgs .loop_colleges .inner_wrp {
    padding: 0px 0 20px 0 !important;
}

.topColgs .testi_wrp .inner_wrp {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    padding: 15px;
    position: relative;
    box-shadow: 0px 3px 8px rgb(0 0 0 / 12%);
    border-radius: 10px;
}

.topColgs .loop_colleges .rating_box {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 86px;
    font-size: 18px;
    color: #000000;
    height: 44px;
    position: absolute;
    top: 15px;
    right: 15px;
    text-align: center;
    line-height: 44px;
}

.topColgs .loop_colleges h3 {
    font-style: normal;
    font-weight: 700;
    width: 100%;
    display: block;
    float: left;
    font-size: 18px;
    padding: 0 15px !important;
    line-height: 26px;
    margin: 10px 0;
    padding: 0;
    color: #000000;
}


.topColgs .loop_colleges p {
    font-style: normal;
    padding: 0 15px !important;
    font-weight: 400;
    margin-bottom: 20px !important;
    font-size: 16px;
    width: 100%;
    display: block;
    float: left;
    line-height: 20px;
    margin: 0px;
    padding: 0px;
    color: rgba(0, 0, 0, 0.6);
}

.topColgs .loop_colleges .college_button_wrp {
    width: 255px;
    display: block;
    margin: 0 auto;
}

.topColgs .loop_colleges a {
    height: 44px;
    width: 121px;
    display: block;
    float: left;
    font-size: 13px;
    color: #00A3F4;
    font-weight: 500;
    border: 1px solid #00A3F4;
    border-radius: 10px;
    line-height: 44px;
    text-align: center;
}

.topColgs .loop_colleges .college_button_wrp .active_bt {
    background: #00A3F4;
    color: #fff;
    margin-right: 10px;
}

.topColgs .loop_colleges .rating_box {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 86px;
    font-size: 18px;
    color: #000000;
    height: 44px;
    position: absolute;
    top: 15px;
    right: 15px;
    text-align: center;
    line-height: 44px;
}

.loop_colleges .rating_box .rating_act {
    color: #FFB108;
    position: relative;
    top: -4px;
}

.all-colleges-admission {
    padding: 120px 0;
}

.all-colleges-admission h2 {
    color: #fff;
    font-size: 32px;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 0 20px 0;
}

.all-colleges-admission p {
    color: #fff;
    margin: 0 0 10px 0;
}

.all-colleges-admission input,
.all-colleges-admission textarea {
    border: 0px;
    padding: 10px;
    font-size: 16px;
    margin: 0 0 15px 0;
}
.best-management-in-course {
    background-size: cover;
    background-position: center center;
    padding: 130px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px #ccc;
}

.best-management-in-course h2 {
    color: #fff;
    font-size: 52px;
    font-weight: 700;
    margin: 0 0 20px 0;
    text-transform: uppercase;
}

.best-management-in-course h2 span {
    color: #00a2f3;
}

.best-management-in-course p {
    color: #fff;
    font-size: 22px;
}

.spac_01 {
    padding: 40px 0;
}
.testi_wrp .lquote {
    width: 35px !important;
    position: absolute;
    top: -11px;
}

.testi_wrp .rquote {
    width: 35px !important;
    position: absolute;
    right: 20px;
    bottom: -12px;
}

.testi_wrp .inner_wrp {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    padding: 15px;
    position: relative;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    overflow: hidden;
}

.loop_testi .item .testi_wrp {
    padding: 10px;
}

.loop_testi .test_content_wrp {
    width: 100%;
    display: block;
    float: left;
    margin-top: 15px;
}

.loop_testi .test_content_wrp ul {
    display: block;
    float: left;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.loop_testi .test_content_wrp ul li {
    display: block;
    float: left;
    color: rgba(0, 0, 0, 0.1);
    margin-right: 5px;
}

.loop_testi .test_content_wrp ul li i {
    font-size: 18px;
}

.loop_testi .test_content_wrp ul li.active {
    color: #F7B500;
}

.loop_testi .test_content_wrp p {
    font-size: 15px;
    font-weight: 300;
    width: 100%;
    display: block;
    float: left;
    margin: 20px 0px;
}

.testi_user {
    display: flex;
    float: left;
    align-items: center;
}

.testi_user img {
    width: 55px !important;
    display: block !important;
    float: left !important;
}

.testi_user span {
    display: block;
    float: left;
    margin-left: 10px;
}

.testi_user span h3 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0px !important;
    color: #000;
}

.testi_user span p {
    margin: 0px !important;
    padding: 0px !important;
    font-size: 13px;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.6);
}

.testi_wrp .testi_hover {
    width: 95%;
    height: 93%;
    display: none;
    background: #00A3F4;
    position: absolute;
    left: 3%;
    border-radius: 10px;
    top: 4%;
    transform: rotate(3.45deg);
}

.testi_wrp:hover .testi_hover {
    display: block;
}

.get-scholorship .full_wdth {margin: 0 0 20px 0;}

.scholarshipsection .owl-carousel .owl-item img{
    width: 100% !important;
}

.newscholarship h3
{ 
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.inner-top-header .college-block {
    background: rgba(0, 0, 0, 0.5);
    padding: 20px
}

.inner-top-header .top-college-header .rating_box {
    color: #fff;
    margin-top: 25px;
}

.inner-top-header .top-college-header h1 span {
    color: #00a2f3
}

.inner-top-header .top-college-header ul {
    margin: 0px 0;
    padding: 0;
    display: inline-block;
}

.inner-top-header .top-college-header ul li {
    display: inline;
    text-align: left;
    color: #fff;
    font-size: 20px;
    padding: 0px 30px 0 10px;
    line-height: 40px;
}

.inner-top-header .top-college-header ul li .bi-pin-map-fill {
    color: red;
}

.reviews-button {
    background: #fff;
    margin: 0 0 0 20px;
    color: #000;
}

.reviews-button:hover {
    background: #3e6eb0;
    color: #fff;
}

.rating_box {
    float: right;
}

.rating_box .rating_act {
    color: #ffb107;
}

.hero_explore {
    width: 75%;
    display: block;
    float: left;
    position: relative;
}

.hero_explore .ex-field {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.14);
    border: 0px;
    border-radius: 10px;
    height: 64px;
    padding: 0 163px 0 40px;
}

.hero_explore img {
    position: absolute;
    z-index: 1;
    left: 13px;
    top: 20px;
}


.text-left{
    text-align: left;
}

.displaynone {
    display: none;
}

.left-widgets {
    border: 1px solid #d9d9d9;
}

.left-widgets .widget-scroll-box {
    height: 240px;
    padding: 5px;
    overflow-y: scroll;
}

.left-widgets .widget-scroll-box ul {
    margin: 5px 0;
    padding: 0px
}

.left-widgets .widget-scroll-box ul li {
    list-style: none;
    font-style: 18px;
    color: #000
}

.left-widgets .widget-scroll-box ul li a {
    float: left;
    width: 100%;
    color: #000;
    font-size: 18px;
    line-height: 30px;
    padding: 10px 10px;
    border-bottom: 1px solid #f0f0f0;
}

.left-widgets .widget-scroll-box ul li a:hover {
    background: #f1f8ff;
    color: #000;
}

.left-widgets .heading-title {
    padding: 10px;
    border-bottom: 1px solid #d9d9d9;
    margin: 0;
    font-size: 22px;
    color: #000;
    font-weight: 600;
}

.left-widgets .heading-title img {
    vertical-align: middle;
}

.top-colleges .col-xl-4{float:left; overflow:hidden; padding:0 15px;}

.topcollege-box {
    box-shadow: -1px 0px 8px rgba(0, 0, 0, 0.12);
    display: block;
    overflow: hidden;
    padding-bottom: 6px;
    margin-bottom: 25px !important;
    border-radius: 10px;
    margin: 0 auto;
}

.topcollege-box .full_wdth{
    margin-left: auto;
    margin-right: auto;
    display: block;
    /*width: 70% !important;*/
    float: none !important;
    height: 190px !important;
    object-fit: contain;
}

.topcollege-box h3 {
    float: left;
    font-size: 20px;
    width: 75%;
    font-weight: 600;
    color: #000;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 50px;
}

.college-content {
    padding: 8px;
    float: left;
}

.rating_box {
    float: right;
}

.rating_box .rating_act {
    color: #ffb107;
}

.college-content p {
    font-size: 14px;
    color: #666;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 55px;
}

.topcollege-box ul {
    margin: 10px 0;
    padding: 0px
}

.topcollege-box li {
    list-style: none;
    font-style: 14px;
    color: #666
}

.topcollege-box:hover {
    background: #f1f8ff
}

.schoolbox .college-content{
    width: 100% !important;
}
.schoolbox .college-content p{
    height: auto !important;
    margin-bottom: 0 !important;
}

.schoolbtnwrap{
    width: 275px;
}

@media screen and (max-width:1024px)
{
    .schoolbtnwrap {
        width: 305px;
    }
}

@media screen and (max-width:768px)
{
    .schoolbtnwrap {
        width: 215px;
    }
}

.topColgs .testi_wrp {
    position: relative;
    width: 100%;
    display: block;
    float: left;
}

.topColgs .loop_colleges .testi_wrp {
    padding: 10px;
}

.topColgs .testi_wrp:hover .testi_hover {
    display: block;
}

.topColgs .testi_wrp .testi_hover {
    width: 95%;
    height: 93%;
    display: none;
    background: #00A3F4;
    position: absolute;
    left: 3%;
    border-radius: 10px;
    top: 4%;
    transform: rotate(3.45deg);
}

.topColgs .loop_colleges .inner_wrp {
    padding: 0px 0 20px 0 !important;
}

.topColgs .testi_wrp .inner_wrp {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    padding: 15px;
    position: relative;
    box-shadow: 0px 3px 8px rgb(0 0 0 / 12%);
    border-radius: 10px;
}

.topColgs .loop_colleges .rating_box {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 86px;
    font-size: 18px;
    color: #000000;
    height: 44px;
    position: absolute;
    top: 15px;
    right: 15px;
    text-align: center;
    line-height: 44px;
}

.topColgs .loop_colleges h3 {
    font-style: normal;
    font-weight: 700;
    width: 100%;
    display: block;
    float: left;
    font-size: 18px;
    padding: 0 15px !important;
    line-height: 26px;
    margin: 10px 0;
    padding: 0;
    color: #000000;
}


.topColgs .loop_colleges p {
    font-style: normal;
    padding: 0 15px !important;
    font-weight: 400;
    margin-bottom: 20px !important;
    font-size: 16px;
    width: 100%;
    display: block;
    float: left;
    line-height: 20px;
    margin: 0px;
    padding: 0px;
    color: rgba(0, 0, 0, 0.6);
}

.topColgs .loop_colleges .college_button_wrp {
    width: 255px;
    display: block;
    margin: 0 auto;
}

.topColgs .loop_colleges a {
    height: 44px;
    width: 121px;
    display: block;
    float: left;
    font-size: 13px;
    color: #00A3F4;
    font-weight: 500;
    border: 1px solid #00A3F4;
    border-radius: 10px;
    line-height: 44px;
    text-align: center;
}

.topColgs .loop_colleges .college_button_wrp .active_bt {
    background: #00A3F4;
    color: #fff;
    margin-right: 10px;
}

.topColgs .loop_colleges .rating_box {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 86px;
    font-size: 18px;
    color: #000000;
    height: 44px;
    position: absolute;
    top: 15px;
    right: 15px;
    text-align: center;
    line-height: 44px;
}

.loop_colleges .rating_box .rating_act {
    color: #FFB108;
    position: relative;
    top: -4px;
}

.all-colleges-admission {
    padding: 120px 0;
}

.all-colleges-admission h2 {
    color: #fff;
    font-size: 32px;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 0 20px 0;
}

.all-colleges-admission p {
    color: #fff;
    margin: 0 0 10px 0;
}

.all-colleges-admission input,
.all-colleges-admission textarea {
    border: 0px;
    padding: 10px;
    font-size: 16px;
    margin: 0 0 15px 0;
}
.best-management-in-course {
    background-size: cover;
    background-position: center center;
    padding: 130px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px #ccc;
}

.best-management-in-course h2 {
    color: #fff;
    font-size: 52px;
    font-weight: 700;
    margin: 0 0 20px 0;
    text-transform: uppercase;
}

.best-management-in-course h2 span {
    color: #00a2f3;
}

.best-management-in-course p {
    color: #fff;
    font-size: 22px;
}

.spac_01 {
    padding: 40px 0;
}
.testi_wrp {
    position: relative;
    width: 100%;
    display: block;
    float: left;
}

.testi_wrp .lquote {
    width: 35px !important;
    position: absolute;
    top: -11px;
}

.testi_wrp .rquote {
    width: 35px !important;
    position: absolute;
    right: 20px;
    bottom: -12px;
}

.testi_wrp .inner_wrp {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    padding: 15px;
    position: relative;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    overflow: hidden;
}

.loop_testi .item .testi_wrp {
    padding: 10px;
}

.loop_testi .test_content_wrp {
    width: 100%;
    display: block;
    float: left;
    margin-top: 15px;
}

.loop_testi .test_content_wrp ul {
    display: block;
    float: left;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.loop_testi .test_content_wrp ul li {
    display: block;
    float: left;
    color: rgba(0, 0, 0, 0.1);
    margin-right: 5px;
}

.loop_testi .test_content_wrp ul li i {
    font-size: 18px;
}

.loop_testi .test_content_wrp ul li.active {
    color: #F7B500;
}

.loop_testi .test_content_wrp p {
    font-size: 15px;
    font-weight: 300;
    width: 100%;
    display: block;
    float: left;
    margin: 20px 0px;
}

.testi_user {
    display: flex;
    float: left;
    align-items: center;
}

.testi_user img {
    width: 55px !important;
    display: block !important;
    float: left !important;
}

.testi_user span {
    display: block;
    float: left;
    margin-left: 10px;
}

.testi_user span h3 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0px !important;
    color: #000;
}

.testi_user span p {
    margin: 0px !important;
    padding: 0px !important;
    font-size: 13px;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.6);
}

.testi_wrp .testi_hover {
    width: 95%;
    height: 93%;
    display: none;
    background: #00A3F4;
    position: absolute;
    left: 3%;
    border-radius: 10px;
    top: 4%;
    transform: rotate(3.45deg);
}

.testi_wrp:hover .testi_hover {
    display: block;
}

.get-scholorship .full_wdth {margin: 0 0 20px 0;}

.blogsection .owl-carousel .owl-item img{
    width: 100% !important;
}

.heightboxcontent h3{
    height: 53px !important;
}
.coming-exam-block {
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    padding: 10px;
}

.upcommingexam-z .owl-stage-outer{
    z-index: 10000;
}
.coming-exam-block h2 {
    font-size: 20px;
    font-weight: 600;
}

.upcoming-exam-list .owl-carousel .owl-item img {
    display: block;
    width: 130px !important;
    border-radius: 10px;
    object-fit: fill;
}

.coming-exam-block .icon div {
    position: absolute;
    background: rgba(0,0,0,0.5);
    width: 130px;
    height: 100px;
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    border-radius: 10px;
    text-align: center;
    padding: 20px 0;
    line-height: 30px;
}

.coming-exam-block .icon div sub {
    bottom: 0em;
    font-size: 18px;
}

.inner-top-header .college-block {
    background: rgba(0, 0, 0, 0.5);
    padding: 20px
}

.inner-top-header .top-college-header .rating_box {
    color: #fff;
    margin-top: 25px;
}

.inner-top-header .top-college-header h1 span {
    color: #00a2f3
}

.inner-top-header .top-college-header ul {
    margin: 0px 0;
    padding: 0;
    display: inline-block;
}

.inner-top-header .top-college-header ul li {
    display: inline;
    text-align: left;
    color: #fff;
    font-size: 20px;
    padding: 0px 30px 0 10px;
    line-height: 40px;
}

.inner-top-header .top-college-header ul li .bi-pin-map-fill {
    color: red;
}

.reviews-button {
    background: #fff;
    margin: 0 0 0 20px;
    color: #000;
}

.reviews-button:hover {
    background: #3e6eb0;
    color: #fff;
}

.spac_02 {
    padding: 20px 0;
}

.rating_box {
    float: right;
}

.rating_box .rating_act {
    color: #ffb107;
}

.hero_explore {
    width: 75%;
    display: block;
    float: left;
    position: relative;
}

.hero_explore .ex-field {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.14);
    border: 0px;
    border-radius: 10px;
    height: 64px;
    padding: 15px 5px 0 50px
}

.hero_explore img {
    position: absolute;
    z-index: 1;
    left: 13px;
    top: 20px;
}

.floatright{
  float: right;
}

.hero_explore .ex-submit {
    background: #00A3F4;
    border-radius: 10px;
    width: 151px;
    height: 56px;
    position: absolute;
    top: 4px;
    right: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    /* identical to box height, or 178% */


    color: #FFFFFF;
    border: 0px;
}

.serachinlinepadding {
    /*padding-top: 35px !important;
    padding-right: 55px !important;*/
    padding: 15px 5px 0 50px !important;
} 

.MuiInput-underline:before{
    border-bottom: 0px !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before{
    border-bottom: 0px !important;
}

.examlistheader{
    padding: 110px 0 100px 0 !important;
    max-height: 550px !important;
}

.examlistheader .coming-exam-block h2 {
    font-size: 19px !important;
}
.bg-dark1{
    background: rgba(0, 0, 0, 0.5);
}
.text-left {
    text-align: left;
}

.displaynone {
    display: none;
}

.left-exam-section ul {
    margin: 0;
    padding: 0;
}

.left-exam-section .article-loop {
    width: 100%;
    margin: 0 2% 30px 0;
    float: left;
    list-style: none;
}

.left-exam-section .article-loop a {
    background: #fff;
    width: 100%;
    display: block;
    border-radius: 10px;
    box-shadow: 0px 0px 5px #ccc;
    overflow: hidden;
}

.left-exam-section .article-loop a:hover {
    background: #f4f7fe;
}

.left-exam-section .article-loop h2 {
    margin: 20px 0;
    font-size: 18px;
    color: #000;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
}

.get-details-now-form {
    background: #f5f5f5;
    padding: 25px;
    border-radius: 10px;
}

.get-details-now-form input {
    background: #fff;
    border: 1px solid #d8e2ee;
    padding: 15px;
    color: #666;
    font-size: 18px;
    margin: 0 0 20px 0;
}

.get-details-now-form input {
    background: #fff;
    border: 1px solid #d8e2ee;
    padding: 15px;
    color: #666;
    font-size: 18px;
    margin: 0 0 20px 0;
}

.get-details-now-form textarea {
    background: #fff;
    border: 1px solid #d8e2ee;
    padding: 15px;
    color: #666;
    font-size: 16px;
    margin: 0 0 20px 0;
}


.get-details-now-form .title-heading {
    color: #000;
    font-size: 25px;
    font-weight: 600;
    margin: 0 0 30px 0;
    text-align: center;
}

.get-details-now-form .title-heading span {
    color: #01a3f4;
}

.top-streams-widget {
    border-radius: 10px;
    overflow: hidden;
    background: #f3f3f3;
    box-shadow: 5px 5px 10px #ccc;
}

.large-heading-bg {
    line-height: 30px;
    padding: 50px 0;
    margin: 0 0 20px 0;
}

.top-manag-course-widget {
    background-size: cover;
    background-position: center center;
    padding: 130px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px #ccc;
}

.article-loop h2 {
    font-size: 20px;
    font-weight: 600;
    color: #000;
    height: 40px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.article-loop .img-fluid{
    /*height: 130px !important;*/
}

.examlistsection .article-loop{
    width: 100% !important;
}

.examlistsection .col-xl-4{
    padding: 0px 5px !important;
}

.article-loop.examcard{
    height: 220px !important;
}

@media (max-width: 1024px) {
    .streamwidget ul li{
        width: 100% !important;
    }
}

@media (max-width: 768px) {
    .article-loop.examcard{
        height: auto !important;
    }

    .streamwidget ul li{
        width: 48% !important;
    }
}

@media print {
  .MuiDialog-root {
    position: absolute !important;
  }
}
.MuiDialog-scrollPaper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.MuiDialog-scrollBody {
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
}
.MuiDialog-scrollBody:after {
  width: 0;
  height: 100%;
  content: "";
  display: inline-block;
  vertical-align: middle;
}
.MuiDialog-container {
  height: 100%;
  outline: 0;
}
@media print {
  .MuiDialog-container {
    height: auto;
  }
}
.MuiDialog-paper {
  margin: 32px;
  position: relative;
  overflow-y: auto;
}
@media print {
  .MuiDialog-paper {
    box-shadow: none;
    overflow-y: visible;
  }
}
.MuiDialog-paperScrollPaper {
  display: flex;
  max-height: calc(100% - 64px);
  flex-direction: column;
}
.MuiDialog-paperScrollBody {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
.MuiDialog-paperWidthFalse {
  max-width: calc(100% - 64px);
}
.MuiDialog-paperWidthXs {
  max-width: 444px;
}
@media (max-width:507.95px) {
  .MuiDialog-paperWidthXs.MuiDialog-paperScrollBody {
    max-width: calc(100% - 64px);
  }
}
.MuiDialog-paperWidthSm {
  max-width: 600px;
}
@media (max-width:663.95px) {
  .MuiDialog-paperWidthSm.MuiDialog-paperScrollBody {
    max-width: calc(100% - 64px);
  }
}
.MuiDialog-paperWidthMd {
  max-width: 960px;
}
@media (max-width:1023.95px) {
  .MuiDialog-paperWidthMd.MuiDialog-paperScrollBody {
    max-width: calc(100% - 64px);
  }
}
.MuiDialog-paperWidthLg {
  max-width: 1280px;
}
@media (max-width:1343.95px) {
  .MuiDialog-paperWidthLg.MuiDialog-paperScrollBody {
    max-width: calc(100% - 64px);
  }
}
.MuiDialog-paperWidthXl {
  max-width: 1920px;
}
@media (max-width:1983.95px) {
  .MuiDialog-paperWidthXl.MuiDialog-paperScrollBody {
    max-width: calc(100% - 64px);
  }
}
.MuiDialog-paperFullWidth {
  width: calc(100% - 64px);
}
.MuiDialog-paperFullScreen {
  width: 100%;
  height: 100%;
  margin: 0;
  max-width: 100%;
  max-height: none;
  border-radius: 0;
}
.MuiDialog-paperFullScreen.MuiDialog-paperScrollBody {
  margin: 0;
  max-width: 100%;
}


.sh-container {
  max-width: 480px;
  margin: 0 auto;
}
@media (min-width: 480px)
{
.sh-page {
    padding: 60px 80px 30px;
    background: #fff !important;
    height: auto !important;
    border-radius: 2px;
    box-shadow: 0px 10px 14.1px 0.9px rgb(0 0 0 / 24%), 0px 4px 19.6px 0.4px rgb(0 0 0 / 16%);
}}

.sh-page {
  margin: 0;
  padding: 0 20px 20px;
  text-align: center;
  position: relative;
}

.sh-store-link {
  padding: 0;
  cursor: pointer;text-decoration: none;
  color: #323032;
  transition: all 0.15s ease;
  outline: none;
}






#hero.inner-top-header {
    padding: 200px 0 100px 0;
}

h4.small-heading {
    font-size: 32px;
    color: #fff;
    font-weight: 600;
}

.coming-exam-block {
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    padding: 10px;
}

.coming-exam-block h2 {
    font-size: 20px;
    font-weight: 600;
}

.upcoming-exam-list .owl-carousel .owl-item img {
    display: block;
    width: 130px;
    border-radius: 10px;
}

.coming-exam-block .icon div {
    position: absolute;
    background: rgba(0,0,0,0.5);
    width: 130px;
    height: 100px;
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    border-radius: 10px;
    text-align: center;
    padding: 20px 0;
    line-height: 30px;
}

.coming-exam-block .icon div sub {
    bottom: 0em;
    font-size: 18px;
}

.inner-top-header .college-block {
    background: rgba(0, 0, 0, 0.5);
    padding: 20px
}

.inner-top-header .top-college-header .rating_box {
    color: #fff;
    margin-top: 25px;
}

.inner-top-header .top-college-header h1 span {
    color: #00a2f3
}

.inner-top-header .top-college-header ul {
    margin: 0px 0;
    padding: 0;
    display: inline-block;
}

.inner-top-header .top-college-header ul li {
    display: inline;
    text-align: left;
    color: #fff;
    font-size: 20px;
    padding: 0px 30px 0 10px;
    line-height: 40px;
}

.inner-top-header .top-college-header ul li .bi-pin-map-fill {
    color: red;
}

.reviews-button {
    background: #fff;
    margin: 0 0 0 20px;
    color: #000;
}

.reviews-button:hover {
    background: #3e6eb0;
    color: #fff;
}

.spac_02 {
    padding: 20px 0;
}

.rating_box {
    float: right;
}

.rating_box .rating_act {
    color: #ffb107;
}

.hero_explore {
    width: 75%;
    display: block;
    float: left;
    position: relative;
}

.hero_explore .ex-field {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.14);
    border: 0px;
    border-radius: 10px;
    height: 64px;
    padding: 0 163px 0 40px;
}

.hero_explore img {
    position: absolute;
    z-index: 1;
    left: 13px;
    top: 20px;
}
.get-exam-now-form {
    background: #f5f5f5;
    padding: 25px;
    border-radius: 10px;
}

.get-exam-now-form input {
    background: #fff;
    border: 1px solid #d8e2ee;
    padding: 15px;
    color: #666;
    font-size: 18px;
    margin: 0 0 20px 0;
}

.get-exam-now-form input {
    background: #fff;
    border: 1px solid #d8e2ee;
    padding: 15px;
    color: #666;
    font-size: 18px;
    margin: 0 0 20px 0;
}

.get-exam-now-form textarea {
    background: #fff;
    border: 1px solid #d8e2ee;
    padding: 15px;
    color: #666;
    font-size: 16px;
    margin: 0 0 20px 0;
}

.get-exam-now-form .title-heading {
    color: #000;
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 25px 0;
    text-align: center;
}

.get-exam-now-form .title-heading span {
    color: #01a3f4;
}

p {
    line-height: 28px;
}

.coming-exam-block .icon div {
    position: absolute;
    background: rgba(0,0,0,0.5);
    width: 130px;
    height: 100px;
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    border-radius: 10px;
    text-align: center;
    padding: 20px 0;
    line-height: 30px;
}

.get-details-now-form .title-heading {
    color: #000;
    font-size: 25px;
    font-weight: 600;
    margin: 0 0 30px 0;
    text-align: center;
}



.coming-exam-update-block .icon div {
    position: absolute;
    background: rgba(0,0,0,0.5);
    width: 130px;
    height: 80px;
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    border-radius: 10px;
    text-align: center;
    padding: 20px 0;
    line-height: 30px;
}




.right-widget .coming-exam-update-block {
    margin-bottom: 15px;
}

.coming-exam-update-block {
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    padding: 10px;
}

.coming-exam-update-block .icon {
    width: 130px;
    height: 82px;
    margin-right: 20px;
    display: flex;
    float: left;
    border-radius: 10px;
}

.coming-exam-update-block img {
    display: block;
    width: 130px;
    border-radius: 10px;
    object-fit: contain;
}

.coming-exam-update-block .icon div sub {
    bottom: 0em;
    font-size: 18px;
}

.right-widget .coming-exam-update-block h2 {
    font-size: 16px;
    font-weight: inherit;
}
.text-left {
    text-align: left;
}

.displaynone {
    display: none;
}

.main-heading {
    font-size: 32px;
    text-align: center;
    color: #000;
    font-weight: 700;
    margin-bottom: 25px;
}

.main-heading.left-align {
    text-align: left;
}

.main-heading span {
    color: #0097e3;
}

.left-exam-section ul {
    margin: 0;
    padding: 0;
}

.left-exam-section .article-loop {
    width: 31%;
    margin: 0 2% 30px 0;
    float: left;
    list-style: none;
}

.left-exam-section .article-loop a {
    background: #fff;
    width: 100%;
    display: block;
    border-radius: 10px;
    box-shadow: 0px 0px 5px #ccc;
    overflow: hidden;
}

.left-exam-section .article-loop a:hover {
    background: #f4f7fe;
}

.left-exam-section .article-loop h2 {
    margin: 20px 0;
    font-size: 18px;
    color: #000;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
}

.get-details-now-form {
    background: #f5f5f5;
    padding: 25px;
    border-radius: 10px;
}

.get-details-now-form input {
    background: #fff;
    border: 1px solid #d8e2ee;
    padding: 15px;
    color: #666;
    font-size: 18px;
    margin: 0 0 20px 0;
}

.get-details-now-form input {
    background: #fff;
    border: 1px solid #d8e2ee;
    padding: 15px;
    color: #666;
    font-size: 18px;
    margin: 0 0 20px 0;
}

.get-details-now-form textarea {
    background: #fff;
    border: 1px solid #d8e2ee;
    padding: 15px;
    color: #666;
    font-size: 16px;
    margin: 0 0 20px 0;
}


.get-details-now-form .title-heading {
    color: #000;
    font-size: 25px;
    font-weight: 600;
    margin: 0 0 30px 0;
    text-align: center;
}

.get-details-now-form .title-heading span {
    color: #01a3f4;
}

.top-streams-widget {
    border-radius: 10px;
    overflow: hidden;
    background: #f3f3f3;
    box-shadow: 5px 5px 10px #ccc;
}

.large-heading-bg {
    line-height: 30px;
    padding: 50px 0;
    margin: 0 0 20px 0;
}

.top-manag-course-widget {
    background-size: cover;
    background-position: center center;
    padding: 130px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px #ccc;
}

p {
    line-height: 28px;
}

.coming-exam-block .icon div {
    position: absolute;
    background: rgba(0,0,0,0.5);
    width: 130px;
    height: 100px;
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    border-radius: 10px;
    text-align: center;
    padding: 20px 0;
    line-height: 30px;
}

.get-details-now-form .title-heading {
    color: #000;
    font-size: 25px;
    font-weight: 600;
    margin: 0 0 30px 0;
    text-align: center;
}

.right-widget .coming-exam-block {
    margin-bottom: 15px;
}

.coming-exam-block {
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    padding: 10px;
}

.coming-exam-block .icon div sub {
    bottom: 0em;
    font-size: 18px;
}

.right-widget .coming-exam-block h2 {
    font-size: 16px;
    font-weight: inherit;
}

.top-manag-course-widget {
    background-size: cover;
    background-position: center center;
    padding: 130px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px #ccc;
}

.mainexamtabs button:hover{
    background: #01a3f4;
    opacity: 1 !important;
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    height: auto;
    min-width: auto;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.mainexamtabs .Mui-disabled, .mainexamtabs .MuiTabs-scrollButtons{
    opacity: 1 !important;
    color: #3e6eb0;
}

.mainexamtabs .MuiButtonBase-root{
    padding: 10px 20px;
    border-radius: 5px;
    height: auto;
    min-width: auto;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin-left: 3px;
    border: 1px solid;
}

.mainexamtabs .Mui-selected{
    padding: 10px 20px;
    background: #01a3f4 !important;
    border-radius: 5px;
    color: #fff;
    height: auto;
    min-width: auto;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.mainexamtabs .MuiTabs-indicator{
    background-color: transparent !important;
}

.bs-editor-text table, .bs-editor-text img {
    max-width: 100%;
    /* width: 100%!important; */
}

.bs-editor-text p {
    font-size: 16px !important;
}

.mainexamtabs .MuiButtonBase-root{
    background: #f0f7ff;
}


.all-colleges-admission {
    padding: 120px 0;
}

.all-colleges-admission h2 {
    color: #fff;
    font-size: 32px;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 0 20px 0;
}

.all-colleges-admission p {
    color: #fff;
    margin: 0 0 10px 0;
}

.all-colleges-admission input,
.all-colleges-admission textarea {
    border: 0px;
    padding: 10px;
    font-size: 16px;
    margin: 0 0 15px 0;
}
.topColgs .testi_wrp {
    position: relative;
    width: 100%;
    display: block;
    float: left;
}

.topColgs .loop_colleges .testi_wrp {
    padding: 10px;
}

.topColgs .testi_wrp:hover .testi_hover {
    display: block;
}

.topColgs .testi_wrp .testi_hover {
    width: 95%;
    height: 93%;
    display: none;
    background: #00A3F4;
    position: absolute;
    left: 3%;
    border-radius: 10px;
    top: 4%;
    transform: rotate(3.45deg);
}

.topColgs .loop_colleges .inner_wrp {
    padding: 0px 0 20px 0 !important;
}

.topColgs .testi_wrp .inner_wrp {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    padding: 15px;
    position: relative;
    box-shadow: 0px 3px 8px rgb(0 0 0 / 12%);
    border-radius: 10px;
}

.topColgs .loop_colleges .rating_box {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 86px;
    font-size: 18px;
    color: #000000;
    height: 44px;
    position: absolute;
    top: 15px;
    right: 15px;
    text-align: center;
    line-height: 44px;
}

.topColgs .loop_colleges h3 {
    font-style: normal;
    font-weight: 700;
    width: 100%;
    display: block;
    float: left;
    font-size: 18px;
    padding: 0 15px !important;
    line-height: 26px;
    margin: 10px 0;
    padding: 0;
    color: #000000;
}


.topColgs .loop_colleges p {
    font-style: normal;
    padding: 0 15px !important;
    font-weight: 400;
    margin-bottom: 20px !important;
    font-size: 16px;
    width: 100%;
    display: block;
    float: left;
    line-height: 20px;
    margin: 0px;
    padding: 0px;
    color: rgba(0, 0, 0, 0.6);
}

.topColgs .loop_colleges .college_button_wrp {
    width: 255px;
    display: block;
    margin: 0 auto;
}

.topColgs .loop_colleges a {
    height: 44px;
    width: 121px;
    display: block;
    float: left;
    font-size: 13px;
    color: #00A3F4;
    font-weight: 500;
    border: 1px solid #00A3F4;
    border-radius: 10px;
    line-height: 44px;
    text-align: center;
}

.topColgs .loop_colleges .college_button_wrp .active_bt {
    background: #00A3F4;
    color: #fff;
    margin-right: 10px;
}

.topColgs .loop_colleges .rating_box {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 86px;
    font-size: 18px;
    color: #000000;
    height: 44px;
    position: absolute;
    top: 15px;
    right: 15px;
    text-align: center;
    line-height: 44px;
}

.loop_colleges .rating_box .rating_act {
    color: #FFB108;
    position: relative;
    top: -4px;
}


#hero.inner-top-header {
    padding: 150px 0 100px 0;
    min-height: 500px;
}

#hero {
    width: 100%;
    overflow: hidden;
}
.text-left {
    text-align: left;
}

.displaynone {
    display: none;
}

.main-heading {
    font-size: 32px;
    text-align: center;
    color: #000;
    font-weight: 700;
    margin-bottom: 25px;
}

.main-heading.left-align {
    text-align: left;
}

.main-heading span {
    color: #0097e3;
}

.get-details-now-form {
    background: #f5f5f5;
    padding: 25px;
    border-radius: 10px;
}

.get-details-now-form input {
    background: #fff;
    border: 1px solid #d8e2ee;
    padding: 15px;
    color: #666;
    font-size: 18px;
    margin: 0 0 20px 0;
}

.get-details-now-form input {
    background: #fff;
    border: 1px solid #d8e2ee;
    padding: 15px;
    color: #666;
    font-size: 18px;
    margin: 0 0 20px 0;
}

.get-details-now-form textarea {
    background: #fff;
    border: 1px solid #d8e2ee;
    padding: 15px;
    color: #666;
    font-size: 16px;
    margin: 0 0 20px 0;
}


.get-details-now-form .title-heading {
    color: #000;
    font-size: 25px;
    font-weight: 600;
    margin: 0 0 30px 0;
    text-align: center;
}

.get-details-now-form .title-heading span {
    color: #01a3f4;
}


p {
    line-height: 28px;
}

.coming-exam-block .icon div {
    position: absolute;
    background: rgba(0,0,0,0.5);
    width: 130px;
    height: 100px;
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    border-radius: 10px;
    text-align: center;
    padding: 20px 0;
    line-height: 30px;
}

.get-details-now-form .title-heading {
    color: #000;
    font-size: 25px;
    font-weight: 600;
    margin: 0 0 30px 0;
    text-align: center;
}
.light-blue-bg {
    background: #eaf7ff;
}

.spac_01 {
    padding: 40px 0;
}

.boards-block {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 13px #cccc;
    padding: 30px;
    margin: 0 0 30px 0;
}

.boards-block h3 {
    margin: 2px 0 25px 0;
    font-size: 22px;
    line-height: 28px;
    color: #000;
    padding: 0 0 25px 0;
    border-bottom: 1px solid #d9d9d9;
    min-height: 81px;
}

.boards-block h3 img {
    float: left;
    margin: -5px 15px 0 0;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    border: 1px solid #ccc;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.boards-block h4 {
    margin: 0px 0 15px 0;
    font-size: 20px;
    color: #1f1f1f;
}

.boards-block p {
    margin: 15px 0;
    font-size: 16px;
    color: #686262;
}

a {
    color: #428bca;
    text-decoration: none;
}

.spac_02 {
    padding: 20px 0;
}

.text-center {
    text-align: center!important;
}

ul.pagination {
    display: inline-block;
    padding: 0;
    margin: 0;
}

ul.pagination li {
    display: inline;
}

.pagination li:first-child a {
    border-left: 1px solid #3e6eb0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

ul.pagination li a, .MuiPagination-ul li button{
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    background: #fff;
    border: 1px solid #3e6eb0;
    border-left: 0px solid #3e6eb0;
}

ul.pagination li a.active, ul.pagination li a:hover {
    background-color: #226cd2;
    color: white;
    border: 1px solid #226cd2;
}

.pagination li:last-child a {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.MuiPagination-root {
    display: inline-block !important;
    padding: 0 !important;
    margin: 0 !important;
}

ul.MuiPagination-ul li {
    display: inline !important;
}

.pagination li:first-child button {
    border-left: 1px solid #3e6eb0 !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.pagination li:last-child button {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

ul.MuiPagination-ul li button.Mui-selected, ul.MuiPagination-ul li button:hover {
    background-color: #226cd2 !important;
    color: white !important;
    border: 1px solid #226cd2 !important;
}

.MuiPagination-root li button {
    color: black !important;
    float: left !important;
    padding: 8px 16px !important;
    text-decoration: none !important;
    background: #fff !important;
    border: 1px solid #3e6eb0 !important;
    /* border-left: 0px solid #3e6eb0 !important; */
}


.left-overviews-section {
    padding: 20px 0;
}

.course-overviews-section .nav-tabs .nav-link.active,
.course-overviews-section .nav-tabs .nav-link:hover {
    padding: 10px 20px;
    background: #3e6eb0;
    border-radius: 5px;
    font-size: 18px;
    color: #fff;
}

.course-overviews-section .nav-tabs .nav-link {
    padding: 10px 20px;
    border: 0px;
    margin: 0 2px 0;
    background: #f0f7ff;
    border-radius: 5px;
    font-size: 18px;
    color: #000;
}

.course-overviews-section .nav-tabs {
    border: 0px;
}

.widgets {
    margin: 0 0 20px 0;
    display: block;
}

.large-heading-bg {
    line-height: 30px;
    padding: 50px 0;
    margin: 0 0 20px 0;
}

.large-heading-bg h2 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
}

.top-streams-widget {
    border-radius: 10px;
    overflow: hidden;
    background: #f3f3f3;
    box-shadow: 5px 5px 10px #ccc;
}

.top-streams-widget ul {
    padding: 20px;
}

.top-streams-widget ul li {
    display: inline-block;
    font-size: 18px;
    width: 48%;
    padding: 5px;
}

.top-streams-widget ul li a {
    color: #000;
}

.top-streams-widget ul li a:hover {
    color: #018ed4;
}

.top-streams-widget ul li a::before {
    content: "\ea41";
    font-family: boxicons !important;
    color: #018ed4;
    font-size: 18px;
    display: inline;
    position: relative;
    left: -8px;
    top: 2px;
    border: 1px solid #018ed4;
    border-radius: 50%;
}

.top-manag-course-widget {
    background-size: cover;
    background-position: center center;
    padding: 130px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px #ccc;
}

.top-manag-course-widget h2 {
    color: #fff;
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 600;
    margin: 0 0 20px 0;
}

.top-manag-course-widget p {
    color: #fff;
    font-size: 18px;
}

p {
    line-height: 28px;
    font-style: 16px;
}

.panel-title > a:before {
    float: right !important;
    content:"\f068";
    padding-right: 5px;
}
.panel-title > a.collapsed:before {
    float: right !important;
    content:"\f067";
}
.panel-title > a:hover, 
.panel-title > a:active, 
.panel-title > a:focus  {
    text-decoration:none;
}
.interested-in-course {
    background-size: cover;
    background-position: center center;
    padding: 130px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px #ccc;
}

.interested-in-course h2 {
    color: #fff;
    font-size: 52px;
    font-weight: 700;
    margin: 0 0 20px 0;
    background: rgba(0, 0, 0, 0.5);
}

.interested-in-course p {
    color: #fff;
    font-size: 22px;
    background: rgba(0, 0, 0, 0.5);
    font-weight: 500;
}
.border-radius-20 {
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 1px 0px 10px #ccc;
}

.blue-bg {
    background: #01a3f4;
}

.college-location img {
    margin: 0 0 0px 0;
}

.college-location h2 {
    margin: 0 0 15px 0;
    color: #fff;
    font-size: 25px;
    font-weight: bold;
}

.college-location p {
    margin: 0 0 0px 0;
    color: #fff;
    font-size: 17px;
}

.college-location-details {
    padding: 30px;
}

.addresslogo{
    width: 646px;
    height:300px;
    object-fit: contain;
}

.textleft{
    text-align: left !important;
}
.breadcrumbs {
    padding: 15px 0;
    background-color: #f5f9fc;
    min-height: 40px;
}

.breadcrumbs h2 {
    font-size: 24px;
    font-weight: 300;
}

.breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 18px;
}

.breadcrumbs ol li+li {
    padding-left: 10px;
}

.breadcrumbs ol li+li::before {
    display: inline-block;
    padding-right: 10px;
    color: #000;
    content: "\ea50";font-family: boxicons !important;
}

.breadcrumbs ol li a.active {
    color:#01a3f4;
}

.breadcrumbs ol li a {
    color:#000;
}


@media (max-width: 768px) {
    .breadcrumbs .d-flex {
        display: block !important;
    }

    .breadcrumbs ol {
        display: block;
    }

    .breadcrumbs ol li {
        display: inline-block;
    }
}
.hero_explore {
    width: 75%;
    display: block;
    float: left;
    position: relative;
}

.hero_explore .ex-field {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.14);
    border: 0px;
    border-radius: 10px;
    height: 64px;
    padding: 0 163px 0 40px;
}

.hero_explore img {
    position: absolute;
    z-index: 1;
    left: 13px;
    top: 20px;
}

.header-search-box input[type="text"].search-box {
    border: 1px solid #ccc;
    background-repeat: no-repeat;
    background-position: 20px center;
    border-radius: 30px;
    width: 95%;
    margin: 0 auto 0px auto !important;
    padding: 15px 15px 15px 50px;
}

.header-search-box .MuiFormControl-fullWidth
{
    border-radius: 30px;
}

.blogsearchbox input[type="text"]{
    margin: 0 auto 0px auto !important;
    padding: 15px 15px 15px 50px;
}
.text-left {
    text-align: left;
}

.displaynone {
    display: none;
}

.main-heading {
    font-size: 32px;
    text-align: center;
    color: #000;
    font-weight: 700;
    margin-bottom: 25px;
}

.main-heading.left-align {
    text-align: left;
}

.main-heading span {
    color: #0097e3;
}

.get-details-now-form {
    background: #f5f5f5;
    padding: 25px;
    border-radius: 10px;
}

.get-details-now-form input {
    background: #fff;
    border: 1px solid #d8e2ee;
    padding: 15px;
    color: #666;
    font-size: 18px;
    margin: 0 0 20px 0;
}
/* .get-details-now-form .react-phone-p-0 input {
    margin: 0 0 20px 0 !important;
} */



.get-details-now-form input {
    background: #fff;
    border: 1px solid #d8e2ee;
    padding: 15px;
    color: #666;
    font-size: 18px;
    margin: 0 0 20px 0;
}

.get-details-now-form textarea {
    background: #fff;
    border: 1px solid #d8e2ee;
    padding: 15px;
    color: #666;
    font-size: 16px;
    margin: 0 0 20px 0;
}

.get-details-now-form .title-heading {
    color: #000;
    font-size: 25px;
    font-weight: 600;
    margin: 0 0 30px 0;
    text-align: center;
}

.get-details-now-form .title-heading span {
    color: #01a3f4;
}

p {
    line-height: 28px;
}

.coming-exam-block .icon div {
    position: absolute;
    background: rgba(0,0,0,0.5);
    width: 130px;
    height: 100px;
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    border-radius: 10px;
    text-align: center;
    padding: 20px 0;
    line-height: 30px;
}

.get-details-now-form .title-heading {
    color: #000;
    font-size: 25px;
    font-weight: 600;
    margin: 0 0 30px 0;
    text-align: center;
}

.right-widget .coming-exam-block {
    margin-bottom: 15px;
}

.coming-exam-block {
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    padding: 10px;
}

.coming-exam-block .icon {
    width: 130px;
    height: 100px;
    margin-right: 20px;
    display: flex;
    float: left;
    border-radius: 10px;
}

.coming-exam-block img {
    display: block;
    width: 130px;
    border-radius: 10px;
    object-fit: contain;
}

.coming-exam-block .icon div sub {
    bottom: 0em;
    font-size: 18px;
}

.right-widget .coming-exam-block h2 {
    font-size: 16px;
    font-weight: inherit;
}







section {
    padding: 60px 0;
    overflow: hidden;
}

.blog-post {
    background: #fff;
    float: left;
    width: 46%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 2px 13px #cccc;
    padding: 0px;
    margin: 0 4% 30px 0;
}

.blog-post img {
    margin: 0px 15px 0 0;
    object-fit: contain;
    width: 100%;
    height: 200px;
}

.blog-content {
    padding: 20px;
    display: block;
}

.blog-post h3 {
    margin: 0;
    font-size: 22px;
    line-height: 28px;
    color: #000;
}

.blog-post p {
    margin: 15px 0;
    font-size: 16px;
    color: #686262;
}

.posted-on-date {
    border: 1px solid #ccc;
    padding: 8px;
    background: #fff;
    font-size: 12px;
    color: #666;
}

a {
    color: #428bca;
    text-decoration: none;
}

.share-this i {
    color: #000;
    font-size: 24px;
    cursor: pointer;
    float: right;
    font-weight: bold;
}


.blog-post h3 {
    float: left;
    font-size: 20px;
    width: 75%;
    font-weight: 600;
    color: #000;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.nohyperlink{
    font-size: 20px !important; 
    color: #444;
}

.nohyperlink:hover, .nohyperlink:focus{
    color: #444;
}

.button_wrp a {
    height: 44px;
    width: 121px;
    display: block;
    float: left;
    font-size: 13px;
    color: #00A3F4;
    font-weight: 500;
    border: 1px solid #00A3F4;
    border-radius: 10px;
    line-height: 44px;
    text-align: center;
}

.button_wrp .firstlink{
    margin-right: 10px;
}

.button_wrp a.share-this{
    float: right;
    border: 0px;
}

.large-spac {
  min-height: 150px;
}

#hero.inner-top-header {
  background-size: cover !important;
  background-position: center center !important;
}

#hero.inner-top-header {
  padding: 200px 0 100px 0;
}

h4.small-heading {
  font-size: 32px;
  color: #fff;
  font-weight: 600;
}

.inner-top-header .college-block {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
}

.inner-top-header .top-college-header .rating_box {
  color: #fff;
  margin-top: 25px;
}

.inner-top-header .top-college-header h1 {
  text-transform: uppercase;
  line-height: 60px;
  font-size: 42px;
  float: left;
  color: #fff;
  font-weight: bold;
}

.inner-top-header .top-college-header h1 img {
  vertical-align: middle;
  margin: 0 20px 0 0;
  float: left;
}

.inner-top-header .top-college-header h1 span {
  color: #00a2f3;
}

.inner-top-header .top-college-header ul {
  margin: 0px 0;
  padding: 0;
  display: inline-block;
}

.inner-top-header .top-college-header ul li {
  display: inline;
  text-align: left;
  color: #fff;
  font-size: 20px;
  padding: 0px 30px 0 10px;
  line-height: 40px;
}

.inner-top-header .top-college-header ul li .bi-pin-map-fill {
  color: red;
}

.spac_02 {
  padding: 20px 0;
}

.rating_box {
  float: right;
}

.rating_box .rating_act {
  color: #ffb107;
}

.hero_explore {
  width: 75%;
  display: block;
  float: left;
  position: relative;
}

.hero_explore .ex-field {
  width: 100%;
  display: block;
  float: left;
  background: #ffffff;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.14);
  border: 0px;
  border-radius: 10px;
  height: 64px;
  padding: 0 163px 0 40px;
}

.hero_explore img {
  position: absolute;
  z-index: 1;
  left: 13px;
  top: 20px;
}

.header-search-box input[type='text'].search-box {
  border: 1px solid #ccc;
  background-repeat: no-repeat;
  background-position: 20px center;
  border-radius: 30px;
  width: 95%;
  margin: 0 auto 0px auto !important;
  padding: 15px 15px 15px 50px;
}


@media only screen and (max-width: 600px) {

    .blog-align{
        text-align: center;
    }

}
.text-left {
    text-align: left;
}

.displaynone {
    display: none;
}

.main-heading {
    font-size: 32px;
    text-align: center;
    color: #000;
    font-weight: 700;
    margin-bottom: 25px;
}

.main-heading.left-align {
    text-align: left;
}

.main-heading span {
    color: #0097e3;
}

.get-details-now-form {
    background: #f5f5f5;
    padding: 25px;
    border-radius: 10px;
}

.get-details-now-form input {
    background: #fff;
    border: 1px solid #d8e2ee;
    padding: 15px;
    color: #666;
    font-size: 18px;
    margin: 0 0 20px 0;
}

.get-details-now-form input {
    background: #fff;
    border: 1px solid #d8e2ee;
    padding: 15px;
    color: #666;
    font-size: 18px;
    margin: 0 0 20px 0;
}

.get-details-now-form textarea {
    background: #fff;
    border: 1px solid #d8e2ee;
    padding: 15px;
    color: #666;
    font-size: 16px;
    margin: 0 0 20px 0;
}

.get-details-now-form .title-heading {
    color: #000;
    font-size: 25px;
    font-weight: 600;
    margin: 0 0 30px 0;
    text-align: center;
}

.get-details-now-form .title-heading span {
    color: #01a3f4;
}

p {
    line-height: 28px;
}

.coming-exam-block .icon div {
    position: absolute;
    background: rgba(0,0,0,0.5);
    width: 130px;
    height: 100px;
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    border-radius: 10px;
    text-align: center;
    padding: 20px 0;
    line-height: 30px;
}

.get-details-now-form .title-heading {
    color: #000;
    font-size: 25px;
    font-weight: 600;
    margin: 0 0 30px 0;
    text-align: center;
}

.right-widget .coming-exam-block {
    margin-bottom: 15px;
}

.coming-exam-block {
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    padding: 10px;
}

.coming-exam-block .icon div sub {
    bottom: 0em;
    font-size: 18px;
}

.right-widget .coming-exam-block h2 {
    font-size: 16px;
    font-weight: inherit;
}







section {
    padding: 60px 0;
    overflow: hidden;
}

.blog-post {
    background: #fff;
    float: left;
    width: 46%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 2px 13px #cccc;
    padding: 0px;
    margin: 0 4% 30px 0;
}

.blog-post img {
    margin: 0px 15px 0 0;
}

.blog-content {
    padding: 20px;
    display: block;
}

.blog-post h3 {
    margin: 0;
    font-size: 22px;
    line-height: 28px;
    color: #000;
}

.blog-post p {
    margin: 15px 0;
    font-size: 16px;
    color: #686262;
}

.posted-on-date {
    border: 1px solid #ccc;
    padding: 8px;
    background: #fff;
    font-size: 12px;
    color: #666;
}

a {
    color: #428bca;
    text-decoration: none;
}

.share-this i {
    color: #000;
    font-size: 24px;
    cursor: pointer;
    float: right;
    font-weight: bold;
}

.sticky-container{
    padding: 0px;
    margin: 0px;
    position: fixed;
    top: 30%;
    width: auto;
    z-index: 1100;
    left: 5px;
}

.sticky li{
    list-style-type:none;
    
    color:#efefef;
    height:43px;
    padding:0px;
    margin:0px 0px 1px 0px;
    transition:all 0.25s ease-in-out;
    cursor:pointer;
}

/* .htmlcontentsection1 img{
    width: 68% !important;
} */
.all-colleges-admission {
    padding: 120px 0;
}

.all-colleges-admission h2 {
    color: #fff;
    font-size: 32px;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 0 20px 0;
}

.all-colleges-admission p {
    color: #fff;
    margin: 0 0 10px 0;
}

.all-colleges-admission input,
.all-colleges-admission textarea {
    border: 0px;
    padding: 10px;
    font-size: 16px;
    margin: 0 0 15px 0;
}
.topColgs .testi_wrp {
    position: relative;
    width: 100%;
    display: block;
    float: left;
}

.topColgs .loop_colleges .testi_wrp {
    padding: 10px;
}

.topColgs .testi_wrp:hover .testi_hover {
    display: block;
}

.topColgs .testi_wrp .testi_hover {
    width: 95%;
    height: 93%;
    display: none;
    background: #00A3F4;
    position: absolute;
    left: 3%;
    border-radius: 10px;
    top: 4%;
    transform: rotate(3.45deg);
}

.topColgs .loop_colleges .inner_wrp {
    padding: 0px 0 20px 0 !important;
}

.topColgs .testi_wrp .inner_wrp {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    padding: 15px;
    position: relative;
    box-shadow: 0px 3px 8px rgb(0 0 0 / 12%);
    border-radius: 10px;
}

.topColgs .loop_colleges .rating_box {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 86px;
    font-size: 18px;
    color: #000000;
    height: 44px;
    position: absolute;
    top: 15px;
    right: 15px;
    text-align: center;
    line-height: 44px;
}


.topColgs .loop_colleges img {
    width: 100%;
    display: block;
    float: left;
}

.topColgs .our_portal .item img {
    width: 100%;
    display: block;
    float: left;
}

.topColgs .full_wdth {
    width: 100%;
    display: block;
    float: left;
}

.topColgs .loop_colleges h3 {
    font-style: normal;
    font-weight: 700;
    width: 100%;
    display: block;
    float: left;
    font-size: 18px;
    padding: 0 15px !important;
    line-height: 26px;
    margin: 10px 0;
    padding: 0;
    color: #000000;
}


.topColgs .loop_colleges p {
    font-style: normal;
    padding: 0 15px !important;
    font-weight: 400;
    margin-bottom: 20px !important;
    font-size: 16px;
    width: 100%;
    display: block;
    float: left;
    line-height: 20px;
    margin: 0px;
    padding: 0px;
    color: rgba(0, 0, 0, 0.6);
}

.topColgs .loop_colleges .college_button_wrp {
    width: 255px;
    display: block;
    margin: 0 auto;
}

.topColgs .loop_colleges a {
    height: 44px;
    width: 121px;
    display: block;
    float: left;
    font-size: 13px;
    color: #00A3F4;
    font-weight: 500;
    border: 1px solid #00A3F4;
    border-radius: 10px;
    line-height: 44px;
    text-align: center;
}

.topColgs .loop_colleges .college_button_wrp .active_bt {
    background: #00A3F4;
    color: #fff;
    margin-right: 10px;
}

.topColgs .loop_colleges .rating_box {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 86px;
    font-size: 18px;
    color: #000000;
    height: 44px;
    position: absolute;
    top: 15px;
    right: 15px;
    text-align: center;
    line-height: 44px;
}

.loop_colleges .rating_box .rating_act {
    color: #FFB108;
    position: relative;
    top: -4px;
}

.wrapblogcontent img{
    max-height: 290px !important;
    object-fit: cover;
}

.wrapblogcontent h3, .wrapblogdetailcontent h3 {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    height: 50px !important;
}

.wrapblogdetailcontent img{
    height: 178px !important;
    object-fit: contain;
}
#hero.inner-top-header {
    padding: 200px 0 100px 0;
}

h4.small-heading {
    font-size: 32px;
    color: #fff;
    font-weight: 600;
}

.inner-top-header .college-block {
    background: rgba(0, 0, 0, 0.5);
    padding: 20px
}

.inner-top-header .top-college-header .rating_box {
    color: #fff;
    margin-top: 25px;
}

.inner-top-header .top-college-header h1 span {
    color: #00a2f3
}

.inner-top-header .top-college-header ul {
    margin: 0px 0;
    padding: 0;
    display: inline-block;
}

.inner-top-header .top-college-header ul li {
    display: inline;
    text-align: left;
    color: #fff;
    font-size: 20px;
    padding: 0px 30px 0 10px;
    line-height: 40px;
}

.inner-top-header .top-college-header ul li .bi-pin-map-fill {
    color: red;
}

.spac_02 {
    padding: 20px 0;
}

.rating_box {
    float: right;
}

.rating_box .rating_act {
    color: #ffb107;
}

.hero_explore {
    width: 75%;
    display: block;
    float: left;
    position: relative;
}

.hero_explore .ex-field {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.14);
    border: 0px;
    border-radius: 10px;
    height: 64px;
    padding: 0 163px 0 40px;
}

.hero_explore img {
    position: absolute;
    z-index: 1;
    left: 13px;
    top: 20px;
}

.header-search-box input[type="text"].search-box {
    border: 1px solid #ccc;
    background-repeat: no-repeat;
    background-position: 20px center;
    border-radius: 30px;
    width: 95%;
    margin: 0 auto 0px auto !important;
    padding: 15px 15px 15px 50px;
}
.text-left {
    text-align: left;
}

.displaynone {
    display: none;
}

.clearfix
{clear:both !important;
    
}

.main-heading {
    font-size: 32px;
    text-align: center;
    color: #000;
    font-weight: 700;
    margin-bottom: 25px;
}

.main-heading.left-align {
    text-align: left;
}

.main-heading span {
    color: #0097e3;
}

.get-details-now-form {
    background: #f5f5f5;
    padding: 25px;
    border-radius: 10px;
}

.get-details-now-form input {
    background: #fff;
    border: 1px solid #d8e2ee;
    padding: 15px;
    color: #666;
    font-size: 18px;
    margin: 0 0 20px 0;
}

.get-details-now-form input {
    background: #fff;
    border: 1px solid #d8e2ee;
    padding: 15px;
    color: #666;
    font-size: 18px;
    margin: 0 0 20px 0;
}

.get-details-now-form textarea {
    background: #fff;
    border: 1px solid #d8e2ee;
    padding: 15px;
    color: #666;
    font-size: 16px;
    margin: 0 0 20px 0;
}

.get-details-now-form .title-heading {
    color: #000;
    font-size: 25px;
    font-weight: 600;
    margin: 0 0 30px 0;
    text-align: center;
}

.get-details-now-form .title-heading span {
    color: #01a3f4;
}

p {
    line-height: 28px;
}

.coming-exam-block .icon div {
    position: absolute;
    background: rgba(0,0,0,0.5);
    width: 130px;
    height: 100px;
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    border-radius: 10px;
    text-align: center;
    padding: 20px 0;
    line-height: 30px;
}

.get-details-now-form .title-heading {
    color: #000;
    font-size: 25px;
    font-weight: 600;
    margin: 0 0 30px 0;
    text-align: center;
}

.right-widget .coming-exam-block {
    margin-bottom: 15px;
}

.coming-exam-block {
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    padding: 10px;
}

.coming-exam-block .icon div sub {
    bottom: 0em;
    font-size: 18px;
}

.right-widget .coming-exam-block h2 {
    font-size: 16px;
    font-weight: inherit;
}




.top-manag-course-widget {
    background-size: cover;
    background-position: center center;
    padding: 130px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px #ccc;
}

section {
    padding: 60px 0;
    overflow: hidden;
}
.blog-post-entrancenews
{

    float: left;
    width: 46%;

 
   
    padding: 0;
    margin: 0 4% 30px 0;

}
.entrancenews{
    width: 98% !important;
}

.owl-carousel .owl-item .entrancenews img{
    width: 135px !important; 
}

.entrance-exam-news {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 10px #ccc;
    float: left;
    width: 46%;
    margin: 0 4% 4% 0;
    padding: 10px;
}

.entrance-exam-news img {
    float: left;
    width: 135px;
    height: 150px;
    margin: 0 15px 0 0;
    border-radius: 10px;
}

.entrance-exam-news h2 {
    font-size: 20px;
    color: #000;
    font-weight: 600;
}

.entrance-exam-news div {
    font-size: 14px;
    color: #333;
}
.itmwnow
{
    width: 405.18px;
    margin-right: 20px;
}

.blog-post-entrancenews h2 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 75px;
}
.topColgs .testi_wrp {
    position: relative;
    width: 100%;
    display: block;
    float: left;
}

.topColgs .loop_colleges .testi_wrp {
    padding: 10px;
}

.topColgs .testi_wrp:hover .testi_hover {
    display: block;
}

.topColgs .testi_wrp .testi_hover {
    width: 95%;
    height: 93%;
    display: none;
    background: #00A3F4;
    position: absolute;
    left: 3%;
    border-radius: 10px;
    top: 4%;
    transform: rotate(3.45deg);
}

.topColgs .loop_colleges .inner_wrp {
    padding: 0px 0 20px 0 !important;
}

.topColgs .testi_wrp .inner_wrp {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    padding: 15px;
    position: relative;
    box-shadow: 0px 3px 8px rgb(0 0 0 / 12%);
    border-radius: 10px;
}

.topColgs .loop_colleges .rating_box {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 86px;
    font-size: 18px;
    color: #000000;
    height: 44px;
    position: absolute;
    top: 15px;
    right: 15px;
    text-align: center;
    line-height: 44px;
}

.topColgs .loop_colleges h3 {
    font-style: normal;
    font-weight: 700;
    width: 100%;
    display: block;
    float: left;
    font-size: 18px;
    padding: 0 15px !important;
    line-height: 26px;
    margin: 10px 0;
    padding: 0;
    color: #000000;
}


.topColgs .loop_colleges p {
    font-style: normal;
    padding: 0 15px !important;
    font-weight: 400;
    margin-bottom: 20px !important;
    font-size: 16px;
    width: 100%;
    display: block;
    float: left;
    line-height: 20px;
    margin: 0px;
    padding: 0px;
    color: rgba(0, 0, 0, 0.6);
}

.topColgs .loop_colleges .college_button_wrp {
    width: 255px;
    display: block;
    margin: 0 auto;
}

.topColgs .loop_colleges a {
    height: 44px;
    width: 121px;
    display: block;
    float: left;
    font-size: 13px;
    color: #00A3F4;
    font-weight: 500;
    border: 1px solid #00A3F4;
    border-radius: 10px;
    line-height: 44px;
    text-align: center;
}

.topColgs .loop_colleges .college_button_wrp .active_bt {
    background: #00A3F4;
    color: #fff;
    margin-right: 10px;
}

.topColgs .loop_colleges .rating_box {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 86px;
    font-size: 18px;
    color: #000000;
    height: 44px;
    position: absolute;
    top: 15px;
    right: 15px;
    text-align: center;
    line-height: 44px;
}

.loop_colleges .rating_box .rating_act {
    color: #FFB108;
    position: relative;
    top: -4px;
}

.featurednewssection img{
    height: auto !important;
    width: 100% !important;
}
#hero.inner-top-header {
    padding: 200px 0 100px 0;
}

h4.small-heading {
    font-size: 32px;
    color: #fff;
    font-weight: 600;
}

.inner-top-header .college-block {
    background: rgba(0, 0, 0, 0.5);
    padding: 20px
}

.inner-top-header .top-college-header .rating_box {
    color: #fff;
    margin-top: 25px;
}

.inner-top-header .top-college-header h1 span {
    color: #00a2f3
}

.inner-top-header .top-college-header ul {
    margin: 0px 0;
    padding: 0;
    display: inline-block;
}

.inner-top-header .top-college-header ul li {
    display: inline;
    text-align: left;
    color: #fff;
    font-size: 20px;
    padding: 0px 30px 0 10px;
    line-height: 40px;
}

.inner-top-header .top-college-header ul li .bi-pin-map-fill {
    color: red;
}

.spac_02 {
    padding: 20px 0;
}

.rating_box {
    float: right;
}

.rating_box .rating_act {
    color: #ffb107;
}

.hero_explore {
    width: 75%;
    display: block;
    float: left;
    position: relative;
}

.hero_explore .ex-field {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.14);
    border: 0px;
    border-radius: 10px;
    height: 64px;
    padding: 0 163px 0 40px;
}

.hero_explore img {
    position: absolute;
    z-index: 1;
    left: 13px;
    top: 20px;
}

.header-search-box input[type="text"].search-box {
    border: 1px solid #ccc;
    background-repeat: no-repeat;
    background-position: 20px center;
    border-radius: 30px;
    width: 95%;
    margin: 0 auto 0px auto !important;
    padding: 15px 15px 15px 50px;
}
.coming-news-block .icon div {
    position: absolute;
    background: rgba(0,0,0,0.5);
    width: 130px;
    height: 100px;
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    border-radius: 10px;
    text-align: center;
    padding: 20px 0;
    line-height: 30px;
}

.right-widget .coming-news-block {
    margin-bottom: 15px;
}

.coming-news-block {
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    padding: 10px;
}

.coming-news-block .icon {
    width: 130px;
    height: 80px;
    margin-right: 20px;
    display: flex;
    float: left;
    border-radius: 10px;
}

.coming-news-block img {
    display: block;
    width: 130px;
    border-radius: 10px;
    object-fit: contain;
}

.coming-news-block .icon div sub {
    bottom: 0em;
    font-size: 18px;
}

.right-widget .coming-news-block h2 {
    font-size: 16px;
    font-weight: inherit;
}


.newshyperlink {
    font-size: 18px !important;
    color: #444;
}

.news-list {
    overflow-x: auto;
    max-height: 474px;
    /* You can further customize the styling here, such as scrollbar styles */
}

.get-news-now-form {
    background: #f5f5f5;
    padding: 25px;
    border-radius: 10px;
}

.get-news-now-form input {
    background: #fff;
    border: 1px solid #d8e2ee;
    padding: 8px;
    color: #666;
    font-size: 18px;
    margin: 0 0 12px 0;
}

.get-news-now-form textarea {
    background: #fff;
    border: 1px solid #d8e2ee;
    padding: 8px;
    color: #666;
    font-size: 16px;
    margin: 0 0 18px 0;
}



.get-news-now-form .title-heading span {
    color: #01a3f4;
}

p {
    line-height: 28px;
}



.get-news-now-form .title-heading {
    color: #000;
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 14px 0;
    text-align: center;
}

.get-news-now-form .submit__message {
    background: #00A3F4;
    border-radius: 15px;
    width: 127px;
    height: 47px;
    border: 0px;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
}










.text-left {
    text-align: left;
}

.displaynone {
    display: none;
}

.main-heading {
    font-size: 32px;
    text-align: center;
    color: #000;
    font-weight: 700;
    margin-bottom: 25px;
}

.main-heading.left-align {
    text-align: left;
}

.main-heading span {
    color: #0097e3;
}

.tab-pane h4 {
    color: #000;
    margin: 10px 0;
}

p {
    line-height: 28px;
}

section {
    padding: 60px 0;
    overflow: hidden;
}

.entrance-exam-news {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 10px #ccc;
    float: left;
    width: 46%;
    margin: 0 4% 4% 0;
    padding: 10px;
}

.entrance-exam-news img {
    float: left;
    width: 135px;
    height: 150px;
    margin: 0 15px 0 0;
    border-radius: 10px;
}

.entrance-exam-news h2 {
    font-size: 20px;
    color: #000;
    font-weight: 600;
}

ul.share-this li {
    display: inline-block;
    padding: 0 10px;
}

ul.share-this li a i {
    background: #fff;
    font-size: 32px;
    box-shadow: 5px 5px 5px #dcdcdc;
    width: 52px;
    height: 52px;
    display: block;
    text-align: center;
    line-height: 52px;
    border-radius: 6px;
}

ul.share-this li a.facebook i {
    color: #3c6bd7 !important;
}

ul.share-this li a.twitter i {
    color: #03a9f5 !important;
}

ul.share-this li a.pinterest i {
    color: #d32f2e !important;
}

ul.share-this li a.linkedin i {
    color: #0076b4 !important;
}

ul.share-this li a.whatsapp i {
    color: #4caf50 !important;
}

ul.share-this li a.link i {
    color: #000 !important;
}


.twitter-icon{

    background: #fff;
    font-size: 31px !important;
    width: 34px !important;
       
}
#hero.inner-top-header {
    padding: 200px 0 100px 0;
}

h4.small-heading {
    font-size: 32px;
    color: #fff;
    font-weight: 600;
}

.inner-top-header .college-block {
    background: rgba(0, 0, 0, 0.5);
    padding: 20px
}

.inner-top-header .top-college-header .rating_box {
    color: #fff;
    margin-top: 25px;
}

.inner-top-header .top-college-header ul {
    margin: 0px 0;
    padding: 0;
    display: inline-block;
}

.floatleft{
    float: left;
}
.text-left {
    text-align: left;
}

.displaynone {
    display: none;
}

.left-exam-section ul {
    margin: 0;
    padding: 0;
}

.left-exam-section .article-loop {
    width: 31%;
    margin: 0 2% 30px 0;
    float: left;
    list-style: none;
}

.left-exam-section .article-loop a {
    background: #fff;
    width: 100%;
    display: block;
    border-radius: 10px;
    box-shadow: 0px 0px 5px #ccc;
    overflow: hidden;
}

.left-exam-section .article-loop a:hover {
    background: #f4f7fe;
}

.left-exam-section .article-loop h2 {
    margin: 20px 0;
    font-size: 18px;
    color: #000;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
}

.get-details-now-form {
    background: #f5f5f5;
    padding: 25px;
    border-radius: 10px;
}

.get-details-now-form input {
    background: #fff;
    border: 1px solid #d8e2ee;
    padding: 15px;
    color: #666;
    font-size: 18px;
    margin: 0 0 20px 0;
}

.get-details-now-form input {
    background: #fff;
    border: 1px solid #d8e2ee;
    padding: 15px;
    color: #666;
    font-size: 18px;
    margin: 0 0 20px 0;
}

.get-details-now-form textarea {
    background: #fff;
    border: 1px solid #d8e2ee;
    padding: 15px;
    color: #666;
    font-size: 16px;
    margin: 0 0 20px 0;
}


.get-details-now-form .title-heading {
    color: #000;
    font-size: 25px;
    font-weight: 600;
    margin: 0 0 30px 0;
    text-align: center;
}

.get-details-now-form .title-heading span {
    color: #01a3f4;
}

.top-streams-widget {
    border-radius: 10px;
    overflow: hidden;
    background: #f3f3f3;
    box-shadow: 5px 5px 10px #ccc;
}

.large-heading-bg {
    line-height: 30px;
    padding: 50px 0;
    margin: 0 0 20px 0;
}

.top-manag-course-widget {
    background-size: cover;
    background-position: center center;
    padding: 130px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px #ccc;
}
.best-management-in-course {
    background-size: cover;
    background-position: center center;
    padding: 130px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px #ccc;
}

.best-management-in-course h2 {
    color: #fff;
    font-size: 42px;
    font-weight: 800;
    margin: 0 0 20px 0;
    text-transform: none;
    text-transform: initial;
}

.best-management-in-course h2 span {
    color: #00a2f3;
}

.best-management-in-course p {
    color: #fff;
    font-size: 22px;
}

.spac_01 {
    padding: 40px 0;
}
.common_sec_h {
    font-family: 'Open Sans';
    font-size: 32px;
    color: #000;
    font-weight: 700;
    margin-bottom: 25px;
    text-align: center;
}

.mt-30 {
    margin-top: 30px;
}

.heder-carsoual .item {
    text-align: center;
}

.heder-carsoual .item h4 {
    color: #fff;
    font-size: 32px;
    margin: 0 0 20px 0;
    text-align: center;
}

.heder-carsoual .item a.apply-now-btn, .apply-now-btn {
    background: #00A3F4;
    color: #fff;
    margin-right: 10px;
    text-align: center;
    display: block;
    width: 150px;
    margin: 0 auto 30px auto;
    border-radius: 10px;
    padding: 13px 10px;
    font-size: 16px;
}

.colorwhite{
    color: #fff !important;
    margin-bottom: 14px !important;
    font-size: 48px !important;
    font-weight: 900 !important;
    text-shadow: 0px 4px 4px rgb(0 0 0 / 30%);
}
.bgdark{
    background-color: #00000050;
}
.scholarship-news {
    background: #fff;
    height: auto;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0px 2px 10px #ccc;
    margin: 0 0 20px 0;
    padding: 20px;
}

.scholarship-news .left-image {
    float: left;
    width: 150px;
    height: auto;
    margin: 0 15px 20px 0;
    border-radius: 10px;
}

.scholarship-news .left-image img {
    width: 100%;
    height: 250px;/*auto;*/
    border-radius: 5px;
    object-fit: contain;
}

.right-desc-block {
    /*float: left;*/
}

.scholarship-news h2 {
    font-size: 20px;
    color: #000;
    font-weight: 600;
}

.right-desc-block ul {
    list-style: none;
    margin: 0px 0 20px 0;
    padding: 0;
}

.right-desc-block li {
    list-style: none;
    color: #5a5959;
    font-size: 18px;
    line-height: 38px;
    margin: 0px;
    padding: 0;
}

.right-desc-block li i {
    font-size: 20px;
    color: #00a3f4;
}

.college_button_wrp .active_bt {
    background: #00A3F4;
    color: #fff;
    margin-right: 10px;
}

.college_button_wrp a {
    height: 44px;
    width: 121px;
    display: block;
    float: left;
    font-size: 13px;
    color: #00A3F4;
    font-weight: 500;
    border: 1px solid #00A3F4;
    border-radius: 10px;
    line-height: 44px;
    text-align: center;
}
.AutocompleteCCC 
{
    border: 1px solid #ccc;
    background-repeat: no-repeat;
    background-position: 20px center;
    border-radius: 30px;
    width: 95%;
    margin: 0 auto 0px auto !important;
    padding-left: 15px ;
}
.text-left {
    text-align: left;
}

.displaynone {
    display: none;
}

.main-heading {
    font-size: 32px;
    text-align: center;
    color: #000;
    font-weight: 700;
    margin-bottom: 25px;
}

.main-heading.left-align {
    text-align: left;
}

.main-heading span {
    color: #0097e3;
}

p {
    line-height: 28px;
}

section {
    padding: 60px 0;
    overflow: hidden;
}

.common_sec_h {
    font-family: 'Open Sans';
    font-size: 32px;
    color: #000;
    font-weight: 700;
    margin-bottom: 25px;
    text-align: center;
}

.mt-30 {
    margin-top: 30px;
}

.heder-carsoual .item {
    text-align: center;
}

.heder-carsoual .item h4 {
    color: #fff;
    font-size: 32px;
    margin: 0 0 20px 0;
    text-align: center;
}

.heder-carsoual .item a.apply-now-btn, .apply-now-btn {
    background: #00A3F4;
    color: #fff;
    margin-right: 10px;
    text-align: center;
    display: block;
    width: 150px;
    margin: 0 auto 30px auto;
    border-radius: 10px;
    padding: 13px 10px;
    font-size: 16px;
}

.spac_10{
    padding: 10px 0px;
}

.admissionsection{
    padding: 150px 0px 50px 0px !important;
}

@media only screen and (max-width: 1024px){
    .admissionsection {
        padding: 80px 0px 50px 0px !important;
    }
}

@media only screen and (max-width: 768px){
    .admissionsection {
        padding: 75px 0px 50px 0px !important;
    }

    .admissionsection .top-course-header{
        padding: 10px !important;
    }
}

@media only screen and (max-width: 425px){
    .admissionsection {
        padding: 50px 0px 50px 0px !important;
    }
}




.alignitemcenter{
    padding: 20px 54px;
    color: #000;
    display: flex;
    float: left;
    line-height: 16px;
    align-items: center;
}

.no-padding{
    padding: 0px;
}

.no-borderradius{
    border-radius: 0px !important;
    width: 100% !important;
    height: 100%;
}

.one-border{
    border: 1px solid #ccc;
}

.cursor_block{
    cursor: not-allowed;
    pointer-events: none;
}

.spac_20{
    padding: 200px 0px;
}

@media only screen and (max-width: 1024px) {
    .spac_20{
        padding: 0px !important;
    }
}
.all-colleges-admission {
    padding: 90px 0;
}

.long_submit_message{
    width: 250px;
}
.common_sec_h {
    font-family: 'Open Sans';
    font-size: 32px;
    color: #000;
    font-weight: 700;
    margin-bottom: 25px;
    text-align: center;
}

.mt-30 {
    margin-top: 30px;
}

.heder-carsoual .item {
    text-align: center;
}

.heder-carsoual .item h4 {
    color: #fff;
    font-size: 32px;
    margin: 0 0 20px 0;
    text-align: center;
}

.heder-carsoual .item a.apply-now-btn, .apply-now-btn {
    background: #00A3F4;
    color: #fff;
    margin-right: 10px;
    text-align: center;
    display: block;
    width: 150px;
    margin: 0 auto 30px auto;
    border-radius: 10px;
    padding: 13px 10px;
    font-size: 16px;
}

.contactheader{
    min-height: 550px !important;
}

.text-left {
        text-align: left;
    }
    
    .displaynone {
        display: none;
    }
    
    .main-heading {
        font-size: 32px;
        text-align: center;
        color: #000;
        font-weight: 700;
        margin-bottom: 25px;
    }
    
    .main-heading.left-align {
        text-align: left;
    }
    
    .main-heading span {
        color: #0097e3;
    }
    
    p {
        line-height: 28px;
    }
    
    section {
        padding: 60px 0;
        overflow: hidden;
    }
.common_sec_h {
    font-family: 'Open Sans';
    font-size: 32px;
    color: #000;
    font-weight: 700;
    margin-bottom: 25px;
    text-align: center;
}

.mt-30 {
    margin-top: 30px;
}

.heder-carsoual .item {
    text-align: center;
}

.heder-carsoual .item h4 {
    color: #fff;
    font-size: 32px;
    margin: 0 0 20px 0;
    text-align: center;
}

.heder-carsoual .item a.apply-now-btn, .apply-now-btn {
    background: #00A3F4;
    color: #fff;
    margin-right: 10px;
    text-align: center;
    display: block;
    width: 150px;
    margin: 0 auto 30px auto;
    border-radius: 10px;
    padding: 13px 10px;
    font-size: 16px;
}
#lefttabs-block {
    background: #f5f5f5;
    border-radius: 10px;
    height: auto;
    overflow: hidden;
    padding: 15px;
}

.left-tabs-nav-widgets {
    float: left;
    background: #fff;
    padding: 8px;
}

.right-content-widgets {
    float: right;
    width: 72%;
}

#lefttabs-block #myTab .nav-item {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
}

#lefttabs-block .nav-tabs .nav-link.active, #lefttabs-block .nav-tabs .nav-link:hover {
    background: #00a3f4;
    color: #fff;
}

#lefttabs-block .nav-tabs .nav-link {
    background: #f0f0f0;
    border-radius: 5px;
    font-size: 16px;
    text-align: center;
    color: #666;
}

.course-overviews-section .nav-tabs .nav-link.active, .course-overviews-section .nav-tabs .nav-link:hover {
    padding: 10px 20px;
    background: #3e6eb0;
    border-radius: 5px;
    font-size: 18px;
    color: #fff;
}

#lefttabs-block .tab-content {
    height: 560px;
    overflow-y: scroll;
}
.left-overviews-section {
    padding: 20px 0;
}

.right-content-widgets .tab-pane ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.right-content-widgets .tab-pane ul li {
    list-style: none;
    margin: 0 0 10px 0;
    padding: 0;
    background: #fff;
    padding: 20px;
}

.right-content-widgets .tab-pane ul li a.apply-btn {
    float: right;
    text-decoration: underline;
}

.right-content-widgets .tab-pane ul li h4 {
    margin: 0px;
    font-size: 18px;
}

.right-content-widgets .tab-pane ul li h4 a {
    color: #000;
    font-size: 18px;
}

.right-lerge-news {
    min-height: 640px;
    display: flex;
    align-items: center;
}

.cursorpointer{
    cursor: pointer;
}

.mynewclass .MuiAccordionSummary-root
{

background: #f0f0f0;
border-radius: 5px;
font-size: 16px;
text-align: center;
color: #666;
}


.mynewclass ul li {
    list-style: none;
    margin: 0 0 10px 0;
    padding: 0;
    background: #fff;
    padding: 20px;
}
.mynewclass ul li a.apply-btn {
    float: right;
    text-decoration: underline;
}

.mynewclass .tab-pane ul li h4 {
    margin: 0px;
    font-size: 18px;
}

.mynewclass ul li h4 a {
    color: #000;
    font-size: 18px;
}
.mynewclass .MuiAccordionDetails-root
{
    background: #f5f5f5;

}

.mynewclass .MuiAccordionSummary-root.Mui-expanded

{
    background: #00a3f4;
    color: white;

}


.mynewclass .Mui-expanded .MuiIconButton-root
{

    color: white;

}


    

.topColgs .testi_wrp {
    position: relative;
    width: 100%;
    display: block;
    float: left;
}

.topColgs .loop_colleges .testi_wrp {
    padding: 10px;
}

.topColgs .testi_wrp:hover .testi_hover {
    display: block;
}

.topColgs .testi_wrp .testi_hover {
    width: 95%;
    height: 93%;
    display: none;
    background: #00A3F4;
    position: absolute;
    left: 3%;
    border-radius: 10px;
    top: 4%;
    transform: rotate(3.45deg);
}

.topColgs .loop_colleges .inner_wrp {
    padding: 0px 0 20px 0 !important;
}

.topColgs .testi_wrp .inner_wrp {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    padding: 15px;
    position: relative;
    box-shadow: 0px 3px 8px rgb(0 0 0 / 12%);
    border-radius: 10px;
}

.topColgs .loop_colleges .rating_box {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 86px;
    font-size: 18px;
    color: #000000;
    height: 44px;
    position: absolute;
    top: 15px;
    right: 15px;
    text-align: center;
    line-height: 44px;
}

.topColgs .loop_colleges h3 {
    font-style: normal;
    font-weight: 700;
    width: 100%;
    display: block;
    float: left;
    font-size: 18px;
    padding: 0 15px !important;
    line-height: 26px;
    margin: 10px 0;
    padding: 0;
    color: #000000;
}


.topColgs .loop_colleges p {
    font-style: normal;
    padding: 0 15px !important;
    font-weight: 400;
    margin-bottom: 20px !important;
    font-size: 16px;
    width: 100%;
    display: block;
    float: left;
    line-height: 20px;
    margin: 0px;
    padding: 0px;
    color: rgba(0, 0, 0, 0.6);
}

.topColgs .loop_colleges .college_button_wrp {
    width: 255px;
    display: block;
    margin: 0 auto;
}

.topColgs .loop_colleges a {
    height: 44px;
    width: 121px;
    display: block;
    float: left;
    font-size: 13px;
    color: #00A3F4;
    font-weight: 500;
    border: 1px solid #00A3F4;
    border-radius: 10px;
    line-height: 44px;
    text-align: center;
}

.topColgs .loop_colleges .college_button_wrp .active_bt {
    background: #00A3F4;
    color: #fff;
    margin-right: 10px;
}

.topColgs .loop_colleges .rating_box {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 86px;
    font-size: 18px;
    color: #000000;
    height: 44px;
    position: absolute;
    top: 15px;
    right: 15px;
    text-align: center;
    line-height: 44px;
}

.loop_colleges .rating_box .rating_act {
    color: #FFB108;
    position: relative;
    top: -4px;
}

.widthset{
    width: 130px !important;
}

.inner-top-header .top-course-header h3 span {
    color: #00a2f3;
}
.blackcolor{
        color: black;
}
.no-bg{
    background: transparent !important;
}

.get-details-now-form select {
    background: #fff;
    border: 1px solid #d8e2ee;
    padding: 15px;
    color: #666;
    font-size: 18px;
    margin: 0 0 20px 0;
}
.whatwedo_icons {
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        margin: 0 auto;
        border-radius: 100%;
    }
    
    .whatwedo_icons img {
        width: 60px;
        display: block;
        margin: 0 auto;
    }
    
    /*
    .progress {
        width: 150px;
        height: 150px !important;
        float: left; 
        line-height: 150px;
        background: none;
        margin: 20px;
        box-shadow: none;
        position: relative;
      }
      .progress:after {
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 12px solid #fff;
        position: absolute;
        top: 0;
        left: 0;
      }
      .progress>span {
        width: 50%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        z-index: 1;
      }
      .progress .progress-left {
        left: 0;
      }
      .progress .progress-bar {
        width: 100%;
        height: 100%;
        background: none;
        border-width: 12px;
        border-style: solid;
        position: absolute;
        top: 0;
      }
      .progress .progress-left .progress-bar {
        left: 100%;
        border-top-right-radius: 80px;
        border-bottom-right-radius: 80px;
        border-left: 0;
        -webkit-transform-origin: center left;
        transform-origin: center left;
      }
      .progress .progress-right {
        right: 0;
      }
      .progress .progress-right .progress-bar {
        left: -100%;
        border-top-left-radius: 80px;
        border-bottom-left-radius: 80px;
        border-right: 0;
        -webkit-transform-origin: center right;
        transform-origin: center right;
        animation: loading-1 1.8s linear forwards;
      }
      .progress .progress-value {
        width: 90%;
        height: 90%;
        border-radius: 50%;
        background: #000;
        font-size: 24px;
        color: #fff;
        line-height: 135px;
        text-align: center;
        position: absolute;
        top: 5%;
        left: 5%;
      }
      .progress.blue .progress-bar {
        border-color: #049dff;
      }
      .progress.blue .progress-left .progress-bar {
        animation: loading-2 1.5s linear forwards 1.8s;
      }
      .progress.yellow .progress-bar {
        border-color: #fdba04;
      }
      .progress.yellow .progress-right .progress-bar {
        animation: loading-3 1.8s linear forwards;
      }
      .progress.yellow .progress-left .progress-bar {
        animation: none;
      }
      @keyframes loading-1 {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
      @keyframes loading-2 {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(144deg);
          transform: rotate(144deg);
        }
      }
      @keyframes loading-3 {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
        }
      }
    
      */
    
      .progress {
        width: 250px;
        height: 250px;
        line-height: 250px;
        background: none;
        margin: 0 auto;
        box-shadow: none;
        position: relative;
      }
      .progress:after {
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 22px solid #efefef;
        position: absolute;
        top: 0;
        left: 0;
      }
      .progress > span {
        width: 50%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        z-index: 1;
      }
      .progress .progress-left {
        left: 0;
      }
      .progress .progress-bar {
        width: 100%;
        height: 100%;
        background: none;
        border-width: 22px;
        border-style: solid;
        position: absolute;
        top: 0;
        border-color: #ffb43e;
      }
    
      .progress .yellowcolor{
        border-color: #ffc220;
      } 
    
      .progress .bluecolor{
        border-color: #4b91d7;
      } 
    
      .progress .pinkcolor{
        border-color: #f86477;
      } 
    
      .bluecolor div{
        color: #4b91d7;
        font-size: 51px;
        font-weight: bold;
      }
    
      .pinkcolor div{
        color: #f86477;
        font-size: 51px;
        font-weight: bold;
      }
    
      .yellowcolor div{
        color: #ffc220;
        font-size: 51px;
        font-weight: bold;
      }
    
      .progress .progress-left .progress-bar {
        left: 100%;
        border-top-right-radius: 250px;
        border-bottom-right-radius: 250px;
        border-left: 0;
        transform-origin: center left;
      }
      .progress .progress-right {
        right: 0;
      }
      .progress .progress-right .progress-bar {
        left: -100%;
        border-top-left-radius: 250px;
        border-bottom-left-radius: 250px;
        border-right: 0;
        transform-origin: center right;
      }
      .progress .progress-value {
        display: flex;
        border-radius: 50%;
        font-size: 1rem;
        text-align: center;
        line-height: 30px;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        font-weight: 300;
      }
      .progress .progress-value span {
        font-size: 24px;
        color:#000;
      }
      /* This for loop creates the necessary css animation names 
       Due to the split circle of progress-left and progress right, we must use the animations on each side. 
       */
      .progress[data-percentage="1"] .progress-right .progress-bar {
        animation: loading-1 0.5s linear forwards;
      }
      .progress[data-percentage="1"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="2"] .progress-right .progress-bar {
        animation: loading-2 0.5s linear forwards;
      }
      .progress[data-percentage="2"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="3"] .progress-right .progress-bar {
        animation: loading-3 0.5s linear forwards;
      }
      .progress[data-percentage="3"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="4"] .progress-right .progress-bar {
        animation: loading-4 0.5s linear forwards;
      }
      .progress[data-percentage="4"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="5"] .progress-right .progress-bar {
        animation: loading-5 0.5s linear forwards;
      }
      .progress[data-percentage="5"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="6"] .progress-right .progress-bar {
        animation: loading-6 0.5s linear forwards;
      }
      .progress[data-percentage="6"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="7"] .progress-right .progress-bar {
        animation: loading-7 0.5s linear forwards;
      }
      .progress[data-percentage="7"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="8"] .progress-right .progress-bar {
        animation: loading-8 0.5s linear forwards;
      }
      .progress[data-percentage="8"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="9"] .progress-right .progress-bar {
        animation: loading-9 0.5s linear forwards;
      }
      .progress[data-percentage="9"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="10"] .progress-right .progress-bar {
        animation: loading-10 0.5s linear forwards;
      }
      .progress[data-percentage="10"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="11"] .progress-right .progress-bar {
        animation: loading-11 0.5s linear forwards;
      }
      .progress[data-percentage="11"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="12"] .progress-right .progress-bar {
        animation: loading-12 0.5s linear forwards;
      }
      .progress[data-percentage="12"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="13"] .progress-right .progress-bar {
        animation: loading-13 0.5s linear forwards;
      }
      .progress[data-percentage="13"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="14"] .progress-right .progress-bar {
        animation: loading-14 0.5s linear forwards;
      }
      .progress[data-percentage="14"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="15"] .progress-right .progress-bar {
        animation: loading-15 0.5s linear forwards;
      }
      .progress[data-percentage="15"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="16"] .progress-right .progress-bar {
        animation: loading-16 0.5s linear forwards;
      }
      .progress[data-percentage="16"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="17"] .progress-right .progress-bar {
        animation: loading-17 0.5s linear forwards;
      }
      .progress[data-percentage="17"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="18"] .progress-right .progress-bar {
        animation: loading-18 0.5s linear forwards;
      }
      .progress[data-percentage="18"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="19"] .progress-right .progress-bar {
        animation: loading-19 0.5s linear forwards;
      }
      .progress[data-percentage="19"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="20"] .progress-right .progress-bar {
        animation: loading-20 0.5s linear forwards;
      }
      .progress[data-percentage="20"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="21"] .progress-right .progress-bar {
        animation: loading-21 0.5s linear forwards;
      }
      .progress[data-percentage="21"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="22"] .progress-right .progress-bar {
        animation: loading-22 0.5s linear forwards;
      }
      .progress[data-percentage="22"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="23"] .progress-right .progress-bar {
        animation: loading-23 0.5s linear forwards;
      }
      .progress[data-percentage="23"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="24"] .progress-right .progress-bar {
        animation: loading-24 0.5s linear forwards;
      }
      .progress[data-percentage="24"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="25"] .progress-right .progress-bar {
        animation: loading-25 0.5s linear forwards;
      }
      .progress[data-percentage="25"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="26"] .progress-right .progress-bar {
        animation: loading-26 0.5s linear forwards;
      }
      .progress[data-percentage="26"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="27"] .progress-right .progress-bar {
        animation: loading-27 0.5s linear forwards;
      }
      .progress[data-percentage="27"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="28"] .progress-right .progress-bar {
        animation: loading-28 0.5s linear forwards;
      }
      .progress[data-percentage="28"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="29"] .progress-right .progress-bar {
        animation: loading-29 0.5s linear forwards;
      }
      .progress[data-percentage="29"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="30"] .progress-right .progress-bar {
        animation: loading-30 0.5s linear forwards;
      }
      .progress[data-percentage="30"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="31"] .progress-right .progress-bar {
        animation: loading-31 0.5s linear forwards;
      }
      .progress[data-percentage="31"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="32"] .progress-right .progress-bar {
        animation: loading-32 0.5s linear forwards;
      }
      .progress[data-percentage="32"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="33"] .progress-right .progress-bar {
        animation: loading-33 0.5s linear forwards;
      }
      .progress[data-percentage="33"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="34"] .progress-right .progress-bar {
        animation: loading-34 0.5s linear forwards;
      }
      .progress[data-percentage="34"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="35"] .progress-right .progress-bar {
        animation: loading-35 0.5s linear forwards;
      }
      .progress[data-percentage="35"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="36"] .progress-right .progress-bar {
        animation: loading-36 0.5s linear forwards;
      }
      .progress[data-percentage="36"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="37"] .progress-right .progress-bar {
        animation: loading-37 0.5s linear forwards;
      }
      .progress[data-percentage="37"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="38"] .progress-right .progress-bar {
        animation: loading-38 0.5s linear forwards;
      }
      .progress[data-percentage="38"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="39"] .progress-right .progress-bar {
        animation: loading-39 0.5s linear forwards;
      }
      .progress[data-percentage="39"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="40"] .progress-right .progress-bar {
        animation: loading-40 0.5s linear forwards;
      }
      .progress[data-percentage="40"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="41"] .progress-right .progress-bar {
        animation: loading-41 0.5s linear forwards;
      }
      .progress[data-percentage="41"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="42"] .progress-right .progress-bar {
        animation: loading-42 0.5s linear forwards;
      }
      .progress[data-percentage="42"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="43"] .progress-right .progress-bar {
        animation: loading-43 0.5s linear forwards;
      }
      .progress[data-percentage="43"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="44"] .progress-right .progress-bar {
        animation: loading-44 0.5s linear forwards;
      }
      .progress[data-percentage="44"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="45"] .progress-right .progress-bar {
        animation: loading-45 0.5s linear forwards;
      }
      .progress[data-percentage="45"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="46"] .progress-right .progress-bar {
        animation: loading-46 0.5s linear forwards;
      }
      .progress[data-percentage="46"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="47"] .progress-right .progress-bar {
        animation: loading-47 0.5s linear forwards;
      }
      .progress[data-percentage="47"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="48"] .progress-right .progress-bar {
        animation: loading-48 0.5s linear forwards;
      }
      .progress[data-percentage="48"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="49"] .progress-right .progress-bar {
        animation: loading-49 0.5s linear forwards;
      }
      .progress[data-percentage="49"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="50"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="50"] .progress-left .progress-bar {
        animation: 0;
      }
      .progress[data-percentage="51"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="51"] .progress-left .progress-bar {
        animation: loading-1 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="52"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="52"] .progress-left .progress-bar {
        animation: loading-2 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="53"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="53"] .progress-left .progress-bar {
        animation: loading-3 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="54"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="54"] .progress-left .progress-bar {
        animation: loading-4 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="55"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="55"] .progress-left .progress-bar {
        animation: loading-5 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="56"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="56"] .progress-left .progress-bar {
        animation: loading-6 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="57"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="57"] .progress-left .progress-bar {
        animation: loading-7 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="58"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="58"] .progress-left .progress-bar {
        animation: loading-8 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="59"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="59"] .progress-left .progress-bar {
        animation: loading-9 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="60"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="60"] .progress-left .progress-bar {
        animation: loading-10 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="61"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="61"] .progress-left .progress-bar {
        animation: loading-11 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="62"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="62"] .progress-left .progress-bar {
        animation: loading-12 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="63"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="63"] .progress-left .progress-bar {
        animation: loading-13 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="64"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="64"] .progress-left .progress-bar {
        animation: loading-14 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="65"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="65"] .progress-left .progress-bar {
        animation: loading-15 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="66"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="66"] .progress-left .progress-bar {
        animation: loading-16 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="67"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="67"] .progress-left .progress-bar {
        animation: loading-17 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="68"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="68"] .progress-left .progress-bar {
        animation: loading-18 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="69"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="69"] .progress-left .progress-bar {
        animation: loading-19 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="70"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="70"] .progress-left .progress-bar {
        animation: loading-20 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="71"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="71"] .progress-left .progress-bar {
        animation: loading-21 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="72"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="72"] .progress-left .progress-bar {
        animation: loading-22 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="73"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="73"] .progress-left .progress-bar {
        animation: loading-23 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="74"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="74"] .progress-left .progress-bar {
        animation: loading-24 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="75"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="75"] .progress-left .progress-bar {
        animation: loading-25 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="76"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="76"] .progress-left .progress-bar {
        animation: loading-26 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="77"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="77"] .progress-left .progress-bar {
        animation: loading-27 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="78"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="78"] .progress-left .progress-bar {
        animation: loading-28 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="79"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="79"] .progress-left .progress-bar {
        animation: loading-29 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="80"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="80"] .progress-left .progress-bar {
        animation: loading-30 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="81"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="81"] .progress-left .progress-bar {
        animation: loading-31 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="82"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="82"] .progress-left .progress-bar {
        animation: loading-32 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="83"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="83"] .progress-left .progress-bar {
        animation: loading-33 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="84"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="84"] .progress-left .progress-bar {
        animation: loading-34 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="85"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="85"] .progress-left .progress-bar {
        animation: loading-35 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="86"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="86"] .progress-left .progress-bar {
        animation: loading-36 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="87"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="87"] .progress-left .progress-bar {
        animation: loading-37 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="88"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="88"] .progress-left .progress-bar {
        animation: loading-38 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="89"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="89"] .progress-left .progress-bar {
        animation: loading-39 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="90"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="90"] .progress-left .progress-bar {
        animation: loading-40 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="91"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="91"] .progress-left .progress-bar {
        animation: loading-41 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="92"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="92"] .progress-left .progress-bar {
        animation: loading-42 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="93"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="93"] .progress-left .progress-bar {
        animation: loading-43 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="94"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="94"] .progress-left .progress-bar {
        animation: loading-44 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="95"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="95"] .progress-left .progress-bar {
        animation: loading-45 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="96"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="96"] .progress-left .progress-bar {
        animation: loading-46 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="97"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="97"] .progress-left .progress-bar {
        animation: loading-47 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="98"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="98"] .progress-left .progress-bar {
        animation: loading-48 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="99"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="99"] .progress-left .progress-bar {
        animation: loading-49 0.5s linear forwards 0.5s;
      }
      .progress[data-percentage="100"] .progress-right .progress-bar {
        animation: loading-50 0.5s linear forwards;
      }
      .progress[data-percentage="100"] .progress-left .progress-bar {
        animation: loading-50 0.5s linear forwards 0.5s;
      }
      @keyframes loading-1 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(3.6deg);
        }
      }
      @keyframes loading-2 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(7.2deg);
        }
      }
      @keyframes loading-3 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(10.8deg);
        }
      }
      @keyframes loading-4 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(14.4deg);
        }
      }
      @keyframes loading-5 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(18deg);
        }
      }
      @keyframes loading-6 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(21.6deg);
        }
      }
      @keyframes loading-7 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(25.2deg);
        }
      }
      @keyframes loading-8 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(28.8deg);
        }
      }
      @keyframes loading-9 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(32.4deg);
        }
      }
      @keyframes loading-10 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(36deg);
        }
      }
      @keyframes loading-11 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(39.6deg);
        }
      }
      @keyframes loading-12 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(43.2deg);
        }
      }
      @keyframes loading-13 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(46.8deg);
        }
      }
      @keyframes loading-14 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(50.4deg);
        }
      }
      @keyframes loading-15 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(54deg);
        }
      }
      @keyframes loading-16 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(57.6deg);
        }
      }
      @keyframes loading-17 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(61.2deg);
        }
      }
      @keyframes loading-18 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(64.8deg);
        }
      }
      @keyframes loading-19 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(68.4deg);
        }
      }
      @keyframes loading-20 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(72deg);
        }
      }
      @keyframes loading-21 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(75.6deg);
        }
      }
      @keyframes loading-22 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(79.2deg);
        }
      }
      @keyframes loading-23 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(82.8deg);
        }
      }
      @keyframes loading-24 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(86.4deg);
        }
      }
      @keyframes loading-25 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(90deg);
        }
      }
      @keyframes loading-26 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(93.6deg);
        }
      }
      @keyframes loading-27 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(97.2deg);
        }
      }
      @keyframes loading-28 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(100.8deg);
        }
      }
      @keyframes loading-29 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(104.4deg);
        }
      }
      @keyframes loading-30 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(108deg);
        }
      }
      @keyframes loading-31 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(111.6deg);
        }
      }
      @keyframes loading-32 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(115.2deg);
        }
      }
      @keyframes loading-33 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(118.8deg);
        }
      }
      @keyframes loading-34 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(122.4deg);
        }
      }
      @keyframes loading-35 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(126deg);
        }
      }
      @keyframes loading-36 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(129.6deg);
        }
      }
      @keyframes loading-37 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(133.2deg);
        }
      }
      @keyframes loading-38 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(136.8deg);
        }
      }
      @keyframes loading-39 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(140.4deg);
        }
      }
      @keyframes loading-40 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(144deg);
        }
      }
      @keyframes loading-41 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(147.6deg);
        }
      }
      @keyframes loading-42 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(151.2deg);
        }
      }
      @keyframes loading-43 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(154.8deg);
        }
      }
      @keyframes loading-44 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(158.4deg);
        }
      }
      @keyframes loading-45 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(162deg);
        }
      }
      @keyframes loading-46 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(165.6deg);
        }
      }
      @keyframes loading-47 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(169.2deg);
        }
      }
      @keyframes loading-48 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(172.8deg);
        }
      }
      @keyframes loading-49 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(176.4deg);
        }
      }
      @keyframes loading-50 {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(180deg);
        }
      }
      
.common_sec_h {
    font-family: 'Open Sans';
    font-size: 32px;
    color: #000;
    font-weight: 700;
    margin-bottom: 25px;
    text-align: center;
}

.testi_wrp {
    position: relative;
    width: 100%;
    display: block;
    float: left;
}

.loop_colleges .testi_wrp {
    padding: 10px;
}

.loop_Universities .inner_wrp {
    overflow: hidden;
}
.loop_colleges .inner_wrp {
    padding: 0px 0 20px 0 !important;
}
.testi_wrp .inner_wrp {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    padding: 15px;
    position: relative;
    box-shadow: 0px 3px 8px rgb(0 0 0 / 12%);
    border-radius: 10px;
    overflow: hidden;
}


.loop_colleges .rating_box {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 86px;
    font-size: 18px;
    color: #000000;
    height: 44px;
    position: absolute;
    top: 15px;
    right: 15px;
    text-align: center;
    line-height: 44px;
}

.loop_colleges .rating_box .rating_act {
    color: #FFB108;
}

.loop_colleges p {
    font-style: normal;
    padding: 0 15px !important;
    font-weight: 400;
    margin-bottom: 20px !important;
    font-size: 16px;
    width: 100%;
    display: block;
    float: left;
    line-height: 20px;
    margin: 0px;
    padding: 0px;
    color: rgba(0, 0, 0, 0.6);
}

.loop_colleges .college_button_wrp {
    width: 255px;
    display: block;
    margin: 0 auto;
}


.loop_colleges .college_button_wrp .active_bt {
    background: #00A3F4;
    color: #fff;
    margin-right: 10px;
}

.loop_colleges a {
    height: 44px;
    width: 121px;
    display: block;
    float: left;
    font-size: 13px;
    color: #00A3F4;
    font-weight: 500;
    border: 1px solid #00A3F4;
    border-radius: 10px;
    line-height: 44px;
    text-align: center;
}

.loop_Exams .overlay_grad {
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    width: 100%;
    height: 100%;
    z-index: 0;
}

.loop_Exams .test_content_wrp {
    z-index: 5;
    position: relative;
}

.loop_Exams .short_title {
    width: 100%;
    display: block;
    float: left;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    padding: 0 15px;
    margin-bottom: 0px;
    margin-top: 180px;
}
.loop_Exams h3 {
    font-size: 22px;
    color: #fff;
    margin: 10px 0;
}

.loop_Exams p {
    font-size: 16px;
    color: #fff;
    margin: 0px 0 0 0 !important;
}



.testimonials-block {
    float: left;
    width: 100%;
    margin: 0 0 30px 0;
}

.audio-block {
    float: left;
    width: 60%;
}

.testimonials-block img {
    width: 100%;
}

.testimonials-block audio {
    width: 99.9%;
}

.testmonila-content {
    float: right;
    width: 38%;
    height: 316px !important;
    overflow-y: scroll;
    background: #f5f5f5;
    padding: 8px;
    text-align: center;
    margin: 0px !important;
}

.testimonials-block .bi-quote {
    color: #00a3f4;
    font-size: 45px;
    font-weight: bold;
}

.testmonila-content strong {
    float: right;
    color: #00a3f4;
}

.w30{
    width: 30px !important;
    margin: 0px 0px 20px 0px;
}
.lowercase {
    text-transform: none !important;
}

.testimonials-block {
    float: left;
    width: 100%;
    margin: 0 0 30px 0;
}

.audio-block {
    float: left;
    width: 60%;
}

.testimonials-block img {
    width: 100%;
}

.testimonials-block audio {
    width: 99.9%;
}

.testmonila-content {
    float: right;
    width: 38%;
    height: 250px;
    overflow-y: scroll;
    background: #f5f5f5;
    padding: 8px;
    text-align: center;
    margin: 62px 0 0 0;
}

.testimonials-block .bi-quote {
    color: #00a3f4;
    font-size: 45px;
    font-weight: bold;
}

.testmonila-content strong {
    float: right;
    color: #00a3f4;
}

.textleft{
    text-align: left !important;
}

.siginsection .nav-pills{
    width: 70%;
    margin: 0 auto;
}

.siginsection .nav-fill .nav-item .nav-link, .siginsection .nav-justified .nav-item .nav-link{
    padding: 18px;
    border-radius: 0px;
}

.siginsection  .nav-pills .nav-link.active{
    background-color: #0d6efd;
    color: white !important;
}

.siginsection .nav-pills .nav-link{
    background: #f5eeee;
    color: black;
}

ul.share-this li a.google i{
    color: #03a9f5 !important;
}
ul.share-this li div.twitter .bi-twitter  {    color: #03a9f5 !important;}
ul.share-this li div.twitter i {
    background: #fff;
    font-size: 32px;
    box-shadow: 5px 5px 5px #dcdcdc;
    width: 52px;
    height: 52px;
    display: block;
    text-align: center;
    line-height: 52px;
    border-radius: 6px;
}



.bg-dark1{
    background: rgba(0, 0, 0, 0.5);
}
.ourteam .topcollege-box .full_wdth{
    height: auto !important;
    object-fit: contain;
}

.ourteam .topcollege-box h3{
    height: auto !important;
    width: 100%;
}

.ourteam .college-content p{
    height: auto !important;
}

.ourteam .topcollege-box .college_button_wrp a{
    float: left;
    padding: 0px 10px;
    width: auto;
}

.navbarclass .MuiButton-root:hover{
    color: black !important;
}

.buttontop:hover{
    background-color: transparent !important;
}
.all-colleges-admission {
    padding: 90px 0;
}

.floatleft{
    float: left !important;
}

.talksection{
    padding: 50px 0px !important;
}

.collegecourseh1tag{
    font-size: 40px !important;
}
.left-overviews-section {
    padding: 20px 0;
}

.course-overviews-section .nav-tabs .nav-link.active,
.course-overviews-section .nav-tabs .nav-link:hover {
    padding: 10px 20px;
    background: #3e6eb0;
    border-radius: 5px;
    font-size: 18px;
    color: #fff;
}

.course-overviews-section .nav-tabs .nav-link {
    padding: 10px 20px;
    border: 0px;
    margin: 0 2px 0;
    background: #f0f7ff;
    border-radius: 5px;
    font-size: 18px;
    color: #000;
}

.course-overviews-section .nav-tabs {
    border: 0px;
}

.widgets {
    margin: 0 0 20px 0;
    display: block;
}

.large-heading-bg {
    line-height: 30px;
    padding: 50px 0;
    margin: 0 0 20px 0;
}

.large-heading-bg h2 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
}

.top-streams-widget {
    border-radius: 10px;
    overflow: hidden;
    background: #f3f3f3;
    box-shadow: 5px 5px 10px #ccc;
}

.top-streams-widget ul {
    padding: 20px;
}

.top-streams-widget ul li {
    display: inline-block;
    font-size: 18px;
    width: 48%;
    padding: 5px;
}

.top-streams-widget ul li a {
    color: #000;
}

.top-streams-widget ul li a:hover {
    color: #018ed4;
}

.top-streams-widget ul li a::before {
    content: "\ea41";
    font-family: boxicons !important;
    color: #018ed4;
    font-size: 18px;
    display: inline;
    position: relative;
    left: -8px;
    top: 2px;
    border: 1px solid #018ed4;
    border-radius: 50%;
}

.top-manag-course-widget {
    background-size: cover;
    background-position: center center;
    padding: 130px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px #ccc;
}

.top-manag-course-widget h2 {
    color: #fff;
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 600;
    margin: 0 0 20px 0;
}

.top-manag-course-widget p {
    color: #fff;
    font-size: 18px;
}

p {
    line-height: 28px;
    font-style: 16px;
}


.interested-in-course {
    background-size: cover;
    background-position: center center;
    padding: 130px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px #ccc;
}

.interested-in-course p {
    color: #fff;
    font-size: 22px;
}

.fullwidth li{
    width: 100% !important;
}



.collegetabcontent table tr td {
    padding: 12px;
    text-align: center;
    border: 1px solid rgb(221, 221, 221);
    font-size: 16px;
    font-weight: 400;
}

.collegetabcontent table tr th {
    padding: 12px;
    text-align: center;
    border: 1px solid rgb(221, 221, 221);
    font-weight: bold;
}

.collegetabcontent #overview table tr td:first-child {
    background: #f2f2f2;
}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px){
    .collegetabcontent table, .collegetabcontent thead, .collegetabcontent tbody, .collegetabcontent th, .collegetabcontent td, .collegetabcontent tr {
        display: revert !important;
    }

}

.topColgs .testi_wrp {
    position: relative;
    width: 100%;
    display: block;
    float: left;
}

.topColgs .loop_colleges .testi_wrp {
    padding: 10px;
}

.topColgs .testi_wrp:hover .testi_hover {
    display: block;
}

.topColgs .testi_wrp .testi_hover {
    width: 95%;
    height: 93%;
    display: none;
    background: #00A3F4;
    position: absolute;
    left: 3%;
    border-radius: 10px;
    top: 4%;
    transform: rotate(3.45deg);
}

.topColgs .loop_colleges .inner_wrp {
    padding: 0px 0 20px 0 !important;
}

.topColgs .testi_wrp .inner_wrp {
    width: 100%;
    display: block;
    float: left;
    background: #FFFFFF;
    padding: 15px;
    position: relative;
    box-shadow: 0px 3px 8px rgb(0 0 0 / 12%);
    border-radius: 10px;
}

.topColgs .loop_colleges .rating_box {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 86px;
    font-size: 18px;
    color: #000000;
    height: 44px;
    position: absolute;
    top: 15px;
    right: 15px;
    text-align: center;
    line-height: 44px;
}

.topColgs .loop_colleges h3 {
    font-style: normal;
    font-weight: 700;
    width: 100%;
    display: block;
    float: left;
    font-size: 18px;
    padding: 0 15px !important;
    line-height: 26px;
    margin: 10px 0;
    padding: 0;
    color: #000000;
}


.topColgs .loop_colleges p {
    font-style: normal;
    padding: 0 15px !important;
    font-weight: 400;
    margin-bottom: 20px !important;
    font-size: 16px;
    width: 100%;
    display: block;
    float: left;
    line-height: 20px;
    margin: 0px;
    padding: 0px;
    color: rgba(0, 0, 0, 0.6);
}

.topColgs .loop_colleges .college_button_wrp {
    width: 255px;
    display: block;
    margin: 0 auto;
}

.topColgs .loop_colleges a {
    height: 44px;
    width: 121px;
    display: block;
    float: left;
    font-size: 13px;
    color: #00A3F4;
    font-weight: 500;
    border: 1px solid #00A3F4;
    border-radius: 10px;
    line-height: 44px;
    text-align: center;
}

.topColgs .loop_colleges .college_button_wrp .active_bt {
    background: #00A3F4;
    color: #fff;
    margin-right: 10px;
}

.topColgs .loop_colleges .rating_box {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    width: 86px;
    font-size: 18px;
    color: #000000;
    height: 44px;
    position: absolute;
    top: 15px;
    right: 15px;
    text-align: center;
    line-height: 44px;
}

.loop_colleges .rating_box .rating_act {
    color: #FFB108;
    position: relative;
    top: -4px;
}

.all-colleges-admission {
    padding: 120px 0;
}

.all-colleges-admission h2 {
    color: #fff;
    font-size: 32px;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 0 20px 0;
}

.all-colleges-admission p {
    color: #fff;
    margin: 0 0 10px 0;
}

.all-colleges-admission input,
.all-colleges-admission textarea {
    border: 0px;
    padding: 10px;
    font-size: 16px;
    margin: 0 0 15px 0;
}
.colourwhite{
    color: white
}
.h2-heading{
  font-size: 36px !important;
}
.MCC-font-size {
  font-size: 30px !important; /* Adjust the value as needed */
}

.fa-map-marker:before {
  content: "\f041";
  color: red !important;
}

.linewidth{
  min-height: 96px !important ;
}

.table-width{
  width: 68% !important;
}
.top80 {
  top: 70% !important;
}
.mbbsimpletable{
  text-align: center !important;
}
.mbbsimpletable {
  border: 1px solid black;  
  text-align: center !important;
}

.mbbsimpletable th,
.mbbsimpletable td
 {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  border: 1px solid black;
  width: 20%; 
  height: 50px;
  font-size: 17px;
  text-align: center !important;
  
}
.top90 {
  top: 80% !important;
}
.bgfooter{
  background: #34345a;
}
.bgfooter a{
  background: #34345a;
}
.h2why {
  text-align: center;
  font-weight: 800;
  font-size: 35px;
  color: yellow;
  /* text-shadow: #FC0 1px 0 10px; */
}

.img200 {
  height: auto;
  width: 130px !important;
}
.coltext {
  text-align: center !important;
  font-weight: 600;
  color: black;
}

.landgingcolleges img {
  float: none !important;
  height: 155px !important;
  object-fit: cover;
}
.landgingcolleges h5 {
  min-height: 72px;
}

.imgcontain {
  object-fit: contain !important;
}

.bgblack {
  background: rgba(0, 0, 0, 0.27);
  border-radius: 11px;
}

.ll {
  letter-spacing: -1px;
}
p {
  font-size: 18px !important;
}
.font23 {
  font-size: 23px !important;
}

.bgcc {
  background-color: #1d415a !important;
}

.dd {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}

.imgh {
  min-height: 400px !important;
}
.bgimage {
  min-height: 659px;
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.hov:hover {
  background-color: white !important;
}
.h1MBBS {
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  color: #000;
  letter-spacing: 1px;
}
.h1MBBS .span {
  color: rgb(75 6 6);
  display: block;
  font-size: 34px !important;
}
.d-block {
  display: block;
}
.cyellow {
  color: yellow;
  font-size: 27px;
}
.p {
  color: #ffffff;
  font-size: 22px;
  margin: 31px 0px;
  font-weight: 600;
}
.form-box {
  max-width: 425px;
  margin: auto;
  padding: 25px;
  background: #00000045;
  border-radius: 11px;
}
.font-weight-bold {
  font-weight: 500;
}
.h4blue{
  color: rgb(0, 151, 227);
    font-weight: 600;
    font-size: 27px !important ;
}
.textmbbs li{
  font-size: 18px;
  color: black;
  line-height: 2;
}
.textmbbs{
  font-size: 18px;
  color: black;
  line-height: 2;
}
.bluecolourp{
  color: rgb(54 38 96);
  font-weight: 600;
  font-size: 20px !important;
}
.fh3mbbs {
  color: rgb(0, 0, 0);
  font-weight: 700;
  font-size: 33px;
}



.fontweght700{
  font-weight: 700;
}
.video {
  height: auto;
  width: 100%;
}
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  width: 100%;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.h200 {
  height: 208px;
}
.clouryello {
  color: yellow;
  margin: 0px;
}
.card-body p {
  margin: 0px;
}
.card-body ul {
  padding-left: 20px;
}
.portflio-item .portfolio-item-content {
  position: absolute;
  content: '';
  right: 0px;
  bottom: 0px;
  opacity: 0;
  transition: all 0.35s ease;
}
.portflio-item:hover .portfolio-item-content {
  opacity: 1;
  bottom: 20px;
  right: 30px;
}
.portflio-item .portfolio-item-content {
  position: absolute;
  content: '';
  right: 0px;
  bottom: 0px;
  opacity: 0;
  transition: all 0.35s ease;
}

.portflio-item:before {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  transition: all 0.35s ease;
  overflow: hidden;
}

.portflio-item:hover:before {
  opacity: 1;
}

.portflio-item:hover .portfolio-item-content {
  opacity: 1;
  bottom: 20px;
  right: 30px;
}

.portflio-item .overlay-item {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80px;
  color: #f75757;
  opacity: 0;
  transition: all 0.35s ease;
}

.portflio-item:hover .overlay-item {
  opacity: 1;
}

.featured-item {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}
.featured-item:hover,
.featured-item.style-1 .featured-icon i,
.featured-item.style-2,
.featured-item.style-6 .featured-icon i {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}

.featured-item.style-2:hover,
.price-table:hover {
  -o-box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
  box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
}

/* ------------------------
    Featured Box
------------------------*/
.featured-item {
  padding: 40px 30px;
  position: relative;
  border-radius: 7px;
  overflow: hidden;
  z-index: 9;
}

.owl-carousel .featured-item {
  margin: 20px;
}

.featured-item .featured-icon {
  display: inline-block;
  position: relative;
}

.featured-desc p {
  margin-bottom: 0;
}

.featured-title h5 {
  margin: 30px 0 20px;
  text-transform: capitalize;
  font-weight: bold;
}

.dark-bg .featured-title h5,
.dark-bg .featured-desc {
  color: #ffffff;
}

.theme-bg .featured-title h5,
.theme-bg .featured-desc,
.theme-bg .featured-icon i {
  color: #ffffff;
}

.grey-bg .featured-desc p {
  color: #1c1d3e;
}

.featured-item.style-1 {
  box-shadow: none;
}
.featured-item .featured-icon i {
  color: #2575fc;
  display: inline-block;
  font-size: 50px;
  line-height: 50px;
}
.featured-item .featured-icon span {
  position: absolute;
  top: 50%;
  left: 50%;
}
.featured-item .featured-icon span::after,
.featured-item .featured-icon span::before {
  border-radius: 50%;
  content: '';
  height: 60px;
  position: absolute;
  width: 60px;
  z-index: -1;
}
.featured-item .featured-icon span::after {
  background: #cdf3f6;
  height: 40px;
  left: -10px;
  top: 5px;
  width: 40px;
}
.featured-item .featured-icon span::before {
  background: #d4f8e6;
  bottom: 0;
  right: -10px;
}
.featured-item::before {
  transform: scale(0);
  background: #005bea;
  background: linear-gradient(180deg, #005bea 0%, #2575fc 80%);
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: -1;
}
.featured-item:hover::before {
  transform: scale(1);
}
.featured-item:after {
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  opacity: 0;
  width: 100%;
  z-index: -1;
  background-size: cover;
}
.featured-item:hover:after {
  opacity: 0.2;
}
.featured-item:hover .featured-icon i,
.featured-item:hover h5,
.featured-item:hover p {
  color: #ffffff;
}
.featured-item.style-1:hover .featured-icon i,
.featured-item.style-2:hover .featured-icon i,
.featured-item.style-6:hover .featured-icon i {
  color: #005bea;
}
.featured-item.style-2:hover h5,
.featured-item.style-6:hover h5 {
  color: #1c1d3e;
}
.featured-item.style-2:hover p,
.featured-item.style-6:hover p {
  color: #5f5f5f;
}
.featured-item.style-2 {
  padding: 60px 30px;
  background: #ffffff;
  overflow: inherit;
}
.featured-item.style-2 .featured-icon i {
  font-size: 80px;
  line-height: 80px;
  z-index: 1;
  position: relative;
}
.featured-item.style-2:hover::before {
  display: none;
}
.featured-item.style-3 {
  background: #ffffff;
}
.featured-item.style-4 {
  background: #ffffff;
  border-radius: 70% 100% 30% 70%;
  overflow: hidden;
  padding: 14px 15px 35px 15px;
  z-index: 9;
}
.featured-item.style-4 .featured-title h5 {
  font-size: 1.2rem;
}
.featured-item.style-5 {
  padding-left: 160px;
  background: #ffffff;
}
.featured-item.style-5 .featured-icon {
  position: absolute;
  top: 40px;
  left: 30px;
}
.featured-item.style-5 h5 {
  margin-top: 0;
}
.featured-item.style-6 {
  padding: 0 0 0 100px;
  box-shadow: none;
  overflow: inherit;
}
.featured-item.style-6 h5 {
  margin-top: 0;
}
.featured-item.style-6::before,
.featured-item.style-6::after {
  display: none;
}
.featured-item.style-6 .featured-icon {
  position: absolute;
  top: 0;
  left: 0;
}
.featured-item.style-6 .featured-icon i {
  background: #ffffff;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 30px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}
.featured-item.style-1 .featured-icon i {
  background: #ffffff;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 30px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}
.featured-item.style-6 .featured-icon i::after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: '';
  top: 0;
  left: 0;
  z-index: 10;
  border: 2px dashed transparent;
}
.featured-item.style-6:hover .featured-icon i {
  background: none;
}
.featured-item.style-6:hover .featured-icon i::after {
  animation: rotateme 9s linear infinite;
  border-color: #2575fc;
}

.featured-item {
  transition: all 0.5s ease-in-out 0s;
}
.featured-item:after,
.featured-item::before {
  transition: all 0.5s ease-in-out 0s;
}

.featured-icon,
.featured-item .featured-icon i,
.price-table {
  transition: all 0.5s ease-in-out 0s;
}

/*--translate-XY--*/
.clear-loader,
.title-effect-2 i,
.featured-item .featured-icon span,
.blink-img,
.hexagon-bg.style-2,
.video-btn-pos,
.video-btn .spinner-eff {
  transform: translateX(-50%) translateY(-50%);
}

.featured-item {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}
.featured-item:hover,
.featured-item.style-1 .featured-icon i,
.featured-item.style-2,
.featured-item.style-6 .featured-icon i {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}

.featured-item.style-2:hover,
.price-table:hover {
  -o-box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
  box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
}

.gutter {
  --bs-gutter-y: 1.5rem !important;
}
@media only screen and (max-width: 600px) {

  .h4blue {
    color: rgb(0, 151, 227);
    font-weight: 600;
    font-size: 26px !important;
}

  .h1MBBS .span {
    color: rgb(75 6 6);
    display: block;
    font-size: 27px !important;
}

  .MCC-font-size {
    font-size: 25px !important; /* Adjust the value as needed */
  }


  .fh3mbbs {
    color: rgb(0, 0, 0);
    font-weight: 700;
    font-size: 29px;
  }


  .mbbs-conselling h5{
    font-size: 18px !important;
  }
.h1mbs{
  font-size: 30px !important;
}
  .h1MBBS {
    margin: 0;
    font-size: 32px;
    font-weight: 700;
    color: #000;
    letter-spacing: 1px;
  }


  .right-sec6{
    bottom: 16% !important;
  }
  .h2-heading{
    font-size: 22px !important;
  }
  .table-width{
    width: 100% !important;
  }
  .mbbsimpletable {
    border: 1px solid black;  
    text-align: center !important;
  }
 
  .mbbsimpletable th,
  .mbbsimpletable td
   {
    border: 1px solid black;
    width: 20%; 
    height: 50px;
    font-size: 15px;
    text-align: center !important;
    
  }
  .featured-item.style-4 .featured-title h5 {
    margin: 0px !important;
  }
  .featured-item .card-img-top {
    height: 100px !important;
  }
}

.slide-in {
  animation: slideIn 1s ease-out;
  animation-delay: 2s;
}
.slide-in2 {
  animation: slideIn2 1s ease-out;
  animation-delay: 2s;
}

@keyframes slideIn2 {
  from {
    transform: translateX(+100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.imgfooter {
  height: auto;
  width: 232px;

}

@keyframes blink-decrease {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.blinking-mount-text-decrease {
  animation: blink-decrease 1s infinite;
}


.padding-footer{
  padding-left: 193px !important;
}


.bdsscope .scope  .bdsscop0 {
  font-size: 23px !important;
  font-weight: 700;
  color: #003d7a;
}
.font-bds li{
  font-size: 16px !important;
}

.bdssyaalbus-padding{
  padding: 2px 7px !important;
}

.fh3BDS {
  color: #003d7a;
  font-weight: 700;
  font-size: 35px;
}

.bdsscope .scope {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);

}


.bdscollegeimage img {
  height: 173px;
  width: 251px;
}

.border-none {
  border: none !important;
}

.bdsscope .scope p {
  color: #003d7a;

}

.bdsscope .scope img {

  height: 170px;
  width: 170px;

}

.btnbdscolour {
  background: #07385a !important;
}

.btnbds {
  background: #07385a !important;
  font-size: 21px !important;
}

.btnbds:hover,
.btnbdscolour:hover,
.btnbdscolour:focus,
button:hover,
.btnbds:focus {
  background: #00a040 !important;
  color: #FFFFFF;
}

.bdslandgingcolleges h5 {
  min-height: 100px !important;
  
}


.customborder1 table tr:nth-child(odd) {
  /* border: 2px solid black; */
  text-align: center;
  overflow: auto;
  background: #d6fff8;


}

.customborder1 table tr:nth-child(even) {

  color: #007480;


}

.customborder1 th {
  border-right: 4px solid white;


}

.customborder1 {
  border: 2px solid #f5e4e4;

  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.customborder1 .documents li {
  font-size: 18px;
  font-weight: 600;

}

.customborder1 table {
  max-width: 100%;
  overflow-y: scroll;
}

.customborder1 tr th {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

/* .customborder1 td{
  border: 2px solid black;
  text-align: center; 
  overflow: auto;
  font-weight: 600;
} */
.customborder2 ul {
  min-height: 313px;
  line-height: 2;
}
.customborder3 ul {
  min-height: 313px !important;
  line-height: 2;
}

.customborder2 {
  overflow: auto;
}

.customborder2 table {
  font-size: 18px;
}

.customborder2 th {
  border: 2px solid black;
  text-align: center;
  overflow: auto;
}

.customborder2 td {
  border: 2px solid black;
  text-align: center;
  overflow: auto;
  /* font-weight: 600; */
}

.top80 {
  top: 70% !important;
}

.top90 {
  top: 80% !important;
}

.top60 {
  top: 55% !important;
}

.top70 {
  top: 65% !important;
}

.bgfooter {
  background: #34345a;
}

.bgfooter a {
  background: #34345a;
}

.bdsh2why {
  text-align: center;
  font-weight: 800;
  font-size: 40px;
  color: rgb(255, 255, 255);
  /* text-shadow: #FC0 1px 0 10px; */
}

.img200 {
  height: auto;
  width: 130px !important;
}

.coltext {
  text-align: center !important;
  font-weight: 600;
  color: black;
}

.landgingcolleges img {
  float: none !important;
  height: 155px !important;
  object-fit: cover;
}

.landgingcolleges h5 {
  min-height: 72px;
}

.imgcontain {
  object-fit: contain !important;
}

.bgblack {
  background: rgba(0, 0, 0, 0.27);
  border-radius: 11px;
}

.ll {
  letter-spacing: -1px;
}

p {
  font-size: 18px !important;
}

.Pfont23 {
  font-size: 23px !important;
  color: #3d3939 !important;
}

.bgcc {
  background-color: #1d415a !important;
}

.dd {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}

.customborder2 .card-header {
  background: darkcyan;
}

.customborder2 h4 {
  color: white;
  margin: 0px !important;
  font-size: 27px !important;
}


.imgh {
  min-height: 400px !important;
}

.bdsimgh {
  min-height: 300px !important;
}

.bgimage {
  min-height: 659px;
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.hov:hover {
  background-color: white !important;
}

.h1BDS {
  margin: 0;
  font-size: 40px;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: 1px;
}

.h1BDS .span {
  color: #003d7a;
  display: block;
  font-size: 32px;
}

.d-block {
  display: block;
}

.bdscyellow {
  color: #36c709;
  font-size: 27px;
}

.p {
  color: #ffffff;
  font-size: 22px;
  margin: 31px 0px;
  font-weight: 600;
}

.form-box {
  max-width: 425px;
  margin: auto;
  padding: 25px;
  background: #00000045;
  border-radius: 11px;
}

.font-weight-bold {
  font-weight: 500;
}

.h4blue {
  color: rgb(0, 151, 227);
  font-weight: 600;
  font-size: 27px;
}

.textmbbs li {
  font-size: 18px;
  color: black;
  line-height: 2;
}

.textmbbs {
  font-size: 18px;
  color: black;
  line-height: 2;
}

.bluecolourp {
  color: rgb(54 38 96);
  font-weight: 600;
  font-size: 20px !important;
}



.fontweght700 {
  font-weight: 700;
}

.video {
  height: auto;
  width: 100%;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  width: 100%;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.h200 {
  height: 208px;
}

.clouryello {
  color: yellow;
  margin: 0px;
}

.card-body p {
  margin: 0px;
}

.card-body ul {
  padding-left: 20px;
}

.portflio-item .portfolio-item-content {
  position: absolute;
  content: '';
  right: 0px;
  bottom: 0px;
  opacity: 0;
  transition: all 0.35s ease;
}

.portflio-item:hover .portfolio-item-content {
  opacity: 1;
  bottom: 20px;
  right: 30px;
}

.portflio-item .portfolio-item-content {
  position: absolute;
  content: '';
  right: 0px;
  bottom: 0px;
  opacity: 0;
  transition: all 0.35s ease;
}

.portflio-item:before {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  transition: all 0.35s ease;
  overflow: hidden;
}

.portflio-item:hover:before {
  opacity: 1;
}

.portflio-item:hover .portfolio-item-content {
  opacity: 1;
  bottom: 20px;
  right: 30px;
}

.portflio-item .overlay-item {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80px;
  color: #f75757;
  opacity: 0;
  transition: all 0.35s ease;
}

.portflio-item:hover .overlay-item {
  opacity: 1;
}

.featured-item {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}

.featured-item:hover,
.featured-item.style-1 .featured-icon i,
.featured-item.style-2,
.featured-item.style-6 .featured-icon i {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}

.featured-item.style-2:hover,
.price-table:hover {
  -o-box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
  box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
}

/* ------------------------
    Featured Box
------------------------*/
.featured-item {
  padding: 40px 30px;
  position: relative;
  border-radius: 7px;
  overflow: hidden;
  z-index: 9;
}

.owl-carousel .featured-item {
  margin: 20px;
}

.featured-item .featured-icon {
  display: inline-block;
  position: relative;
}

.featured-desc p {
  margin-bottom: 0;
}

.featured-title h5 {
  margin: 30px 0 20px;
  text-transform: capitalize;
  font-weight: bold;
}

.dark-bg .featured-title h5,
.dark-bg .featured-desc {
  color: #ffffff;
}

.theme-bg .featured-title h5,
.theme-bg .featured-desc,
.theme-bg .featured-icon i {
  color: #ffffff;
}

.grey-bg .featured-desc p {
  color: #1c1d3e;
}

.featured-item.style-1 {
  box-shadow: none;
}

.featured-item .featured-icon i {
  color: #2575fc;
  display: inline-block;
  font-size: 50px;
  line-height: 50px;
}

.featured-item .featured-icon span {
  position: absolute;
  top: 50%;
  left: 50%;
}

.featured-item .featured-icon span::after,
.featured-item .featured-icon span::before {
  border-radius: 50%;
  content: '';
  height: 60px;
  position: absolute;
  width: 60px;
  z-index: -1;
}

.featured-item .featured-icon span::after {
  background: #cdf3f6;
  height: 40px;
  left: -10px;
  top: 5px;
  width: 40px;
}

.featured-item .featured-icon span::before {
  background: #d4f8e6;
  bottom: 0;
  right: -10px;
}

.featured-item::before {
  transform: scale(0);
  background: #005bea;
  background: linear-gradient(180deg, #005bea 0%, #2575fc 80%);
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: -1;
}

.featured-item:hover::before {
  transform: scale(1);
}

.featured-item:after {
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  opacity: 0;
  width: 100%;
  z-index: -1;
  background-size: cover;
}

.featured-item:hover:after {
  opacity: 0.2;
}

.featured-item:hover .featured-icon i,
.featured-item:hover h5,
.featured-item:hover p {
  color: #ffffff;
}

.featured-item.style-1:hover .featured-icon i,
.featured-item.style-2:hover .featured-icon i,
.featured-item.style-6:hover .featured-icon i {
  color: #005bea;
}

.featured-item.style-2:hover h5,
.featured-item.style-6:hover h5 {
  color: #1c1d3e;
}

.featured-item.style-2:hover p,
.featured-item.style-6:hover p {
  color: #5f5f5f;
}

.featured-item.style-2 {
  padding: 60px 30px;
  background: #ffffff;
  overflow: inherit;
}

.featured-item.style-2 .featured-icon i {
  font-size: 80px;
  line-height: 80px;
  z-index: 1;
  position: relative;
}

.featured-item.style-2:hover::before {
  display: none;
}

.featured-item.style-3 {
  background: #ffffff;
}

.featured-item.style-4 {
  background: #ffffff;
  border-radius: 70% 100% 30% 70%;
  overflow: hidden;
  padding: 14px 15px 35px 15px;
  z-index: 9;
}

.featured-item.style-4 .featured-title h5 {
  font-size: 1.2rem;
}

.featured-item.style-5 {
  padding-left: 160px;
  background: #ffffff;
}

.featured-item.style-5 .featured-icon {
  position: absolute;
  top: 40px;
  left: 30px;
}

.featured-item.style-5 h5 {
  margin-top: 0;
}

.featured-item.style-6 {
  padding: 0 0 0 100px;
  box-shadow: none;
  overflow: inherit;
}

.featured-item.style-6 h5 {
  margin-top: 0;
}

.featured-item.style-6::before,
.featured-item.style-6::after {
  display: none;
}

.featured-item.style-6 .featured-icon {
  position: absolute;
  top: 0;
  left: 0;
}

.featured-item.style-6 .featured-icon i {
  background: #ffffff;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 30px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.featured-item.style-1 .featured-icon i {
  background: #ffffff;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 30px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.featured-item.style-6 .featured-icon i::after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: '';
  top: 0;
  left: 0;
  z-index: 10;
  border: 2px dashed transparent;
}

.featured-item.style-6:hover .featured-icon i {
  background: none;
}

.featured-item.style-6:hover .featured-icon i::after {
  animation: rotateme 9s linear infinite;
  border-color: #2575fc;
}

.featured-item {
  transition: all 0.5s ease-in-out 0s;
}

.featured-item:after,
.featured-item::before {
  transition: all 0.5s ease-in-out 0s;
}

.featured-icon,
.featured-item .featured-icon i,
.price-table {
  transition: all 0.5s ease-in-out 0s;
}

/*--translate-XY--*/
.clear-loader,
.title-effect-2 i,
.featured-item .featured-icon span,
.blink-img,
.hexagon-bg.style-2,
.video-btn-pos,
.video-btn .spinner-eff {
  transform: translateX(-50%) translateY(-50%);
}

.featured-item {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}

.featured-item:hover,
.featured-item.style-1 .featured-icon i,
.featured-item.style-2,
.featured-item.style-6 .featured-icon i {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}

.featured-item.style-2:hover,
.price-table:hover {
  -o-box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
  box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
}

.gutter {
  --bs-gutter-y: 1.5rem !important;
}

.fontweigth400 {
  font-weight: 400;
}

.mt63 {
  margin-top: 0px;
}

@media only screen and (max-width: 600px) {

  .font-bds li{
    font-size: 14px !important;
  }

  .bdsscope .scope  .bdsscop0 {
    font-size: 19px !important;
    font-weight: 700;
    color: #003d7a;
  }

  .fh3BDS {
    color: #003d7a;
    font-weight: 700;
    font-size: 32px !important;
  }

  .h1BDS .span {
    color: #003d7a;
    display: block;
    font-size: 27px;
  }

  .bdsimg4 {
    height: auto;
    width: 180px;
  }

  .featured-item.style-4 .featured-title h5 {
    margin: 0px !important;
  }

  .mt63 {
    margin-top: 63px;
  }

  .featured-item .card-img-top {
    height: 100px !important;
  }

  .bdsscope .d-flex img {
    height: auto;
    width: 149px;

  }

  .btnbds {
    font-size: 17px !important;


  }

  .bdscollegeimage img {
    height: 156px;
    width: 353px;
  }

  .bdslandgingcolleges h5 {
    min-height: auto !important;
  }

  .customborder1 {
    border: 1px solid black;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }

  .customborder2 ul {
    min-height: auto;
    margin-bottom: 10px;
    line-height: 2;

  }


}








.slide-in {
  animation: slideIn 1s ease-out;
  animation-delay: 2s;
}

.slide-in2 {
  animation: slideIn2 1s ease-out;
  animation-delay: 2s;
}

@keyframes slideIn2 {
  from {
    transform: translateX(+100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

.imgfooter {
  height: auto;
  width: 232px;

}
.swamiscope .d-flex p {
  font-size: 23px !important;
  font-weight: 700;
}

@media screen and (max-width: 767px) {
   .divpadding .d-md-none {
      display: block !important;
      padding: 0px 0px !important;
  }
}

@media only screen and (max-width: 600px) {


  
  .store-wrapper-bnys a {
      padding-left: 2px !important;
  }
}

.h1bnys{
    margin: 0;
    font-size: 40px !important;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 1px;

}

.btnbyvs {
  
  background: #f72206 !important;
  color: #ffffff;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 37px;
  border: 0px;
  padding: 0px 6px;
}

.bnys-font{
  font-size: 35px !important;
}


.imgfooter-bnys{
  width: 300px !important;
  height: auto;
}

.left138{
  left: -143px !important;
}
.left290{
  left: -290px !important;

}
.left138:hover{
  left: 0px !important;
}
.left290:hover{
  left: 0px !important;
}

.color-bnys {
  font-size: 26px !important;
  color: #f1ff1f !important;
  font-weight: bold;

}

.bdscollegeimage img {
  height: 173px;
  width: 251px;
}

.border-none {
  border: none !important;
}
.swamiscope .scope p {
  /* font-size: 23px !important; */
  /* font-weight: 700; */
  color: #003d7a;
}
.swamiscope .scope {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.swamiscope .scope span {
  font-size: 16px;
  font-weight: 100;
  color: black;
  display: block;
}
.swamiscope .scope img {
  height: 125px;
  width: 125px;
}
.btnbdscolour {
  background: #07385a !important;
}
.btnbds {
  background: #07385a !important;
  font-size: 21px !important;
}
.btnbds:hover,
.btnbdscolour:hover,
.btnbdscolour:focus,
button:hover,
.btnbds:focus {
  background: #00a040 !important;
  color: #ffffff;
}
.bdslandgingcolleges h5 {
  min-height: 100px !important;
}

.swamicustomborder1 table tr:nth-child(odd) {
  /* border: 2px solid black; */
  text-align: center;
  overflow: auto;
  background: #cce1ed;
}
.swamicustomborder1 table tr:nth-child(even) {
  color: #343e70;
}
.swamicustomborder1 th {
  border-right: 4px solid white;
}

.swamicustomborder1 {
  border: 2px solid #f5e4e4;

  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
.swamicustomborder1 .documents li {
  font-size: 18px;
  font-weight: 600;
}
.swamicustomborder1 table {
  max-width: 100%;
  overflow-y: scroll;
}
.swamicustomborder1 tr th {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.swamicustomborder1 tr td {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
/* .swamicustomborder1 td{
  border: 2px solid black;
  text-align: center; 
  overflow: auto;
  font-weight: 600;
} */
.customborder2 ul {
  min-height: 367px;
  line-height: 2;
}
.customborder2 {
  overflow: auto;
}
.customborder2 table {
  font-size: 18px;
}
.customborder2 th {
  border: 2px solid black;
  text-align: center;
  overflow: auto;
}
.customborder2 td {
  border: 2px solid black;
  text-align: center;
  overflow: auto;
  /* font-weight: 600; */
}
.top80 {
  top: 70% !important;
}
.top90 {
  top: 80% !important;
}
.top60 {
  top: 55% !important;
}
.top70 {
  top: 65% !important;
}
.bgfooter {
  background: #34345a;
}
.bgfooter a {
  background: #34345a;
}
.sayushh2why {
  text-align: center;
  font-weight: 800;
  font-size: 35px;
  color: #2d3663;
  /* text-shadow: #FC0 1px 0 10px; */
}
.sayushh2why .fontw400 {
  font-weight: 400;
}

.customboxes{
   background: white !important;
   padding: 16px 0px  !important;
} 
.customboxes .customboxes div{

  padding: 12px 14px !important;
  background: #2d90c8 !important;
}
.backgroundcard {
  background-color: rgb(0 58 142);
  color: white !important;
}
.backgroundcard1 {
  background-color: #cce1ed;
  height: auto !important;
}

.img200 {
  height: auto;
  width: 130px !important;
}
.coltext {
  text-align: center !important;
  font-weight: 600;
  color: black;
}

.landgingcolleges img {
  float: none !important;
  height: 155px !important;
  object-fit: cover;
}
.landgingcolleges h5 {
  min-height: 72px;
}

.imgcontain {
  object-fit: contain !important;
}

.bgblacksyn {
  background: rgb(0 0 0 / 48%);
  border-radius: 11px;
}
.bgblacksyn .firstp {
  font-size: 17px !important;
  font-weight: 700;
  color: #f6ff3f;
  line-height: 1.5;
}
.divfont {
  font-size: 27px;
  font-weight: 600;
}

.ll {
  letter-spacing: -1px;
}
p {
  font-size: 18px !important;
}
.Pfont23 {
  font-size: 23px !important;
  color: #3d3939 !important;
}

.bgcc {
  background-color: #1d415a !important;
}

.dd {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}

.ddsyns {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: rgb(8, 8, 8);
}
.customborder2 .card-header {
  background: darkcyan;
}
.customborder2 h4 {
  color: white;
  margin: 0px !important;
  font-size: 27px !important;
}

.imgh {
  min-height: 400px !important;
}
.bdsimgh {
  min-height: 300px !important;
}
.bgimage {
  min-height: 659px;
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.synsbgimage {
  min-height: 659px;
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: top !important;
}


.hov:hover {
  background-color: white !important;
}
.h1BDS {
  margin: 0;
  font-size: 40px;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: 1px;
}
.h1BDS .span {
  color: #003d7a;
  display: block;
  font-size: 32px;
}
.d-block {
  display: block;
}
.swamibule {
  color: #006ba6;
  font-size: 18px;
}
.p {
  color: #ffffff;
  font-size: 22px;
  margin: 31px 0px;
  font-weight: 600;
}
.form-box {
  max-width: 425px;
  margin: auto;
  padding: 25px;
  background: #00000045;
  border-radius: 11px;
}
.font-weight-bold {
  font-weight: 500;
}
.h4blue {
  color: rgb(0, 151, 227);
  font-weight: 600;
  font-size: 27px;
}
.textmbbs li {
  font-size: 18px;
  color: black;
  line-height: 2;
}
.textmbbs {
  font-size: 18px;
  color: black;
  line-height: 2;
}
.textsyns {
  font-size: 16px;
  color: black;
}
.bodrdertext {
  border: 3px solid grey;
}
.bluecolourp {
  color: rgb(54 38 96);
  font-weight: 600;
  font-size: 20px !important;
}
.fh3BDS {
  color: #003d7a;
  font-weight: 700;
  font-size: 35px;
}
.fontweght700 {
  font-weight: 700;
}
.video {
  height: auto;
  width: 100%;
}
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  width: 100%;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.h200 {
  height: 208px;
}
.clouryello {
  color: yellow;
  margin: 0px;
}
.card-body p {
  margin: 0px;
}
.card-body ul {
  padding-left: 20px;
}
.portflio-item .portfolio-item-content {
  position: absolute;
  content: '';
  right: 0px;
  bottom: 0px;
  opacity: 0;
  transition: all 0.35s ease;
}
.portflio-item:hover .portfolio-item-content {
  opacity: 1;
  bottom: 20px;
  right: 30px;
}
.portflio-item .portfolio-item-content {
  position: absolute;
  content: '';
  right: 0px;
  bottom: 0px;
  opacity: 0;
  transition: all 0.35s ease;
}

.portflio-item:before {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  transition: all 0.35s ease;
  overflow: hidden;
}

.portflio-item:hover:before {
  opacity: 1;
}

.portflio-item:hover .portfolio-item-content {
  opacity: 1;
  bottom: 20px;
  right: 30px;
}

.portflio-item .overlay-item {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80px;
  color: #f75757;
  opacity: 0;
  transition: all 0.35s ease;
}

.portflio-item:hover .overlay-item {
  opacity: 1;
}

.featured-item {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}
.featured-item:hover,
.featured-item.style-1 .featured-icon i,
.featured-item.style-2,
.featured-item.style-6 .featured-icon i {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}

.featured-item.style-2:hover,
.price-table:hover {
  -o-box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
  box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
}

/* ------------------------
    Featured Box
------------------------*/
.featured-item {
  padding: 40px 30px;
  position: relative;
  border-radius: 7px;
  overflow: hidden;
  z-index: 9;
}

.owl-carousel .featured-item {
  margin: 20px;
}

.featured-item .featured-icon {
  display: inline-block;
  position: relative;
}

.featured-desc p {
  margin-bottom: 0;
}

.featured-title h5 {
  margin: 30px 0 20px;
  text-transform: capitalize;
  font-weight: bold;
}

.dark-bg .featured-title h5,
.dark-bg .featured-desc {
  color: #ffffff;
}

.theme-bg .featured-title h5,
.theme-bg .featured-desc,
.theme-bg .featured-icon i {
  color: #ffffff;
}

.grey-bg .featured-desc p {
  color: #1c1d3e;
}

.featured-item.style-1 {
  box-shadow: none;
}
.featured-item .featured-icon i {
  color: #2575fc;
  display: inline-block;
  font-size: 50px;
  line-height: 50px;
}
.featured-item .featured-icon span {
  position: absolute;
  top: 50%;
  left: 50%;
}
.featured-item .featured-icon span::after,
.featured-item .featured-icon span::before {
  border-radius: 50%;
  content: '';
  height: 60px;
  position: absolute;
  width: 60px;
  z-index: -1;
}
.featured-item .featured-icon span::after {
  background: #cdf3f6;
  height: 40px;
  left: -10px;
  top: 5px;
  width: 40px;
}
.featured-item .featured-icon span::before {
  background: #d4f8e6;
  bottom: 0;
  right: -10px;
}
.featured-item::before {
  transform: scale(0);
  background: #005bea;
  background: linear-gradient(180deg, #005bea 0%, #2575fc 80%);
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: -1;
}
.featured-item:hover::before {
  transform: scale(1);
}
.featured-item:after {
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  opacity: 0;
  width: 100%;
  z-index: -1;
  background-size: cover;
}
.featured-item:hover:after {
  opacity: 0.2;
}
.featured-item:hover .featured-icon i,
.featured-item:hover h5,
.featured-item:hover p {
  color: #ffffff;
}
.featured-item.style-1:hover .featured-icon i,
.featured-item.style-2:hover .featured-icon i,
.featured-item.style-6:hover .featured-icon i {
  color: #005bea;
}
.featured-item.style-2:hover h5,
.featured-item.style-6:hover h5 {
  color: #1c1d3e;
}
.featured-item.style-2:hover p,
.featured-item.style-6:hover p {
  color: #5f5f5f;
}
.featured-item.style-2 {
  padding: 60px 30px;
  background: #ffffff;
  overflow: inherit;
}
.featured-item.style-2 .featured-icon i {
  font-size: 80px;
  line-height: 80px;
  z-index: 1;
  position: relative;
}
.featured-item.style-2:hover::before {
  display: none;
}
.featured-item.style-3 {
  background: #ffffff;
}
.featured-item.style-4 {
  background: #ffffff;
  border-radius: 70% 100% 30% 70%;
  overflow: hidden;
  padding: 14px 15px 35px 15px;
  z-index: 9;
}
.featured-item.style-4 .featured-title h5 {
  font-size: 1.2rem;
}
.featured-item.style-5 {
  padding-left: 160px;
  background: #ffffff;
}
.featured-item.style-5 .featured-icon {
  position: absolute;
  top: 40px;
  left: 30px;
}
.featured-item.style-5 h5 {
  margin-top: 0;
}
.featured-item.style-6 {
  padding: 0 0 0 100px;
  box-shadow: none;
  overflow: inherit;
}
.featured-item.style-6 h5 {
  margin-top: 0;
}
.featured-item.style-6::before,
.featured-item.style-6::after {
  display: none;
}
.featured-item.style-6 .featured-icon {
  position: absolute;
  top: 0;
  left: 0;
}
.featured-item.style-6 .featured-icon i {
  background: #ffffff;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 30px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}
.featured-item.style-1 .featured-icon i {
  background: #ffffff;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 30px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}
.featured-item.style-6 .featured-icon i::after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: '';
  top: 0;
  left: 0;
  z-index: 10;
  border: 2px dashed transparent;
}
.featured-item.style-6:hover .featured-icon i {
  background: none;
}
.featured-item.style-6:hover .featured-icon i::after {
  animation: rotateme 9s linear infinite;
  border-color: #2575fc;
}

.featured-item {
  transition: all 0.5s ease-in-out 0s;
}
.featured-item:after,
.featured-item::before {
  transition: all 0.5s ease-in-out 0s;
}

.featured-icon,
.featured-item .featured-icon i,
.price-table {
  transition: all 0.5s ease-in-out 0s;
}

/*--translate-XY--*/
.clear-loader,
.title-effect-2 i,
.featured-item .featured-icon span,
.blink-img,
.hexagon-bg.style-2,
.video-btn-pos,
.video-btn .spinner-eff {
  transform: translateX(-50%) translateY(-50%);
}

.featured-item {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}
.featured-item:hover,
.featured-item.style-1 .featured-icon i,
.featured-item.style-2,
.featured-item.style-6 .featured-icon i {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}

.featured-item.style-2:hover,
.price-table:hover {
  -o-box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
  box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
}

.gutter {
  --bs-gutter-y: 1.5rem !important;
}

.fontweigth400 {
  font-weight: 400;
}
.mt63 {
  margin-top: 0px;
}
.swamiimg4 {
  height: auto;
  width: 120px;
}
.h3syns {
  font-size: 27px;
  font-weight: 600;
  color: #fefefe;
}
.leftsec {
  left: -286px !important;
}
.leftsec:hover {
  left: 0px !important;
}
.bggreay {
  background: #bdbcc4;
}
.synsfaq-img{
width: 44% !important;
}
.synsfaq-sec{

  width: 56% !important;
}


@media only screen and (max-width: 400px) {
  .backgroundcard1 {
    background-color: #cce1ed;
    min-height: 137px;
}
}


.custom-card{

  line-height: 2;
}
@media only screen and (max-width: 600px) {

  .h1bnys{
    margin: 0;
    font-size: 28px !important;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 1px;

}

  .bnys-font{
    font-size: 29px !important;
  }


  .color-bnys {
    font-size: 23px !important;
    color: #f1ff1f !important;
    font-weight: bold;
  
  }


  .synsfaq-img{
    width: 100% !important;
    }
    .synsfaq-sec{

      width: 100% !important;
    }
    

  .btnsyns {
    width: 141px !important;
    background: #f72206 !important;
    color: #ffffff;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 13px !important;
    line-height: 33px !important;
    border: 0px !important;
    padding: 2px 1px !important;
}
  



  .h1BDS .span {
    color: #003d7a;
    display: block;
    font-size: 27px;
  }
  .h3syns {
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
  }
  .divfont {
    font-size: 18px !important;
    font-weight: 600;
  }

  .swamiimg4 {
    height: auto;
    width: 130px;
  }

  .swamibule p{
    font-size: 16PX !important;
  }

  .featured-item.style-4 .featured-title h5 {
    margin: 0px !important;
  }
  .mt63 {
    margin-top: 63px;
  }
  .featured-item .card-img-top {
    height: 100px !important;
  }
  .swamiscope .d-flex img {
    height: auto;
    width: 130px;
  }
  .btnbds {
    font-size: 17px !important;
  }
  .bdscollegeimage img {
    height: 156px;
    width: 353px;
  }

  .bdslandgingcolleges h5 {
    min-height: auto !important;
  }
  .swamicustomborder1 {
    border: 1px solid black;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
  .customborder2 ul {
    min-height: auto;
    margin-bottom: 10px;
    line-height: 2;
  }
}

.slide-in {
  animation: slideIn 1s ease-out;
  animation-delay: 2s;
}
.slide-in2 {
  animation: slideIn2 1s ease-out;
  animation-delay: 2s;
}

@keyframes slideIn2 {
  from {
    transform: translateX(+100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.imgfooter {
  height: auto;
  width: 232px;
}

.btnsyns {
  width: 133px;
  background: #f72206 !important;
  color: #ffffff;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 37px;
  border: 0px;
  padding: 0px 0px;
}


.ambscope .d-flex p {
  font-size: 20px !important;
  font-weight: 600;
}

.bdscollegeimage img {
  height: 173px;
  width: 251px;
}

.smallfot-amb{
  font-weight: 600 !important;
  font-size: 25px !important;
}

.border-none {
  border: none !important;
}
.ambscope .scope p {
  /* font-size: 23px !important; */
  /* font-weight: 700; */
  color: #2277af;
}
.ambscope .scope span {
  font-size: 16px;
  font-weight: 100;
  color: black;
  display: block;
}
.ambscope .scope img {
  height: 100px;
  width: 100px;
}
.btnbdscolour {
  background: #07385a !important;
}
.btnbds {
  background: #07385a !important;
  font-size: 21px !important;
}
.btnbds:hover,
.btnbdscolour:hover,
.btnbdscolour:focus,
button:hover,
.btnbds:focus {
  background: #00a040 !important;
  color: #ffffff;
}
.bdslandgingcolleges h5 {
  min-height: 100px !important;
}

.ambscopecustomborder table tr:nth-child(odd) {
  /* border: 2px solid black; */
  text-align: center;
  overflow: auto;
  background: #a5cae3;
}
.ambscopecustomborder table tr:nth-child(even) {
  color: #2277af;
}
.ambscopecustomborder th {
  border-right: 4px solid white;
}

.ambscopecustomborder {
  border: 2px solid #f5e4e4;

  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
.ambscopecustomborder .documents li {
  font-size: 18px;
  font-weight: 600;
}
.ambscopecustomborder table {
  max-width: 100%;
  overflow-y: scroll;
}
.ambscopecustomborder tr th {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.ambscopecustomborder tr td {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
/* .ambscopecustomborder td{
  border: 2px solid black;
  text-align: center; 
  overflow: auto;
  font-weight: 600;
} */
.customborder2 ul {
  min-height: 367px;
  line-height: 2;
}
.customborder2 {
  overflow: auto;
}
.customborder2 table {
  font-size: 18px;
}
.customborder2 th {
  border: 2px solid black;
  text-align: center;
  overflow: auto;
}
.customborder2 td {
  border: 2px solid black;
  text-align: center;
  overflow: auto;
  /* font-weight: 600; */
}
.top80 {
  top: 70% !important;
}
.top90 {
  top: 80% !important;
}
.top60 {
  top: 55% !important;
}
.top70 {
  top: 65% !important;
}
.bgfooter {
  background: #34345a;
}
.bgfooter a {
  background: #34345a;
}
.Ambh2 {
  text-align: center;
  font-weight: 800;
  font-size: 35px;
  color: #148580;
  /* text-shadow: #FC0 1px 0 10px; */
}
.Ambh2 .fontw400 {
  font-weight: 400;
}

.customboxes {
  background: white !important;
  padding: 16px 0px !important;
}
.customboxes .customboxes div {
  padding: 12px 14px !important;
  background: #2d90c8 !important;
}
.backgroundcard {
  background-color: rgb(0 58 142);
  color: white !important;
}
.backgroundcard1 {
  background-color: #cce1ed;
  height: auto !important;
}

.img200 {
  height: auto;
  width: 130px !important;
}
.coltext {
  text-align: center !important;
  font-weight: 600;
  color: black;
}

.landgingcolleges img {
  float: none !important;
  height: 155px !important;
  object-fit: cover;
}
.landgingcolleges h5 {
  min-height: 72px;
}

.imgcontain {
  object-fit: contain !important;
}

.bgblackamb {
  background: rgb(109 91 91 / 53%);
  border-radius: 11px;
}
.firstpamb {
  font-size: 27px !important;
  font-weight: 600;
  color: #fcff00;
}
.divfont {
  font-size: 27px;
  font-weight: 600;
}

.ll {
  letter-spacing: -1px;
}
p {
  font-size: 18px !important;
}
.Pfont23 {
  font-size: 23px !important;
  color: #3d3939 !important;
}

.bgcc {
  background-color: #1d415a !important;
}

.dd {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}

.ddAmb {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: rgb(8, 8, 8);
}
.customborder2 .card-header {
  background: darkcyan;
}
.customborder2 h4 {
  color: white;
  margin: 0px !important;
  font-size: 27px !important;
}

.imgh {
  min-height: 400px !important;
}
.bdsimgh {
  min-height: 300px !important;
}
.bgimage {
  min-height: 659px;
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.missionimage {
  min-height: 659px;
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}


.Ambedkarimage {
  min-height: 659px;
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: top !important;
}

.hov:hover {
  background-color: white !important;
}
.h1amb {
  margin: 0;
  font-size: 35px;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: 1px;
}
.h1amb .span {
  color: #003d7a;
  display: block;
  font-size: 32px;
}
.d-block {
  display: block;
}
.Ambblue {
  color: #2277af;
  font-size: 18px;
}
.p {
  color: #ffffff;
  font-size: 22px;
  margin: 31px 0px;
  font-weight: 600;
}
.form-boxamb {
  max-width: 425px;
  margin: auto;
  padding: 25px;
  background: #00000069;
  border-radius: 11px;
}
.font-weight-bold {
  font-weight: 500;
}
.h4blue {
  color: rgb(0, 151, 227);
  font-weight: 600;
  font-size: 27px;
}
.textmbbs li {
  font-size: 18px;
  color: black;
  line-height: 2;
}
.textmbbs {
  font-size: 18px;
  color: black;
  line-height: 2;
}
.textAmb {
  font-size: 16px;
  color: black;
}
.bodrdertext {
  border: 3px solid grey;
}
.bluecolourp {
  color: rgb(54 38 96);
  font-weight: 600;
  font-size: 20px !important;
}
.f3mdu{
  color: #148580;
  font-weight: 700;
  font-size: 35px;
}
.fontweght700 {
  font-weight: 700;
}
.video {
  height: auto;
  width: 100%;
}
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  width: 100%;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.h200 {
  height: 208px;
}
.clouryello {
  color: yellow;
  margin: 0px;
}
.card-body p {
  margin: 0px;
}
.card-body ul {
  padding-left: 20px;
}
.portflio-item .portfolio-item-content {
  position: absolute;
  content: '';
  right: 0px;
  bottom: 0px;
  opacity: 0;
  transition: all 0.35s ease;
}
.portflio-item:hover .portfolio-item-content {
  opacity: 1;
  bottom: 20px;
  right: 30px;
}
.portflio-item .portfolio-item-content {
  position: absolute;
  content: '';
  right: 0px;
  bottom: 0px;
  opacity: 0;
  transition: all 0.35s ease;
}

.portflio-item:before {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  transition: all 0.35s ease;
  overflow: hidden;
}

.portflio-item:hover:before {
  opacity: 1;
}

.portflio-item:hover .portfolio-item-content {
  opacity: 1;
  bottom: 20px;
  right: 30px;
}

.portflio-item .overlay-item {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80px;
  color: #f75757;
  opacity: 0;
  transition: all 0.35s ease;
}

.portflio-item:hover .overlay-item {
  opacity: 1;
}

.featured-item {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}
.featured-item:hover,
.featured-item.style-1 .featured-icon i,
.featured-item.style-2,
.featured-item.style-6 .featured-icon i {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}

.featured-item.style-2:hover,
.price-table:hover {
  -o-box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
  box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
}

/* ------------------------
    Featured Box
------------------------*/
.featured-item {
  padding: 40px 30px;
  position: relative;
  border-radius: 7px;
  overflow: hidden;
  z-index: 9;
}

.owl-carousel .featured-item {
  margin: 20px;
}

.featured-item .featured-icon {
  display: inline-block;
  position: relative;
}

.featured-desc p {
  margin-bottom: 0;
}

.featured-title h5 {
  margin: 30px 0 20px;
  text-transform: capitalize;
  font-weight: bold;
}

.dark-bg .featured-title h5,
.dark-bg .featured-desc {
  color: #ffffff;
}

.theme-bg .featured-title h5,
.theme-bg .featured-desc,
.theme-bg .featured-icon i {
  color: #ffffff;
}

.grey-bg .featured-desc p {
  color: #1c1d3e;
}

.featured-item.style-1 {
  box-shadow: none;
}
.featured-item .featured-icon i {
  color: #2575fc;
  display: inline-block;
  font-size: 50px;
  line-height: 50px;
}
.featured-item .featured-icon span {
  position: absolute;
  top: 50%;
  left: 50%;
}
.featured-item .featured-icon span::after,
.featured-item .featured-icon span::before {
  border-radius: 50%;
  content: '';
  height: 60px;
  position: absolute;
  width: 60px;
  z-index: -1;
}
.featured-item .featured-icon span::after {
  background: #cdf3f6;
  height: 40px;
  left: -10px;
  top: 5px;
  width: 40px;
}
.featured-item .featured-icon span::before {
  background: #d4f8e6;
  bottom: 0;
  right: -10px;
}
.featured-item::before {
  transform: scale(0);
  background: #005bea;
  background: linear-gradient(180deg, #005bea 0%, #2575fc 80%);
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: -1;
}
.featured-item:hover::before {
  transform: scale(1);
}
.featured-item:after {
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  opacity: 0;
  width: 100%;
  z-index: -1;
  background-size: cover;
}
.featured-item:hover:after {
  opacity: 0.2;
}
.featured-item:hover .featured-icon i,
.featured-item:hover h5,
.featured-item:hover p {
  color: #ffffff;
}
.featured-item.style-1:hover .featured-icon i,
.featured-item.style-2:hover .featured-icon i,
.featured-item.style-6:hover .featured-icon i {
  color: #005bea;
}
.featured-item.style-2:hover h5,
.featured-item.style-6:hover h5 {
  color: #1c1d3e;
}
.featured-item.style-2:hover p,
.featured-item.style-6:hover p {
  color: #5f5f5f;
}
.featured-item.style-2 {
  padding: 60px 30px;
  background: #ffffff;
  overflow: inherit;
}
.featured-item.style-2 .featured-icon i {
  font-size: 80px;
  line-height: 80px;
  z-index: 1;
  position: relative;
}
.featured-item.style-2:hover::before {
  display: none;
}
.featured-item.style-3 {
  background: #ffffff;
}
.featured-item.style-4 {
  background: #ffffff;
  border-radius: 70% 100% 30% 70%;
  overflow: hidden;
  padding: 14px 15px 35px 15px;
  z-index: 9;
}
.featured-item.style-4 .featured-title h5 {
  font-size: 1.2rem;
}
.featured-item.style-5 {
  padding-left: 160px;
  background: #ffffff;
}
.featured-item.style-5 .featured-icon {
  position: absolute;
  top: 40px;
  left: 30px;
}
.featured-item.style-5 h5 {
  margin-top: 0;
}
.featured-item.style-6 {
  padding: 0 0 0 100px;
  box-shadow: none;
  overflow: inherit;
}
.featured-item.style-6 h5 {
  margin-top: 0;
}
.featured-item.style-6::before,
.featured-item.style-6::after {
  display: none;
}
.featured-item.style-6 .featured-icon {
  position: absolute;
  top: 0;
  left: 0;
}
.featured-item.style-6 .featured-icon i {
  background: #ffffff;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 30px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}
.featured-item.style-1 .featured-icon i {
  background: #ffffff;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 30px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}
.featured-item.style-6 .featured-icon i::after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: '';
  top: 0;
  left: 0;
  z-index: 10;
  border: 2px dashed transparent;
}
.featured-item.style-6:hover .featured-icon i {
  background: none;
}
.featured-item.style-6:hover .featured-icon i::after {
  animation: rotateme 9s linear infinite;
  border-color: #2575fc;
}

.featured-item {
  transition: all 0.5s ease-in-out 0s;
}
.featured-item:after,
.featured-item::before {
  transition: all 0.5s ease-in-out 0s;
}

.featured-icon,
.featured-item .featured-icon i,
.price-table {
  transition: all 0.5s ease-in-out 0s;
}

/*--translate-XY--*/
.clear-loader,
.title-effect-2 i,
.featured-item .featured-icon span,
.blink-img,
.hexagon-bg.style-2,
.video-btn-pos,
.video-btn .spinner-eff {
  transform: translateX(-50%) translateY(-50%);
}

.featured-item {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}
.featured-item:hover,
.featured-item.style-1 .featured-icon i,
.featured-item.style-2,
.featured-item.style-6 .featured-icon i {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}

.featured-item.style-2:hover,
.price-table:hover {
  -o-box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
  box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
}

.gutter {
  --bs-gutter-y: 1.5rem !important;
}

.fontweigth400 {
  font-weight: 400;
}
.mt63 {
  margin-top: 0px;
}
.swamiimg4 {
  height: auto;
  width: 160px;
}
.h3Amb {
  font-size: 27px;
  font-weight: 600;
  color: #fefefe;
}
.ambleftsec {
  left: -317px !important;
}
.ambleftsec:hover {
  left: 0px !important;
}
.bggreay {
  background: #bdbcc4;
}
.Ambfaq-img {
  width: 44% !important;
}
.Ambfaq-sec {
  width: 56% !important;
}

@media only screen and (max-width: 400px) {
  .backgroundcard1 {
    background-color: #cce1ed;
    min-height: 137px;
  }
}

.custom-card {
  line-height: 2;
}

.Ambblueh3 {
  font-size: 35px !important;
  font-weight: 700;
  font-weight: 700  ;
}


.ambmain-margin{
  margin-top: auto;
}

.paddingamb-icon{
   padding: 0px 171px;
}

.talkdiv-mbd{
  max-width: 344px;
  margin: auto;
}
@media only screen and (max-width: 600px) {
  .talkdiv-mbd{
    max-width: 207px;
    margin: auto;
  }
  .paddingamb-icon{
    padding: 0px 16px;
 }
  .ambmain-margin{
    margin-top: 153px;
  }

  .missionimage {
    min-height: 115px !important;
    width: 100%;
    background-position: top !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
  
  }
  .Ambedkarimage {
    background-size: contain !important;
    background-position: top !important;
  }
  .f3mdu{
    color: #148580;
    font-weight: 700;
    font-size: 30px;
  }

  .Ambfaq-img {
    width: 100% !important;
  }
  .Ambfaq-sec {
    width: 100% !important;
  }

  .btnAmb {
    width: 141px !important;
    background: #f72206 !important;
    color: #ffffff;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 13px !important;
    line-height: 33px !important;
    border: 0px !important;
    padding: 2px 1px !important;
  }

  .h1amb .span {
    color: #003d7a;
    display: block;
    font-size: 27px;
  }
  .h3Amb {
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
  }
  .divfont {
    font-size: 18px !important;
    font-weight: 600;
  }

  .swamiimg4 {
    height: auto;
    width: 130px;
  }

 

  .featured-item.style-4 .featured-title h5 {
    margin: 0px !important;
  }
  .mt63 {
    margin-top: 63px;
  }
  .featured-item .card-img-top {
    height: 100px !important;
  }
  .ambscope .d-flex img {
    height: auto;
    width: 130px;
  }
  .btnbds {
    font-size: 17px !important;
  }
  .bdscollegeimage img {
    height: 156px;
    width: 353px;
  }

  .bdslandgingcolleges h5 {
    min-height: auto !important;
  }
  .ambscopecustomborder {
    border: 1px solid black;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
  .customborder2 ul {
    min-height: auto;
    margin-bottom: 10px;
    line-height: 2;
  }
}

.slide-in {
  animation: slideIn 1s ease-out;
  animation-delay: 2s;
}
.slide-in2 {
  animation: slideIn2 1s ease-out;
  animation-delay: 2s;
}

@keyframes slideIn2 {
  from {
    transform: translateX(+100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.Ambimgfooter {
  height: auto;
  width: 135px;
}

.btnAmb {
  width: 133px;
  background: #f72206 !important;
  color: #ffffff;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 37px;
  border: 0px;
  padding: 0px 0px;
}

.mbbsscope .d-flex p {
  font-size: 20px !important;
  font-weight: 600;
}

.bdscollegeimage img {
  height: 173px;
  width: 251px;
}

.smallfot-amb {
  font-weight: 600 !important;
  font-size: 25px !important;
}

.border-none {
  border: none !important;
}
.mbbsscope .scope p {
  /* font-size: 23px !important; */
  /* font-weight: 700; */
  color: #146306;
}
.mbbsscope .scope {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}
.mbbsscope .scope span {
  font-size: 16px;
  font-weight: 100;
  color: black;
  display: block;
}
.mbbsscope .scope img {
  height: 100px;
  width: 100px;
}
.btnbdscolour {
  background: #07385a !important;
}
.btnbds {
  background: #07385a !important;
  font-size: 21px !important;
}
.btnbds:hover,
.btnbdscolour:hover,
.btnbdscolour:focus,
button:hover,
.btnbds:focus {
  background: #00a040 !important;
  color: #ffffff;
}
.bamscolllege .h100 {
  min-height: 100px !important;
}
.bamsfont25 {
  font-size: 25px;
  color: black !important;
}
.mbbsscopecustomborder table tr:nth-child(odd) {
  /* border: 2px solid black; */
  text-align: center;
  overflow: auto;
  background: #e4f6e2;
  /* background: #81c0c0; */
}
.mbbsscopecustomborder table tr:nth-child(even) {
  color: #146306;
}
.mbbsscopecustomborder th {
  border-right: 4px solid white;
}

.mbbsscopecustomborder {
  border: 2px solid #f5e4e4;

  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
.mbbsscopecustomborder .documents li {
  font-size: 18px;
  font-weight: 600;
}
.mbbsscopecustomborder table {
  max-width: 100%;
  overflow-y: scroll;
}
.mbbsscopecustomborder tr th {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.mbbsscopecustomborder tr td {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
/* .mbbsscopecustomborder td{
  border: 2px solid black;
  text-align: center; 
  overflow: auto;
  font-weight: 600;
} */
.customberder-mbbs-admission ul {
  min-height: 190px;
  line-height: 2;
}
.customberder-mbbs-admission {
  overflow: auto;
}
.customberder-mbbs-admission table {
  font-size: 18px;
}
.customberder-mbbs-admission th {
  border: 2px solid black;
  text-align: center;
  overflow: auto;
}
.customberder-mbbs-admission td {
  border: 2px solid black;
  text-align: center;
  overflow: auto;
  /* font-weight: 600; */
}
.top80 {
  top: 70% !important;
}
.top90 {
  top: 80% !important;
}
.top60 {
  top: 55% !important;
}
.top70 {
  top: 65% !important;
}
.bgfooter {
  background: #091314 !important;
  /* background: #34345a; */
}
.bgfooter a {
  background: #091314 !important;
  /* background: #34345a; */
}
.Ambh2 {
  text-align: center;
  font-weight: 800;
  font-size: 35px;
  color: #148580;
  /* text-shadow: #FC0 1px 0 10px; */
}
.Ambh2 .fontw400 {
  font-weight: 400;
}

.customboxes {
  background: white !important;
  padding: 16px 0px !important;
}
.customboxes .customboxes div {
  padding: 12px 14px !important;
  background: #2d90c8 !important;
}
.mbbscard {
  background-color: rgb(0 58 142);
  color: white !important;
}
.mbbscard1 {
  background-color: #cce1ed;
  height: auto !important;
}

.img200 {
  height: auto;
  width: 130px !important;
}
.coltext {
  text-align: center !important;
  font-weight: 600;
  color: black;
}

.landgingcolleges img {
  float: none !important;
  height: 155px !important;
  object-fit: cover;
}
.landgingcolleges h5 {
  min-height: 72px;
}

.imgcontain {
  object-fit: contain !important;
}

.bgblackmbbs {
  /* background: rgb(109 91 91 / 53%); */
  background: #504f4f69;
  border-radius: 11px;
}
.firstmbbs {
  font-size: 29px !important;
  font-weight: 600;
  color: #000000;
  line-height: 1.2;
}
.divfont {
  font-size: 27px;
  font-weight: 600;
}

.ll {
  letter-spacing: -1px;
}
p {
  font-size: 18px !important;
}
.Pfont23 {
  font-size: 23px !important;
  color: #3d3939 !important;
}

.bgmbbs {
  background-color: rgb(18, 34, 55) !important;
}

.dd {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}

.ddAmb {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: rgb(8, 8, 8);
}
.customberder-mbbs-admission .card-header {
  background: #268406;
}
.customberder-mbbs-admission h4 {
  color: white;
  margin: 0px !important;
  font-size: 27px !important;
}

.imgh {
  min-height: 400px !important;
}
.bdsimgh {
  min-height: 300px !important;
}
.bgimage {
  min-height: 659px;
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.missionimage {
  min-height: 659px;
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}

.bamsimage {
  min-height: 659px;
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: top !important;
}

.hov:hover {
  background-color: white !important;
}
.h1amb {
  margin: 0;
  font-size: 35px;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: 1px;
}
.h1amb .span {
  color: #003d7a;
  display: block;
  font-size: 32px;
}
.d-block {
  display: block;
}
.Ambblue {
  color: #2277af;
  font-size: 18px;
}
.p {
  color: #ffffff;
  font-size: 22px;
  margin: 31px 0px;
  font-weight: 600;
}
.form-boxamb {
  max-width: 425px;
  margin: auto;
  padding: 25px;
  background: #00000069;
  border-radius: 11px;
}
.font-weight-bold {
  font-weight: 500;
}
.h4blue {
  color: rgb(0, 151, 227);
  font-weight: 600;
  font-size: 27px;
}
.textmbbs li {
  font-size: 18px;
  color: black;
  line-height: 2;
}
.textmbbs {
  font-size: 18px;
  color: black;
  line-height: 2;
}
.textAmb {
  font-size: 16px;
  color: black;
}
.bodrdertext {
  border: 3px solid grey;
}
.bluecolourp {
  color: rgb(54 38 96);
  font-weight: 600;
  font-size: 20px !important;
}
.f3mbbs {
  color: #133906;
  font-weight: 700;
  font-size: 35px;
}
.fontweght700 {
  font-weight: 700;
}
.video {
  height: auto;
  width: 100%;
}
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  width: 100%;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.h200 {
  height: 208px;
}
.clouryello {
  color: yellow;
  margin: 0px;
}
.card-body p {
  margin: 0px;
}
.card-body ul {
  padding-left: 20px;
}
.portflio-item .portfolio-item-content {
  position: absolute;
  content: '';
  right: 0px;
  bottom: 0px;
  opacity: 0;
  transition: all 0.35s ease;
}
.portflio-item:hover .portfolio-item-content {
  opacity: 1;
  bottom: 20px;
  right: 30px;
}
.portflio-item .portfolio-item-content {
  position: absolute;
  content: '';
  right: 0px;
  bottom: 0px;
  opacity: 0;
  transition: all 0.35s ease;
}

.portflio-item:before {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  transition: all 0.35s ease;
  overflow: hidden;
}

.portflio-item:hover:before {
  opacity: 1;
}

.portflio-item:hover .portfolio-item-content {
  opacity: 1;
  bottom: 20px;
  right: 30px;
}

.portflio-item .overlay-item {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80px;
  color: #f75757;
  opacity: 0;
  transition: all 0.35s ease;
}

.portflio-item:hover .overlay-item {
  opacity: 1;
}

.featured-item {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}
.featured-item:hover,
.featured-item.style-1 .featured-icon i,
.featured-item.style-2,
.featured-item.style-6 .featured-icon i {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}

.featured-item.style-2:hover,
.price-table:hover {
  -o-box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
  box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
}

/* ------------------------
    Featured Box
------------------------*/
.featured-item {
  padding: 40px 30px;
  position: relative;
  border-radius: 7px;
  overflow: hidden;
  z-index: 9;
}

.owl-carousel .featured-item {
  margin: 20px;
}

.featured-item .featured-icon {
  display: inline-block;
  position: relative;
}

.featured-desc p {
  margin-bottom: 0;
}

.featured-title h5 {
  margin: 30px 0 20px;
  text-transform: capitalize;
  font-weight: bold;
}

.dark-bg .featured-title h5,
.dark-bg .featured-desc {
  color: #ffffff;
}

.theme-bg .featured-title h5,
.theme-bg .featured-desc,
.theme-bg .featured-icon i {
  color: #ffffff;
}

.grey-bg .featured-desc p {
  color: #1c1d3e;
}

.featured-item.style-1 {
  box-shadow: none;
}
.featured-item .featured-icon i {
  color: #2575fc;
  display: inline-block;
  font-size: 50px;
  line-height: 50px;
}
.featured-item .featured-icon span {
  position: absolute;
  top: 50%;
  left: 50%;
}
.featured-item .featured-icon span::after,
.featured-item .featured-icon span::before {
  border-radius: 50%;
  content: '';
  height: 60px;
  position: absolute;
  width: 60px;
  z-index: -1;
}
.featured-item .featured-icon span::after {
  background: #cdf3f6;
  height: 40px;
  left: -10px;
  top: 5px;
  width: 40px;
}
.featured-item .featured-icon span::before {
  background: #d4f8e6;
  bottom: 0;
  right: -10px;
}
.featured-item::before {
  transform: scale(0);
  background: #005bea;
  background: linear-gradient(180deg, #005bea 0%, #2575fc 80%);
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: -1;
}
.featured-item:hover::before {
  transform: scale(1);
}
.featured-item:after {
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  opacity: 0;
  width: 100%;
  z-index: -1;
  background-size: cover;
}
.featured-item:hover:after {
  opacity: 0.2;
}
.featured-item:hover .featured-icon i,
.featured-item:hover h5,
.featured-item:hover p {
  color: #ffffff;
}
.featured-item.style-1:hover .featured-icon i,
.featured-item.style-2:hover .featured-icon i,
.featured-item.style-6:hover .featured-icon i {
  color: #005bea;
}
.featured-item.style-2:hover h5,
.featured-item.style-6:hover h5 {
  color: #1c1d3e;
}
.featured-item.style-2:hover p,
.featured-item.style-6:hover p {
  color: #5f5f5f;
}
.featured-item.style-2 {
  padding: 60px 30px;
  background: #ffffff;
  overflow: inherit;
}
.featured-item.style-2 .featured-icon i {
  font-size: 80px;
  line-height: 80px;
  z-index: 1;
  position: relative;
}
.featured-item.style-2:hover::before {
  display: none;
}
.featured-item.style-3 {
  background: #ffffff;
}
.featured-item.style-4 {
  background: #ffffff;
  border-radius: 70% 100% 30% 70%;
  overflow: hidden;
  padding: 14px 15px 35px 15px;
  z-index: 9;
}
.featured-item.style-4 .featured-title h5 {
  font-size: 1.2rem;
}
.featured-item.style-5 {
  padding-left: 160px;
  background: #ffffff;
}
.featured-item.style-5 .featured-icon {
  position: absolute;
  top: 40px;
  left: 30px;
}
.featured-item.style-5 h5 {
  margin-top: 0;
}
.featured-item.style-6 {
  padding: 0 0 0 100px;
  box-shadow: none;
  overflow: inherit;
}
.featured-item.style-6 h5 {
  margin-top: 0;
}
.featured-item.style-6::before,
.featured-item.style-6::after {
  display: none;
}
.featured-item.style-6 .featured-icon {
  position: absolute;
  top: 0;
  left: 0;
}
.featured-item.style-6 .featured-icon i {
  background: #ffffff;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 30px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}
.featured-item.style-1 .featured-icon i {
  background: #ffffff;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 30px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}
.featured-item.style-6 .featured-icon i::after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: '';
  top: 0;
  left: 0;
  z-index: 10;
  border: 2px dashed transparent;
}
.featured-item.style-6:hover .featured-icon i {
  background: none;
}
.featured-item.style-6:hover .featured-icon i::after {
  animation: rotateme 9s linear infinite;
  border-color: #2575fc;
}

.featured-item {
  transition: all 0.5s ease-in-out 0s;
}
.featured-item:after,
.featured-item::before {
  transition: all 0.5s ease-in-out 0s;
}

.featured-icon,
.featured-item .featured-icon i,
.price-table {
  transition: all 0.5s ease-in-out 0s;
}

/*--translate-XY--*/
.clear-loader,
.title-effect-2 i,
.featured-item .featured-icon span,
.blink-img,
.hexagon-bg.style-2,
.video-btn-pos,
.video-btn .spinner-eff {
  transform: translateX(-50%) translateY(-50%);
}

.featured-item {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}
.featured-item:hover,
.featured-item.style-1 .featured-icon i,
.featured-item.style-2,
.featured-item.style-6 .featured-icon i {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}

.featured-item.style-2:hover,
.price-table:hover {
  -o-box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
  box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
}

.gutter {
  --bs-gutter-y: 1.5rem !important;
}

.fontweigth400 {
  font-weight: 400;
}
.mt63 {
  margin-top: 0px;
}
.swamiimg4 {
  height: auto;
  width: 160px;
}
.h3Amb {
  font-size: 27px;
  font-weight: 600;
  color: #fefefe;
}
.bamleftsec {
  left: -235px !important;
}
.bamleftsec:hover {
  left: 0px !important;
}
.bggreay {
  background: #bdbcc4;
}
.Ambfaq-img {
  width: 44% !important;
}
.Ambfaq-sec {
  width: 56% !important;
}

@media only screen and (max-width: 400px) {
  .mbbscard1 {
    background-color: #cce1ed;
    min-height: 137px;
  }
}

.custom-card {
  line-height: 2;
}

.Ambblueh3 {
  font-size: 35px !important;
  font-weight: 700;
  font-weight: 700;
}

.ambmain-margin {
  margin-top: auto;
}

.paddingamb-icon {
  padding: 0px 171px;
}

.talkdiv-mbd {
  max-width: 344px;
  margin: auto;
}

.mabbsorangen {
  color: #ff8e08;
  font-size: 27px;
}

.bams-height-college {
  min-height: 168px !important;
}

.bamsimg4 {
  height: 170px;
  width: 170px;
}

.mbbdbtnpadding {
  background-color: #ff8902 !important;
}
.mbbdbtnpadding :hover {
  background-color: #00b4f6 !important;
}
.footerp {
  font-size: 14px !important;
}

@media only screen and (max-width: 600px) {
  .store-wrapper a {
    padding-left: 10px !important;
  }
  .btnsmbb .btn {
    border-radius: 6px;
    font-size: 13px;
    line-height: 28px;
    padding: 4px 5px;
  }

  .firstmbbs {
    font-size: 18px !important;
  }
  .bams-height-college {
    min-height: auto !important;
  }
  .mbbdbtnpadding {
    padding: 3px 10px !important;
    background-color: #ff8902 !important;
  }
  .talkdiv-mbd {
    max-width: 207px;
    margin: auto;
  }
  .paddingamb-icon {
    padding: 0px 16px;
  }
  .ambmain-margin {
    margin-top: 153px;
  }

  .missionimage {
    min-height: 115px !important;
    width: 100%;
    background-position: top !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
  }
  .bamsimage {
    /* background-size: contain !important; */
    background-position: top !important;
  }
  .f3mbbs {
    color: #133906;
    font-weight: 700;
    font-size: 30px;
  }

  .Ambfaq-img {
    width: 100% !important;
  }
  .Ambfaq-sec {
    width: 100% !important;
  }

  .btnmbbs {
    width: 200px !important;
    background: #ff8902 !important;
    color: #ffffff;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 13px !important;
    line-height: 33px !important;
    border: 0px !important;
    padding: 2px 1px !important;
  }

  .h1amb .span {
    color: #003d7a;
    display: block;
    font-size: 27px;
  }
  .h3Amb {
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
  }
  .divfont {
    font-size: 18px !important;
    font-weight: 600;
  }

  .swamiimg4 {
    height: auto;
    width: 130px;
  }

  .featured-item.style-4 .featured-title h5 {
    margin: 0px !important;
  }
  .mt63 {
    margin-top: 63px;
  }
  .featured-item .card-img-top {
    height: 100px !important;
  }
  .mbbsscope .d-flex img {
    height: auto;
    width: 130px;
  }
  .btnbds {
    font-size: 17px !important;
  }
  .bdscollegeimage img {
    height: 156px;
    width: 353px;
  }

  .bamscolllege .h100 {
    min-height: auto !important;
  }
  .mbbsscopecustomborder {
    border: 1px solid black;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
  .customberder-mbbs-admission ul {
    min-height: auto;
    margin-bottom: 10px;
    line-height: 2;
    padding-bottom: 24px;
  }
}

.slide-in {
  animation: slideIn 1s ease-out;
  animation-delay: 2s;
}
.slide-in2 {
  animation: slideIn2 1s ease-out;
  animation-delay: 2s;
}

@keyframes slideIn2 {
  from {
    transform: translateX(+100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.Ambimgfooter {
  height: auto;
  width: 135px;
}

.btnmbbs {
  width: 133px;
  background: #ff8902 !important;
  color: #ffffff;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 37px;
  border: 0px;
  padding: 0px 0px;
}

.bdsscope-add .d-flex p {
  font-size: 20px !important;
  font-weight: 600;
}
.bdsscope-add .scope {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1) !important;
}
.bds-font30 {
  font-size: 30px !important;
  color: white;
  background: #0000003d;
}
.scope-padd{
  padding: 6px 0px;
}

.bds-bgimage {
  min-height: 659px;
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.bds-add-h2 {
  text-align: center;
  font-weight: 800;
  font-size: 40px;
  color: rgb(255, 255, 255);
}

.min-width-tableheading {
  width: 240px !important;
}

.bdsktable-row td:first-child {
  font-weight: 700 !important;
}

.bdsktable-header th {
  font-size: 17px !important;
}

.bdsktable-header {
  background-color: #95a5a6;
  /* font-size: 14px; */
  /* text-transform: uppercase; */
}
.bdsktable-row {
  background-color: #ffffff;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
}
.bdsktable-row td {
  padding: 0px !important;
  font-size: 16px !important;
}

.bdscollegeimage img {
  height: 173px;
  width: 251px;
}

.smallfot-amb {
  font-weight: 600 !important;
  font-size: 25px !important;
}

.border-none {
  border: none !important;
}
.bdsscope-add .scope p {
  /* font-size: 23px !important; */
  /* font-weight: 700; */
  color: #751b1b;
}

.bdsscope-add .scope span {
  font-size: 16px;
  font-weight: 100;
  color: black;
  display: block;
}
.bdsscope-add .scope img {
  height: 100px;
  width: 100px;
}
.btn-bds-addcolour {
  background: #e47c00 !important;
}
.btn-bds-add {
  background: #e47c00 !important;
  font-size: 21px !important;
}
.btn-bds-add:hover,
.btn-bds-addcolour:hover,
.btn-bds-addcolour:focus,
button:hover,
.btn-bds-add:focus {
  background: #00a040 !important;
  color: #ffffff;
}
.bamscolllege .h100 {
  min-height: 100px !important;
}
.bamsfont25 {
  font-size: 25px;
  color: black !important;
}
.bdssimpletable {
  text-align: center;
  overflow: auto;
}

/* .bdssimpletable tr th {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
} */
.bdssimpletable tr td {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.bdssimpletable table {
  max-width: 100%;
  overflow-y: scroll;
}

.bds-table table tr:nth-child(odd) {
  /* border: 2px solid black; */
  text-align: center;
  overflow: auto;
  background: #e9f9ff;
  /* background: #e4f6e2; */
  /* background: #81c0c0; */
}
.bds-table table tr:nth-child(even) {
  color: #3683a0;
}
.bds-table th {
  border-right: 4px solid white;
}

.bds-table {
  border: 2px solid #f5e4e4;

  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
.bds-table.documents li {
  font-size: 18px;
  font-weight: 600;
}
.bds-table table {
  max-width: 100%;
  overflow-y: scroll;
}
.bds-table tr th {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.bds-table tr td {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
/* .bds-tabletd{
  border: 2px solid black;
  text-align: center; 
  overflow: auto;
  font-weight: 600;
} */
.customberder-mbbs-admission ul {
  min-height: 190px;
  line-height: 2;
}
.customberder-mbbs-admission {
  overflow: auto;
}
.customberder-mbbs-admission table {
  font-size: 18px;
}
.customberder-mbbs-admission th {
  border: 2px solid black;
  text-align: center;
  overflow: auto;
}
.customberder-mbbs-admission td {
  border: 2px solid black;
  text-align: center;
  overflow: auto;
  /* font-weight: 600; */
}
.top80 {
  top: 70% !important;
}
.top90 {
  top: 80% !important;
}
.top60 {
  top: 55% !important;
}
.top70 {
  top: 65% !important;
}
.bgfooter {
  background: #34345a;
}
.bgfooter a {
  background: #34345a;
}
.Ambh2 {
  text-align: center;
  font-weight: 800;
  font-size: 35px;
  color: #148580;
  /* text-shadow: #FC0 1px 0 10px; */
}
.Ambh2 .fontw400 {
  font-weight: 400;
}

.customboxes {
  background: white !important;
  padding: 16px 0px !important;
}
.customboxes .customboxes div {
  padding: 12px 14px !important;
  background: #2d90c8 !important;
}
.mbbscard {
  background-color: rgb(0 58 142);
  color: white !important;
}
.mbbscard1 {
  background-color: #cce1ed;
  height: auto !important;
}

.img200 {
  height: auto;
  width: 130px !important;
}
.coltext {
  text-align: center !important;
  font-weight: 600;
  color: black;
}

.landgingcolleges img {
  float: none !important;
  height: 155px !important;
  object-fit: cover;
}
.landgingcolleges h5 {
  min-height: 72px;
}

.imgcontain {
  object-fit: contain !important;
}

.bgblackmbbs {
  /* background: rgb(109 91 91 / 53%); */
  background: #504f4f69;
  border-radius: 11px;
}
.first-bds {
  font-size: 29px !important;
  font-weight: 600;
  color: #fff900;
  line-height: 1.2;
}
.divfont {
  font-size: 27px;
  font-weight: 600;
}

.ll {
  letter-spacing: -1px;
}
p {
  font-size: 18px !important;
}
.Pfont23 {
  font-size: 23px !important;
  color: #3d3939 !important;
}

.bgmbbs {
  background-color: rgb(18, 34, 55) !important;
}

.dd {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}

.ddAmb {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: rgb(8, 8, 8);
}
.customberder-mbbs-admission .card-header {
  background: #268406;
}
.customberder-mbbs-admission h4 {
  color: white;
  margin: 0px !important;
  font-size: 27px !important;
}

.imgh {
  min-height: 400px !important;
}
.bdsimgh {
  min-height: 300px !important;
}
.bgimage {
  min-height: 659px;
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.missionimage {
  min-height: 659px;
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}

.bamsimage {
  min-height: 659px;
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: top !important;
}

.hov:hover {
  background-color: white !important;
}
.h1amb {
  margin: 0;
  font-size: 35px;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: 1px;
}
.h1amb .span {
  color: #003d7a;
  display: block;
  font-size: 32px;
}
.d-block {
  display: block;
}
.Ambblue {
  color: #2277af;
  font-size: 18px;
}
.p {
  color: #ffffff;
  font-size: 22px;
  margin: 31px 0px;
  font-weight: 600;
}
.form-boxamb {
  max-width: 425px;
  margin: auto;
  padding: 25px;
  background: #00000069;
  border-radius: 11px;
}
.font-weight-bold {
  font-weight: 500;
}
.h4blue {
  color: rgb(0, 151, 227);
  font-weight: 600;
  font-size: 27px;
}
.textmbbs li {
  font-size: 18px;
  color: black;
  line-height: 2;
}
.textmbbs {
  font-size: 18px;
  color: black;
  line-height: 2;
}
.textAmb {
  font-size: 16px;
  color: black;
}
.bodrdertext {
  border: 3px solid grey;
}
.bluecolourp {
  color: rgb(54 38 96);
  font-weight: 600;
  font-size: 20px !important;
}
.f3bds-addmission {
  color: #25427c;
  font-weight: 700;
  font-size: 35px;
}
.fontweght700 {
  font-weight: 700;
}
.video {
  height: auto;
  width: 100%;
}
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  width: 100%;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.h200 {
  height: 208px;
}
.clouryello {
  color: yellow;
  margin: 0px;
}
.card-body p {
  margin: 0px;
}
.card-body ul {
  padding-left: 20px;
}
.portflio-item .portfolio-item-content {
  position: absolute;
  content: '';
  right: 0px;
  bottom: 0px;
  opacity: 0;
  transition: all 0.35s ease;
}
.portflio-item:hover .portfolio-item-content {
  opacity: 1;
  bottom: 20px;
  right: 30px;
}
.portflio-item .portfolio-item-content {
  position: absolute;
  content: '';
  right: 0px;
  bottom: 0px;
  opacity: 0;
  transition: all 0.35s ease;
}

.portflio-item:before {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  transition: all 0.35s ease;
  overflow: hidden;
}

.portflio-item:hover:before {
  opacity: 1;
}

.portflio-item:hover .portfolio-item-content {
  opacity: 1;
  bottom: 20px;
  right: 30px;
}

.portflio-item .overlay-item {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80px;
  color: #f75757;
  opacity: 0;
  transition: all 0.35s ease;
}

.portflio-item:hover .overlay-item {
  opacity: 1;
}

.featured-item {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}
.featured-item:hover,
.featured-item.style-1 .featured-icon i,
.featured-item.style-2,
.featured-item.style-6 .featured-icon i {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}

.featured-item.style-2:hover,
.price-table:hover {
  -o-box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
  box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
}

/* ------------------------
    Featured Box
------------------------*/
.featured-item {
  padding: 40px 30px;
  position: relative;
  border-radius: 7px;
  overflow: hidden;
  z-index: 9;
}

.owl-carousel .featured-item {
  margin: 20px;
}

.featured-item .featured-icon {
  display: inline-block;
  position: relative;
}

.featured-desc p {
  margin-bottom: 0;
}

.featured-title h5 {
  margin: 30px 0 20px;
  text-transform: capitalize;
  font-weight: bold;
}

.dark-bg .featured-title h5,
.dark-bg .featured-desc {
  color: #ffffff;
}

.theme-bg .featured-title h5,
.theme-bg .featured-desc,
.theme-bg .featured-icon i {
  color: #ffffff;
}

.grey-bg .featured-desc p {
  color: #1c1d3e;
}

.featured-item.style-1 {
  box-shadow: none;
}
.featured-item .featured-icon i {
  color: #2575fc;
  display: inline-block;
  font-size: 50px;
  line-height: 50px;
}
.featured-item .featured-icon span {
  position: absolute;
  top: 50%;
  left: 50%;
}
.featured-item .featured-icon span::after,
.featured-item .featured-icon span::before {
  border-radius: 50%;
  content: '';
  height: 60px;
  position: absolute;
  width: 60px;
  z-index: -1;
}
.featured-item .featured-icon span::after {
  background: #cdf3f6;
  height: 40px;
  left: -10px;
  top: 5px;
  width: 40px;
}
.featured-item .featured-icon span::before {
  background: #d4f8e6;
  bottom: 0;
  right: -10px;
}
.featured-item::before {
  transform: scale(0);
  background: #005bea;
  background: linear-gradient(180deg, #005bea 0%, #2575fc 80%);
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: -1;
}
.featured-item:hover::before {
  transform: scale(1);
}
.featured-item:after {
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  opacity: 0;
  width: 100%;
  z-index: -1;
  background-size: cover;
}
.featured-item:hover:after {
  opacity: 0.2;
}
.featured-item:hover .featured-icon i,
.featured-item:hover h5,
.featured-item:hover p {
  color: #ffffff;
}
.featured-item.style-1:hover .featured-icon i,
.featured-item.style-2:hover .featured-icon i,
.featured-item.style-6:hover .featured-icon i {
  color: #005bea;
}
.featured-item.style-2:hover h5,
.featured-item.style-6:hover h5 {
  color: #1c1d3e;
}
.featured-item.style-2:hover p,
.featured-item.style-6:hover p {
  color: #5f5f5f;
}
.featured-item.style-2 {
  padding: 60px 30px;
  background: #ffffff;
  overflow: inherit;
}
.featured-item.style-2 .featured-icon i {
  font-size: 80px;
  line-height: 80px;
  z-index: 1;
  position: relative;
}
.featured-item.style-2:hover::before {
  display: none;
}
.featured-item.style-3 {
  background: #ffffff;
}
.featured-item.style-4 {
  background: #ffffff;
  border-radius: 70% 100% 30% 70%;
  overflow: hidden;
  padding: 14px 15px 35px 15px;
  z-index: 9;
}
.featured-item.style-4 .featured-title h5 {
  font-size: 1.2rem;
}
.featured-item.style-5 {
  padding-left: 160px;
  background: #ffffff;
}
.featured-item.style-5 .featured-icon {
  position: absolute;
  top: 40px;
  left: 30px;
}
.featured-item.style-5 h5 {
  margin-top: 0;
}
.featured-item.style-6 {
  padding: 0 0 0 100px;
  box-shadow: none;
  overflow: inherit;
}
.featured-item.style-6 h5 {
  margin-top: 0;
}
.featured-item.style-6::before,
.featured-item.style-6::after {
  display: none;
}
.featured-item.style-6 .featured-icon {
  position: absolute;
  top: 0;
  left: 0;
}
.featured-item.style-6 .featured-icon i {
  background: #ffffff;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 30px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}
.featured-item.style-1 .featured-icon i {
  background: #ffffff;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 30px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}
.featured-item.style-6 .featured-icon i::after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: '';
  top: 0;
  left: 0;
  z-index: 10;
  border: 2px dashed transparent;
}
.featured-item.style-6:hover .featured-icon i {
  background: none;
}
.featured-item.style-6:hover .featured-icon i::after {
  animation: rotateme 9s linear infinite;
  border-color: #2575fc;
}

.featured-item {
  transition: all 0.5s ease-in-out 0s;
}
.featured-item:after,
.featured-item::before {
  transition: all 0.5s ease-in-out 0s;
}

.featured-icon,
.featured-item .featured-icon i,
.price-table {
  transition: all 0.5s ease-in-out 0s;
}

/*--translate-XY--*/
.clear-loader,
.title-effect-2 i,
.featured-item .featured-icon span,
.blink-img,
.hexagon-bg.style-2,
.video-btn-pos,
.video-btn .spinner-eff {
  transform: translateX(-50%) translateY(-50%);
}

.featured-item {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}
.featured-item:hover,
.featured-item.style-1 .featured-icon i,
.featured-item.style-2,
.featured-item.style-6 .featured-icon i {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}

.featured-item.style-2:hover,
.price-table:hover {
  -o-box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
  box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
}

.gutter {
  --bs-gutter-y: 1.5rem !important;
}

.fontweigth400 {
  font-weight: 400;
}
.mt63 {
  margin-top: 0px;
}
.swamiimg4 {
  height: auto;
  width: 160px;
}
.h3Amb {
  font-size: 27px;
  font-weight: 600;
  color: #fefefe;
}
.bamleftsec {
  left: -235px !important;
}
.bamleftsec:hover {
  left: 0px !important;
}
.bggreay {
  background: #bdbcc4;
}
.Ambfaq-img {
  width: 44% !important;
}
.Ambfaq-sec {
  width: 56% !important;
}

.bds-position {
  position: relative;
  right: 320px;
  top: 294px;
}
@media only screen and (max-width: 400px) {
  .mbbscard1 {
    background-color: #cce1ed;
    min-height: 137px;
  }
}

.custom-card {
  line-height: 2;
}

.Ambblueh3 {
  font-size: 35px !important;
  font-weight: 700;
  font-weight: 700;
}

.ambmain-margin {
  margin-top: auto;
}

.paddingamb-icon {
  padding: 0px 171px;
}

.talkdiv-mbd {
  max-width: 344px;
  margin: auto;
}

.mabbsorangen {
  color: #ff8e08;
  font-size: 27px;
}

.bams-height-college {
  min-height: 168px !important;
}

.bamsimg4 {
  height: 170px;
  width: 170px;
}

.mbbdbtnpadding {
  background-color: #ff8902 !important;
}
.mbbdbtnpadding :hover {
  background-color: #00b4f6 !important;
}

@media only screen and (max-width: 600px) {


  .bds-bgimage {
    min-height: 160px;
    padding: 0px !important;
    width: 100%;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }


  .bds-font30 {
    font-size: 23px !important;
    color: white;
    background: #0000003d;
  }

  .bds-add-h2 {
    text-align: center;
    font-weight: 800;
    font-size: 32px !important;
    color: rgb(255, 255, 255);
  }

  .bdsktable-header th {
    font-size: 15px !important;
  }
  .bdsktable-row td {
    padding: 0px !important;
    font-size: 14px !important;
  }

  .min-width-tableheading {
    width: 100px !important;
  }
  .bams-height-college {
    min-height: auto !important;
  }
  .mbbdbtnpadding {
    padding: 3px 10px !important;
    background-color: #ff8902 !important;
  }
  .talkdiv-mbd {
    max-width: 207px;
    margin: auto;
  }
  .paddingamb-icon {
    padding: 0px 16px;
  }
  .ambmain-margin {
    margin-top: 153px;
  }

  .missionimage {
    min-height: 115px !important;
    width: 100%;
    background-position: top !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
  }
  .bamsimage {
    /* background-size: contain !important; */
    background-position: top !important;
  }
  .f3bds-addmission {
    color: #25427c;
    font-weight: 700;
    font-size: 30px;
  }

  .Ambfaq-img {
    width: 100% !important;
  }
  .Ambfaq-sec {
    width: 100% !important;
  }

  .btnmbbs {
    width: 200px !important;
    background: #ff8902 !important;
    color: #ffffff;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 13px !important;
    line-height: 33px !important;
    border: 0px !important;
    padding: 2px 1px !important;
  }

  .h1amb .span {
    color: #003d7a;
    display: block;
    font-size: 27px;
  }
  .h3Amb {
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
  }
  .divfont {
    font-size: 18px !important;
    font-weight: 600;
  }

  .swamiimg4 {
    height: auto;
    width: 130px;
  }

  .featured-item.style-4 .featured-title h5 {
    margin: 0px !important;
  }
  .mt63 {
    margin-top: 63px;
  }
  .featured-item .card-img-top {
    height: 100px !important;
  }
  .bdsscope-add .d-flex img {
    height: auto;
    width: 130px;
  }
  .btn-bds-add {
    font-size: 17px !important;
  }
  .bdscollegeimage img {
    height: 156px;
    width: 353px;
  }

  .bamscolllege .h100 {
    min-height: auto !important;
  }

  .bds-table {
    border: 1px solid black;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
  .bdssimpletable {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
  .customberder-mbbs-admission ul {
    min-height: auto;
    margin-bottom: 10px;
    line-height: 2;
    padding-bottom: 24px;
  }
}

.slide-in {
  animation: slideIn 1s ease-out;
  animation-delay: 2s;
}
.slide-in2 {
  animation: slideIn2 1s ease-out;
  animation-delay: 2s;
}

@keyframes slideIn2 {
  from {
    transform: translateX(+100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.Ambimgfooter {
  height: auto;
  width: 135px;
}

.btnmbbs {
  width: 133px;
  background: #ff8902 !important;
  color: #ffffff;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 37px;
  border: 0px;
  padding: 0px 0px;
}



.custom-tabs .nav-link {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 0;
  color: #353637;
  border-radius: 12px;
  cursor: pointer;
}
.topTrendingCourses .owl-item{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.font-weight-bold1 {
  font-weight: 600;
}
.f25yene{
  color: #dc8835;
    font-size: 28px !important;
}


.yenepoyacards {

  border: 0.0625rem solid #d6a800;
  background: #b9c0ff17;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.yenepoyacards p{

  font-size: 18px !important;
}
.yenepoyacards .card-title{

  margin-bottom: 0px !important;
}
.yenepoyacards h5{

  font-size: 25px !important;
    color: #5229a7;
}

.h99{
  min-height: 99px !important;
}
.yenepoyacards ul{

  list-style: none;
}
.card-image-yenepoya{
  height: 56px;
  width: auto;
}
.card-icon-yenepoya{
  height: 40px;
  width: auto;
}
.custom-tabs .nav-link.active {
  background-color: #2d4b13;
    color: #fff;
    border: 2px solid #80d484;
}
  /* background-color: #007bff;
  color: #fff;
  border: 1px solid #007bff;
} */

.custom-tabs .nav-link:hover {
  background-color: #e9ecef;
  color: #212529;
}

.custom-tabs .tab-content {
  border: 1px solid #dee2e6;
  border-top: none;
  padding: 20px;
}

.custom-tabs .tab-pane {
  display: none;
}

.custom-tabs .tab-pane.active {
  display: block;
}

.bdsscope-add .d-flex p {
  font-size: 20px !important;
  font-weight: 600;
}
.countercards-yenepoya div{
  background-color: #222E3C;
  border-style: solid;
  border-width: 4px 0 0 0;
  border-color: #DB312B;
  transition: background 0.3s,border 0.3s,border-radius 0.3s,box-shadow 0.3s;
  margin: 0px 14px 0px 20px;
  --e-column-margin-right: 20px;
  --e-column-margin-left: 20px;
  padding: 0px 20px -1px 20px;
  text-align: center;
  font-size: 39px;
  font-weight: 600;
}
.modal-90w {
  max-width: none!important;
  width: 90%;
}
.font50 {
  font-size: 50px !important;
  color: #ffc600 !important;
}
.modal-90w .modal-content{
  background-color: #2f1a45 !important;
  color: white !important;
}
.bds-font30 {
  font-size: 30px !important;
  color: white;
  background: #0000003d;
}

.bds-bgimage {
  min-height: 659px;
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.bds-add-h2 {
  text-align: center;
  font-weight: 800;
  font-size: 40px;
  color: rgb(255, 255, 255);
}

.min-width-tableheading {
  width: 240px !important;
}

.bdsktable-row td:first-child {
  font-weight: 700 !important;
}

.bdsktable-header th {
  font-size: 17px !important;
}

.bdsktable-header {
  background-color: #95a5a6;
  /* font-size: 14px; */
  /* text-transform: uppercase; */
}
.bdsktable-row {
  background-color: #ffffff;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
}
.bdsktable-row td {
  padding: 0px !important;
  font-size: 16px !important;
}

.bdscollegeimage img {
  height: 173px;
  width: 251px;
}

.smallfot-amb {
  font-weight: 600 !important;
  font-size: 25px !important;
}

.border-none {
  border: none !important;
}
.bdsscope-add .scope p {
  /* font-size: 23px !important; */
  /* font-weight: 700; */
  color: #751b1b;
}
.bdsscope-add .scope span {
  font-size: 16px;
  font-weight: 100;
  color: black;
  display: block;
}
.bdsscope-add .scope img {
  height: 100px;
  width: 100px;
}
.btn-bds-addcolour {
  background: #e47c00 !important;
}
.btn-bds-add {
  background: #e47c00 !important;
  font-size: 21px !important;
}
.btn-bds-add:hover,
.btn-bds-addcolour:hover,
.btn-bds-addcolour:focus,
button:hover,
.btn-bds-add:focus {
  background: #00a040 !important;
  color: #ffffff;
}
.bamscolllege .h100 {
  min-height: 100px !important;
}
.bamsfont25 {
  font-size: 25px;
  color: black !important;
}
.bdssimpletable {
  text-align: center;
  overflow: auto;
}

/* .bdssimpletable tr th {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
} */
.bdssimpletable tr td {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.bdssimpletable table {
  max-width: 100%;
  overflow-y: scroll;
}

.bds-table table tr:nth-child(odd) {
  /* border: 2px solid black; */
  text-align: center;
  overflow: auto;
  background: #e9f9ff;
  /* background: #e4f6e2; */
  /* background: #81c0c0; */
}
.bds-table table tr:nth-child(even) {
  color: #3683a0;
}
.bds-table th {
  border-right: 4px solid white;
}

.bds-table {
  border: 2px solid #f5e4e4;

  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
.bds-table.documents li {
  font-size: 18px;
  font-weight: 600;
}
.bds-table table {
  max-width: 100%;
  overflow-y: scroll;
}
.bds-table tr th {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.bds-table tr td {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
/* .bds-tabletd{
  border: 2px solid black;
  text-align: center; 
  overflow: auto;
  font-weight: 600;
} */
.customberder-mbbs-admission ul {
  min-height: 190px;
  line-height: 2;
}
.customberder-mbbs-admission {
  overflow: auto;
}
.customberder-mbbs-admission table {
  font-size: 18px;
}
.customberder-mbbs-admission th {
  border: 2px solid black;
  text-align: center;
  overflow: auto;
}
.customberder-mbbs-admission td {
  border: 2px solid black;
  text-align: center;
  overflow: auto;
  /* font-weight: 600; */
}
.top80 {
  top: 70% !important;
}
.top90 {
  top: 80% !important;
}
.top60 {
  top: 55% !important;
}
.top70 {
  top: 65% !important;
}
.bgfooter {
  background: #34345a;
}
.bgfooter a {
  background: #34345a;
}
.Ambh2 {
  text-align: center;
  font-weight: 800;
  font-size: 35px;
  color: #148580;
  /* text-shadow: #FC0 1px 0 10px; */
}
.Ambh2 .fontw400 {
  font-weight: 400;
}

.customboxes {
  background: white !important;
  padding: 16px 0px !important;
}
.customboxes .customboxes div {
  padding: 12px 14px !important;
  background: #2d90c8 !important;
}
.mbbscard {
  background-color: rgb(0 58 142);
  color: white !important;
}
.mbbscard1 {
  background-color: #cce1ed;
  height: auto !important;
}

.img200 {
  height: auto;
  width: 130px !important;
}
.coltext {
  text-align: center !important;
  font-weight: 600;
  color: black;
}

.landgingcolleges img {
  float: none !important;
  height: 155px !important;
  object-fit: cover;
}
.landgingcolleges h5 {
  min-height: 72px;
}

.imgcontain {
  object-fit: contain !important;
}

.bgblackyenepoya {
  /* background: rgb(109 91 91 / 53%); */
  background: #8b9bb169;
  border-radius: 11px;
}
.first-bds {
  font-size: 29px !important;
  font-weight: 600;
  color: #fff900;
  line-height: 1.2;
}
.divfont {
  font-size: 27px;
  font-weight: 600;
}

.ll {
  letter-spacing: -1px;
}
p {
  font-size: 18px !important;
}
.Pfont23 {
  font-size: 23px !important;
  color: #3d3939 !important;
}

.bgmbbs {
  background-color: rgb(18, 34, 55) !important;
}

.dd {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}

.ddAmb {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: rgb(8, 8, 8);
}
.customberder-mbbs-admission .card-header {
  background: #268406;
}
.customberder-mbbs-admission h4 {
  color: white;
  margin: 0px !important;
  font-size: 27px !important;
}

.imgh {
  min-height: 400px !important;
}
.bdsimgh {
  min-height: 300px !important;
}
.bgimage {
  min-height: 659px;
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.missionimage {
  min-height: 659px;
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}

.bamsimage {
  min-height: 659px;
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: top !important;
}

.hov:hover {
  background-color: white !important;
}
.h1yenepoya {
  margin: 0;
  font-size: 35px;
  font-weight: 700;
  color: #000000;
  letter-spacing: 1px;
}
.h1amb .span {
  color: #003d7a;
  display: block;
  font-size: 32px;
}
.d-block {
  display: block;
}
.Ambblue {
  color: #2277af;
  font-size: 18px;
}
.p {
  color: #ffffff;
  font-size: 22px;
  margin: 31px 0px;
  font-weight: 600;
}
.form-boxamb {
  max-width: 425px;
  margin: auto;
  padding: 25px;
  background: #00000069;
  border-radius: 11px;
}
.font-weight-bold {
  font-weight: 500;
}
.h4blue {
  color: rgb(0, 151, 227);
  font-weight: 600;
  font-size: 27px;
}
.textmbbs li {
  font-size: 18px;
  color: black;
  line-height: 2;
}
.textmbbs {
  font-size: 18px;
  color: black;
  line-height: 2;
}
.textAmb {
  font-size: 16px;
  color: black;
}
.bodrdertext {
  border: 3px solid grey;
}
.bluecolourp {
  color: rgb(54 38 96);
  font-weight: 600;
  font-size: 20px !important;
}
.f3bds-addmission {
  color: #25427c;
  font-weight: 700;
  font-size: 35px;
}
.f3yenepoya {
  color: #013d0c;
  font-weight: 700;
  font-size: 30px;
}
.fontweght700 {
  font-weight: 700;
}
.video {
  height: auto;
  width: 100%;
}
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  width: 100%;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.h150 {
  height: 150px;
}
.clouryello {
  color: yellow;
  margin: 0px;
}
.card-body p {
  margin: 0px;
}
.card-body ul {
  padding-left: 20px;
}
.portflio-item .portfolio-item-content {
  position: absolute;
  content: '';
  right: 0px;
  bottom: 0px;
  opacity: 0;
  transition: all 0.35s ease;
}
.portflio-item:hover .portfolio-item-content {
  opacity: 1;
  bottom: 20px;
  right: 30px;
}
.portflio-item .portfolio-item-content {
  position: absolute;
  content: '';
  right: 0px;
  bottom: 0px;
  opacity: 0;
  transition: all 0.35s ease;
}

.portflio-item:before {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  transition: all 0.35s ease;
  overflow: hidden;
}

.portflio-item:hover:before {
  opacity: 1;
}

.portflio-item:hover .portfolio-item-content {
  opacity: 1;
  bottom: 20px;
  right: 30px;
}

.portflio-item .overlay-item {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80px;
  color: #f75757;
  opacity: 0;
  transition: all 0.35s ease;
}

.portflio-item:hover .overlay-item {
  opacity: 1;
}

.featured-item {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}
.featured-item:hover,
.featured-item.style-1 .featured-icon i,
.featured-item.style-2,
.featured-item.style-6 .featured-icon i {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}

.featured-item.style-2:hover,
.price-table:hover {
  -o-box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
  box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
}

/* ------------------------
    Featured Box
------------------------*/
.featured-item {
  padding: 40px 30px;
  position: relative;
  border-radius: 7px;
  overflow: hidden;
  z-index: 9;
}

.owl-carousel .featured-item {
  margin: 20px;
}

.featured-item .featured-icon {
  display: inline-block;
  position: relative;
}

.featured-desc p {
  margin-bottom: 0;
}

.featured-title h5 {
  margin: 30px 0 20px;
  text-transform: capitalize;
  font-weight: bold;
}

.dark-bg .featured-title h5,
.dark-bg .featured-desc {
  color: #ffffff;
}

.theme-bg .featured-title h5,
.theme-bg .featured-desc,
.theme-bg .featured-icon i {
  color: #ffffff;
}

.grey-bg .featured-desc p {
  color: #1c1d3e;
}

.featured-item.style-1 {
  box-shadow: none;
}
.featured-item .featured-icon i {
  color: #2575fc;
  display: inline-block;
  font-size: 50px;
  line-height: 50px;
}
.featured-item .featured-icon span {
  position: absolute;
  top: 50%;
  left: 50%;
}
.featured-item .featured-icon span::after,
.featured-item .featured-icon span::before {
  border-radius: 50%;
  content: '';
  height: 60px;
  position: absolute;
  width: 60px;
  z-index: -1;
}
.featured-item .featured-icon span::after {
  background: #cdf3f6;
  height: 40px;
  left: -10px;
  top: 5px;
  width: 40px;
}
.featured-item .featured-icon span::before {
  background: #d4f8e6;
  bottom: 0;
  right: -10px;
}
.featured-item::before {
  transform: scale(0);
  background: #005bea;
  background: linear-gradient(180deg, #005bea 0%, #2575fc 80%);
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: -1;
}
.featured-item:hover::before {
  transform: scale(1);
}
.featured-item:after {
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  opacity: 0;
  width: 100%;
  z-index: -1;
  background-size: cover;
}
.featured-item:hover:after {
  opacity: 0.2;
}
.featured-item:hover .featured-icon i,
.featured-item:hover h5,
.featured-item:hover p {
  color: #ffffff;
}
.featured-item.style-1:hover .featured-icon i,
.featured-item.style-2:hover .featured-icon i,
.featured-item.style-6:hover .featured-icon i {
  color: #005bea;
}
.featured-item.style-2:hover h5,
.featured-item.style-6:hover h5 {
  color: #1c1d3e;
}
.featured-item.style-2:hover p,
.featured-item.style-6:hover p {
  color: #5f5f5f;
}
.featured-item.style-2 {
  padding: 60px 30px;
  background: #ffffff;
  overflow: inherit;
}
.featured-item.style-2 .featured-icon i {
  font-size: 80px;
  line-height: 80px;
  z-index: 1;
  position: relative;
}
.featured-item.style-2:hover::before {
  display: none;
}
.featured-item.style-3 {
  background: #ffffff;
}
.featured-item.style-4 {
  background: #ffffff;
  border-radius: 70% 100% 30% 70%;
  overflow: hidden;
  padding: 14px 15px 35px 15px;
  z-index: 9;
}
.featured-item.style-4 .featured-title h5 {
  font-size: 1.2rem;
}
.featured-item.style-5 {
  padding-left: 160px;
  background: #ffffff;
}
.featured-item.style-5 .featured-icon {
  position: absolute;
  top: 40px;
  left: 30px;
}
.featured-item.style-5 h5 {
  margin-top: 0;
}
.featured-item.style-6 {
  padding: 0 0 0 100px;
  box-shadow: none;
  overflow: inherit;
}
.featured-item.style-6 h5 {
  margin-top: 0;
}
.featured-item.style-6::before,
.featured-item.style-6::after {
  display: none;
}
.featured-item.style-6 .featured-icon {
  position: absolute;
  top: 0;
  left: 0;
}
.featured-item.style-6 .featured-icon i {
  background: #ffffff;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 30px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}
.featured-item.style-1 .featured-icon i {
  background: #ffffff;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 30px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}
.featured-item.style-6 .featured-icon i::after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: '';
  top: 0;
  left: 0;
  z-index: 10;
  border: 2px dashed transparent;
}
.featured-item.style-6:hover .featured-icon i {
  background: none;
}
.featured-item.style-6:hover .featured-icon i::after {
  animation: rotateme 9s linear infinite;
  border-color: #2575fc;
}

.featured-item {
  transition: all 0.5s ease-in-out 0s;
}
.featured-item:after,
.featured-item::before {
  transition: all 0.5s ease-in-out 0s;
}

.featured-icon,
.featured-item .featured-icon i,
.price-table {
  transition: all 0.5s ease-in-out 0s;
}

/*--translate-XY--*/
.clear-loader,
.title-effect-2 i,
.featured-item .featured-icon span,
.blink-img,
.hexagon-bg.style-2,
.video-btn-pos,
.video-btn .spinner-eff {
  transform: translateX(-50%) translateY(-50%);
}

.featured-item {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}
.featured-item:hover,
.featured-item.style-1 .featured-icon i,
.featured-item.style-2,
.featured-item.style-6 .featured-icon i {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}

.featured-item.style-2:hover,
.price-table:hover {
  -o-box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
  box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
}

.gutter {
  --bs-gutter-y: 1.5rem !important;
}

.fontweigth400 {
  font-weight: 400;
}
.mt63 {
  margin-top: 0px;
}
.swamiimg4 {
  height: auto;
  width: 160px;
}
.h3Amb {
  font-size: 27px;
  font-weight: 600;
  color: #fefefe;
}
.yenepoyaleft {
  left: -215px !important;
}
.yenepoyaleft:hover {
  left: 0px !important;
}
.bggreay {
  background: #bdbcc4;
}
.Ambfaq-img {
  width: 44% !important;
}
.Ambfaq-sec {
  width: 56% !important;
}

.bds-position {
  position: relative;
  right: 320px;
  top: 294px;
}
@media only screen and (max-width: 400px) {
  .mbbscard1 {
    background-color: #cce1ed;
    min-height: 137px;
  }
}

.custom-card {
  line-height: 2;
}

.Ambblueh3 {
  font-size: 35px !important;
  font-weight: 700;
  font-weight: 700;
}

.ambmain-margin {
  margin-top: auto;
}

.paddingamb-icon {
  padding: 0px 171px;
}

.talkdiv-mbd {
  max-width: 344px;
  margin: auto;
}

.mabbsorangen {
  color: #ff8e08;
  font-size: 27px;
}

.bams-height-college {
  min-height: 168px !important;
}

.bamsimg4 {
  height: 170px;
  width: 170px;
}

.mbbdbtnpadding {
  background-color: #ff8902 !important;
}
.mbbdbtnpadding :hover {
  background-color: #00b4f6 !important;
}

@media only screen and (max-width: 600px) {

  .h1yenepoya {
    margin: 0;
    font-size: 35px;
    font-weight: 700;
    color: #000000;
    letter-spacing: 1px;
  }

  .h99{
    min-height: auto !important;
  }

  .bds-bgimage {
    min-height: 160px;
    padding: 0px !important;
    width: 100%;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }


  .bds-font30 {
    font-size: 23px !important;
    color: white;
    background: #0000003d;
  }

  .bds-add-h2 {
    text-align: center;
    font-weight: 800;
    font-size: 32px !important;
    color: rgb(255, 255, 255);
  }

  .bdsktable-header th {
    font-size: 15px !important;
  }
  .bdsktable-row td {
    padding: 0px !important;
    font-size: 14px !important;
  }

  .min-width-tableheading {
    width: 100px !important;
  }
  .bams-height-college {
    min-height: auto !important;
  }
  .mbbdbtnpadding {
    padding: 3px 10px !important;
    background-color: #ff8902 !important;
  }
  .talkdiv-mbd {
    max-width: 207px;
    margin: auto;
  }
  .paddingamb-icon {
    padding: 0px 16px;
  }
  .ambmain-margin {
    margin-top: 153px;
  }

  .missionimage {
    min-height: 115px !important;
    width: 100%;
    background-position: top !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
  }
  .bamsimage {
    /* background-size: contain !important; */
    background-position: top !important;
  }
  .f3bds-addmission {
    color: #25427c;
    font-weight: 700;
    font-size: 30px;
  }
  .f3yenepoya {
    color: #013d0c;
    font-weight: 700;
    font-size: 25px;
  }

  .Ambfaq-img {
    width: 100% !important;
  }
  .Ambfaq-sec {
    width: 100% !important;
  }

  .btnmbbs {
    width: 200px !important;
    background: #ff8902 !important;
    color: #ffffff;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 13px !important;
    line-height: 33px !important;
    border: 0px !important;
    padding: 2px 1px !important;
  }

  .h1amb .span {
    color: #003d7a;
    display: block;
    font-size: 27px;
  }
  .h3Amb {
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
  }
  .divfont {
    font-size: 18px !important;
    font-weight: 600;
  }

  .swamiimg4 {
    height: auto;
    width: 130px;
  }

  .featured-item.style-4 .featured-title h5 {
    margin: 0px !important;
  }
  .mt63 {
    margin-top: 63px;
  }
  .featured-item .card-img-top {
    height: 100px !important;
  }
  .bdsscope-add .d-flex img {
    height: auto;
    width: 130px;
  }
  .btn-bds-add {
    font-size: 17px !important;
  }
  .bdscollegeimage img {
    height: 156px;
    width: 353px;
  }

  .bamscolllege .h100 {
    min-height: auto !important;
  }

  .bds-table {
    border: 1px solid black;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
  .bdssimpletable {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
  .customberder-mbbs-admission ul {
    min-height: auto;
    margin-bottom: 10px;
    line-height: 2;
    padding-bottom: 24px;
  }
}

.slide-in {
  animation: slideIn 1s ease-out;
  animation-delay: 2s;
}
.slide-in2 {
  animation: slideIn2 1s ease-out;
  animation-delay: 2s;
}

@keyframes slideIn2 {
  from {
    transform: translateX(+100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.Ambimgfooter {
  height: auto;
  width: 135px;
}

.btnmbbs {
  width: 133px;
  background: #ff8902 !important;
  color: #ffffff;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 37px;
  border: 0px;
  padding: 0px 0px;
}

.textMds{
  font-size: 30px !important;
    font-weight: 700 !important;
    color: black !important;
  line-height: 1.5 ;
}

.fh3MDS-dental {
  color: #750a94;
  font-weight: 700;
  font-size: 35px !important;
}

.fh3MDS-what {
  color: #750a94;
  font-weight: 700;
  font-size: 35px !important;
}

.admissionbgs {
  color: #9bff3a;
  font-size: 22px !important;
  font-weight: 600;
  margin: 31px 0;
}

.icpadding{
  padding-left: 70px !important;
}


.card-bg {
  background-color: #049292;
  color: white !important;
}

.card-bg:hover {
  background-color: #065b5b;
}

.mds .card {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.CardMDS {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.gapMds {
  grid-row-gap: 21px;
  row-gap: 21px;
}

.btnmds {
  background: #07385a !important;
  font-size: 14px !important;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  color: #ffffff;
  border: 0px;
  padding: 0px 11px;
}

.font18MDS {
  font-size: 19px !important;
  font-weight: 600;
}

.card-background {
  background-color: rgb(255, 255, 255) !important;
}

.CardMDS:hover {
  background: linear-gradient(86deg, rgb(0 0 0 / 39%), rgb(0 0 0 / 44%)),
    url(/static/media/BgProsthodontics.f22dd527.png);
  background-size: cover !important;
  background-position: center !important;
  color: white !important;
}

.CardMDS1:hover {
  background: linear-gradient(86deg, rgb(0 0 0 / 39%), rgb(0 0 0 / 44%)),
    url(/static/media/Pathologybg.9ab648e6.png);
  /* background-image: url(); */
  background-size: cover !important;
  background-position: center !important;
  color: white !important;
}

.CardMDS2:hover {
  background: linear-gradient(86deg, rgb(0 0 0 / 39%), rgb(0 0 0 / 44%)),
    url(/static/media/Maxillofacialbg.ef9d5ccb.png);
  /* background-image: url(../../../../assets/MDS/Maxillofacialbg.png); */
  background-size: cover !important;
  background-position: center !important;
  color: white !important;
}

.CardMDS3:hover {
  background: linear-gradient(86deg, rgb(0 0 0 / 39%), rgb(0 0 0 / 44%)),
    url(/static/media/Orthodonticsbg.3248e566.png);
  /* background-image: url(../../../../assets/MDS/Orthodonticsbg.png); */
  background-size: cover !important;
  background-position: center !important;
  color: white !important;
}

.CardMDS4:hover {
  background: linear-gradient(86deg, rgb(0 0 0 / 39%), rgb(0 0 0 / 44%)),
    url(/static/media/Periodontologybg.f97a1115.png);
  /* background-image: url(../../../../assets/MDS/Periodontologybg.png); */
  background-size: cover !important;
  background-position: center !important;
  color: white !important;
}

.CardMDS5:hover {
  background: linear-gradient(86deg, rgb(0 0 0 / 39%), rgb(0 0 0 / 44%)),
    url(/static/media/Healthbg.87fe61b4.png);
  /* background-image: url(../../../../assets/MDS/Healthbg.png); */
  background-size: cover !important;
  background-position: center !important;
  color: white !important;
}

.CardMDS6:hover {
  background: linear-gradient(86deg, rgb(0 0 0 / 39%), rgb(0 0 0 / 44%)),
    url(/static/media/Radiologybg.32c261d0.png);
  /* background-image: url(../../../../assets/MDS/Radiologybg.png); */
  background-size: cover !important;
  background-position: center !important;
  color: white !important;
}

.CardMDS7:hover {
  background: linear-gradient(86deg, rgb(0 0 0 / 39%), rgb(0 0 0 / 44%)),
    url(/static/media/Endodontics.df118851.png);
  /* background-image: url(../../../../assets/MDS/Endodontics.png); */
  background-size: cover !important;
  background-position: center !important;
  color: white !important;
}

.CardMDS8:hover {
  background: linear-gradient(86deg, rgb(0 0 0 / 39%), rgb(0 0 0 / 44%)),
    url(/static/media/PreventiveDentistry.f21a012e.png);
  /* background-image: url(); */
  background-size: cover !important;
  background-position: center !important;
  color: white !important;
  object-fit: contain !important;
}

.Card-width {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.image-container {
  width: 300px;
  height: 200px;
  background-color: #f0f0f0 !important;
  transition: background-image 0.3s ease-in-out;
}

.mdsfont {
  background-color: #68007a;
  color: white;
  display: inline-block;
  border-radius: 10px;
  font-size: 25px !important;
  font-weight: 600;
}

.btnmdscolour {
  background: #d36329 !important;
}

.iconmds {
  width: 100% !important;
}

.equal-height-row {
  display: flex;
}

.equal-height-row .col-12 {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.mdsimage {
  width: 982px;
  height: 270px;
}

.btnmds {
  background: #07385a !important;
  font-size: 14px !important;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  color: #ffffff;
  border: 0px;
  padding: 0px 11px;
}

.fh3MDS {
  color: #750a94;
  font-weight: 700;
  font-size: 35px;
}

.olcards,
.olcards * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.olcards {
  list-style: none;
  counter-reset: cardCount;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  --cardsGap: 1rem;
  grid-gap: var(--cardsGap);
  gap: var(--cardsGap);
  padding-bottom: var(--cardsGap);
  justify-content: center !important;
}

.olcards li {
  counter-increment: cardCount;
  display: flex;
  color: white;
  --labelOffset: 0.5rem;
  --arrowClipSize: 1.5rem;
  margin-top: var(--labelOffset);
}

.olcards li::before {
  content: counter(cardCount, decimal-leading-zero);
  background: white;
  color: var(--cardColor);
  font-size: 2em;
  font-weight: 700;
  transform: translateY(calc(-1 * var(--labelOffset)));
  margin-right: calc(-1 * var(--labelOffset));
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 0.5em;
}

.olcards li .content {
  background-color: var(--cardColor);
  --inlinePadding: 1em;
  --boxPadding: 0.5em;
  /* display: grid; */
  padding: var(--boxPadding) calc(var(--inlinePadding) + var(--arrowClipSize))
    var(--boxPadding) calc(var(--inlinePadding) + var(--labelOffset));
  grid-template-areas:
    'icon title'
    'icon text';
  grid-gap: 0.25em 1em;
  gap: 0.25em 1em;
  -webkit-clip-path: polygon(
    0 0,
    calc(100% - var(--arrowClipSize)) 0,
    100% 50%,
    calc(100% - var(--arrowClipSize)) 100%,
    calc(100% - var(--arrowClipSize)) calc(100% + var(--cardsGap)),
    0 calc(100% + var(--cardsGap))
  );
          clip-path: polygon(
    0 0,
    calc(100% - var(--arrowClipSize)) 0,
    100% 50%,
    calc(100% - var(--arrowClipSize)) 100%,
    calc(100% - var(--arrowClipSize)) calc(100% + var(--cardsGap)),
    0 calc(100% + var(--cardsGap))
  );
  position: relative;
}

.olcards li .content::before {
  content: '';
  position: absolute;
  width: var(--labelOffset);
  height: var(--labelOffset);
  background: var(--cardColor);
  left: 0;
  bottom: 0;
  -webkit-clip-path: polygon(0 0, 100% 0, 0 100%);
          clip-path: polygon(0 0, 100% 0, 0 100%);
  filter: brightness(0.75);
}

.olcards li .content::after {
  content: '';
  position: absolute;
  height: var(--cardsGap);
  width: var(--cardsGap);
  background: linear-gradient(to right, rgba(0, 0, 0, 0.25), transparent 50%);
  left: 0;
  top: 100%;
}

.olcards li .icon {
  grid-area: icon;
  align-self: center;
  font-size: 2em;
}

.olcards li .content .title {
  grid-area: title;
  font-size: 23px;
  font-weight: 700;
  color: white;
  font-family: "Poppins", sans-serif;
}

.olcards li .content .text {
  grid-area: text;
}

.card-font {
  padding: 5px !important;
  text-align: center !important;
}

.image-container:hover {
  background-size: cover;
  background-position: center;
}

.fontmds {
  color: white;
  font-size: 18px !important;
}

.bgmds {
  background-size: cover !important;
  background-position: center !important;
}

.bgmds {
  background-color: #003d7a;
}

.mds-card1 ::after {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 143px;
  width: 356px;
  background: red !important;
  transition: background-color 0.3s ease;
  /* Smooth transition on hover */
}

.mds-card1:hover {
  background: url(/static/media/BgProsthodontics.f22dd527.png) !important;
  /* Revert to the initial background color on hover */
}

.mds-card ::after {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 143px;
  width: 356px;
  background: red !important;
  transition: background-color 0.3s ease;
  /* Smooth transition on hover */
}

.mds-card :hover {
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll !important;
  background: initial !important;
  /* Revert to the initial background color on hover */
}

.mdsimg4 {
  height: 115px;
  width: 290px;
}

.bdsscope .d-flex p {
  font-size: 23px !important;
  font-weight: 700;
}

.bdscollegeimage img {
  height: 173px;
  width: 251px;
}

.border-none {
  border: none !important;
}

.bdsscope .scope p {
  /* font-size: 23px !important; */
  /* font-weight: 700; */
  color: #003d7a;
}

.bdsscope .scope img {
  height: 170px;
  width: 170px;
}

.btnbdscolour {
  background: #07385a !important;
}

.btnbds {
  background: #07385a !important;
  font-size: 21px !important;
}

.btnbds:hover,
.btnbdscolour:hover,
.btnbdscolour:focus,
button:hover,
.btnbds:focus {
  background: #00a040 !important;
  color: #ffffff;
}

.bdslandgingcolleges h5 {
  min-height: 100px !important;
}

.mds-customborder1 table tr:nth-child(odd) {
  /* border: 2px solid black; */
  text-align: center;
  overflow: auto;
  background: #d6fff8;
}

.mds-customborder1 table tr:nth-child(even) {
  color: #007480;
}

.mds-customborder1 th {
  border-right: 4px solid white;
}

.mds-customborder1 {
  border: 2px solid #f5e4e4;
  max-width: 700px !important;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.mds-customborder1 .documents li {
  font-size: 18px;
  font-weight: 600;
}

.mds-customborder1 table {
  /* max-width: 100%; */
  max-width: 700px;

  overflow-y: scroll;
}

.mds-customborder1 tr th {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.mds-customborder1 tr td {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

/* .mds-customborder1 td{
  border: 2px solid black;
  text-align: center; 
  overflow: auto
  font-weight: 600;
} */
.customborder2 ul {
  min-height: 367px;
  line-height: 2;
}

.customborder2 {
  overflow: auto;
}

.customborder2 table {
  font-size: 18px;
}

.customborder2 th {
  border: 2px solid black;
  text-align: center;
  overflow: auto;
}

.customborder2 td {
  border: 2px solid black;
  text-align: center;
  overflow: auto;
  /* font-weight: 600; */
}

.top80 {
  top: 70% !important;
}

.top90 {
  top: 80% !important;
}

.top60 {
  top: 55% !important;
}

.top70 {
  top: 65% !important;
}

.bgfooter {
  background: #34345a;
}

.bgfooter a {
  background: #34345a;
}

.bdsh2why {
  text-align: center;
  font-weight: 800;
  font-size: 40px;
  color: rgb(255, 255, 255);
  /* text-shadow: #FC0 1px 0 10px; */
}

.img200 {
  height: auto;
  width: 130px !important;
}

.coltext {
  text-align: center !important;
  font-weight: 600;
  color: black;
}

.landgingcolleges img {
  float: none !important;
  height: 155px !important;
  object-fit: cover;
}

.landgingcolleges h5 {
  min-height: 72px;
}

.imgcontain {
  object-fit: contain !important;
}

.bgblack {
  background: rgb(0 0 0 / 37%);
  border-radius: 11px;
}

.ll {
  letter-spacing: -1px;
}

p {
  font-size: 18px !important;
}

.Pfont23 {
  font-size: 23px !important;
  color: #3d3939 !important;
}

.bgcc {
  background-color: #1d415a !important;
}

.dd {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}

.customborder2 .card-header {
  background: darkcyan;
}

.customborder2 h4 {
  color: white;
  margin: 0px !important;
  font-size: 27px !important;
}

.imgh {
  min-height: 400px !important;
}

.bdsimgh {
  min-height: 300px !important;
}

.bgimage {
  min-height: 659px;
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.hov:hover {
  background-color: white !important;
}

.h1MDS {
  color: #ffffff;
  margin: 0;
  font-size: 40px;
  font-weight: 700;

  letter-spacing: 1px;
}

.d-block {
  display: block;
}

.bdscyellow {
  color: #36c709;
  font-size: 27px;
}

.p {
  color: #ffffff;
  font-size: 22px;
  margin: 31px 0px;
  font-weight: 600;
}

.p-MDS {
  color: #ffffff;
  font-size: 22px;
  margin: 31px 0px;
  font-weight: 500;
}

.form-box {
  max-width: 425px;
  margin: auto;
  padding: 25px;
  background: #00000045;
  border-radius: 11px;
}

.font-weight-bold {
  font-weight: 500;
}

.h4blue {
  color: rgb(0, 151, 227);
  font-weight: 600;
  font-size: 27px;
}

.textmbbs li {
  font-size: 17px !important;
  color: #000;
  line-height: 2;
}

.textmbbs {
  font-size: 18px;
  color: black;
  line-height: 2;
}

.bluecolourp {
  color: rgb(54 38 96);
  font-weight: 600;
  font-size: 20px !important;
}

.fh3BDS {
  color: #003d7a;
  font-weight: 700;
  font-size: 35px;
}

.fontweght700 {
  font-weight: 700;
}

.video {
  height: auto;
  width: 100%;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  width: 100%;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.h200 {
  height: 208px;
}

.clouryello {
  color: yellow;
  margin: 0px;
}

.card-body p {
  margin: 0px;
}

.card-body ul {
  padding-left: 20px;
}

.portflio-item .portfolio-item-content {
  position: absolute;
  content: '';
  right: 0px;
  bottom: 0px;
  opacity: 0;
  transition: all 0.35s ease;
}

.portflio-item:hover .portfolio-item-content {
  opacity: 1;
  bottom: 20px;
  right: 30px;
}

.portflio-item .portfolio-item-content {
  position: absolute;
  content: '';
  right: 0px;
  bottom: 0px;
  opacity: 0;
  transition: all 0.35s ease;
}

.portflio-item:before {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  transition: all 0.35s ease;
  overflow: hidden;
}

.portflio-item:hover:before {
  opacity: 1;
}

.portflio-item:hover .portfolio-item-content {
  opacity: 1;
  bottom: 20px;
  right: 30px;
}

.portflio-item .overlay-item {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80px;
  color: #f75757;
  opacity: 0;
  transition: all 0.35s ease;
}

.portflio-item:hover .overlay-item {
  opacity: 1;
}

.featured-item {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}

.featured-item:hover,
.featured-item.style-1 .featured-icon i,
.featured-item.style-2,
.featured-item.style-6 .featured-icon i {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}

.featured-item.style-2:hover,
.price-table:hover {
  -o-box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
  box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
}

/* ------------------------
    Featured Box
------------------------*/
.featured-item {
  padding: 40px 30px;
  position: relative;
  border-radius: 7px;
  overflow: hidden;
  z-index: 9;
}

.owl-carousel .featured-item {
  margin: 20px;
}

.featured-item .featured-icon {
  display: inline-block;
  position: relative;
}

.featured-desc p {
  margin-bottom: 0;
}

.featured-title h5 {
  margin: 30px 0 20px;
  text-transform: capitalize;
  font-weight: bold;
}

.dark-bg .featured-title h5,
.dark-bg .featured-desc {
  color: #ffffff;
}

.theme-bg .featured-title h5,
.theme-bg .featured-desc,
.theme-bg .featured-icon i {
  color: #ffffff;
}

.grey-bg .featured-desc p {
  color: #1c1d3e;
}

.featured-item.style-1 {
  box-shadow: none;
}

.featured-item .featured-icon i {
  color: #2575fc;
  display: inline-block;
  font-size: 50px;
  line-height: 50px;
}

.featured-item .featured-icon span {
  position: absolute;
  top: 50%;
  left: 50%;
}

.featured-item .featured-icon span::after,
.featured-item .featured-icon span::before {
  border-radius: 50%;
  content: '';
  height: 60px;
  position: absolute;
  width: 60px;
  z-index: -1;
}

.featured-item .featured-icon span::after {
  background: #cdf3f6;
  height: 40px;
  left: -10px;
  top: 5px;
  width: 40px;
}

.featured-item .featured-icon span::before {
  background: #d4f8e6;
  bottom: 0;
  right: -10px;
}

.featured-item::before {
  transform: scale(0);
  background: #005bea;
  background: linear-gradient(180deg, #005bea 0%, #2575fc 80%);
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: -1;
}

.featured-item:hover::before {
  transform: scale(1);
}

.featured-item:after {
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  opacity: 0;
  width: 100%;
  z-index: -1;
  background-size: cover;
}

.featured-item:hover:after {
  opacity: 0.2;
}

.featured-item:hover .featured-icon i,
.featured-item:hover h5,
.featured-item:hover p {
  color: #ffffff;
}

.featured-item.style-1:hover .featured-icon i,
.featured-item.style-2:hover .featured-icon i,
.featured-item.style-6:hover .featured-icon i {
  color: #005bea;
}

.featured-item.style-2:hover h5,
.featured-item.style-6:hover h5 {
  color: #1c1d3e;
}

.featured-item.style-2:hover p,
.featured-item.style-6:hover p {
  color: #5f5f5f;
}

.featured-item.style-2 {
  padding: 60px 30px;
  background: #ffffff;
  overflow: inherit;
}

.featured-item.style-2 .featured-icon i {
  font-size: 80px;
  line-height: 80px;
  z-index: 1;
  position: relative;
}

.featured-item.style-2:hover::before {
  display: none;
}

.featured-item.style-3 {
  background: #ffffff;
}

.featured-item.style-4 {
  background: #ffffff;
  border-radius: 70% 100% 30% 70%;
  overflow: hidden;
  padding: 14px 15px 35px 15px;
  z-index: 9;
}

.featured-item.style-4 .featured-title h5 {
  font-size: 1.2rem;
}

.featured-item.style-5 {
  padding-left: 160px;
  background: #ffffff;
}

.featured-item.style-5 .featured-icon {
  position: absolute;
  top: 40px;
  left: 30px;
}

.featured-item.style-5 h5 {
  margin-top: 0;
}

.featured-item.style-6 {
  padding: 0 0 0 100px;
  box-shadow: none;
  overflow: inherit;
}

.featured-item.style-6 h5 {
  margin-top: 0;
}

.featured-item.style-6::before,
.featured-item.style-6::after {
  display: none;
}

.featured-item.style-6 .featured-icon {
  position: absolute;
  top: 0;
  left: 0;
}

.featured-item.style-6 .featured-icon i {
  background: #ffffff;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 30px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.featured-item.style-1 .featured-icon i {
  background: #ffffff;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 30px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.featured-item.style-6 .featured-icon i::after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: '';
  top: 0;
  left: 0;
  z-index: 10;
  border: 2px dashed transparent;
}

.featured-item.style-6:hover .featured-icon i {
  background: none;
}

.featured-item.style-6:hover .featured-icon i::after {
  animation: rotateme 9s linear infinite;
  border-color: #2575fc;
}

.featured-item {
  transition: all 0.5s ease-in-out 0s;
}

.featured-item:after,
.featured-item::before {
  transition: all 0.5s ease-in-out 0s;
}

.featured-icon,
.featured-item .featured-icon i,
.price-table {
  transition: all 0.5s ease-in-out 0s;
}

/*--translate-XY--*/
.clear-loader,
.title-effect-2 i,
.featured-item .featured-icon span,
.blink-img,
.hexagon-bg.style-2,
.video-btn-pos,
.video-btn .spinner-eff {
  transform: translateX(-50%) translateY(-50%);
}

.featured-item {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}

.featured-item:hover,
.featured-item.style-1 .featured-icon i,
.featured-item.style-2,
.featured-item.style-6 .featured-icon i {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}

.featured-item.style-2:hover,
.price-table:hover {
  -o-box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
  box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
}

.gutter {
  --bs-gutter-y: 1.5rem !important;
}

.fontweigth400 {
  font-weight: 400;
}

.mt63 {
  margin-top: 0px;
}

@media only screen and (max-width: 600px) {
  .fh3MDS-dental {
    color: #750a94;
    font-weight: 700;
    font-size: 27px !important;
  }
  
  .fh3MDS-what {
    color: #750a94;
    font-weight: 700;
    font-size: 25px !important;
  }
  .icpadding{
    padding-left: 70px !important;
  }
  

 .store-wrapper-mds{
  padding-left: 10px !important;
 }
  .textMds {
    font-size: 21px !important;
    font-weight: 700 !important;
    color: black !important;
    line-height: 1.5;
}

  .mds-customborder1 {
    max-width: 100%;
  }
  .mds-customborder1 table {
    max-width: 100%;
    overflow-y: scroll;
  }

  .fh3MDS {
    color: #750a94;
    font-weight: 700;
    font-size: 26px !important;
  }


  

  .mdsfont {
    background-color: #68007a;
    color: white;
    display: inline-block;
    border-radius: 10px;
    font-size: 19px !important;
    font-weight: 600;
  }

  .olcards li .content .title {
    grid-area: title;
    font-size: 17px;
    font-weight: 700;
    color: white;
  }

  .h1MDS .span {
    color: #003d7a;
    display: block;
    font-size: 27px;
  }

  .bdsimg4 {
    height: auto;
    width: 180px;
  }

  .featured-item.style-4 .featured-title h5 {
    margin: 0px !important;
  }

  .mt63 {
    margin-top: 63px;
  }

  .featured-item .card-img-top {
    height: 100px !important;
  }

  .bdsscope .d-flex img {
    height: auto;
    width: 149px;
  }

  .btnbds {
    font-size: 17px !important;
  }

  .bdscollegeimage img {
    height: 156px;
    width: 353px;
  }

  .bdslandgingcolleges h5 {
    min-height: auto !important;
  }

  .mds-customborder1 {
    border: 1px solid black;
    max-width: 700px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }

  .customborder2 ul {
    min-height: auto;
    margin-bottom: 10px;
    line-height: 2;
  }
}

.slide-in {
  animation: slideIn 1s ease-out;
  animation-delay: 2s;
}

.slide-in2 {
  animation: slideIn2 1s ease-out;
  animation-delay: 2s;
}

@keyframes slideIn2 {
  from {
    transform: translateX(+100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

.imgfooter {
  height: auto;
  width: 232px;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.blink-text-christ {
  animation: blink 2s infinite;
  /* Adjust the duration (2s) as needed */
}

.center {
  display: block;
  margin-left: 55px;
  margin-right: auto;
  width: 50%;
}
.padding-top-homo{
  padding-top: 0px !important;
}
.padding-top-homo .inside-homo{
  padding-top: 100px !important;
}

.notificationCard li i{
  color: green !important;
}
.notificationCard {
  background:rgb(63 135 169 / 36%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.123);
  border-radius: 20px;
  height: 100%;
}

.bellIcon {
  width: 50px;
  margin: 20px 0px;
}

.bellIcon path {
  fill: rgb(168, 131, 255);
}

.notificationHeading {
  color: black;
  font-weight: 600;
  font-size: 0.8em;
}

.notificationPara {
  color: rgb(133, 133, 133);
  font-size: 0.6em;
  font-weight: 600;
  text-align: center;
}

.buttonContainer {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
  padding-top: 11px;
    padding-bottom: 23px
;
}

.AllowBtn {
  width: 120px;
  height: 25px;
  background-color: rgb(168, 131, 255);
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 0.7em;
  font-weight: 600;
  cursor: pointer;
}

.NotnowBtn {
  width: 120px;
  height: 25px;
  color: rgb(168, 131, 255);
  border: none;
  background-color: transparent;
  font-weight: 600;
  font-size: 0.7em;
  cursor: pointer;
  border-radius: 20px;
}

.NotnowBtn:hover {
  background-color: rgb(239, 227, 255);
}

.AllowBtn:hover {
  background-color: rgb(153, 110, 255);
}





.BHMS {
  font-size: 19px;
    font-weight: 700;
    color: black;
    text-align: left;
   
}


.notificationCard ul {
list-style-type: none;
min-height: 200px !important;
}







.Homoeopathicscopecustomborder table tr:nth-child(odd) {
  /* border: 2px solid black; */
  text-align: center;
  overflow: auto;
  background: whitesmoke;
  background: #5c5479;
  color: white;
  /* background: #81c0c0; */
}
.Homoeopathicscopecustomborder table tr:nth-child(even) p {
  color: black;
  background: #d3dcec;
}

.Homoeopathicscopecustomborder table tr:nth-child(even) p {
  color: black;
  font-size: 20px !important;
  font-weight: 600 !important;
  text-align: center !important;
}

.Homoeopathicscopecustomborder th {
  border-right: 4px solid white;
}

.Homoeopathicscopecustomborder {
  border: 2px solid #f1eaea;

  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
.Homoeopathicscopecustomborder .documents li {
  font-size: 18px;
  font-weight: 600;
}
.Homoeopathicscopecustomborder table {
  max-width: 100%;
  overflow-y: scroll;
}
.Homoeopathicscopecustomborder tr th {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.Homoeopathicscopecustomborder tr td {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}



.homoeopathic-btn {
  background: #ff8902 !important;
}
.homoeopathic-btn-blue {
  background: #0f02ff !important;
}

.homo-backgroundcard .homoeopathic-btn{
  padding: 3px 14px !important; 


}
.f3homoeopathic {
    color: black;
    font-weight: 700;
    font-size: 29px;
}

.f4homoeopathic{
  color: #e9df13;
  font-weight: 700;
  font-size: 29px;
}



.cardbg li{
  font-size: 17px !important;
}
.homo-backgroundcard {
  background-color: rgb(3 18 61 / 78%);
  color: white !important;
}
.f3Yenepoya-addmission {
  color: #8c2f2f;
  font-weight: 700;
  font-size: 35px;
}
.Iconhomopethic{

  font-size: 20px !important;
    font-weight: 800 !important;
    color: #258c8e !important;
}
.Iconimg{

  width: 30px;
    height: auto;
}


.cardbg{

  color: white;
    background-color: #48486bf0;
}
.buttonfonthomo{

  padding: 1px 6px !important;
  margin-top: 12px !important;
  font-size: 17px !important;
  color: white;
}

.cardhomoeopathic{

  color: rgb(235, 221, 221);
  text-align: center;
  font-size: 16px!important;
  font-weight: 500;

}


.heading-yenepoya {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  color: #000000;
  letter-spacing: 1px;
}
.h1-yenepoya {
  margin: 0;
  font-size: 31px;
  font-weight: 700;
  color: #ffcc00;
  letter-spacing: 1px;
}
.navbar-logo{
  width:165px !important; 
  height:57px !important;

}


.custom-tabs .nav-link {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 0;
  color: #353637;
  border-radius: 12px;
  cursor: pointer;
}
.topTrendingCourses .owl-item{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.font-weight-bold1 {
  font-weight: 600;
}
.f25yene{
  color: #dc8835;
    font-size: 28px !important;
}


.yenepoyacards {

  border: 0.0625rem solid #d6a800;
  background: #b9c0ff17;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.yenepoyacards p{

  font-size: 18px !important;
}
.yenepoyacards .card-title{

  margin-bottom: 0px !important;
}
.yenepoyacards h5{

  font-size: 25px !important;
    color: #5229a7;
}

.h99{
  min-height: 99px !important;
}
.yenepoyacards ul{

  list-style: none;
}
.card-image-yenepoya{
  height: 56px;
  width: auto;
}
.card-icon-yenepoya{
  height: 40px;
  width: auto;
}
.custom-tabs .nav-link.active {
  background-color: #2d4b13;
    color: #fff;
    border: 2px solid #80d484;
}
  /* background-color: #007bff;
  color: #fff;
  border: 1px solid #007bff;
} */

.custom-tabs .nav-link:hover {
  background-color: #e9ecef;
  color: #212529;
}

.custom-tabs .tab-content {
  border: 1px solid #dee2e6;
  border-top: none;
  padding: 20px;
}

.custom-tabs .tab-pane {
  display: none;
}

.custom-tabs .tab-pane.active {
  display: block;
}

.bdsscope-add .d-flex p {
  font-size: 20px !important;
  font-weight: 600;
}
.countercards-yenepoya div{
  background-color: #222E3C;
  border-style: solid;
  border-width: 4px 0 0 0;
  border-color: #DB312B;
  transition: background 0.3s,border 0.3s,border-radius 0.3s,box-shadow 0.3s;
  margin: 0px 14px 0px 20px;
  --e-column-margin-right: 20px;
  --e-column-margin-left: 20px;
  padding: 0px 20px -1px 20px;
  text-align: center;
  font-size: 39px;
  font-weight: 600;
}
.modal-90w {
  max-width: none!important;
  width: 90%;
}
.font50 {
  font-size: 50px !important;
  color: #ffc600 !important;
}
.modal-90w .modal-content{
  background-color: #2f1a45 !important;
  color: white !important;
}
.bds-font30 {
  font-size: 30px !important;
  color: white;
  background: #0000003d;
}

.bds-bgimage {
  min-height: 659px;
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.bds-add-h2 {
  text-align: center;
  font-weight: 800;
  font-size: 40px;
  color: rgb(255, 255, 255);
}

.min-width-tableheading {
  width: 240px !important;
}

.bdsktable-row td:first-child {
  font-weight: 700 !important;
}

.bdsktable-header th {
  font-size: 17px !important;
}

.bdsktable-header {
  background-color: #95a5a6;
  /* font-size: 14px; */
  /* text-transform: uppercase; */
}
.bdsktable-row {
  background-color: #ffffff;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
}
.bdsktable-row td {
  padding: 0px !important;
  font-size: 16px !important;
}

.bdscollegeimage img {
  height: 173px;
  width: 251px;
}

.smallfot-amb {
  font-weight: 600 !important;
  font-size: 25px !important;
}

.border-none {
  border: none !important;
}
.bdsscope-add .scope p {
  /* font-size: 23px !important; */
  /* font-weight: 700; */
  color: #751b1b;
}
.bdsscope-add .scope span {
  font-size: 16px;
  font-weight: 100;
  color: black;
  display: block;
}
.bdsscope-add .scope img {
  height: 100px;
  width: 100px;
}
.btn-bds-addcolour {
  background: #e47c00 !important;
}
.btn-bds-add {
  background: #e47c00 !important;
  font-size: 21px !important;
}
.btn-bds-add:hover,
.btn-bds-addcolour:hover,
.btn-bds-addcolour:focus,
button:hover,
.btn-bds-add:focus {
  background: #00a040 !important;
  color: #ffffff;
}
.bamscolllege .h100 {
  min-height: 100px !important;
}
.bamsfont25 {
  font-size: 25px;
  color: black !important;
}
.bdssimpletable {
  text-align: center;
  overflow: auto;
}

/* .bdssimpletable tr th {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
} */
.bdssimpletable tr td {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.bdssimpletable table {
  max-width: 100%;
  overflow-y: scroll;
}

.bds-table table tr:nth-child(odd) {
  /* border: 2px solid black; */
  text-align: center;
  overflow: auto;
  background: #e9f9ff;
  /* background: #e4f6e2; */
  /* background: #81c0c0; */
}
.bds-table table tr:nth-child(even) {
  color: #3683a0;
}
.bds-table th {
  border-right: 4px solid white;
}

.bds-table {
  border: 2px solid #f5e4e4;

  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
.bds-table.documents li {
  font-size: 18px;
  font-weight: 600;
}
.bds-table table {
  max-width: 100%;
  overflow-y: scroll;
}
.bds-table tr th {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.bds-table tr td {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
/* .bds-tabletd{
  border: 2px solid black;
  text-align: center; 
  overflow: auto;
  font-weight: 600;
} */
.customberder-mbbs-admission ul {
  min-height: 190px;
  line-height: 2;
}
.customberder-mbbs-admission {
  overflow: auto;
}
.customberder-mbbs-admission table {
  font-size: 18px;
}
.customberder-mbbs-admission th {
  border: 2px solid black;
  text-align: center;
  overflow: auto;
}
.customberder-mbbs-admission td {
  border: 2px solid black;
  text-align: center;
  overflow: auto;
  /* font-weight: 600; */
}
.top80 {
  top: 70% !important;
}
.top90 {
  top: 80% !important;
}
.top60 {
  top: 55% !important;
}
.top70 {
  top: 65% !important;
}
.bgfooter {
  background: #34345a;
}
.bgfooter a {
  background: #34345a;
}
.Ambh2 {
  text-align: center;
  font-weight: 800;
  font-size: 35px;
  color: #148580;
  /* text-shadow: #FC0 1px 0 10px; */
}
.Ambh2 .fontw400 {
  font-weight: 400;
}

.customboxes {
  background: white !important;
  padding: 16px 0px !important;
}
.customboxes .customboxes div {
  padding: 12px 14px !important;
  background: #2d90c8 !important;
}
.mbbscard {
  background-color: rgb(0 58 142);
  color: white !important;
}
.mbbscard1 {
  background-color: #cce1ed;
  height: auto !important;
}

.img200 {
  height: auto;
  width: 130px !important;
}
.coltext {
  text-align: center !important;
  font-weight: 600;
  color: black;
}

.landgingcolleges img {
  float: none !important;
  height: 155px !important;
  object-fit: cover;
}
.landgingcolleges h5 {
  min-height: 72px;
}

.imgcontain {
  object-fit: contain !important;
}

.bgblackyenepoya {
  /* background: rgb(109 91 91 / 53%); */
  background: #8b9bb169;
  border-radius: 11px;
}
.first-bds {
  font-size: 29px !important;
  font-weight: 600;
  color: #fff900;
  line-height: 1.2;
}
.divfont {
  font-size: 27px;
  font-weight: 600;
}

.ll {
  letter-spacing: -1px;
}
p {
  font-size: 18px !important;
}
.Pfont23 {
  font-size: 23px !important;
  color: #3d3939 !important;
}

.bgmbbs {
  background-color: rgb(18, 34, 55) !important;
}

.dd {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}

.ddAmb {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: rgb(8, 8, 8);
}
.customberder-mbbs-admission .card-header {
  background: #268406;
}
.customberder-mbbs-admission h4 {
  color: white;
  margin: 0px !important;
  font-size: 27px !important;
}

.imgh {
  min-height: 400px !important;
}
.bdsimgh {
  min-height: 300px !important;
}
.bgimage {
  min-height: 659px;
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.missionimage {
  min-height: 659px;
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}

.bamsimage-homo {
  min-height: 912px;
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: top !important;
}

.hov:hover {
  background-color: white !important;
}
.h1yenepoya {
  margin: 0;
  font-size: 33px;
  font-weight: 700;
  color: #000000;
  letter-spacing: 1px;
}
.h1amb .span {
  color: #003d7a;
  display: block;
  font-size: 32px;
}
.d-block {
  display: block;
}
.Ambblue {
  color: #2277af;
  font-size: 18px;
}
.p {
  color: #ffffff;
  font-size: 22px;
  margin: 31px 0px;
  font-weight: 600;
}
.form-boxamb {
  max-width: 425px;
  margin: auto;
  padding: 25px;
  background: #00000069;
  border-radius: 11px;
}
.font-weight-bold {
  font-weight: 500;
}
.h4blue {
  color: rgb(0, 151, 227);
  font-weight: 600;
  font-size: 27px;
}
.textmbbs li {
  font-size: 18px;
  color: black;
  line-height: 2;
}
.textmbbs {
  font-size: 18px;
  color: black;
  line-height: 2;
}
.textAmb {
  font-size: 16px;
  color: black;
}
.bodrdertext {
  border: 3px solid grey;
}
.bluecolourp {
  color: rgb(54 38 96);
  font-weight: 600;
  font-size: 20px !important;
}
.f3bds-addmission {
  color: #25427c;
  font-weight: 700;
  font-size: 35px;
}
.f3yenepoya {
  color: #013d0c;
  font-weight: 700;
  font-size: 30px;
}
.fontweght700 {
  font-weight: 700;
}
.video {
  height: auto;
  width: 100%;
}
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  width: 100%;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.h150 {
  height: 150px;
}
.clouryello {
  color: yellow;
  margin: 0px;
}
.card-body p {
  margin: 0px;
}
.card-body ul {
  padding-left: 20px;
}
.portflio-item .portfolio-item-content {
  position: absolute;
  content: '';
  right: 0px;
  bottom: 0px;
  opacity: 0;
  transition: all 0.35s ease;
}
.portflio-item:hover .portfolio-item-content {
  opacity: 1;
  bottom: 20px;
  right: 30px;
}
.portflio-item .portfolio-item-content {
  position: absolute;
  content: '';
  right: 0px;
  bottom: 0px;
  opacity: 0;
  transition: all 0.35s ease;
}

.portflio-item:before {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  transition: all 0.35s ease;
  overflow: hidden;
}

.portflio-item:hover:before {
  opacity: 1;
}

.portflio-item:hover .portfolio-item-content {
  opacity: 1;
  bottom: 20px;
  right: 30px;
}

.portflio-item .overlay-item {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80px;
  color: #f75757;
  opacity: 0;
  transition: all 0.35s ease;
}

.portflio-item:hover .overlay-item {
  opacity: 1;
}

.featured-item {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}
.featured-item:hover,
.featured-item.style-1 .featured-icon i,
.featured-item.style-2,
.featured-item.style-6 .featured-icon i {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}

.featured-item.style-2:hover,
.price-table:hover {
  -o-box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
  box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
}

/* ------------------------
    Featured Box
------------------------*/
.featured-item {
  padding: 40px 30px;
  position: relative;
  border-radius: 7px;
  overflow: hidden;
  z-index: 9;
}

.owl-carousel .featured-item {
  margin: 20px;
}

.featured-item .featured-icon {
  display: inline-block;
  position: relative;
}

.featured-desc p {
  margin-bottom: 0;
}

.featured-title h5 {
  margin: 30px 0 20px;
  text-transform: capitalize;
  font-weight: bold;
}

.dark-bg .featured-title h5,
.dark-bg .featured-desc {
  color: #ffffff;
}

.theme-bg .featured-title h5,
.theme-bg .featured-desc,
.theme-bg .featured-icon i {
  color: #ffffff;
}

.grey-bg .featured-desc p {
  color: #1c1d3e;
}

.featured-item.style-1 {
  box-shadow: none;
}
.featured-item .featured-icon i {
  color: #2575fc;
  display: inline-block;
  font-size: 50px;
  line-height: 50px;
}
.featured-item .featured-icon span {
  position: absolute;
  top: 50%;
  left: 50%;
}
.featured-item .featured-icon span::after,
.featured-item .featured-icon span::before {
  border-radius: 50%;
  content: '';
  height: 60px;
  position: absolute;
  width: 60px;
  z-index: -1;
}
.featured-item .featured-icon span::after {
  background: #cdf3f6;
  height: 40px;
  left: -10px;
  top: 5px;
  width: 40px;
}
.featured-item .featured-icon span::before {
  background: #d4f8e6;
  bottom: 0;
  right: -10px;
}
.featured-item::before {
  transform: scale(0);
  background: #005bea;
  background: linear-gradient(180deg, #005bea 0%, #2575fc 80%);
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: -1;
}
.featured-item:hover::before {
  transform: scale(1);
}
.featured-item:after {
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  opacity: 0;
  width: 100%;
  z-index: -1;
  background-size: cover;
}
.featured-item:hover:after {
  opacity: 0.2;
}
.featured-item:hover .featured-icon i,
.featured-item:hover h5,
.featured-item:hover p {
  color: #ffffff;
}
.featured-item.style-1:hover .featured-icon i,
.featured-item.style-2:hover .featured-icon i,
.featured-item.style-6:hover .featured-icon i {
  color: #005bea;
}
.featured-item.style-2:hover h5,
.featured-item.style-6:hover h5 {
  color: #1c1d3e;
}
.featured-item.style-2:hover p,
.featured-item.style-6:hover p {
  color: #5f5f5f;
}
.featured-item.style-2 {
  padding: 60px 30px;
  background: #ffffff;
  overflow: inherit;
}
.featured-item.style-2 .featured-icon i {
  font-size: 80px;
  line-height: 80px;
  z-index: 1;
  position: relative;
}
.featured-item.style-2:hover::before {
  display: none;
}
.featured-item.style-3 {
  background: #ffffff;
}
.featured-item.style-4 {
  background: #ffffff;
  border-radius: 70% 100% 30% 70%;
  overflow: hidden;
  padding: 14px 15px 35px 15px;
  z-index: 9;
}
.featured-item.style-4 .featured-title h5 {
  font-size: 1.2rem;
}
.featured-item.style-5 {
  padding-left: 160px;
  background: #ffffff;
}
.featured-item.style-5 .featured-icon {
  position: absolute;
  top: 40px;
  left: 30px;
}
.featured-item.style-5 h5 {
  margin-top: 0;
}
.featured-item.style-6 {
  padding: 0 0 0 100px;
  box-shadow: none;
  overflow: inherit;
}
.featured-item.style-6 h5 {
  margin-top: 0;
}
.featured-item.style-6::before,
.featured-item.style-6::after {
  display: none;
}
.featured-item.style-6 .featured-icon {
  position: absolute;
  top: 0;
  left: 0;
}
.featured-item.style-6 .featured-icon i {
  background: #ffffff;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 30px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}
.featured-item.style-1 .featured-icon i {
  background: #ffffff;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 30px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}
.featured-item.style-6 .featured-icon i::after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: '';
  top: 0;
  left: 0;
  z-index: 10;
  border: 2px dashed transparent;
}
.featured-item.style-6:hover .featured-icon i {
  background: none;
}
.featured-item.style-6:hover .featured-icon i::after {
  animation: rotateme 9s linear infinite;
  border-color: #2575fc;
}

.featured-item {
  transition: all 0.5s ease-in-out 0s;
}
.featured-item:after,
.featured-item::before {
  transition: all 0.5s ease-in-out 0s;
}

.featured-icon,
.featured-item .featured-icon i,
.price-table {
  transition: all 0.5s ease-in-out 0s;
}

/*--translate-XY--*/
.clear-loader,
.title-effect-2 i,
.featured-item .featured-icon span,
.blink-img,
.hexagon-bg.style-2,
.video-btn-pos,
.video-btn .spinner-eff {
  transform: translateX(-50%) translateY(-50%);
}

.featured-item {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}
.featured-item:hover,
.featured-item.style-1 .featured-icon i,
.featured-item.style-2,
.featured-item.style-6 .featured-icon i {
  -o-box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
  box-shadow: 0px 0px 15px 0px rgba(72, 73, 121, 0.15);
}

.featured-item.style-2:hover,
.price-table:hover {
  -o-box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
  box-shadow: 0px 0px 15px 0px rgba(153, 153, 153, 0.5);
}

.gutter {
  --bs-gutter-y: 1.5rem !important;
}

.fontweigth400 {
  font-weight: 400;
}
.mt63 {
  margin-top: 0px;
}
.swamiimg4 {
  height: auto;
  width: 160px;
}
.h3Amb {
  font-size: 27px;
  font-weight: 600;
  color: #fefefe;
}
.yenepoyaleft {
  left: -215px !important;
}
.yenepoyaleft:hover {
  left: 0px !important;
}
.bggreay {
  background: #bdbcc4;
}
.Ambfaq-img {
  width: 44% !important;
}
.Ambfaq-sec {
  width: 56% !important;
}

.bds-position {
  position: relative;
  right: 320px;
  top: 294px;
}
@media only screen and (max-width: 400px) {
  .mbbscard1 {
    background-color: #cce1ed;
    min-height: 137px;
  }
}

.custom-card {
  line-height: 2;
}

.Ambblueh3 {
  font-size: 35px !important;
  font-weight: 700;
  font-weight: 700;
}

.ambmain-margin {
  margin-top: auto;
}

.paddingamb-icon {
  padding: 0px 171px;
}

.talkdiv-mbd {
  max-width: 344px;
  margin: auto;
}

.mabbsorangen {
  color: #ff8e08;
  font-size: 27px;
}

.bams-height-college {
  min-height: 168px !important;
}

.bamsimg4 {
  height: 170px;
  width: 170px;
}

.mbbdbtnpadding {
  background-color: #ff8902 !important;
}
.mbbdbtnpadding :hover {
  background-color: #00b4f6 !important;
}

@media only screen and (max-width: 600px) {
  .padding-top-homo .inside-homo{
    padding-top: 46px !important;
  }

  .padding-top-homo{
    padding-top: 81px !important;
  }

  .notificationCard ul {
    /* list-style-type: none; */
    min-height: auto !important;
    }
    
    .center {
      display: block;
      margin-left: auto;
     
      margin-right: auto;
      width: 50%;
    }
    
  
  
  
  .notificationCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.123);
    border-radius: 20px;
    height: auto;
  }

  .Homoeopathicscopecustomborder {
    border: 2px solid #f1eaea;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

.h1-yenepoya {
  margin: 0;
  font-size: 19px;
  font-weight: 700;
  color: #e6ff00;
  letter-spacing: 1px;
}

  .h1yenepoya {
    margin: 0;
    font-size: 35px;
    font-weight: 700;
    color: #000000;
    letter-spacing: 1px;
  }

  .h99{
    min-height: auto !important;
  }

  .bds-bgimage {
    min-height: 160px;
    padding: 0px !important;
    width: 100%;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }


  .bds-font30 {
    font-size: 23px !important;
    color: white;
    background: #0000003d;
  }

  .bds-add-h2 {
    text-align: center;
    font-weight: 800;
    font-size: 32px !important;
    color: rgb(255, 255, 255);
  }

  .bdsktable-header th {
    font-size: 15px !important;
  }
  .bdsktable-row td {
    padding: 0px !important;
    font-size: 14px !important;
  }

  .min-width-tableheading {
    width: 100px !important;
  }
  .bams-height-college {
    min-height: auto !important;
  }
  .mbbdbtnpadding {
    padding: 3px 10px !important;
    background-color: #ff8902 !important;
  }
  .talkdiv-mbd {
    max-width: 207px;
    margin: auto;
  }
  .paddingamb-icon {
    padding: 0px 16px;
  }
  .ambmain-margin {
    margin-top: 153px;
  }

  .missionimage {
    min-height: 115px !important;
    width: 100%;
    background-position: top !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
  }
  .bamsimage-homo {
    /* background-size: contain !important; */
    background-position: top !important;
  }
  .f3bds-addmission {
    color: #25427c;
    font-weight: 700;
    font-size: 30px;
  }
  .f3yenepoya {
    color: #013d0c;
    font-weight: 700;
    font-size: 25px;
  }

  .Ambfaq-img {
    width: 100% !important;
  }
  .Ambfaq-sec {
    width: 100% !important;
  }

  .btnmbbs {
    width: 200px !important;
    background: #ff8902 !important;
    color: #ffffff;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 13px !important;
    line-height: 33px !important;
    border: 0px !important;
    padding: 2px 1px !important;
  }

  .h1amb .span {
    color: #003d7a;
    display: block;
    font-size: 27px;
  }
  .h3Amb {
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
  }
  .divfont {
    font-size: 18px !important;
    font-weight: 600;
  }

  .swamiimg4 {
    height: auto;
    width: 130px;
  }

  .featured-item.style-4 .featured-title h5 {
    margin: 0px !important;
  }
  .mt63 {
    margin-top: 63px;
  }
  .featured-item .card-img-top {
    height: 100px !important;
  }
  .bdsscope-add .d-flex img {
    height: auto;
    width: 130px;
  }
  .btn-bds-add {
    font-size: 17px !important;
  }
  .bdscollegeimage img {
    height: 156px;
    width: 353px;
  }

  .bamscolllege .h100 {
    min-height: auto !important;
  }

  .bds-table {
    border: 1px solid black;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
  .bdssimpletable {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
  .customberder-mbbs-admission ul {
    min-height: auto;
    margin-bottom: 10px;
    line-height: 2;
    padding-bottom: 24px;
  }
}

.slide-in {
  animation: slideIn 1s ease-out;
  animation-delay: 2s;
}
.slide-in2 {
  animation: slideIn2 1s ease-out;
  animation-delay: 2s;
}

@keyframes slideIn2 {
  from {
    transform: translateX(+100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.Ambimgfooter {
  height: auto;
  width: 135px;
}

.btnmbbs {
  width: 133px;
  background: #ff8902 !important;
  color: #ffffff;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 37px;
  border: 0px;
  padding: 0px 0px;
}

.cta-banner {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    /* background: #9a1c1c;
     */
     background-color: #1d415a;
    padding: 25px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto !important;
    border-radius: 30px;
    width: 900px;
  }
  .cta-banner .cta-banner__text {
    font-weight: 500;
    font-size: 18px;
    line-height: 170%;
    color: white;
  }
  .single #mega-container #content .main-content-wrap article a {
    color: #9c2443;
  }
.cta-banner .red.flat.button {
    border-radius: 38px;
    line-height: 40px !important;
    box-shadow: none;
    letter-spacing: normal !important;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    font-size: 14px !important;
    font-weight: bold;
  }
  .red.flat.button {
    color: #fff !important;
    text-decoration: none;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.16);
    background: #9a1c1c;
    border-color: transparent;
    text-transform: uppercase;
    font-weight: 500;
  }
  .red.flat.button {
    font-family: Gotham SSm A, Gotham SSm B;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 34px;
    display: inline-block;
    vertical-align: top;
    padding: 0 2em;
    width: auto;
    position: relative;
    z-index: 1;
    text-align: center;
    text-transform: uppercase;
    border: 1px solid;
    border-radius: 3px;
    overflow: hidden;
    white-space: nowrap;
  }
  
@media only screen and (max-width:900px)
{
    .cta-banner
    {
          width:600px;
          
         
    }
}
@media only screen and (max-width:390px)
{
   .red.flat.button{
    max-width:350px;
    width:100%;
    text-align: center;
   }
}

.bsc-text-align
{ 
   /* max-width:70%; */
  margin: 0 auto;
  width:100%;
  padding-top: 25px; 
  text-align: center;
}

.bsc-nurs .right-sec {
  position: fixed;
    transform: rotate(0deg);
    left: -120px;
    top: 38%;
    z-index: 10;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    transition: all 0.9s ease;
    transform-origin: bottom;
}

/* Custom background colors */
.custom-striped-container {
  width: 100%;
  font-size: 1.125rem; /* Equivalent to text-lg in Tailwind */
}

.custom-striped-item {
  padding: 1rem; /* Equivalent to p-4 in Tailwind */
  margin-bottom: 0.5rem; /* Add spacing between items */
  border-radius: 0.5rem; /* Rounded corners for a sleek look */
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); /* Shadow effect */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth hover effect */
}

.custom-striped-item:hover {
  transform: translateY(-2px); /* Slight lift on hover */
  box-shadow: 0 6px 12px -1px rgba(0, 0, 0, 0.15), 0 4px 6px -1px rgba(0, 0, 0, 0.1); /* Enhanced shadow on hover */
}

/* Alternating background colors */
.custom-striped-item:nth-child(odd) {
  background-color: #f0f4ff; /* Light blue for odd items */
}

.custom-striped-item:nth-child(even) {
  background-color: #e3f2fd; /* Slightly darker blue for even items */
}

/* Custom text color */
.custom-striped-item {
  color: #1e3a8a; /* Dark blue text for contrast */
}
.h1all {
    margin: 0;
    font-size: 41px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 1px;
  }
  .cblack{
    color: #f3ff43;
  }

  .fh3allcourses{
    color:rgb(195 31 31);
    font-size: 33px !important;
  }
.font-size23{
    font-size: 23px !important;
    
}
.font-white23{
    font-size: 21px !important;
    color: white;
    
}
.font40{
    font-size: 40px !important;
}
.allcourses .card{
    width: 100%;
}
.custompaddingall{
  /* padding-left: 38px; */
    padding-top: 10px;
    float: right;
}
@media only screen and (max-width: 600px) {
    .h1all {
        margin: 0;
        font-size: 33px;
        font-weight: 700;
        color: #ffffff;
        letter-spacing: 1px;
      }
      .font40{
        font-size: 25px !important;
    }
    .font-white23{
      font-size: 18px !important;
      color: white;
      
  }
  .testimonials .testi-item-inner{
    padding: 0px;
  }
  }


