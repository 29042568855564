.sh-container {
  max-width: 480px;
  margin: 0 auto;
}
@media (min-width: 480px)
{
.sh-page {
    padding: 60px 80px 30px;
    background: #fff !important;
    height: auto !important;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-box-shadow: 0px 10px 14.1px 0.9px rgb(0 0 0 / 24%), 0px 4px 19.6px 0.4px rgb(0 0 0 / 16%);
    -moz-box-shadow: 0px 10px 14.1px 0.9px rgba(0, 0, 0, 0.24), 0px 4px 19.6px 0.4px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 10px 14.1px 0.9px rgb(0 0 0 / 24%), 0px 4px 19.6px 0.4px rgb(0 0 0 / 16%);
}}

.sh-page {
  margin: 0;
  padding: 0 20px 20px;
  text-align: center;
  position: relative;
}

.sh-store-link {
  padding: 0;
  cursor: pointer;text-decoration: none;
  color: #323032;
  -moz-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  outline: none;
}





