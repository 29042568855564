.cta-banner {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    /* background: #9a1c1c;
     */
     background-color: #1d415a;
    padding: 25px 50px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: auto !important;
    border-radius: 30px;
    width: 900px;
  }
  .cta-banner .cta-banner__text {
    font-weight: 500;
    font-size: 18px;
    line-height: 170%;
    color: white;
  }
  .single #mega-container #content .main-content-wrap article a {
    color: #9c2443;
  }
.cta-banner .red.flat.button {
    border-radius: 38px;
    line-height: 40px !important;
    box-shadow: none;
    letter-spacing: normal !important;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    font-size: 14px !important;
    font-weight: bold;
  }
  .red.flat.button {
    color: #fff !important;
    text-decoration: none;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.16);
    background: #9a1c1c;
    border-color: transparent;
    text-transform: uppercase;
    font-weight: 500;
  }
  .red.flat.button {
    font-family: Gotham SSm A, Gotham SSm B;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 34px;
    display: inline-block;
    vertical-align: top;
    padding: 0 2em;
    width: auto;
    position: relative;
    z-index: 1;
    text-align: center;
    text-transform: uppercase;
    border: 1px solid;
    border-radius: 3px;
    overflow: hidden;
    white-space: nowrap;
  }
  
@media only screen and (max-width:900px)
{
    .cta-banner
    {
          width:600px;
          
         
    }
}
@media only screen and (max-width:390px)
{
   .red.flat.button{
    max-width:350px;
    width:100%;
    text-align: center;
   }
}

.bsc-text-align
{ 
   /* max-width:70%; */
  margin: 0 auto;
  width:100%;
  padding-top: 25px; 
  text-align: center;
}

.bsc-nurs .right-sec {
  position: fixed;
    transform: rotate(0deg);
    left: -120px;
    top: 38%;
    z-index: 10;
    display: flex;
    gap: 10px;
    transition: all 0.9s ease;
    transform-origin: bottom;
}

/* Custom background colors */
.custom-striped-container {
  width: 100%;
  font-size: 1.125rem; /* Equivalent to text-lg in Tailwind */
}

.custom-striped-item {
  padding: 1rem; /* Equivalent to p-4 in Tailwind */
  margin-bottom: 0.5rem; /* Add spacing between items */
  border-radius: 0.5rem; /* Rounded corners for a sleek look */
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); /* Shadow effect */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth hover effect */
}

.custom-striped-item:hover {
  transform: translateY(-2px); /* Slight lift on hover */
  box-shadow: 0 6px 12px -1px rgba(0, 0, 0, 0.15), 0 4px 6px -1px rgba(0, 0, 0, 0.1); /* Enhanced shadow on hover */
}

/* Alternating background colors */
.custom-striped-item:nth-child(odd) {
  background-color: #f0f4ff; /* Light blue for odd items */
}

.custom-striped-item:nth-child(even) {
  background-color: #e3f2fd; /* Slightly darker blue for even items */
}

/* Custom text color */
.custom-striped-item {
  color: #1e3a8a; /* Dark blue text for contrast */
}